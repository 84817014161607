
/* global BigInt */

import React, { createContext, useContext, useState } from "react";
import { AuthClient } from "@dfinity/auth-client";
// import { gatewayUrl, icUrl } from "./ws.config.js";
// import { canisterId, pleximail_backend/*, createActor*/ } from "../declarations/pleximail_backend";
// import { /*canisterId, pleximail_backend,*/ createActor } from "../declarations/pleximail_identity";

import {PlexiMailService} from "./PlexiMailService.js";
import { initializePlexiMailServiceClient, intializeIndexClient } from "./client.js";
import { PlexiIdentityService } from "./PlexiIdentityService.js";
import { createIdentityActor } from "./actor.js";
import { SetupWizardState } from "../utils/Types.js";
import ContractBackupService from "../components/contract/ContractBackupService.js";


// const indexClient = intializeIndexClient(true);
// const plexiMailServiceClient = initializePlexiMailServiceClient(true, indexClient);

const authClient = await AuthClient.create({idleOptions: {disableIdle: true}});


const initialContext = {
  identity: null,
  isAuthenticated: false,
  plexiMailService: null,
  login: () => {
    throw new Error("login function must be overridden");
  },
  logout: async () => {
    throw new Error("logout function must be overridden");
  },
  checkAuth: () => {
    throw new Error("checkAuth function must be overridden");
  },
};

const ContextWrapper = createContext(initialContext);

export const useAuth = () => {
  return useContext(ContextWrapper);
};

const ICPAuthProvider = ({ children }) => {
  const [identity, setIdentity] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [plexiMailService, setPlexiMailService] = useState(null);
  const [plexiIdentityService, setPlexiIdentityService] = useState(null);
// console.log(process.env);
  
  const login = async () => {

    const result = {
      promise: null,
      resolve: null,
      reject: null
    };

    result.promise = new Promise((resolve, reject) => {
      result.resolve = resolve;
      result.reject = reject;
    });

    await authClient.login({
      maxTimeToLive: BigInt(196) * BigInt('3600000000000'),
      identityProvider: process.env.DFX_NETWORK === 'ic'
        ? 'https://identity.ic0.app/#authorize'
        : `http://${process.env.CANISTER_ID_INTERNET_IDENTITY}.localhost:4943/#authorize`,
    // `http://localhost:8000?canisterId=${process.env.INTERNET_IDENTITY_CANISTER_ID}#authorize`,
      windowOpenerFeatures: `left=${window.screen.width / 2 - 525 / 2}, top=${window.screen.height / 2 - 705 / 2},toolbar=0,location=0,menubar=0,width=550,height=705`,
      onSuccess: () => {
        checkAuth(true).then(() => {
          result.resolve();
        }).catch(e => {
          result.reject(e);
        })
      },
      onError: (err) => {
        result.reject(err);
      },
    });
    
    let oldPrincipal = localStorage.getItem('app.recent-ii');

    await result.promise;

    if (await authClient.isAuthenticated()) {
      const _identity = authClient.getIdentity();
      setIdentity(_identity);

      const principal = _identity.getPrincipal().toString();

      
      if (window.appConfig.setupWizardState !== SetupWizardState.ConnectWallet && oldPrincipal && oldPrincipal.length > 0 && oldPrincipal !== principal) {
        await logout();
        throw new Error('Internet Identity Mismatch.');
      }
    }
  };

  const checkAuth = async (createIfNeeded=false) => {
    try {
      if (await authClient.isAuthenticated()) {

        setIsAuthenticated(true);

        const _identity = authClient.getIdentity();
        setIdentity(_identity);

        const principal = _identity.getPrincipal().toString();
        const group = window.appConfig.domainName;// "ai-fi.cc";
        
        // // set backend actor
        // const _backendActor = createActor({
        //   agentOptions: {
        //     identity: _identity,
        //   },
        // });


        
        // // set websocket client
        // const _ws = new IcWebSocket(gatewayUrl, undefined, {
        //   canisterId: canisterId,
        //   canisterActor: pleximail_backend,
        //   identity: _identity,
        //   networkUrl: icUrl,
        // });
        // setWs(_ws);
        let isLocal = process.env.DFX_NETWORK === 'local';
        const indexClient = intializeIndexClient(isLocal, _identity);
        const plexiMailServiceClient = initializePlexiMailServiceClient(isLocal, _identity, indexClient);

        // const group = "ai-fi.cc";
        
        const _plexiMailService = new PlexiMailService(principal, group, indexClient, plexiMailServiceClient);
        if (createIfNeeded) {
          try {
            await _plexiMailService.createCanisterByGroupIfNeeded();
          } catch (e) {
            console.error(e);
            throw e;
          }
        }

        setPlexiMailService(_plexiMailService);

        window.plexiMailService = _plexiMailService;
        localStorage.setItem('app.recent-ii', _plexiMailService.getPrincipal());
        // window.icpWs = _ws;
        // handleWs(_ws);

        // window.icpWsOpts = {
        //   url: gatewayUrl, 
        //   protocols: null, 
        //   config: {
        //     canisterId: canisterId,
        //     canisterActor: pleximail_backend,
        //     identity: _identity,
        //     networkUrl: icUrl,
        //   }
        // };
        
        const identityActor = createIdentityActor(process.env.CANISTER_ID_PLEXIMAIL_IDENTITY, {
          agentOptions: {
            identity: _identity,
          },
        });
        const _plexiIdentityService =  new PlexiIdentityService(identityActor);
        setPlexiIdentityService(_plexiIdentityService);
        window.plexiIdentityService = _plexiIdentityService;
        const _contractBackupService = new ContractBackupService();
        window.contractBackupService = _contractBackupService;
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error in checkAuth", error);
      return false;
    }
  };

  const logout = async () => {
    console.log('---- Log out Internet Identity');
    await authClient.logout();
    setIsAuthenticated(false);
    setIdentity(null);
  };

  return (
    <ContextWrapper.Provider
      value={{
        identity,
        isAuthenticated,
        plexiMailService,
        login,
        logout,
        checkAuth,
      }}
    >
      {children}
    </ContextWrapper.Provider>
  );
};

export default ICPAuthProvider;
