import EthAccountsHandler from "./EthAccountsHandler";
import EthCallHandler from "./EthCallHandler";
import EthDecryptHandler from "./EthDecryptHandler";
import EthGetBalanceHandler from "./EthGetBalanceHandler";
import EthGetEncryptionPublicKeyHandler from "./EthGetEncryptionPublicKeyHandler";
import EthRequestAccountsHandler from "./EthRequestAccountsHandler";
import EthSendTransactionHandler from "./EthSendTransactionHandler";
import EthSignHandler from "./EthSignHandler";
import WalletAddEthereumChainHandler from "./WalletAddEthereumChainHandler";
import WalletGetPermissionsHandler from "./WalletGetPermissionsHandler";
import WalletRegisterOnboardingHandler from "./WalletRegisterOnboardingHandler";
import WalletRequestPermissionsHandler from "./WalletRequestPermissionsHandler";
import WalletSwitchEthereumChainHandler from "./WalletSwitchEthereumChainHandler";
import WalletWatchAssetHandler from "./WalletWatchAssetHandler";
import PersonalSignHandler from './PersonalSignHandler';
// import EthChainIdHandler from "./EthChainIdHandler";
import NetVersionHandler from "./NetVersionHandler";
// import EthBlockNumberHandler from "./EthBlockNumberHandler";

import AFEncryptMnemonicHandler from './AFEncryptMnemonicHandler';
import AFCryptoServiceHandler from './AFCryptoServiceHandler';

const RPCHandlers = {
    // Ethereum JSON-RPC Methods
    eth_accounts: new EthAccountsHandler(),
    // eth_call: new EthCallHandler(),
    // eth_getBalance: new EthGetBalanceHandler(),
    eth_sendTransaction: new EthSendTransactionHandler(),
    eth_sign: new EthSignHandler(),
    // eth_chainId: new EthChainIdHandler(),
    // eth_blockNumber: new EthBlockNumberHandler(),
    net_version: new NetVersionHandler(),
    // Restricted Methods
    eth_requestAccounts: new EthRequestAccountsHandler(),
    wallet_getPermissions: new WalletGetPermissionsHandler(),
    wallet_requestPermissions: new WalletRequestPermissionsHandler(),
    // Unrestricted Methods
    eth_decrypt: new EthDecryptHandler(),
    eth_getEncryptionPublicKey: new EthGetEncryptionPublicKeyHandler(),
    wallet_addEthereumChain: new WalletAddEthereumChainHandler(),
    wallet_switchEthereumChain: new WalletSwitchEthereumChainHandler(),
    wallet_registerOnboarding: new WalletRegisterOnboardingHandler(),
    wallet_watchAsset: new WalletWatchAssetHandler(),
    personal_sign: new PersonalSignHandler(),
    // Ai-Fi 
    af_encryptMnemonic: new AFEncryptMnemonicHandler(),
    af_cryptoService: new AFCryptoServiceHandler()
}

export default RPCHandlers;