

// import { ethers } from 'hardhat';
import localforage from 'localforage';
import { ethers } from 'ethers';

import React from 'react'
import AFWalletRPCProvider from './AFWalletRPCProvider';
import { WalletUIStatus } from './AFWalletUI';
import { EthereumChains } from '../utils/Types';
import AFWalletRPCError from './AFWalletRPCError';

import { HDKey } from "ethereum-cryptography/hdkey";
// import { wordlist } from "ethereum-cryptography/bip39/wordlists/english";
import * as bip39 from "ethereum-cryptography/bip39";
import { keys } from 'libp2p-crypto';

import plexMailRootRegistry from '../PlexiMailRootRegistryABI.json';
import BackupService from './service/BackupService';
import { ClientError } from '../common/errors';

import * as CurrencyUtils from '../utils/CurrencyUtils';
// import * as TorUtils from '../utils/TorUtils';

import WalletStrings from './WalletStrings';
import AppConfig from '../config/AppConfig'
const fromHexString = (hexString) => {
    return Uint8Array.from(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));
}

export default class AFWallet {
    static #store_key_prefix = 'afwallet.';
    static #store_keys = {
        wallet: AFWallet.#store_key_prefix + 'wallet',
        rpcProviderType: AFWallet.#store_key_prefix + 'rpcProviderType',
        password: AFWallet.#store_key_prefix + 'password',
        isBackup: AFWallet.#store_key_prefix + 'isBackup',
        accounts: AFWallet.#store_key_prefix + 'accounts',
    };
    static RPCProviderType = {
        Unset: 0,
        AFWallet: 1,
        Other: 2,
    };
    static counter = 0;

    #isConnected = false;
    #rpcProvider = null;
    disclosure = null;
    setStatus = null;
    setVersion = null;
    #version = 0;
    #store = null;
    #inMemoryStore = {};

    #accounts = [];
    #wallet = null;
    #ethersProviders = null;
    #signers = null;

    instanceNo = 0;

    #pendingRequest = null;
    // #requestCounter = 0;
    #abi = null;
    #hdkey = null;

    #backupService = null;

    #pin = null;
    #setPin(pin) {
        if (window.appConfig.isConfigured) {
            this.#pin = pin;
        } else {
            if (window.appConfig.privacyLevelIsNormal) {
                sessionStorage.setItem(AFWallet.#store_keys.password, pin);
            } else {
                this.#pin = pin;
            }
        }
    }

    #getPin() {
        if (window.appConfig.isConfigured) {
            return this.#pin;
        } else {
            if (window.appConfig.privacyLevelIsNormal) {
                return sessionStorage.getItem(AFWallet.#store_keys.password);
            } else {
                return this.#pin;
            }
        }
    }

    removePinFromSessionStorageIfNeeded() {
        
        if (!this.#pin || this.#pin.length === 0) {
            const pin = sessionStorage.getItem(AFWallet.#store_keys.password);
            if (pin && pin.length > 0) {
                this.#pin = pin;
            }
        }
        // this.#pin = this.#getPin();
        if (window.appConfig.privacyLevelIsNormal) {
            sessionStorage.removeItem(AFWallet.#store_keys.password);
        }
    }

    get rpcProvider() {
        return this.#rpcProvider;
    }

    get store() {
        return this.#store;
    }
    get accounts() {
        return this.#accounts;
    }
    get activatedWallet() {
        return this.#wallet;
    }
    get getActivatedWallet() {
        if (this.accounts && this.accounts.length > 0) {
            return {name: 'Main', address: this.accounts[0]};
        }
        return null;
    }
    get pendingRequest() {
        return this.#pendingRequest ? this.#pendingRequest.request : null;
    }
    get address() {
        return this.#wallet ? this.#wallet.address : null;
    }

    async isConfigured() {
        const walletJSON = await this.#getItem(AFWallet.#store_keys.wallet);
        return (walletJSON !== null && walletJSON.length !== 0);
    }

    isUnlocked() {
        return this.#wallet !== null;
    }

    async isReady() {
        const isConf = await this.isConfigured();
        const isUnlocked = this.isUnlocked();
        return isConf && isUnlocked;
    }

    async getWalletList() {
        if (!this.#wallet) {
            // const password = sessionStorage.getItem(AFWallet.#store_keys.password);
            // const password = await this.#getItem(AFWallet.#store_keys.password);
            const password = this.#getPin();
            if (!password || password.length === 0) {
                return [];
            }
            const walletJSON = await this.#getItem(AFWallet.#store_keys.wallet);
            const wallet = await ethers.Wallet.fromEncryptedJson(walletJSON, password);
            this.#wallet = wallet;
        }
        if (!this.#wallet) {
            return [];
        }
        return [{name: 'Main', address: this.#wallet.address, selected: true, activated: true}];
    }
    
    async getOrderedSelectedAddresses() {
        if (!this.#wallet) {
            // const password = sessionStorage.getItem(AFWallet.#store_keys.password);
            // const password = await this.#getItem(AFWallet.#store_keys.password);
            const password = this.#getPin();
            if (!password || password.length === 0) {
                return [];
            }
            const walletJSON = await this.#getItem(AFWallet.#store_keys.wallet);
            const wallet = await ethers.Wallet.fromEncryptedJson(walletJSON, password);
            this.#wallet = wallet;
        }
        if (!this.#wallet) {
            return [];
        }
        return [this.#wallet.address];
    }

    #rpcProviderType = 0;
    async setRpcProviderType(type) {
        // await this.#setItem(AFWallet.#store_keys.rpcProviderType, type);
        if (window.appConfig.privacyLevelIsNormal) {
            localStorage.setItem(AFWallet.#store_keys.rpcProviderType, type);
        }
        this.#rpcProviderType = type;
    }
    get asDefaultWallet() {
        return this.rpcProviderType() === AFWallet.RPCProviderType.AFWallet;
    }

    rpcProviderType() {
        if (this.#rpcProviderType !== AFWallet.RPCProviderType.Unset) {
            return this.#rpcProviderType;
        }
        if (!window.appConfig.privacyLevelIsNormal) {
            return this.#rpcProviderType;
        }
        
        const type = localStorage.getItem(AFWallet.#store_keys.rpcProviderType) || '';
        const typeNumber = type === '' ? AFWallet.RPCProviderType.Unset : parseInt(type);
        this.#rpcProviderType = typeNumber;
        return typeNumber;

        // if (this.#rpcProviderType === 0) {
        //     const type = await this.#getItem(AFWallet.#store_keys.rpcProviderType);
        //     if (type === AFWallet.RPCProviderType.AFWallet) {
        //         const isReady = await this.isConfigured();
        //         if (!isReady) {
        //             return AFWallet.RPCProviderType.Unset;
        //         }
        //     }
        //     this.#rpcProviderType = (type || AFWallet.RPCProviderType.Unset);
        // }
        // return this.#rpcProviderType;
    }

    get network() {
        if (window.chainId === EthereumChains.Mainnet) {
            return 'mainnet';
        } else if (window.chainId === EthereumChains.Goerli) {
            return 'goerli';
        } else if (window.chainId === EthereumChains.Sepolia) {
            return 'sepolia';
        } else if (window.chainId === EthereumChains.Fuji) {
            return 'fuji';
        } else if (window.chainId === EthereumChains.Avalanche) {
            return 'avalanche';
        }
        throw AFWalletRPCError.chainIDNotSupported;
    }

    get etherProvider() {
        return this.#ethersProviders[this.network];
    }
    get signer() {
        return this.#signers[this.network];
    }

    get backupService() {
        return this.#backupService;
    }
    
    constructor(disclosure, setStatus, setVersion) {
        this.#rpcProvider = new AFWalletRPCProvider(this);
        this.disclosure = disclosure;
        this.setStatus = setStatus;
        this.setVersion = setVersion;
        this.#backupService = new BackupService();
        const store = localforage.createInstance({
            driver      : localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
            name        : 'afwallet',
            version     : 3,
            // size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
            storeName   : 'wallet', // Should be alphanumeric, with underscores.
            description : 'data of wallet'
        });
        // localforage.config({
        //     driver      : localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
        //     name        : 'afwallet',
        //     version     : 3,
        //     // size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
        //     storeName   : 'wallet', // Should be alphanumeric, with underscores.
        //     description : 'data of wallet'
        // });
        // const store = localforage;
        this.#store = store;

        const goerliEthersProvider = new ethers.InfuraProvider('goerli', '3755e24b21be49618434ff544763d7ce');
        const sepoliaEthersProvider = new ethers.InfuraProvider('sepolia', '3755e24b21be49618434ff544763d7ce');
        // const mainEthersProvider = new ethers.InfuraProvider('mainnet', '3755e24b21be49618434ff544763d7ce');
        const fujiEthersProvider = new ethers.JsonRpcProvider('https://api.avax-test.network/ext/bc/C/rpc');
        const avalancheEthersProvider = new ethers.JsonRpcProvider('https://api.avax.network/ext/bc/C/rpc');
        this.#ethersProviders = {
            // mainnet: mainEthersProvider,
            sepolia: sepoliaEthersProvider,
            goerli: goerliEthersProvider,
            fuji: fujiEthersProvider,
            avalanche: avalancheEthersProvider,
            mainnet: avalancheEthersProvider,
        };
        this.instanceNo = AFWallet.counter++;
        console.log('AFWallet - constructor: ', AFWallet.counter);
        this.#abi = ethers.Interface.from(plexMailRootRegistry.abi);
    }

    async #mnemonicToSeed(mnemonic) {
        return bip39.mnemonicToSeed(mnemonic)
    }
    

    async #hdKeyFromMnemonic(mnemonic) {
        const seed = await this.#mnemonicToSeed(mnemonic.phrase || mnemonic);
        const promise = new Promise((resolve, reject) => {
            try {
                const hdkey = HDKey.fromMasterSeed(seed);
                resolve(hdkey);
            } catch (e) {   
                reject(e);
            }
        });
        return promise;
    }
    async changePINCode(pinCode, reload=true) {
        const self = this;
        const json = await this.#wallet.encrypt(pinCode);

        await self.#setItem(AFWallet.#store_keys.wallet, json);
        // sessionStorage.setItem(AFWallet.#store_keys.password, pinCode);
        this.#setPin(pinCode);
        
        if (reload) {
            // await self.#setItem(AFWallet.#store_keys.isBackup, false);
            window.forceQuit = true;
            // window.location.reload();
            setTimeout(function(){
                window.location.reload(true);
            });
        }
    }
    
    async migrate(address, eth=null) {

        const balance = (eth !== null) ? ethers.parseEther(eth) : await this.etherProvider.getBalance(this.#accounts[0]);

        // const wei = ethers.parseEther("0.2");
        let tx = {
            to: address,
            // Convert currency unit from ether to wei
            value: balance,
            // type: 2,
            // type: 1,
            // gasLimit: "0x100000",// ethers.utils.hexlify(10000000000), // 100000
            // gasLimit: ethers.utils.hexlify(30000000),
            // gasPrice: gasPriceInHex,
        };

        if (!eth) {
            const estimateGas = await this.etherProvider.estimateGas(tx)

            const gasPriceHex = await this.etherProvider.send('eth_gasPrice', []);
            // const gasPriceInt = parseInt(gasPriceHex, 16);
            // const gasPrice = 
            //const gasPrice = await this.etherProvider.getGasPrice();
            const gasPrice = ethers.toBigInt(gasPriceHex);
            // const gasPrice = ethers.BigInt(gasPriceHex);
            const estimateTxFee = gasPrice * estimateGas * ethers.toBigInt('2');
            const BN = balance - estimateTxFee;

            tx.value = BN;
        }
        const txResp = await this.signer.sendTransaction(tx);
        if (txResp) {}
        this.disclosure.onClose()
    }

    async showUI(status = WalletUIStatus.Wallet) {
        const self = this;
        self.setVersion(++self.#version);
        self.setStatus(status);
        self.disclosure.onOpen();
    }

    async showSwitchWallet() {
        await this.showUI(WalletUIStatus.SwitchWallet);
    }
    
    
    async continueProcessingConnectRequest() {
        const self = this;
        self.setVersion(++self.#version);
        self.setStatus(WalletUIStatus.Connect);
    }

    async isBackup() {
        const self = this;
        const res = self.#getItem(AFWallet.#store_keys.isBackup) || false;
        return res;
    }

    async dropBackup() {
        await this.#setItem(AFWallet.#store_keys.isBackup, false);
    }
    async showChangeWeb3StorageAPIToken() {
        await this.showUI(WalletUIStatus.ChangeWeb3StorageAPIToken);
    }

    async showBackup(message) {
        const self = this;

        const cfg = window.appConfig;
        if (cfg 
            && cfg.setupFromRecovery
            && (cfg.cryptonEntropySalt && cfg.cryptonEntropySalt.length > 0)
            && (cfg.cryptonPassphrase && cfg.cryptonPassphrase.length > 0)
            && (cfg.web3StorageApiToken && cfg.web3StorageApiToken.length > 0)) {
            await self.backup(cfg.cryptonEntropySalt, cfg.cryptonPassphrase, cfg.web3StorageApiToken, true, false);
            return;
        }

        self.pendingBackup = null;
        
        const promise = new Promise((resolve, reject) => {
            self.pendingBackup = {resolve, reject, message: message || null};
            self.showUI(WalletUIStatus.Backup).then(() => {}).catch(e => {
                console.error(e);
            });

        });
        return promise;
    }

    async backup(salt, password, apiToken, force=false, local=false) {
        const self = this;
        
        // const wallet = await self.#getItem(AFWallet.#store_keys.wallet);
        const mnemonic = this.#wallet.mnemonic;

        try {
            const pinCode = this.#getPin();
            const privacyLevel = window.appConfig.privacyLevel;

            await this.#backupService.backup(salt, password, mnemonic.phrase, apiToken, pinCode, privacyLevel, force, local);
            await self.#setItem(AFWallet.#store_keys.isBackup, true);
            // if (self.pendingBackup) {
            //     self.pendingBackup.resolve();
            //     self.pendingBackup = null;
            // }
        } catch (e) {
            console.error(e);
            // if (self.pendingBackup) {
            //     self.pendingBackup.reject(e);
            //     self.pendingBackup = null;
            // }
            throw e;
        }
        // backup(salt, password, wallet, apiToken, force=false, local=false) {
    
    }

    async recover(salt, password, token=null) {

        const self = this;

        const cryptonToken = await self.#backupService.recover(salt, password, token);
        if (!cryptonToken) {
            throw ClientError.cryptonExistedError(WalletStrings.ui.config.recovery.token_not_exists)
        }
        const {wallet, apiToken, chainId, privacyLevel, pinCode} = cryptonToken;

        if (window.isOneClickResume) {
            if (window.torInfo && window.torInfo.isIndexedDBSupported) {
                if (privacyLevel) {    
                    window.appConfig.privacyLevel = privacyLevel;
                } else {
                    window.appConfig.privacyLevel = AppConfig.PrivacyLevel.Normal;
                }
            } else {
                window.appConfig.privacyLevel = AppConfig.PrivacyLevel.High;
            }
        }

        // self.#wallet = await ethers.Wallet.fromPhrase(wallet, password);
        const newWallet = await ethers.Wallet.fromPhrase(wallet);
        self.#wallet = newWallet;
        await self.#commonSetup(self.#wallet);

        window.appConfig.recentActiveAccount = self.#wallet.address.toLowerCase();
        window.appConfig.web3StorageApiToken = apiToken;
        
        if (chainId && chainId.length > 0) {
            window.appConfig.chainId = chainId;
            window.appConfig.recentChainId = chainId;
        } else {
            window.appConfig.chainId = EthereumChains.Fuji;
            window.appConfig.recentChainId = EthereumChains.Fuji;
        }
        window.appConfig.cryptonEntropySalt = salt;
        window.appConfig.cryptonPassphrase = password;
        window.appConfig.setupFromRecovery = true;
        

        await self.#setItem(AFWallet.#store_keys.isBackup, true);

        if (window.isOneClickResume && (pinCode && pinCode.length > 0)) {
            
            await self.setRpcProviderType(AFWallet.RPCProviderType.AFWallet);
            await self.changePINCode(pinCode, false);

            const accounts = await self.accountsInWallet();
            await self.onConnected(accounts, null);
            self.disclosure.onClose();
        } else {
            self.setVersion(++self.#version);
            self.setStatus(WalletUIStatus.ResetPINCode);
        }

        // await self.#setItem(AFWallet.#store_keys.wallet, wallet);
        // const walletJSON = JSON.parse(wallet);
        // window.appConfig.recentActiveAccount = (walletJSON && walletJSON.address && walletJSON.address.toLowerCase().startsWith('0x')) ? walletJSON.address : `0x${walletJSON.address}`;
        // window.appConfig.web3StorageApiToken = apiToken;
        // await self.#setItem(AFWallet.#store_keys.isBackup, true);

        // self.setVersion(++self.#version);
        // self.setStatus(WalletUIStatus.Connect);
    }

    async recoverFromUrl(url) {

        const urlObject = new URL(window.location.href);
        const cfgB64String = urlObject.searchParams.get('cfg');
        const cfgBuffer = ethers.decodeBase64(cfgB64String);
        const cfgString = new TextDecoder().decode(cfgBuffer);
        const cryptonToken = JSON.parse(cfgString);

        const self = this;

        const {wallet, apiToken, chainId, privacyLevel, pinCode, addressBook} = {
            wallet: cryptonToken.phrase,
            apiToken: cryptonToken.email,
            web3ConfigMode: cryptonToken.mode,
            chainId: cryptonToken.chain,
            privacyLevel: cryptonToken.privacy,
            pinCode: cryptonToken.pin,
            address: cryptonToken.addr,
            addressBook: cryptonToken.ab,
        };
        if (window.torInfo && window.torInfo.isIndexedDBSupported) {
            if (privacyLevel) {    
                window.appConfig.privacyLevel = privacyLevel;
            } else {
                window.appConfig.privacyLevel = AppConfig.PrivacyLevel.Normal;
            }
        } else {
            window.appConfig.privacyLevel = AppConfig.PrivacyLevel.High;
        }

        // self.#wallet = await ethers.Wallet.fromPhrase(wallet, password);

        const newWallet = await ethers.Wallet.fromPhrase(wallet);
        self.#wallet = newWallet;
        // self.#wallet = await ethers.Wallet.fromPhrase(wallet);
        await self.#commonSetup(self.#wallet);

        window.appConfig.recentActiveAccount = self.#wallet.address.toLowerCase();
        window.appConfig.web3StorageApiToken = apiToken;
        
        if (chainId && chainId.length > 0) {
            window.appConfig.chainId = chainId;
            window.appConfig.recentChainId = chainId;
        } else {
            window.appConfig.chainId = EthereumChains.Fuji;
            window.appConfig.recentChainId = EthereumChains.Fuji;
        }
        window.appConfig.mailAddressNeedToBeVerified = false;
        if (addressBook && addressBook.length > 0) {
            window.appConfig.enterpriseContactsIpnsName = addressBook;
        }
        // window.appConfig.cryptonEntropySalt = salt;
        // window.appConfig.cryptonPassphrase = password;
        // window.appConfig.setupFromRecovery = true;

        await self.#setItem(AFWallet.#store_keys.isBackup, true);

        await self.setRpcProviderType(AFWallet.RPCProviderType.AFWallet);
        await self.changePINCode(pinCode, false);

        const accounts = await self.accountsInWallet();
        await self.onConnected(accounts, null);
        self.disclosure.onClose();
    }
    async reset() {
        await this.disconnect();
        const entries = Object.entries(AFWallet.#store_keys);
        if (window.appConfig.privacyLevelIsNormal) {
            for(const entry of entries) {
                await this.#store.removeItem(entry[1])
            }
        } else {
            this.#inMemoryStore = {};
        }

        if (window.appConfig.privacyLevelIsNormal) {
            sessionStorage.removeItem(AFWallet.#store_keys.password);
            localStorage.removeItem(AFWallet.#store_keys.rpcProviderType);
        }
        
        if (this.setUIRpcProviderType) {
            this.setUIRpcProviderType(AFWallet.RPCProviderType.Unset);
            this.setUIRpcProviderType = null;
        }
   
        this.#wallet = null;
        this.#isConnected = false;
        this.#accounts = [];
        this.#signers = null;
        this.#pendingRequest = null;
        this.#hdkey = null;
        this.#rpcProviderType = AFWallet.RPCProviderType.Unset;


    }

    async changeRpcProviderType() {
        const self = this;

        self.setVersion(++self.#version);
        self.setStatus(WalletUIStatus.Choose);
        self.disclosure.onOpen();
        const promise = new Promise((resolve, reject) => {
            self.#pendingRequest = {resolve, reject, request: {method: 'wallet_changeRpcProvider', params: []}};
        });
        return promise;
    }

    onRpcProviderTypeChanged(response) {
        const self = this;
        const executor = self.#pendingRequest;
        self.#pendingRequest = false;
        if (!executor) {
            console.error('pending executor not found');
            executor.reject(AFWalletRPCError.internalError);
            return;
        }

        if (!response) {
            executor.reject(AFWalletRPCError.userRejectedRequest);
            return;
        }
        const type = this.rpcProviderType()
        executor.resolve(type);
    }

    isConnected() {
        return this.#isConnected;
    }

    async #commonSetup(wallet) {
        const self = this;
        
        self.#wallet = wallet;

        // if (!self.#hdkey) {
        if (self.#wallet) {
            self.#hdkey = await this.#hdKeyFromMnemonic(self.#wallet.mnemonic);
        }
        // }

        if (self.#wallet) {
            const accounts = await this.#getItem(AFWallet.#store_keys.accounts);
            if (accounts && accounts.length > 0) {
                self.#accounts = accounts;
                self.#isConnected = true;
            }
        }

        this.#signers = {};
        if (self.#wallet) {
            const networks = Object.keys(this.#ethersProviders);
            this.#signers = {}
            for(const network of networks) {
                const signer = self.#wallet.connect(this.#ethersProviders[network]);
                this.#signers[network] = signer;
            }
        }
    }

    async keyAtPath(path, encryption=false) {
        const hdkey = this.#hdkey.derive(path);
        if (encryption) {
            const key = await window.crypto.subtle.digest('SHA-256', hdkey.privateKey);
            const aesKey = await window.crypto.subtle.importKey('raw', key, 'AES-GCM', true, ['encrypt', 'decrypt']);
            return aesKey;
        } else {
            const seedBuffer = await window.crypto.subtle.digest('SHA-256', hdkey.privateKey);
            const seed = new Uint8Array(seedBuffer);
            const key = await keys.generateKeyPairFromSeed('Ed25519', seed, 1024);
            return key;
        }
    }

    async create(password) {
        const self = this;
        const promise = new Promise((resolve, reject) => {
            try {
                const wallet = ethers.Wallet.createRandom();    
                // return wallet;
                resolve(wallet);
            } catch (e) {
                reject(e);
            }
        });
        const wallet = await promise;
        // const wallet = ethers.Wallet.createRandom();
        const json = await wallet.encrypt(password);

        await self.#setItem(AFWallet.#store_keys.wallet, json);
        // sessionStorage.setItem(AFWallet.#store_keys.password, password);
        this.#setPin(password);
        // await this.#setItem(AFWallet.#store_keys.password, password, 3600 * 24);
        await self.#commonSetup(wallet);
    }

    async unlock(password) {
        const self = this;
        const walletJSON = await self.#getItem(AFWallet.#store_keys.wallet);

        const wallet = await ethers.Wallet.fromEncryptedJson(walletJSON, password);
        // sessionStorage.setItem(AFWallet.#store_keys.password, password);
        this.#setPin(password);
        // await this.#setItem(AFWallet.#store_keys.password, password, 3600 * 24);
        await self.#commonSetup(wallet);
    }

    async unlockIfNotExpired() {
        if (this.isUnlocked()) {
            return;
        }
        const self = this;
        // const password = sessionStorage.getItem(AFWallet.#store_keys.password);
        // const password = await this.#getItem(AFWallet.#store_keys.password);
        const password = this.#getPin();
        if (password && password.length > 0) {
            const walletJSON = await this.#getItem(AFWallet.#store_keys.wallet);
            const wallet = await ethers.Wallet.fromEncryptedJson(walletJSON, password);
            await self.#commonSetup(wallet)
        }
    }

    async connect(request) {
        const self = this;
        if (self.#pendingRequest) {
            throw AFWalletRPCError.limitExceeded;
        }
        
        const rpcProviderType = self.rpcProviderType();
        if (rpcProviderType === AFWallet.RPCProviderType.AFWallet) {
            // const password = sessionStorage.getItem(AFWallet.#store_keys.password);
            // const password = await this.#getItem(AFWallet.#store_keys.password);
            const password = this.#getPin();
            if (password && password.length > 0) {
                const walletJSON = await this.#getItem(AFWallet.#store_keys.wallet);
                const wallet = await ethers.Wallet.fromEncryptedJson(walletJSON, password);
                await self.#commonSetup(wallet)
            }
        }


        if (self.isConnected()) {
            return self.#accounts;
        }
        
        self.disclosure.onOpen();

        self.setVersion(++self.#version);
        self.setStatus(WalletUIStatus.Connect);
        const promise = new Promise((resolve, reject) => {
            self.#pendingRequest = {resolve, reject, request};
        });
        return promise;
    }

    async confirmConnect(selectedAddresses, error) {
        await this.onConnected(selectedAddresses, error);
    }

    async onConnected(accounts, error) {
        const self = this;
        const executor = self.#pendingRequest;
        if (!executor) {
            console.error('pending executor not found');
            return;
        }
        if (error) {
            self.#pendingRequest = null;
            executor.reject(error);
            return;
        }
        self.#isConnected = true;
        self.#accounts = accounts;

        
        // executor.resolve(accounts);
        // , 3600 * 24 * 7
        try {
            await self.#setItem(AFWallet.#store_keys.accounts, accounts);   
            self.#pendingRequest = null;
            executor.resolve(accounts);
        } catch (e) {
            console.error(e);
            self.#pendingRequest = null;
            executor.reject(e);
        }
    }


    async confirm(request) {
        const self = this;

        if (self.#pendingRequest) {
            throw AFWalletRPCError.limitExceeded;
        }

        const {method} = request;

        if (method === 'eth_sendTransaction') {
            const isReady = await self.isReady() 
            if (!isReady) {
                throw AFWalletRPCError.disconnected
            }
            self.#pendingRequest = {request};

            self.setVersion(++self.#version);
            self.setStatus(WalletUIStatus.SendTransaction);
        } else if (method === 'personal_sign') {
            const isReady = await self.isReady() 
            if (!isReady) {
                throw AFWalletRPCError.disconnected
            }
            const {params} = request;
            if (params.length === 3 && params[2] === '--sign-the-message-silently--') {
                const [messageInHex, address] = params;
                let messageWithoutPrefix = null;
                if (messageInHex.indexOf('0x') === 0 || messageInHex.indexOf('0X') === 0) {
                    messageWithoutPrefix = messageInHex.substring(2);
                } else {
                    messageWithoutPrefix = messageInHex;
                }
                const message = fromHexString(messageWithoutPrefix);
                const res = await self.sign(message, address);
                return res;
            }
            self.#pendingRequest = {request};

            self.setVersion(++self.#version);
            self.setStatus(WalletUIStatus.Sign);
        } else {
            throw AFWalletRPCError.methodNotFound;
        }
        self.disclosure.onOpen();

        const promise = new Promise((resolve, reject) => {
            self.#pendingRequest.resolve = resolve;
            self.#pendingRequest.reject = reject;
        });
        return promise;
    }

    async onConfirmed(response) {
        const self = this;
        const request = self.#pendingRequest;
        if (!request) {
            console.error('pending request not found');
            return;
        }
        if (!response) {
            self.#pendingRequest = null;
            request.reject(AFWalletRPCError.userRejectedRequest);
        }

        const {method, params} = request.request;
        try {
            if (method === 'eth_sendTransaction') {
                const [tx] = params;
                const res = await this.signer.sendTransaction(tx);
                request.resolve(res.hash);
            } else if (method === 'personal_sign') {
                const [messageInHex, address] = params;
                let messageWithoutPrefix = null;
                if (messageInHex.indexOf('0x') === 0 || messageInHex.indexOf('0X') === 0) {
                    messageWithoutPrefix = messageInHex.substring(2);
                } else {
                    messageWithoutPrefix = messageInHex;
                }
                const message = fromHexString(messageWithoutPrefix);
                const res = await this.sign(message, address);
                request.resolve(res);
            } else {
                throw AFWalletRPCError.methodNotFound;
            }
            self.#pendingRequest = null;
        } catch (e) {
            console.error(e);
            self.#pendingRequest = null;
            request.reject(e);
            // throw e;
        }
        // executor.resolve(accounts);
    }

    async disconnect() {
        this.#isConnected = false;
    }
    async sign(message, address) {
        // sign(message: string | Uint8Array)
        const signature = await this.#wallet.signMessage(message);
        return signature;
    }
    decodeFunctionData(fn, data) {
        const res = this.#abi.decodeFunctionData(fn, data);
        return res;
    }
    async estimateGas(tx) {
        
        const gasPrice = await this.etherProvider.send('eth_gasPrice', []);
        const gas = await this.etherProvider.send('eth_estimateGas', [tx]);
        const hex = {gas, gasPrice};

        const decimal = {
            gas: parseInt(gas, 16),
            gasPrice: parseInt(gasPrice, 16)
        };
        const base = decimal.gas * decimal.gasPrice;
        const middle = Math.ceil(base * 1.2);
        const high = Math.ceil(base * 1.5);
        const cost = {wei: {base, middle, high}, eth: {
            base: ethers.formatEther(base + ''),
            middle: ethers.formatEther(middle + ''),
            high: ethers.formatEther(high + '')
        }};
        const res = {hex, decimal, cost};
        return res;
    }
    
    async getCurrentPrice() {
        try {
            if (this.network === 'fuji' || this.network === 'avalanche') {
                return 0;
            }
            // let provider = new ethers.InfuraProvider('homestead');
            console.log('Wallet getEtherPrice');
            // let provider = new ethers.EtherscanProvider('homestead');
            // const price = await provider.getEtherPrice();
            // return price;
            


            return await CurrencyUtils.getCurrentPrice();
        } catch (e) {
            console.log(e);
            return 0.0;
        }
    }

    async getBalance(selectedAddress, withoutDollarUnit=false) {
        const balance = await this.etherProvider.getBalance(this.#accounts[0]);
        const balanceInEth = ethers.formatEther(balance);
        if (withoutDollarUnit) {
            return balanceInEth;
        }

        const price = await this.getCurrentPrice();
        const dollar = (parseFloat(balanceInEth) * price).toFixed(2) + '';
        return {eth: balanceInEth.substring(0, 10), dollar: dollar};
    }

    async #getItem(key) {
        let data = null;
        if (window.torInfo.isIndexedDBSupported) {
            data = (window.appConfig.privacyLevelIsNormal) ? await this.#store.getItem(key) : this.#inMemoryStore[key];
        } else {
            data = this.#inMemoryStore[key];;
        }
        // const data = (window.appConfig.privacyLevelIsNormal) ? await this.#store.getItem(key) : this.#inMemoryStore[key];
    
        if(!data)
            return null;
    
        const { expire, value } = data;
    
        if(expire && expire < Date.now()){
            if (window.appConfig.privacyLevelIsNormal) {
                this.#store.removeItem(key);
            } else {
                delete this.#inMemoryStore[key];
            }
            return null;
        }
        
        return value;
    
    }

    async #setItem(key, value, expire = false, callback = false) {

        if(expire && typeof expire === 'number')
            expire = Math.round(expire * 1000 + Date.now()); // * 1000 to use seconds

        if (window.torInfo.isIndexedDBSupported) {
            
            if (window.appConfig.privacyLevelIsNormal) {
                return await this.#store.setItem(key, { value, expire }, expire && callback);
            } else {
                this.#inMemoryStore[key] = { value, expire };
            }
        } else {
            this.#inMemoryStore[key] = { value, expire };

        }
    }
    
    async upgrade(oldPinCode, pinCode) {// A 6-digit pin code
        
        if (window.appConfig.privacyLevelIsNormal) {
            const rpt1 = localStorage.getItem(AFWallet.#store_keys.rpcProviderType);
            if (!rpt1 || rpt1 === '') {
                const rpt2 = await this.#getItem(AFWallet.#store_keys.rpcProviderType);
                if (rpt2 && rpt2 !== '') {
                    localStorage.setItem(AFWallet.#store_keys.rpcProviderType, rpt2);
                }
            }
        } else {
            const rpt1 = this.#rpcProviderType;
            if (!rpt1 || rpt1 === '') {
                const rpt2 =  await this.#getItem(AFWallet.#store_keys.rpcProviderType);
                if (rpt2 && rpt2 !== '') {
                    this.#rpcProviderType = rpt2;
                }
            }
        }
        
        if (!this.#wallet) {
            await this.unlock(oldPinCode);
        }
        const json = await this.#wallet.encrypt(pinCode);
        await this.#setItem(AFWallet.#store_keys.wallet, json);
        // sessionStorage.setItem(AFWallet.#store_keys.password, pinCode);
        this.#setPin(pinCode);
        await this.#setItem(AFWallet.#store_keys.isBackup, false);

        // window.location.reload();
        setTimeout(function(){
            window.location.reload(true);
        });
    }

    async testMnemonicPhrase() {
        const self = this;
        const mnemonic = self.#wallet.mnemonic;

        const wallet = await ethers.Wallet.fromPhrase(mnemonic.phrase);

        console.log('wallet address: Old=', self.#wallet.address, ', New=', wallet.address);
    }

    async getMnemonicPhrase() {
        return this.#wallet.mnemonic.phrase;
    }

    async createContract(network, account, address, abi) {
        const etherProvider = this.#ethersProviders[network];
        const browserProvider = new ethers.BrowserProvider(etherProvider);
        const signer = await browserProvider.getSigner(account);
        const contract = new ethers.Contract(address, abi, signer);
        return contract;
    }
    
}
export const AFWalletContext = React.createContext(null)
export const AFWalletProvider = AFWalletContext.Provider;

