import { IndexClient } from "candb-client-typescript/dist/IndexClient";
import { ActorClient } from "candb-client-typescript/dist/ActorClient";

import { idlFactory as IndexCanisterIDL } from "../declarations/pleximail_index/index";
import { idlFactory as PlexiMailServiceCanisterIDL } from "../declarations/pleximail_backend/index";
// import { IndexCanister } from "../../../declarations/pleximail_index/pleximail_index.did";
// import { PlexiMailService } from "../../../declarations/pleximail_backend/pleximail_backend.did";


export function intializeIndexClient(isLocal, identity) {
  const host = isLocal ? "http://127.0.0.1:4943" : "https://ic0.app";
  // canisterId of your index canister
  const canisterId = isLocal ? process.env.CANISTER_ID_PLEXIMAIL_INDEX : process.env.CANISTER_ID_PLEXIMAIL_INDEX; // "<prod_canister_id>";
  return new IndexClient({
    IDL: IndexCanisterIDL,
    canisterId, 
    agentOptions: {
      identity,
      host,
    },
  })
};

export function initializePlexiMailServiceClient(isLocal, identity, indexClient) {
  const host = isLocal ? "http://127.0.0.1:4943" : "https://ic0.app";
  return new ActorClient({
    actorOptions: {
      IDL: PlexiMailServiceCanisterIDL,
      agentOptions: {
        identity,
        host,
      }
    },
    indexClient, 
  })
};