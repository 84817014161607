/*
{
    chakra-ui-color-mode: "light"
    mail.folders-sign-key: "CAESQDq/dZ1UWDJmbWyW/WY3VHlskleHmLHA+3YeI5bdc2qNa2cPIyqbMsZhEIRjL36HiWWxo943FaNcQZZAZaXOPjw="
    mail.prekeys-sign-key: "CAESQE+Np11BRtZPXBH/DGw1oRV7KO9RWJSAQ3rGWo514RBiw9gR+rgQG7JEBfFkQuN66MncTe5eRk+etHflENB6WlE="
    mail.signal-context-sign-key: "CAESQEwmM2hToqbIZqXqVw6YawjXCliAtLW2cLTsXNGywbP3xZqnqM/uuQ6Y8UHeN6DVnvxkWXPdDpfN+5XIPhK0hfU="
    web3.storage-token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDVBMTMzNTJBNzcwYkE4M2M2YUU4ZTQwOWIyMTU4NGY1YjJhZmI3OGMiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2NTUyNjUzOTcwNDAsIm5hbWUiOiJFeGFtcGxlIn0.o89ztC7cQ3lyvqgI77phbL7Vz3_pZK0IbKIA-clZ-X4"
}
*/
import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Button,
  SimpleGrid,
  Link,
  FormLabel,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  // Link,
} from "@chakra-ui/react";

import { useState } from "react";
import { MnemonicBackupMethod, SetupWizardState } from "../utils/Types";
import Strings from "../config/Strings";
import { showError } from "../common/errors";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";
// import { TutorialModalLink } from './TutorialModalTrigger';

function MnemonicRestorePanel({ setSetupWizardState, selectedAccount }) {
  const S = Strings.mnemonic.restore;
  function onChange(e) {
    if (!e.target.files && e.target.files.length === 0) {
      return;
    }
    e.target.files[0]
      .text()
      .then((tokenString) => {
        return window.mailService.getCryptonToken(
          selectedAccount,
          window.appConfig.tempMasterKey,
          tokenString
        );
      })
      .then((token) => {
        saveMnemonic(token.mnemonic);
        window.appConfig.web3StorageApiToken = token.apiToken;
      })
      .catch((e) => {
        showError(e);
      });
  }
  function restoreFromLocalFile() {
    const input = document.getElementById("config-file");
    input.click();
  }

  function restoreFromWeb3Storage() {
    window.mailService
      .getCryptonToken(selectedAccount, window.appConfig.tempMasterKey)
      .then((token) => {
        saveMnemonic(token.mnemonic);
        window.appConfig.web3StorageApiToken = token.apiToken;
      })
      .catch((e) => {
        showError(e);
      });
  }

  function restoreMnemonic() {
    if (
      window.appConfig.mnemonicBackupMethod === MnemonicBackupMethod.LocalFile
    ) {
      restoreFromLocalFile();
    } else if (
      window.appConfig.mnemonicBackupMethod === MnemonicBackupMethod.Web3Storage
    ) {
      restoreFromWeb3Storage();
    }
  }

  function saveMnemonic(mnemonic) {
    try {
      window.appConfig.mnemonic = mnemonic;
      window.mailService
        .initCryptServiceIfNeeded()
        .then(() => {
          window.appConfig.tempMnemonic = window.appConfig.mnemonic;
          setSetupWizardState(SetupWizardState.RestoreWeb3Storage);
        })
        .catch((e) => {
          showError(e);
        });
    } catch (e) {
      console.error(e);
      showError(e);
    }
  }

  function backClicked() {
    window.appConfig.mnemonic = "";
    window.appConfig.tempMnemonic = "";
    window.appConfig.encryptedMnemonic = "";
    setSetupWizardState(SetupWizardState.EnterPassword);
  }

  return (
    <>
      <Stack spacing={4}>
        <Heading
          color={"gray.800"}
          lineHeight={1.1}
          fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
        >
          <Text
            as={"span"}
            bgGradient="linear(to-r, red.400,pink.400)"
            bgClip="text"
          >
            {S.step} &nbsp;
          </Text>{" "}
          {S.title}
        </Heading>

        {S.content.paragraph1}
        {/* {S.content.paragraph2} */}
      </Stack>
      <Box as={"form"} mt={10}>
        <Button
          fontFamily={"heading"}
          mt={8}
          w={"full"}
          bgGradient="linear(to-r, red.400,pink.400)"
          color={"white"}
          _hover={{
            bgGradient: "linear(to-r, red.400,pink.400)",
            boxShadow: "xl",
          }}
          onClick={restoreMnemonic}
        >
          {window.appConfig.mnemonicBackupMethod ===
            MnemonicBackupMethod.LocalFile && S.button.local.title}
          {window.appConfig.mnemonicBackupMethod ===
            MnemonicBackupMethod.Web3Storage && S.button.web3.title}
        </Button>

        <Button
          fontFamily={"heading"}
          mt={8}
          w={"full"}
          bgColor={"gray.200"}
          color={"black"}
          onClick={backClicked}
        >
          {S.button.back.title}
        </Button>

        <input id="config-file" type={"file"} hidden onChange={onChange} />
      </Box>
    </>
  );
}

function MnemonicGeneratePanel({ setSetupWizardState, selectedAccount }) {
  const S = Strings.mnemonic.generate;
  const [state, setState] = useState(0);
  const [mnemonic, setMnemonic] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isGenerating, setGenerating] = useState(false);

  const showMnemonic = () => {
    window.mailService
      .initCryptServiceIfNeeded()
      .then(() => {
        window.appConfig.tempMnemonic = window.appConfig.mnemonic;
        setMnemonic(window.appConfig.mnemonic);
        // setDisabled(false);
      })
      .catch((e) => {
        showError(e);
      });
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  function saveMnemonic() {
    // gotoConfigWeb3Storage();
    setGenerating(true);

    window.mailService
      .initCryptServiceIfNeeded()
      .then(() => {
        window.appConfig.tempMnemonic = window.appConfig.mnemonic;
        // setMnemonic(window.appConfig.mnemonic);

        setGenerating(false);
        gotoConfigWeb3Storage();

        // if (window.appConfig.mnemonicBackupMethod === MnemonicBackupMethod.LocalFile) {
        //     window.mailService.saveCryptonToken(selectedAccount, window.appConfig.tempMasterKey, window.appConfig.mnemonic, true, true).then(() => {

        //         setGenerating(false);
        //         gotoConfigWeb3Storage();
        //     }).catch(e => {
        //         setGenerating(false);
        //         showError(e);
        //     });
        // } else if (window.appConfig.mnemonicBackupMethod === MnemonicBackupMethod.Web3Storage) {
        //     gotoConfigWeb3Storage();
        // } else {
        //     setGenerating(false);
        // }
      })
      .catch((e) => {
        setGenerating(false);
        showError(e);
      });

    // window.mailService.initCryptServiceIfNeeded().then(() => {
    //     window.appConfig.tempMnemonic = window.appConfig.mnemonic;
    //     setMnemonic(window.appConfig.mnemonic);
    //     return window.mailService.saveCryptonToken(selectedAccount, window.appConfig.tempMasterKey, mnemonic)
    // }).then(() => {
    //     gotoConfigWeb3Storage();
    // }).catch(e => {
    //     showError(e);
    // })
  }

  function gotoConfigWeb3Storage() {
    try {
      setSetupWizardState(SetupWizardState.ConfigWeb3Storage);
    } catch (e) {
      console.error(e);
      showError(e);
    }
  }

  function verifyPassword(e) {}

  function backClicked() {
    window.appConfig.mnemonic = "";
    window.appConfig.tempMnemonic = "";
    window.appConfig.encryptedMnemonic = "";
    // window.appConfig.masterKey = null;
    // window.appConfig.removeTempMasterKey();
    setSetupWizardState(SetupWizardState.ConfigPassword);
  }

  return (
    <>
      <Stack spacing={4}>
        <Heading
          color={"gray.800"}
          lineHeight={1.1}
          fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
        >
          <Text
            as={"span"}
            bgGradient="linear(to-r, red.400,pink.400)"
            bgClip="text"
          >
            {S.step} &nbsp;
          </Text>{" "}
          {S.title}
        </Heading>
        {S.content.paragraph1}
        {/* {S.content.paragraph2} */}
      </Stack>
      <Box as={"form"} mt={10}>
        <Stack hidden={true}>
          {(!mnemonic || mnemonic.length === 0) && (
            <>
              <Link
                backgroundColor={"#f5f5f5"}
                minHeight={"128px"}
                width={"100%"}
                textAlign={"center"}
                lineHeight={"128px"}
                onClick={showMnemonic}
              >
                {S.button.display.title}
              </Link>
              <Text color={"gray"}>{S.content.paragraph2}</Text>
            </>
          )}

          {mnemonic && mnemonic.length > 0 && (
            <>
              <CopyToClipboard
                text={mnemonic}
                onCopy={() => {
                  setState(state + 1);
                  toast(S.message.copied);
                }}
              >
                <Link
                  backgroundColor={"#f5f5f5"}
                  minHeight={"128px"}
                  width={"100%"}
                  padding={3}
                >
                  {mnemonic}
                </Link>
              </CopyToClipboard>
              <Text color={"gray"}>{S.content.paragraph1}</Text>
            </>
          )}
        </Stack>

        <Stack mt={4} hidden={true}>
          <FormLabel htmlFor="country">Passphrase</FormLabel>
          <InputGroup>
            <Input
              autoComplete="off"
              type={showPassword ? "text" : "password"}
              onChange={verifyPassword}
            />
            <InputRightElement>
              {showPassword && (
                <IconButton icon={<FaEye />} onClick={togglePassword} />
              )}
              {!showPassword && (
                <IconButton icon={<FaEyeSlash />} onClick={togglePassword} />
              )}
            </InputRightElement>
          </InputGroup>
        </Stack>

        <Button
          fontFamily={"heading"}
          mt={8}
          w={"full"}
          bgGradient="linear(to-r, red.400,pink.400)"
          color={"white"}
          // disabled={disabled}
          isLoading={isGenerating}
          _hover={{
            bgGradient: "linear(to-r, red.400,pink.400)",
            boxShadow: "xl",
          }}
          onClick={saveMnemonic}
        >
          {window.appConfig.mnemonicBackupMethod ===
            MnemonicBackupMethod.Web3Storage && S.button.gen.title}
          {window.appConfig.mnemonicBackupMethod ===
            MnemonicBackupMethod.LocalFile && S.button.gen_save.title}
        </Button>

        <Button
          fontFamily={"heading"}
          mt={8}
          w={"full"}
          bgColor={"gray.200"}
          color={"black"}
          onClick={backClicked}
        >
          {S.button.back.title}
        </Button>
      </Box>
    </>
  );
}

export default function MnemonicScreen({
  setSetupWizardState,
  setupWizardState,
  selectedAccount,
}) {
  return (
    <Box position={"relative"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
        >
          {setupWizardState === SetupWizardState.RestoreMnemonic && (
            <MnemonicRestorePanel
              setSetupWizardState={setSetupWizardState}
              selectedAccount={selectedAccount}
            />
          )}

          {setupWizardState === SetupWizardState.GenerateMnemonic && (
            <MnemonicGeneratePanel
              setSetupWizardState={setSetupWizardState}
              selectedAccount={selectedAccount}
            />
          )}
        </Stack>
      </Container>
    </Box>
  );
}
