import { WarningTwoIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Container,
  Button,
  SimpleGrid,
  Avatar,
  AvatarGroup,
  useBreakpointValue,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Menu,
  MenuButton,
  HStack,
  MenuList,
  useColorModeValue,
  MenuItem,
  Link,
  List,
  ListItem,
  Divider,
  ModalCloseButton,
  Image,
  InputGroup,
  Input,
  InputRightElement,
  IconButton,
  PinInput,
  PinInputField,
  Spinner,
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  TabPanels,
  TabPanel,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  // usePinInput,
  // usePinInputField
} from "@chakra-ui/react";
// Here we have used react-icons package for the icons
// And react-slick as our Carousel Lib
// import {
//   // encrypt,
//   recoverPersonalSignature,
//   // recoverTypedSignatureLegacy,
//   // recoverTypedSignature,
//   // recoverTypedSignature_v4 as recoverTypedSignatureV4,
// } from 'eth-sig-util';

// import { recoverPersonalSignature } from 'eth-sig-util';

// import * as ethUtil from "ethereumjs-util";
// import { randomBytes } from 'ethers/lib/utils';
import { useState, useEffect, createRef /*, useContext*/ } from "react";
import { FiChevronDown } from "react-icons/fi";
import { AiOutlineSound, AiFillSound } from "react-icons/ai";
import { ClientError, showError } from "../common/errors";
// import {CircleLoader, ClipLoader} from 'react-spinners';
// import { ethers } from "ethers";
// import Web3Modal from "web3modal";
//import appConfig from '../config/AppConfig';

import {
  EthereumChains,
  SetupWizardState,
  PlexiMailStatus,
  StorageProvider,
} from "../utils/Types";
//import WalletConnectProvider from "@walletconnect/web3-provider";
// import {ReactComponent as MetamaskLogo} from './metamask.svg';
import {ReactComponent as ICPIcon} from './icp.svg';
// import Web3 from 'web3';
import ConfirmationButton from "./ConfirmationButton";
import Strings from "../config/Strings";
import {
  ENTERPRISE_FEATURES_ENABLED,
  HELP_DOC_URL,
  homeScreenHasMenu,
  isSupportedBrowser,
  isUnsupportedBrowser,
} from "../common/constants";
import TutorialModal from "./TutorialModal";
import { TutorialModalLink } from "./TutorialModalTrigger";
import { isMobile } from "../common/constants";
import { FaEthereum, FaEye, FaEyeSlash } from "react-icons/fa";
import AFWallet from "../wallet/AFWalletV2";
// import Web3 from 'web3';
import { ethers } from "ethers";

// import { IndexedDBDriver } from '@web3-storage/access/drivers/indexeddb'
// import {IndexedDBDriver} from "../common/indexeddb";
// import plexMailRootRegistry from '../PlexiMailRootRegistryABI.json';
// import { WalletUIStatus } from '../wallet/AFWalletUI';
import PrivacyLevelSelectorAlert from "./PrivacyLevelSelectorAlert";
// import W3UpWeb3Storage from './w3ui/W3UpWeb3Storage';
import { useAuth } from "../icp/authContext";
import InternetIdentityAuthAlert from "./InternetIdentityAuthAlert";
import EnterpriseCreationModal from "./enterprise/EnterpriseCreationModal";

const avatars = [
  {
    name: "Ryan Florence",
    url: "avatar/ryan-florence.jpeg",
  },
  {
    name: "Segun Adebayo",
    url: "avatar/prosper-baba.jpeg",
  },
  {
    name: "Kent Dodds",
    url: "avatar/kent-c-dodds.jpeg",
  },
  {
    name: "Prosper Otemuyiwa",
    url: "avatar/girl.jpeg",
  },
  {
    name: "Christian Nwamba",
    url: "avatar/code-beast.jpeg",
  },
];
/*
const test = async () => {
  const storage = new W3UpWeb3Storage();
  await storage.loadAgent();
  console.log('w3up loaded')
  const account = await storage.authenticate('zhanleewo@gmail.com');
  console.log('w3up authenticated:', account)
  storage.setCurrentSpace('did:key:z6Mkpsr71q5iCCaXsWDy3Tc6gosM3LVnnCJPnrMEaN9sfLBM');
  console.log('w3up space changed:', 'did:key:z6Mkpsr71q5iCCaXsWDy3Tc6gosM3LVnnCJPnrMEaN9sfLBM')
  const res = await storage.put([new File(['hello world - b'], {name: 'b.txt'})]);
  console.log('w3up saved:', res)
}
*/
// const MinimumBalanceRequirement = localStorage["MinimumBalanceRequirement"] || '1000000000000000';
// const MinimalRequiredBalance = parseInt(MinimumBalanceRequirement) || 1000000000000000;
//const MinimalRequiredBalance = 1000000000000000000;

function UnsupportedModal({ disclosure }) {
  return (
    <Modal
      size={"full"}
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{Strings.home.dialog.unsupported.title}</ModalHeader>
        {/* <ModalCloseButton /> */}

        <ModalBody>
          <Text>{Strings.home.dialog.unsupported.content.paragraph1}</Text>
          <List pl={"20px"}>
            {isMobile && (
              <>
                <ListItem fontWeight={"bold"}>
                  {Strings.home.dialog.unsupported.content.paragraph2}
                </ListItem>
                <ListItem fontWeight={"bold"}>
                  {Strings.home.dialog.unsupported.content.paragraph6 ||
                    "MetaMask Browser"}
                </ListItem>
              </>
            )}

            {!isMobile && (
              <>
                <ListItem fontWeight={"bold"}>
                  {Strings.home.dialog.unsupported.content.paragraph2}
                </ListItem>
                <ListItem>
                  {Strings.home.dialog.unsupported.content.paragraph3}
                </ListItem>
                <ListItem>
                  {Strings.home.dialog.unsupported.content.paragraph4}
                </ListItem>
                <ListItem>
                  {Strings.home.dialog.unsupported.content.paragraph5}
                </ListItem>
              </>
            )}
          </List>
        </ModalBody>

        {/* <ModalFooter>
      <Button colorScheme='green' onClick={() => {
        window.open('https://metamask.io/', '_blank')
        //window.location.href = ('https://metamask.io/');
      }}>Download &amp; Install</Button>
  </ModalFooter> */}
      </ModalContent>
    </Modal>
  );
}

// function TutorialModal({disclosure, walletNotFoundDisclosure}) {

//   const [slider, setSlider] = useState(null);
//   const [configButton, setConfigButton] = useState(<><IconButton icon={<FiHelpCircle />} mr={3} color="gray.600" onClick={() => {// ghost
//     window.appConfig.isNewUser = false;
//     walletNotFoundDisclosure.onOpen();
//   }} /><Button color="gray.800" mr={3} onClick={() => {// ghost
//     window.appConfig.isNewUser = false;
//     window.location.reload();
//     //window.location.href = ('https://metamask.io/');
//   }}>Install MetaMask &amp; Skip Tutorial</Button><Button colorScheme='green' onClick={() => {// ghost
//     slider?.slickNext()
//     //window.location.href = ('https://metamask.io/');
//   }}>Read Tutorial</Button></>);

//   return (<Modal size={"full"} isOpen={disclosure.isOpen} onClose={disclosure.onClose} closeOnOverlayClick={false}>
//   <ModalOverlay />
//     <ModalContent>
//       {/* <ModalHeader></ModalHeader> */}
//     {/* <ModalHeader>MetaMask Wallet not Found</ModalHeader> */}
//     {/* <ModalCloseButton /> */}

//     <ModalBody>
//       <TutorialContent slider={slider} setSlider={setSlider} setConfigButton={setConfigButton} walletNotFoundDisclosure={walletNotFoundDisclosure} />
//     </ModalBody>

//     <ModalFooter>
//         {configButton}
//     </ModalFooter>
//     </ModalContent>
//   </Modal>)
// }

function InstallWalletModal({ disclosure }) {
  const S = Strings.home.dialog.walletNotFound;

  return (
    <Modal
      size={"xl"}
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader></ModalHeader>
    <ModalHeader>Your Run Environment Is Not Ready</ModalHeader>
    <ModalCloseButton>Skip</ModalCloseButton>
    
    <ModalBody>
      <Text fontSize={{ base: 'sm', lg: 'md' }} 
        // color="GrayText"
      >
        You are about to enter into PlexiMail, the most advanced secure mail dApp, made possible by the advent of Blockchain technology. It is a Web3 dApp, requiring a cryptocurrency wallet (MetaMask) and some offchain Web3 cloud storage. <br /><br />
        <i>NOTE: Both MetaMask and Web3.Storage are well-trusted open source Web3 services. We hesitate to integrate them into our PlexiMail baseline to avoid muddying the water of their trustiness. They are also ideal introduction to the brave new world of Web3 dApps. Please return here to try your free PlexiMail after the successful completion of their installation.</i><br /><br />
        <strong>If you have experience with web3.0 and web3.storage, you can skip this tutorial, otherwise you should read through it.</strong>
      </Text>
    </ModalBody> */}

        <ModalHeader>{S.title}</ModalHeader>
        {/* <ModalCloseButton /> */}

        <ModalBody>
          <Box align="center">
            {/* <MetamaskLogo width={"200px"} /> */}
            <Image src="/intro/wallet-icon.png" width={300} />
          </Box>
          <Text mt={3} fontSize={{ base: "sm", lg: "sm" }}>
            {S.content.paragraph1}
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="orange"
            mr={3}
            onClick={() => {
              window.appConfig.isNewUser = true;
              // window.location.reload();
              setTimeout(function () {
                window.location.reload(true);
              });
              // window.location.href = ('https://metamask.io/');
            }}
          >
            {S.button.read.title}
          </Button>
          <ConfirmationButton
            buttonTitle={S.button.install.title}
            title={S.button.install.confirm.title}
            message={S.button.install.confirm.content}
            onClick={() => {
              // ghost
              window.open("https://metamask.io/", "_blank");
              //window.location.href = ('https://metamask.io/');
            }}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function NewUserModal({ disclosure, tutorialDisclosure }) {
  return (
    <Modal
      size={"xl"}
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader></ModalHeader>
    <ModalHeader>Your Run Environment Is Not Ready</ModalHeader>
    <ModalCloseButton>Skip</ModalCloseButton>
    
    <ModalBody>
      <Text fontSize={{ base: 'sm', lg: 'md' }} 
        // color="GrayText"
      >
        You are about to enter into PlexiMail, the most advanced secure mail dApp, made possible by the advent of Blockchain technology. It is a Web3 dApp, requiring a cryptocurrency wallet (MetaMask) and some offchain Web3 cloud storage. <br /><br />
        <i>NOTE: Both MetaMask and Web3.Storage are well-trusted open source Web3 services. We hesitate to integrate them into our PlexiMail baseline to avoid muddying the water of their trustiness. They are also ideal introduction to the brave new world of Web3 dApps. Please return here to try your free PlexiMail after the successful completion of their installation.</i><br /><br />
        <strong>If you have experience with web3.0 and web3.storage, you can skip this tutorial, otherwise you should read through it.</strong>
      </Text>
    </ModalBody> */}

        <ModalHeader>{Strings.home.dialog.notReady.title}</ModalHeader>
        <ModalCloseButton
          onClick={() => {
            window.appConfig.isNewUser = false;
            // window.location.reload();
            setTimeout(function () {
              window.location.reload(true);
            });
            // disclosure.onClose();
            // tutorialDisclosure.onOpen();
          }}
          width="auto"
          pl={2}
          pr={2}
        >
          {Strings.home.dialog.notReady.button.skip}
        </ModalCloseButton>

        <ModalBody>
          {/* <MetamaskLogo /> */}

          <Box align="center">
            <WarningTwoIcon boxSize={"50px"} color={"orange.300"} />
          </Box>
          <Text mt={3} fontSize={{ base: "sm", lg: "sm" }}>
            {Strings.home.dialog.notReady.content.paragraph1} <br />
            <br />
            <i>{Strings.home.dialog.notReady.content.paragraph2}</i>
            <br />
            <br />
            <strong>{Strings.home.dialog.notReady.content.paragraph3}</strong>
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="green"
            onClick={() => {
              tutorialDisclosure.onOpen();
              // window.location.href = ('https://metamask.io/');
            }}
          >
            {Strings.home.dialog.notReady.button.read}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

const ForgetPasswordAlert = ({ disclosure }) => {
  const [isResetting, setResetting] = useState(false);

  function reset() {
    window.mailService
      .reset()
      .then(() => {
        return window.wallet.reset();
      })
      .then(() => {
        window.forceQuit = true;
        // window.location.reload();
        setTimeout(function () {
          window.location.reload(true);
        });
        setResetting(false);
      })
      .catch((e) => {
        setResetting(false);
        console.error(e);
        showError(e);
      });
  }
  const signOut = () => {
    if (window.web3Helper && window.web3Helper.magic) {
      setResetting(true);

      reset();

      // window.web3Helper.magic.wallet.disconnect().then(() => {
      // // window.web3Helper.magic.connect.disconnect().then(() => {
      //   reset();
      // }).catch(e => {
      //   console.error(e);
      //   reset();
      // })
    } else {
      reset();
    }
  };

  return (
    <Modal
      size={"xl"}
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {Strings.home.dialog.reset.title}
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Text color={"red"} fontWeight={"bold"}>
            {Strings.home.dialog.reset.content.paragraph1}
          </Text>
          <Text>{Strings.home.dialog.reset.content.paragraph2}</Text>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="gray"
            disabled={isResetting}
            onClick={disclosure.onClose}
            mr={4}
          >
            {Strings.home.dialog.reset.button.cancel}
          </Button>

          <Button colorScheme="red" isLoading={isResetting} onClick={signOut}>
            {Strings.home.dialog.reset.button.reset}
          </Button>
          {/* <Tooltip label="Sent Mail"></Tooltip> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
const HomeScreenAction = {
  None: 0,
  Enter: 1,
  Continue: 2,
  Resume: 3,
  OneClickResume: 4,
};

export default function HomeScreen({
  setSetupWizardState,
  setAccounts,
  setSelectedAccount,
  faucetDisclosure,
}) {
  var [chainId, setChianId] = useState(
    window.appConfig ? window.appConfig.chainId : EthereumChains.Goerli
  );
  const { isAuthenticated, login, checkAuth } = useAuth();
  const [action, setAction] = useState(HomeScreenAction.None);
  // const { isOpen, onOpen, onClose } = useDisclosure();

  const tutorialDisclosure = useDisclosure();
  const welcomeDisclosure = useDisclosure();
  const forgetPasswordDisclosure = useDisclosure();
  const walletNotFoundDisclosure = useDisclosure();
  const privacyLevelSelectorDisclosure = useDisclosure();
  const enterpriseCreationDisclosure = useDisclosure();
  const [activatedWallet, setActivatedWallet] = useState(null);

  const [isConnecting, setConnecting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const [unlockDisabled, setUnlockDisabled] = useState(false);
  const [aifiWalletIsDefaultWallet, setAifiWalletIsDefaultWallet] = useState(
    window.wallet.asDefaultWallet
  );
  const passwordRef = createRef();
  // const [password, setPassword] = useState(null);
  const [unlockInputIsVallid, setUnlockInputIsVallid] = useState(false);
  const [unlockInputIsDisabled, setUnlockInputDisabled] = useState(false);
  const [showSwitchAccount, setShowSwitchAccount] = useState(false);
  const [isAlreadyBackup, setAlreadyBackup] = useState(false);

  console.log('Internet Identity: isAuthenticated=', isAuthenticated);
  // const context = usePinInput({ autoFocus: true })
  // const input1 = usePinInputField({ context })
  // const input2 = usePinInputField({ context })
  // const input3 = usePinInputField({ context })
  // const input4 = usePinInputField({ context })
  // const input5 = usePinInputField({ context })
  // const input6 = usePinInputField({ context })

  const [pincode, setPincode] = useState("");

  let size = useBreakpointValue({ base: "md", md: "lg" });
  function chianName(chainId) {
    chainId = chainId.toLowerCase();
    return EthereumChains[chainId] || "Unsupported Network";
  }
  // const wallet = useContext(AFWalletContext);
  // console.log('HomeScreen - wallet: ', wallet.instanceNo);
  async function changeNetwork(newChainId) {
    //
    if (newChainId === chainId) {
      return;
    }

    setChianId(newChainId);
    window.chainId = newChainId;

    if (newChainId === EthereumChains.Goerli) {
      window.web3Helper.init("goerli");
    } else if (newChainId === EthereumChains.Mainnet) {
      window.web3Helper.init("mainnet");
    } else if (newChainId === EthereumChains.Fuji) {
      window.web3Helper.init("fuji");
    } else if (newChainId === EthereumChains.Avalanche) {
      window.web3Helper.init("mainnet");
    } else if (newChainId === EthereumChains.Sepolia) {
      // window.web3Helper.init('sepolia');

      const sepOpts = {
        // rpcUrl: 'https://rpc.sepolia.org', // your ethereum, polygon, or optimism mainnet/testnet rpc URL
        // rpcUrl: 'https://polygon-mumbai-infura.wallet.coinbase.com?targetName=ethereum-sepolia',
        rpcUrl:
          "https://eth-sepolia.g.alchemy.com/v2/tApGyKU4AgHcem9l6xFsuMCdVe6mah6P",
        // rpcUrl: 'https://sepolia.rpc.thirdweb.com',
        chainId: 11155111,
      };
      window.web3Helper.init(sepOpts);
    } else {
      window.web3Helper.init("fuji");
    }
  }

  let connecting = false;
  useEffect(() => {
    checkAuth();
    if (window.wallet) {
      setAifiWalletIsDefaultWallet(window.wallet.asDefaultWallet);
    }

    console.log("HomeScreen - componentDidMount");
    window.wallet.hasMultipleAccounts().then((res) => {
      setShowSwitchAccount(res);

      if (
        !res &&
        window.appConfig.setupWizardState === SetupWizardState.Completed
      ) {
        const cfg = new URL(window.location.href).searchParams.get("cfg");
        try {
          const buf = ethers.decodeBase64(cfg);
          if (buf && buf.length > 0) {
            const str = new TextDecoder().decode(buf);
            if (str && str.length > 0) {
              const obj = JSON.parse(str);
              if (obj && obj.pin && obj.pin.length > 0) {
                setPincode(obj.pin);
                unlockClicked(obj.pin, true);
              }
            }
          }
        } catch (e) {}
      }
    });
    window.wallet
      .isBackup()
      .then((isBackup) => {
        setAlreadyBackup(isBackup);
        console.log("isAlreadyBackup:", isBackup);

        return window.wallet.getActivatedWallet();
      })
      .then((activatedWallet) => {
        if (
          activatedWallet &&
          activatedWallet.address.toLowerCase() ===
            window.appConfig.recentActiveAccount
        ) {
          setActivatedWallet(activatedWallet);
        } else {
          // console.warn('Activated Wallet not match pleximail recent account');
        }
      });

    if (window.appConfig.setupWizardState !== SetupWizardState.Completed) {
      if (window.web3Helper.ethereum) {
        /*
        if (!isConnecting && !connecting && window.appConfig.isPushApiTokenConfigured) {
          connectWallet().then(()=> {
  
          }).catch((e) => {
            console.error(e);
          });
        }
        */
        // onOpen();
      } else {
        if (isUnsupportedBrowser) {
          const type = window.wallet.rpcProviderType();
          if (type === AFWallet.RPCProviderType.Other) {
            welcomeDisclosure.onOpen();
          }
        } else {
          const type = window.wallet.rpcProviderType();
          if (type === AFWallet.RPCProviderType.Other) {
            if (window.appConfig.isNewUser) {
              welcomeDisclosure.onOpen();
            } else {
              walletNotFoundDisclosure.onOpen();
            }
          }
        }
      }
    }
    
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const icpAuthDisclosure = useDisclosure();
  const [icpAuthTask, setIcpAuthTask] = useState(null);
  const showIcpAuthIntroIfNeeded = async () => {
    if (localStorage.getItem('internet.identity.auth.intro.disabled')) {
      return;
    }

    const task = {}
    const promise = new Promise((resolve, reject) => {
      task.resolve = resolve;
      task.reject = reject;
    })
    task.promise = promise;

    setIcpAuthTask(task);

    icpAuthDisclosure.onOpen();
    const disabled = await promise;
    localStorage.setItem('internet.identity.auth.intro.disabled', disabled);
  }

  const icpLogin = async() => {
    await showIcpAuthIntroIfNeeded();
    await login();
  };

  function togglePassword() {
    setShowPassword(!showPassword);
  }

  async function unlock(pinCode) {
    try {
      const password = pinCode || passwordRef.current.value;
      // const promise = new Promise((resolve, reject) => {
      //   window.mailService.verifyPassword(password).then(() => {
      //     resolve();
      //   }).catch(e => {
      //     reject(e);
      //   })
      // });
      // await promise;
      await window.mailService.verifyPassword(password);

      window.appConfig.masterKey = password;
    } catch (e) {
      console.error(e);
      throw ClientError.invalidParameterError(
        Strings.common.message.incorrect_password
      );
    }
  }

  async function unlockClicked(pinCode = null, ignoreError = false) {
    try {

      setUnlockInputIsVallid(false);
      // connecting = true;
      setConnecting(true);
      const isAuthenticated = await checkAuth();
      if (!isAuthenticated) {
        try {
          // await login();
          await icpLogin();
        } catch (e) {
          setConnecting(true);
          if ('UserInterrupt' !== e) {
            showError(e);
          }
          console.error(e);
          return;
        }
      }
      await unlock(pinCode);
      connecting = false;
      setConnecting(false);
      if (window.web3Helper.ethereum) {
        if (
          !isConnecting &&
          !connecting &&
          window.appConfig.isPushApiTokenConfigured
        ) {
          await connectWallet();
        }
      }

      connecting = false;
      setConnecting(false);
    } catch (e) {
      console.error(e);

      setUnlockInputIsVallid(true);
      setConnecting(false);
      if (!ignoreError) {
        showError(e);
      }
      setTimeout(() => {
        setPincode("");
        setUnlockInputIsVallid(false);
      }, 2000);
    }
  }
  async function connectClicked() {
    delete window.isOneClickResume;

    const isAuthenticated = await checkAuth();

    if (!isAuthenticated || window.appConfig.setupWizardState === SetupWizardState.ConnectWallet) {

      connecting = true;
      setConnecting(true);
      try {
        // await login();
        await icpLogin();
        const isRegistered = await window.plexiMailService.isRegistered();
        if (isRegistered) {
          setAction(HomeScreenAction.OneClickResume);
          window.isOneClickResume = true;
          await connectWallet();
          return;
        }
      } catch (e) {
        if ('UserInterrupt' !== e) {
          showError(e);
        }
        console.error(e);
        return;
      } finally {
        connecting = false;
        setConnecting(false);
      }
    }

    const walletIsConfigured = window.wallet
      ? await window.wallet.isConfigured()
      : false;
    if (walletIsConfigured) {
      await connectWallet();
    } else {
      await connectWallet();
      // privacyLevelSelectorDisclosure.onOpen();
    }
    // await connectWallet()
  }
  async function oneClickConnectClicked() {
    const isAuthenticated = await checkAuth();

    if (!isAuthenticated || window.appConfig.setupWizardState === SetupWizardState.ConnectWallet) {

      connecting = true;
      setConnecting(true);
      try {
        // await login();
        await icpLogin();

        const isRegistered = await window.plexiMailService.isRegistered();
        if (!isRegistered) {
          throw new Error("You have not registered the PlexiMail with the current Internet Identity.")
        }
      } catch (e) {
        console.error(e);
        if ('UserInterrupt' !== e) {
          showError(e);
        }
        return;
      } finally {
        connecting = false;
        setConnecting(false);
      }
    }
    
    window.isOneClickResume = true;

    await connectWallet();
  }
  async function connectWallet() {
    if (!window.web3Helper.ethereum) {
      connecting = false;
      setConnecting(false);
      return;
    }

    try {
      connecting = true;
      setConnecting(true);
      // await changeNetwork(chainId);
      if (window.appConfig.chainId && window.appConfig.chainId.length >= 0) {
        if (window.appConfig.recentChainId !== window.appConfig.chainId) {
          window.appConfig.recentChainId = window.appConfig.chainId;
        }
      } else {
        if (
          window.appConfig.recentChainId &&
          window.appConfig.recentChainId.length > 0
        ) {
          window.appConfig.chainId = window.appConfig.recentChainId;
        }
      }
      // await changeNetwork(window.appConfig.recentChainId);

      console.log("HomeScreen - connectWallet");
      let accounts = null;
      let rpcProviderType = window.wallet.rpcProviderType();
      if (rpcProviderType === AFWallet.RPCProviderType.Unset) {
        rpcProviderType = await window.wallet.changeRpcProviderType();
      }
      if (rpcProviderType === AFWallet.RPCProviderType.AFWallet) {
        accounts = await window.web3Helper.web3.eth.requestAccounts();
      } else {
        accounts = await window.web3Helper.web3.eth.requestAccounts();
        // if (chainId === EthereumChains.Sepolia) {
        //   accounts = await window.magic.wallet.connectToMetaMask();
        // } else {
        //   accounts = await window.magic.wallet.connectWithUI();
        // }
      }

      // const accounts = await window.web3Helper.web3.eth.requestAccounts();

      const selectedAddress =
        accounts.length > 0 ? accounts[0].toLowerCase() : "";
      if (!selectedAddress || selectedAddress.length === 0) {
        throw ClientError.invalidParameterError("No account in your wallet");
      }

      window.appConfig.recentActiveAccount = selectedAddress;
      window.appConfig.migrateIfNeeded(selectedAddress);
      // window.appConfig.chainId = chainId;

      if (window.appConfig.recentChainId !== window.appConfig.chainId) {
        window.appConfig.chainId = window.appConfig.recentChainId;
        await changeNetwork(window.appConfig.recentChainId);
      }

      if (
        window.appConfig.tempMasterKey &&
        window.appConfig.tempMasterKey.length > 0 &&
        (!window.appConfig.masterKey || window.appConfig.masterKey === "")
      ) {
        window.appConfig.masterKey = window.appConfig.tempMasterKey;
      }
      if (
        window.appConfig.tempMnemonic &&
        window.appConfig.tempMnemonic.length > 0 &&
        (!window.appConfig.mnemonic || window.appConfig.mnemonic === "")
      ) {
        window.appConfig.mnemonic = window.appConfig.tempMnemonic;
      }

      const accountIndex = window.appConfig.assignAccountIndex(selectedAddress);
      window.mailService.selectedAccountIndex = accountIndex;
      window.mailService.selectedAccount = selectedAddress;

      if (window.appConfig.isPushApiTokenConfigured) {
        await prepareToken(selectedAddress);
      } else {
        await authenticate(selectedAddress);
      }

      setAccounts(accounts);
      setSelectedAccount(selectedAddress);

      // await window.mailService.initCryptServiceIfNeeded();

      connecting = false;
      setConnecting(false);

      // trigger state machine
      if (
        window.appConfig.setupWizardState === SetupWizardState.ConnectWallet
      ) {
        // const isBackup = await window.wallet.isBackup();

        // !isBackup ||

        if (window.wallet.asDefaultWallet) {
          // && (window.appConfig.isNewCreatedUser)
          if (
            window.appConfig.plexiMailStatus === PlexiMailStatus.WaitForNew ||
            window.appConfig.plexiMailStatus ===
              PlexiMailStatus.WaitForOneClickNew
          ) {
            await window.mailService.initCryptServiceIfNeeded();
            window.appConfig.tempMnemonic = window.appConfig.mnemonic;
            window.appConfig.setupWizardState =
              SetupWizardState.ConfigWeb3Storage;
            setSetupWizardState(SetupWizardState.ConfigWeb3Storage);
          } else if (
            window.appConfig.plexiMailStatus ===
              PlexiMailStatus.WaitForRecovery ||
            window.appConfig.plexiMailStatus ===
              PlexiMailStatus.WaitForOneClickRecovery
          ) {
            window.appConfig.setupWizardState =
              SetupWizardState.SelectSetupMethod;
            setSetupWizardState(SetupWizardState.SelectSetupMethod);
          } else {
            window.appConfig.setupWizardState =
              SetupWizardState.SelectSetupMethod;
            setSetupWizardState(SetupWizardState.SelectSetupMethod);
          }

          // await window.mailService.initCryptServiceIfNeeded();
          // window.appConfig.tempMnemonic = window.appConfig.mnemonic;
          // window.appConfig.setupWizardState = SetupWizardState.ConfigWeb3Storage;
          // setSetupWizardState(SetupWizardState.ConfigWeb3Storage);

          // window.appConfig.setupWizardState = SetupWizardState.SelectSetupMethod;
          // setSetupWizardState(SetupWizardState.SelectSetupMethod);
        } else {
          window.appConfig.setupWizardState =
            SetupWizardState.SelectSetupMethod;
          setSetupWizardState(SetupWizardState.SelectSetupMethod);

          // await window.mailService.initCryptServiceIfNeeded();
          // window.appConfig.tempMnemonic = window.appConfig.mnemonic;
          // window.appConfig.setupWizardState = SetupWizardState.RestoreWeb3Storage;
          // setSetupWizardState(SetupWizardState.RestoreWeb3Storage);
        }
      } else {
        if (
          (SetupWizardState.ConfigSignal ===
            window.appConfig.setupWizardState ||
            SetupWizardState.ExportConfig ===
              window.appConfig.setupWizardState ||
            SetupWizardState.WaitForTransactionReceipt ===
              window.appConfig.setupWizardState ||
            SetupWizardState.Completed === window.appConfig.setupWizardState)
            // && !window.mailService.web3StorageClient
        ) {
          // await window.mailService.initCryptServiceIfNeeded();
          if (window.appConfig.storageProvider === StorageProvider.LightHouse) {
            await window.mailService.web3StorageClient.loadAgent();
          }
          await window.mailService.initFoldersIfNeeded(false, false)
          // await window.mailService.initWeb3StorageClient(
          //   window.appConfig.web3StorageApiToken
          // );
        }

        setSetupWizardState(window.appConfig.setupWizardState);
        if (window.appConfig.setupWizardState === SetupWizardState.Completed) {
          
          window.isUnlocked = true;
        }
      }
      /*
      if (window.appConfig.setupWizardState >= SetupWizardState.ConnectWallet && window.appConfig.setupWizardState < SetupWizardState.WaitForTransactionReceipt) {
        // const web3 =  window.web3Helper.web3;
        if (window.appConfig.setupWizardState === SetupWizardState.ConfigSignal) {
          const web3 = window.web3Helper.web3;
          const balance = await web3.eth.getBalance(selectedAddress);
          if (balance <= MinimalRequiredBalance) {
            faucetDisclosure.onOpen();
          } else {
            // if (window.wallet) {
            //   window.wallet.isBackup().then(isBackup => {
            //     if (!isBackup) {
            //       window.wallet.showUI(WalletUIStatus.Backup).then(() => {
            //       }).catch(e => {
            //         console.error(e);
            //         showError(e);
            //       });
            //     }
            //   }).then(() => {}).catch(e => {
            //     console.error(e);
            //     showError(e);
            //   })
            // }
          }
        }
      }
      */
    } catch (e) {
      console.error("failed to login: ", e);
      if (e.code === -32002) {
        connecting = false;
        setConnecting(false);
        showError(Strings.error.rpc[e.code + ""] || e.message);
        return;
      }
      connecting = false;
      setConnecting(false);
      showError(e);
    }
  }

  /*
  async function tryLiveDemo() {
      chainId = EthereumChains.Goerli;
      setChianId(EthereumChains.Goerli);
      //changeNetwork('0x5')
      await connectWallet()
  }
  */

  /*
  function bufferToHex (buffer) {
    return [...new Uint8Array (buffer)]
        .map (b => b.toString (16).padStart (2, "0"))
        .join ("");
  }
  */
  async function prepareToken(account) {
    try {
      await window.mailService.checkToken(window.appConfig.pushApiToken);
    } catch (e) {
      console.error(e);
      await authenticate(account);
    }
  }

  async function getNonce(account) {
    // const nonce = randomBytes(32);
    // const nonceInHex = bufferToHex(nonce);
    // return 'Hi there! Your special nonce: ' + nonceInHex;
    // // return fetch()
    const nonce = await window.mailService.getNonce(account);
    return nonce;
  }

  async function authenticate(account) {
    const message = await getNonce(account);
    // const msg = `0x${Buffer.from(exampleMessage, 'utf8').toString('hex')}`;
    // personal_sign
    const pwd =
      window.wallet && window.wallet.asDefaultWallet
        ? "--sign-the-message-silently--"
        : "";
    const web3 = window.web3Helper.web3;
    const signatureHex = await web3.eth.personal.sign(message, account, pwd);
    // const signatureHex = await window.web3Helper.ethereum.request({
    //   method: "personal_sign",
    //   params: [
    //     message,                    // 待签名的数据
    //     account
    //   ]
    // });
    const recoveredAddr = window.web3Helper.ecRecover({
      data: message,
      signature: signatureHex,
    });
    if (recoveredAddr.toLowerCase() === account) {
      window.appConfig.pushApiToken = await window.mailService.login({
        account: account,
        signature: signatureHex,
      });
    } else {
      throw ClientError.invalidParameterError(
        Strings.error.client.invalid_signature
      );
    }
  }

  const endPlaying = () => {
    setPlaying(false);
  };

  const startPlaying = () => {
    setPlaying(true);
  };

  const play = () => {
    let audio = document.getElementById("aifi_audio");
    audio.play();
  };

  const reset = () => {
    window.mailService
      .reset(true)
      .then(() => {
        return window.wallet.reset(true);
      })
      .then(() => {
        setSetupWizardState(SetupWizardState.ConnectWallet);
        window.appConfig.setupWizardState = SetupWizardState.ConnectWallet;
        window.forceQuit = true;
        setTimeout(function () {
          window.location.reload(true);
        });
      })
      .catch((e) => {
        console.error(e);
        showError(e);
      });
  };

  // const reset1 = () => {

  //   window.appConfig.mnemonic = null;
  //   window.appConfig.masterKey = null;
  //   const recentActiveAccount = window.appConfig.recentActiveAccount || '';
  //   localStorage.clear();
  //   window.appConfig.recentActiveAccount = recentActiveAccount;

  //   window.mailService.reset(true).then(() => {
  //       return window.wallet.reset(true);
  //   }).then(() => {
  //       setSetupWizardState(SetupWizardState.ConnectWallet);
  //       window.appConfig.setupWizardState = SetupWizardState.ConnectWallet;
  //       // window.location.reload();
  //       setTimeout(function(){
  //         window.location.reload(true);
  //       });
  //   }).catch((e) => {
  //       console.error(e);
  //       showError(e);
  //   });
  // };

  const menuBg = useColorModeValue("white", "gray.900");
  const menuBorderColor = useColorModeValue("gray.200", "gray.700");

  const [domainName, setDomainName] = useState(window.appConfig.domainName); 
  const [tabIndex, setTabIndex] = useState(window.appConfig.subscriptionType === "1" ? 1 : 0);
  const showBusinessCreationWindow = async () => {
    const isAuthenticated = await checkAuth();
      if (!isAuthenticated) {
        try {
          // await login();
          await icpLogin();
          enterpriseCreationDisclosure.onOpen();
        } catch (e) {
          setConnecting(true);
          if ('UserInterrupt' !== e) {
            showError(e);
          }
          console.error(e);
          return;
        }
      }

      enterpriseCreationDisclosure.onOpen();

  };
  
  const isBusinessEntityInvalid = (value=null) => {
    
    if (
      /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(
        value || domainName
      )
    ) {
        return false;
    }
    return true;
  }
  const isInvalid = isBusinessEntityInvalid(); // isBusinessEntityInvalid();

  return (
    <>
    <EnterpriseCreationModal disclosure={enterpriseCreationDisclosure} onComplete={(result) => {
      if (result) {
        setDomainName(result);
      }
    }} />
      <Box position={"relative"}>
        <Box
          justifyContent={{ base: "space-between", md: "flex-end" }}
          textAlign={"right"}
          pt={4}
          pr={4}
        >
          {homeScreenHasMenu &&
            window.appConfig.setupWizardState ===
              SetupWizardState.ConnectWallet && (
              <Menu>
                <MenuButton
                  py={2}
                  transition="all 0.3s"
                  _focus={{ boxShadow: "none" }}
                >
                  <HStack>
                    <Box display={{ base: "none", md: "flex" }}>
                      <FaEthereum size={12} />
                    </Box>
                    <Text fontSize="xs" color="gray.600">
                      {chianName(chainId)}
                    </Text>
                    <Box display={{ base: "none", md: "flex" }}>
                      <FiChevronDown />
                    </Box>
                  </HStack>
                </MenuButton>

                <MenuList bg={menuBg} borderColor={menuBorderColor}>
                  <MenuItem
                    onClick={() => {
                      changeNetwork(EthereumChains.Mainnet);
                    }}
                  >
                    <HStack>
                      <Text fontSize="xs" color="gray.600">
                        {" "}
                        Ethereum Mainnet
                      </Text>
                    </HStack>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      changeNetwork(EthereumChains.Sepolia); //'0xaa36a7')
                    }}
                  >
                    <HStack>
                      <Text fontSize="xs" color="gray.600">
                        {" "}
                        Sepolia
                      </Text>
                    </HStack>
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      changeNetwork(EthereumChains.Fuji); //'0xa869')
                    }}
                  >
                    <HStack>
                      <Text fontSize="xs" color="gray.600">
                        {" "}
                        Fuji
                      </Text>
                    </HStack>
                  </MenuItem>

                  {/* <MenuItem onClick={() => {
                changeNetwork(EthereumChains.Goerli)
              }}>
                <HStack>
                  <Text fontSize="xs" color="gray.600"> Goerli</Text>
                </HStack>
              </MenuItem> */}

                  <Divider orientation="horizontal" />

                  <MenuItem
                    onClick={() => {
                      window.open(HELP_DOC_URL, "_blank");
                    }}
                  >
                    <HStack>
                      <Text fontSize="xs" color="gray.600">
                        {" "}
                        Help
                      </Text>
                    </HStack>
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          {!homeScreenHasMenu &&
            window.appConfig.setupWizardState ===
              SetupWizardState.ConnectWallet && (
              <Link href={HELP_DOC_URL} target="_blank">
                <Text fontSize="xs" color="gray.600">
                  {" "}
                  Help
                </Text>
              </Link>
            )}
        </Box>
        <Container
          as={SimpleGrid}
          maxW={"7xl"}
          columns={{ base: 1, md: 2 }}
          spacing={{ base: 10, lg: 32 }}
          py={{ base: 10, sm: 20, lg: 32 }}
        >
          <Stack spacing={{ base: 10, md: 20 }}>
            <Heading
              lineHeight={1.1}
              fontSize={{ base: "3xl", sm: "4xl", md: "4xl", lg: "4xl" }}
            >
              <HStack alignItems="start">
                <Text>{Strings.home.promotion.title}</Text>{" "}
                <Link onClick={play} disabled={isPlaying}>
                  {isPlaying && <AiFillSound size={24} />}
                  {!isPlaying && <AiOutlineSound size={24} />}
                </Link>
                <audio
                  id="aifi_audio"
                  onEnded={endPlaying}
                  onPlay={startPlaying}
                >
                  <source src="pronounce.mp3" type="audio/mp3" />
                  {/* <span style="font-size: 0.4rem; color: ">Your browser does not support HTML5 audio element.<span> */}
                </audio>
              </HStack>
              <Text
                mt={4}
                fontSize={{ base: "3xl", sm: "3xl", md: "3xl", lg: "3xl" }}
              >
                {Strings.home.promotion.content.paragraph1}
              </Text>
              <Text fontSize={{ base: "3xl", sm: "3xl", md: "3xl", lg: "3xl" }}>
                {Strings.home.promotion.content.paragraph2}
              </Text>
              <Text fontSize={{ base: "3xl", sm: "3xl", md: "3xl", lg: "3xl" }}>
                {Strings.home.promotion.content.paragraph3}
              </Text>
              <Text fontSize={{ base: "3xl", sm: "3xl", md: "3xl", lg: "3xl" }}>
                {/* <Text
                as={'span'}
                bgGradient="linear(to-r, red.400,pink.400)"
                bgClip="text">
                 *
              </Text>  */}
                {Strings.home.promotion.content.paragraph4}
              </Text>
            </Heading>
            <Stack direction={"row"} spacing={4} align={"center"}>
              <AvatarGroup>
                {avatars.map((avatar) => (
                  <Avatar
                    key={avatar.name}
                    name={avatar.name}
                    src={avatar.url}
                    size={size}
                    position={"relative"}
                    zIndex={2}
                    _before={{
                      content: '""',
                      width: "full",
                      height: "full",
                      rounded: "full",
                      transform: "scale(1.125)",
                      bgGradient: "linear(to-bl, red.400, pink.400)",
                      position: "absolute",
                      zIndex: -1,
                      top: 0,
                      left: 0,
                    }}
                  />
                ))}
              </AvatarGroup>
              <Text
                fontFamily={"heading"}
                fontSize={{ base: "4xl", md: "6xl" }}
              >
                +
              </Text>
              <Flex
                align={"center"}
                justify={"center"}
                fontFamily={"heading"}
                fontSize={{ base: "sm", md: "lg" }}
                bg={"gray.800"}
                color={"white"}
                rounded={"full"}
                width={useBreakpointValue({ base: "44px", md: "60px" })}
                height={useBreakpointValue({ base: "44px", md: "60px" })}
                position={"relative"}
                _before={{
                  content: '""',
                  width: "full",
                  height: "full",
                  rounded: "full",
                  transform: "scale(1.125)",
                  bgGradient: "linear(to-bl, orange.400,yellow.400)",
                  position: "absolute",
                  zIndex: -1,
                  top: 0,
                  left: 0,
                }}
              >
                YOU
              </Flex>

              {/* <Link onClick={()=> {
            test().then(() => {}).catch(e => console.error(e));
          }}>test</Link> */}
            </Stack>
          </Stack>

          
          {window.appConfig.setupWizardState !== SetupWizardState.Completed && (

          <Tabs position='relative' variant='unstyled' index={tabIndex} onChange={(index) => {
            window.appConfig.subscriptionType = index;
            setTabIndex(index)
          }}>
            <TabList>
              <Tab>Individual</Tab>
              {ENTERPRISE_FEATURES_ENABLED && <Tab>Business</Tab>}
            </TabList>
            <TabIndicator mt='-1.5px' height='2px' bg='pink.400' borderRadius='1px' />
            <TabPanels>
              <TabPanel>
                  
                <Stack
                  bg={"gray.50"}
                  rounded={"xl"}
                  p={{ base: 4, sm: 6, md: 8 }}
                  spacing={{ base: 8 }}
                  maxW={{ lg: "lg" }}
                >
                  <Stack spacing={4}>
                    <Heading
                      color={"gray.800"}
                      lineHeight={1.1}
                      fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
                    >
                      {/* <Text
                    as={'span'}
                    bgGradient="linear(to-r, red.400,pink.400)"
                    bgClip="text">
                    {Strings.home.signIn.step} &nbsp;
                  </Text>{Strings.home.signIn.title} */}
                      {Strings.home.signIn.title_1}
                      <Text
                        as={"span"}
                        bgGradient="linear(to-r, red.400,pink.400)"
                        bgClip="text"
                      >
                        &nbsp;Zero&nbsp;
                      </Text>
                      {Strings.home.signIn.title_2}
                    </Heading>
                    <Text fontSize={{ base: "md", sm: "lg" }} fontWeight="bold">
                      {Strings.home.signIn.description_1}
                      {/* <Link style={{color: '#00aaff'}} onClick={() => {tutorialDisclosure.onOpen()}} target={"_blank"}>Learn more</Link> */}
                      {/* <Link style={{color: '#00aaff'}} href={HELP_DOC_URL} target={"_blank"}>Learn more</Link> */}
                    </Text>
                    {/* <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
                {Strings.home.signIn.description_2} 
                </Text> */}

                    <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
                      {/* <Image src='/avatar/metamask.svg' align={"middle"} height={"20px"} style={{display: 'inline', verticalAlign: 'bottom'}} /> */}
                      {isAlreadyBackup
                        ? Strings.home.signIn.description_3_continue
                        : Strings.home.signIn.description_3}{" "}
                      <TutorialModalLink
                        title={"Learn more"}
                        anchor={"pleximail-address"}
                      />
                    </Text>

                    <Text color={"black"} fontSize={{ base: "sm", sm: "md" }}>
                      {isAlreadyBackup
                        ? Strings.home.signIn.note_continue
                        : Strings.home.signIn.note}
                    </Text>

                    {activatedWallet && (
                      <>
                        <Text
                          alignSelf={"center"}
                          fontSize={"sm"}
                          fontWeight={"bold"}
                          color={"pink.400"}
                        >
                          {activatedWallet.name}
                        </Text>

                        {/* <Text alignSelf={"center"} fontSize={"xs"} color={"gray.600"}>{activatedWallet.address}{mailAddressSuffix()}</Text> */}
                      </>
                    )}
                    {/* <Link src="https://ai-fi.cc/help.html">Find out more and various faucet options</Link> */}
                  </Stack>
                  <Box as={"form"} mt={5}>
                    {window.appConfig.setupWizardState ===
                      SetupWizardState.ConnectWallet && (
                      <>
                        <Button
                          fontFamily={"heading"}
                          mt={4}
                          w={"full"}
                          bgGradient="linear(to-r, red.400,pink.400)"
                          color={"white"}
                          // onClick={connectWallet}
                          onClick={async () => {
                            setAction(HomeScreenAction.Enter);
                            await connectClicked()
                          }}
                          isLoading={isConnecting && action === HomeScreenAction.Enter}
                          leftIcon={<ICPIcon width={"24px"} />}
                          _hover={{
                            bgGradient: "linear(to-r, red.400,pink.400)",
                            boxShadow: "xl",
                          }}
                        >
                          
                          {/* <ClipLoader size={20} color={'white'} loading={true} />  */}
                          <Text>
                            {isAlreadyBackup
                              ? Strings.home.signIn.continue
                              : Strings.home.signIn.button}
                          </Text>
                        </Button>
                        {/* <Button
                          fontFamily={"heading"}
                          mt={4}
                          w={"full"}
                          color={"white"}
                          onClick={async() => {
                            setAction(HomeScreenAction.OneClickResume);
                            await oneClickConnectClicked()
                          }}
                          isLoading={isConnecting && action === HomeScreenAction.OneClickResume}
                          disabled={isConnecting}
                          hidden={isAlreadyBackup}
                          bgGradient="linear(to-r, gray.600,gray.400)"
                          _hover={{
                            bgGradient: "linear(to-r, gray.400,gray.600)",
                            boxShadow: "xl",
                          }}
                        >
                          <Text>{Strings.home.signIn.one_click_resume}</Text>
                        </Button> */}
                        {window.appConfig.privacyLevelIsNormal &&
                          showSwitchAccount && (
                            <Flex
                              width={"100%"}
                              mt={3}
                              direction={"row"}
                              justifyContent={"flex-end"}
                              align="center"
                            >
                              <Link
                                color="#00aaff"
                                onClick={() => {
                                  window.wallet.showSwitchWallet();
                                }}
                              >
                                {Strings.home.signIn.switch_wallet}
                              </Link>
                            </Flex>
                          )}
                      </>
                    )}
                    {window.appConfig.setupWizardState !==
                      SetupWizardState.ConnectWallet && (
                      <>
                        <Text color={"orange"} fontSize={{ base: "sm", sm: "md" }}>
                          {Strings.home.signIn.incomplete_setup_detected}
                        </Text>
                        <Button
                          fontFamily={"heading"}
                          mt={4}
                          w={"full"}
                          bgGradient="linear(to-r, red.400,pink.400)"
                          color={"white"}
                          onClick={async() => {
                            setAction(HomeScreenAction.Resume);
                            await connectWallet()
                          }}
                          // onClick={connectClicked}
                          isLoading={isConnecting && action === HomeScreenAction.Resume}
                          _hover={{
                            bgGradient: "linear(to-r, red.400,pink.400)",
                            boxShadow: "xl",
                          }}
                        >
                          {/* <ClipLoader size={20} color={'white'} loading={true} />  */}
                          <Text>{Strings.home.signIn.resume}</Text>
                        </Button>
                        <Button
                          fontFamily={"heading"}
                          mt={4}
                          w={"full"}
                          color={"white"}
                          onClick={reset}
                          disabled={isConnecting}
                          bgGradient="linear(to-r, gray.600,gray.400)"
                          _hover={{
                            bgGradient: "linear(to-r, gray.400,gray.600)",
                            boxShadow: "xl",
                          }}
                        >
                          {/* <ClipLoader size={20} color={'white'} loading={true} />  */}
                          <Text>{Strings.home.signIn.reset}</Text>
                        </Button>
                      </>
                    )}
                    {isUnsupportedBrowser && (
                      <UnsupportedModal disclosure={welcomeDisclosure} />
                    )}
                    {isSupportedBrowser && (
                      <>
                        <NewUserModal
                          disclosure={welcomeDisclosure}
                          tutorialDisclosure={tutorialDisclosure}
                        />
                        <InstallWalletModal disclosure={walletNotFoundDisclosure} />
                        <TutorialModal disclosure={tutorialDisclosure} />
                      </>
                    )}
                  </Box>
                </Stack>
              </TabPanel>
              {ENTERPRISE_FEATURES_ENABLED &&
              <TabPanel>
                <Stack
                  bg={"gray.50"}
                  rounded={"xl"}
                  p={{ base: 4, sm: 6, md: 8 }}
                  spacing={{ base: 8 }}
                  maxW={{ lg: "lg" }}
                >
                  <Stack spacing={4}>
                    <Heading
                      color={"gray.800"}
                      lineHeight={1.1}
                      fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
                    >
                      {Strings.home.business.title_1}
                      <Text
                        as={"span"}
                        bgGradient="linear(to-r, red.400,pink.400)"
                        bgClip="text"
                      >
                        &nbsp;Zero&nbsp;
                      </Text>
                      {Strings.home.business.title_2}
                    </Heading>
                    <Text fontSize={{ base: "md", sm: "lg" }} fontWeight="bold">
                      {Strings.home.business.description_1}
                    </Text>

                    <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
                    {Strings.home.business.description_2} 
                    </Text>

                    <FormControl isInvalid={isInvalid}>
                      <FormLabel>Business Entity</FormLabel>
                      <Input type='text' defaultValue={window.appConfig.domainName || ''} value={domainName} placeholder={"eg. ai-fi.net"} onChange={(e) => {
                        setDomainName(e.target.value);
                        if (!isBusinessEntityInvalid(e.target.value)) {
                          window.appConfig.domainName = e.target.value
                        }
                      }} />
                      {isInvalid
                        ? <FormErrorMessage>Business entity is invalid.</FormErrorMessage> 
                        : <FormHelperText textAlign={"right"}><Link textDecoration={"underline"} href="#" onClick={() => {
                          showBusinessCreationWindow().then(() => {}).catch(e => {
                            console.error(e);
                            showError(e);
                          })
                          }}>Not subscribed to PlexiMail for business yet?</Link></FormHelperText>}
                      
                      
                    </FormControl>
                    {/* <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
                      {isAlreadyBackup
                        ? Strings.home.business.description_3_continue
                        : Strings.home.business.description_3}{" "}
                      <TutorialModalLink
                        title={"Learn more"}
                        anchor={"pleximail-address"}
                      />
                    </Text> */}

                    <Text color={"black"} fontSize={{ base: "sm", sm: "md" }}>
                      {isAlreadyBackup
                        ? Strings.home.business.note_continue
                        : Strings.home.business.note}
                    </Text>

                    {activatedWallet && (
                      <>
                        <Text
                          alignSelf={"center"}
                          fontSize={"sm"}
                          fontWeight={"bold"}
                          color={"pink.400"}
                        >
                          {activatedWallet.name}
                        </Text>

                        {/* <Text alignSelf={"center"} fontSize={"xs"} color={"gray.600"}>{activatedWallet.address}{mailAddressSuffix()}</Text> */}
                      </>
                    )}
                    {/* <Link src="https://ai-fi.cc/help.html">Find out more and various faucet options</Link> */}
                  </Stack>
                  <Box as={"form"} mt={5}>
                    {window.appConfig.setupWizardState ===
                      SetupWizardState.ConnectWallet && (
                      <>
                        <Button
                          fontFamily={"heading"}
                          mt={4}
                          w={"full"}
                          bgGradient="linear(to-r, red.400,pink.400)"
                          color={"white"}
                          // onClick={connectWallet}
                          onClick={async () => {
                            setAction(HomeScreenAction.Enter);
                            await connectClicked()
                          }}
                          isDisabled={isInvalid}
                          disabled={isInvalid}
                          isLoading={isConnecting && action === HomeScreenAction.Enter}
                          leftIcon={<ICPIcon width={"24px"} />}
                          _hover={{
                            bgGradient: "linear(to-r, red.400,pink.400)",
                            boxShadow: "xl",
                          }}
                        >
                          
                          {/* <ClipLoader size={20} color={'white'} loading={true} />  */}
                          <Text>
                            {isAlreadyBackup
                              ? Strings.home.signIn.continue
                              : Strings.home.signIn.button}
                          </Text>
                        </Button>
                        <Button
                          fontFamily={"heading"}
                          mt={4}
                          w={"full"}
                          color={"white"}
                          onClick={async() => {
                            setAction(HomeScreenAction.OneClickResume);
                            await oneClickConnectClicked()
                          }}
                          isLoading={isConnecting && action === HomeScreenAction.OneClickResume}
                          disabled={isConnecting || isInvalid}
                          isDisabled={isConnecting || isInvalid}
                          hidden={isAlreadyBackup}
                          bgGradient="linear(to-r, gray.600,gray.400)"
                          _hover={{
                            bgGradient: "linear(to-r, gray.400,gray.600)",
                            boxShadow: "xl",
                          }}
                        >
                          {/* <ClipLoader size={20} color={'white'} loading={true} />  */}
                          <Text>{Strings.home.signIn.one_click_resume}</Text>
                        </Button>
                        {window.appConfig.privacyLevelIsNormal &&
                          showSwitchAccount && (
                            <Flex
                              width={"100%"}
                              mt={3}
                              direction={"row"}
                              justifyContent={"flex-end"}
                              align="center"
                            >
                              <Link
                                color="#00aaff"
                                onClick={() => {
                                  window.wallet.showSwitchWallet();
                                }}
                              >
                                {Strings.home.signIn.switch_wallet}
                              </Link>
                            </Flex>
                          )}
                      </>
                    )}
                    {window.appConfig.setupWizardState !==
                      SetupWizardState.ConnectWallet && (
                      <>
                        <Text color={"orange"} fontSize={{ base: "sm", sm: "md" }}>
                          {Strings.home.signIn.incomplete_setup_detected}
                        </Text>
                        <Button
                          fontFamily={"heading"}
                          mt={4}
                          w={"full"}
                          bgGradient="linear(to-r, red.400,pink.400)"
                          color={"white"}
                          onClick={async() => {
                            setAction(HomeScreenAction.Resume);
                            await connectWallet()
                          }}
                          // onClick={connectClicked}
                          isLoading={isConnecting && action === HomeScreenAction.Resume}
                          _hover={{
                            bgGradient: "linear(to-r, red.400,pink.400)",
                            boxShadow: "xl",
                          }}
                        >
                          {/* <ClipLoader size={20} color={'white'} loading={true} />  */}
                          <Text>{Strings.home.signIn.resume}</Text>
                        </Button>
                        <Button
                          fontFamily={"heading"}
                          mt={4}
                          w={"full"}
                          color={"white"}
                          onClick={reset}
                          disabled={isConnecting}
                          bgGradient="linear(to-r, gray.600,gray.400)"
                          _hover={{
                            bgGradient: "linear(to-r, gray.400,gray.600)",
                            boxShadow: "xl",
                          }}
                        >
                          {/* <ClipLoader size={20} color={'white'} loading={true} />  */}
                          <Text>{Strings.home.signIn.reset}</Text>
                        </Button>
                      </>
                    )}
                    {isUnsupportedBrowser && (
                      <UnsupportedModal disclosure={welcomeDisclosure} />
                    )}
                    {isSupportedBrowser && (
                      <>
                        <NewUserModal
                          disclosure={welcomeDisclosure}
                          tutorialDisclosure={tutorialDisclosure}
                        />
                        <InstallWalletModal disclosure={walletNotFoundDisclosure} />
                        <TutorialModal disclosure={tutorialDisclosure} />
                      </>
                    )}
                  </Box>
                </Stack>
              </TabPanel>
              }
            </TabPanels>
          </Tabs>
          )}

          {window.appConfig.setupWizardState === SetupWizardState.Completed && (
            <Stack
              bg={"gray.50"}
              rounded={"xl"}
              p={{ base: 4, sm: 6, md: 8 }}
              spacing={{ base: 8 }}
              maxW={{ lg: "lg" }}
            >
              <Stack spacing={4}>
                <Heading
                  color={"gray.800"}
                  lineHeight={1.1}
                  fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
                >
                  <Text
                    as={"span"}
                    bgGradient="linear(to-r, red.400,pink.400)"
                    bgClip="text"
                  >
                    {aifiWalletIsDefaultWallet
                      ? Strings.home.unlock.title_pin
                      : Strings.home.unlock.title}
                    {/* {Strings.home.unlock.title} */}
                  </Text>
                </Heading>
              </Stack>

              <Stack>
                {activatedWallet && (
                  <>
                    <Text
                      alignSelf={"center"}
                      fontSize={"sm"}
                      fontWeight={"bold"}
                      color={"pink.400"}
                    >
                      {activatedWallet.name}
                    </Text>

                    {/* <Text alignSelf={"center"} fontSize={"xs"} color={"gray.600"}>{activatedWallet.address}{mailAddressSuffix()}</Text> */}
                  </>
                )}
                {aifiWalletIsDefaultWallet && (
                  <HStack mt={2} justifyContent="center">
                    <PinInput
                      value={pincode}
                      isDisabled={unlockInputIsDisabled}
                      isInvalid={unlockInputIsVallid}
                      autoFocus={true}
                      type="number"
                      mask={true}
                      onChange={(value) => {
                        setPincode(value);
                      }}
                      onComplete={(password) => {
                        window.appConfig.idleTimeout = false;
                        setUnlockInputDisabled(true);
                        unlockClicked(password)
                          .then(() => {
                            setUnlockInputDisabled(false);
                          })
                          .catch((e) => {
                            setUnlockInputDisabled(false);
                          });
                      }}
                    >
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                )}
                {aifiWalletIsDefaultWallet &&
                  !isConnecting &&
                  window.appConfig.idleTimeout && (
                    <Flex width={"100%"} direction={"column"} align="center">
                      <HStack mt={5} align="center">
                        <Text color="red">
                          {Strings.home.unlock.idle_timeout}
                        </Text>
                      </HStack>
                    </Flex>
                  )}

                {aifiWalletIsDefaultWallet && isConnecting && (
                  <Flex width={"100%"} direction={"column"} align="center">
                    <HStack mt={5} align="center">
                      <Spinner size={"sm"} color="orange" />
                      <Text color="orange">Unlocking...</Text>
                    </HStack>
                  </Flex>
                )}
                {/* <FormLabel htmlFor='country'>{Strings.pwd.initial.tf.pwd.title}</FormLabel> */}

                {!aifiWalletIsDefaultWallet && (
                  <InputGroup>
                    <Input
                      autoComplete="off"
                      type={showPassword ? "text" : "password"}
                      ref={passwordRef}
                      onChange={(e) => {
                        const v = e.target.value || "";
                        const disabled = v === "";
                        if (disabled !== unlockDisabled) {
                          setUnlockDisabled(disabled);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          unlockClicked();
                        }
                        // console.log('on key down: ', e);
                      }}
                    />

                    <InputRightElement>
                      {showPassword && (
                        <IconButton icon={<FaEye />} onClick={togglePassword} />
                      )}
                      {!showPassword && (
                        <IconButton
                          icon={<FaEyeSlash />}
                          onClick={togglePassword}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                )}
              </Stack>

              <Box as={"form"} mt={4}>
                <Button
                  fontFamily={"heading"}
                  mt={4}
                  w={"full"}
                  bgGradient="linear(to-r, red.400,pink.400)"
                  color={"white"}
                  hidden={aifiWalletIsDefaultWallet}
                  onClick={unlockClicked}
                  disabled={unlockDisabled}
                  isLoading={isConnecting}
                  _hover={{
                    bgGradient: "linear(to-r, red.400,pink.400)",
                    boxShadow: "xl",
                  }}
                >
                  {/* <ClipLoader size={20} color={'white'} loading={true} />  */}
                  <Text>{Strings.home.unlock.button.unlock}</Text>
                </Button>
                <Flex mt={3} justifyContent={"end"}>
                  <Link
                    alignSelf={"right"}
                    onClick={() => {
                      forgetPasswordDisclosure.onToggle();
                    }}
                  >
                    {Strings.home.unlock.button.forgot}
                  </Link>
                </Flex>

                {window.appConfig.privacyLevelIsNormal && showSwitchAccount && (
                  <Flex
                    width={"100%"}
                    mt={3}
                    direction={"row"}
                    justifyContent={"flex-end"}
                    align="center"
                  >
                    <Link
                      color="#00aaff"
                      onClick={() => {
                        window.wallet.showSwitchWallet();
                      }}
                    >
                      {Strings.home.unlock.button.switch_wallet}
                    </Link>
                  </Flex>
                )}
                <ForgetPasswordAlert disclosure={forgetPasswordDisclosure} />
              </Box>
            </Stack>
          )}
        </Container>
        <Blur
          position={"absolute"}
          top={-10}
          left={-10}
          style={{ filter: "blur(70px)" }}
        />
      </Box>
      <PrivacyLevelSelectorAlert
        disclosure={privacyLevelSelectorDisclosure}
        onComplete={(err) => {
          if (err) {
            showError(err);
            return;
          }
          connectWallet();
        }}
      />
      <InternetIdentityAuthAlert onCompleted={(disabled) => {
        icpAuthTask && icpAuthTask.resolve(disabled);
      }} disclosure={icpAuthDisclosure} />
    </>
  );
}

export const Blur = (props) => {
  return (
    <Icon
      width={useBreakpointValue({ base: "100%", md: "40vw", lg: "30vw" })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  );
};

/*export default function HomeScreen() {
  const ob = useDisclosure()

return (
  <>
    <Button onClick={ob.onOpen}>Open Modal</Button>

    <Modal blockScrollOnMount={false} isOpen={ob.isOpen} onClose={ob.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Modal Title</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontWeight='bold' mb='1rem'>
            You can scroll the content behind the modal
          </Text>
          // { <Lorem count={2} /> }
        </ModalBody>

        <ModalFooter>
          <Button colorScheme='blue' mr={3} onClick={ob.onClose}>
            Close
          </Button>
          <Button variant='ghost'>Secondary Action</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  </>
)
}
*/
