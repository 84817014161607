// import './App.css';

import React, { useEffect, useState } from "react";
import {
  Avatar,
  HStack,
  VStack,
  Text,
  List,
  ListItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  InputGroup,
  Input,
  InputRightAddon,
  InputRightElement,
  IconButton,
  Divider,
  Center,
  Spinner,
  Flex,
  FormErrorMessage,
  // Switch
} from "@chakra-ui/react";

// import appConfig from '../config/AppConfig';
import { showError, showSuccess } from "../common/errors";
import { FiLock, FiTrash, FiDatabase, FiPocket, FiMail, FiSearch, FiArchive, FiZap, FiMessageCircle, FiFileText } from "react-icons/fi";
import { GoUnverified, GoVerified, GoLock } from "react-icons/go";
import { ContractConstants } from "../utils/Types";
import { ContractThreadLevel } from "./contract/ContractCommon";
import Strings from "../config/Strings";
import SegmentControl from "./contract/SegmentControl";

// import { useToasts } from 'react-toast-notifications'
const SearchBar = ({inputError, setInputError, onSearch}) => {
    const [searchType, setSearchType] = useState("1");
    const [conditions, setConditions] = useState({
        keyword: '',
        from: null,
        subject: null,
        after: null,
        before: null,
    });
    return (<VStack mb={2}>
      <FormControl isInvalid={inputError}>

        <InputGroup flex={1}>
          <Input autoFocus={true} value={conditions.keyword} onChange={(e) => {
              const keyword = e.target.value || '';
              if (keyword.length > 100) {
                setInputError('The keyword exceeds the length limit');
                return;
              } else {
                if (inputError) {
                  setInputError(null);
                }
              }
              let from = null;
              let subject = null;
              if (searchType === '1') {
                subject = keyword;
              } else if (searchType === '2') {
                from = keyword;
                
              } else if (searchType === '3') {
                from = keyword;
                subject = keyword;
              }
              const newConditions = {keyword, subject, from, after: conditions.after, before: conditions.before};
              setConditions(newConditions);
          }}  onKeyDown={(e) => {
              if (e.code === 'Enter') {
                  onSearch(conditions);
              }
          }}/>
          <InputRightElement>
          <IconButton icon={<FiSearch />} onClick={() => { onSearch(conditions)}} />
          </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{inputError}</FormErrorMessage>

        </FormControl>
        <HStack width={"100%"} justifyContent={"left"}>
        <SegmentControl label={"Scope:"} options={[{name: "Subject", value: "1"}, {name: "From", value: "2"}, {name: "All", value: "3"}]} defaultValue={searchType} onChange={(searchType) => {
            
            const keyword = conditions.keyword;
            let from = null;
            let subject = null;
            if (searchType === '1') {
              subject = keyword;
            } else if (searchType === '2') {
              from = keyword;
              
            } else if (searchType === '3') {
              from = keyword;
              subject = keyword;
            }
            const newConditions = {keyword, subject, from, after: conditions.after, before: conditions.before};
            setConditions(newConditions);
            setSearchType(searchType);
          }} />
        </HStack>

        <HStack width={"100%"} justifyContent={"left"}>
          <Text fontSize={"xs"}>From</Text>
          <Input placeholder="Select Date and Time" size="sm" type="date" onChange={(e) => {
            try {
              const date = new Date(e.target.value);
              const after = date ? date.getTime() : 0;
              const newConditions = {...conditions, after};
              setConditions(newConditions);
            } catch (e) {
              console.error('Error: ', e);
            }
          }} />

          <Text fontSize={"xs"}>To</Text>{/*datetime-local*/}
          <Input placeholder="Select Date and Time" size="sm" type="date" onChange={(e) => {
            try {
              const date = new Date(e.target.value);
              const before = date ? date.getTime() : 0;
              const newConditions = {...conditions, before};
              setConditions(newConditions);
            } catch (e) {
              console.error('Error: ', e);
            }
          }} />
        </HStack>
        <Divider />
    </VStack>)
};

const SearchResultList = ({isSearching, selectedAccount, searchResults, onSelected }) => {

  const searchResultToItem = (searchResult) => {
    if (searchResult.type === 'message') {
        return {
            type: '[Message]', 
            color: 'blue',
            icon: <FiMail />, 
            sender: `From ${searchResult.message.from}`, 
            title: searchResult.message.decryptedHeader ? searchResult.message.decryptedHeader.subject : 'This is an unread PlexiMail',
            time: `Created At ${new Date(searchResult.message.sendDate).toLocaleString()}`
        }
    }
    if (searchResult.type === 'contract') {
        return {
            type: '[Contract]', 
            color: searchResult.contract.mode ===
            ContractConstants.ThreadMode.QuickSign ? 'green' : 'orange',
            icon: searchResult.contract.mode ===
            ContractConstants.ThreadMode.QuickSign ? <FiZap /> : <FiArchive />, 
            sender: `Initiator ${searchResult.contract.moderator}`,
            title: searchResult.contract.title || 'This is an unread Contract',
            time: `Created At ${new Date(searchResult.contract.created).toLocaleString()}`
        }
    }
    if (searchResult.type === 'discussion') {
        return {
            type: '[Discussion]', 
            color: 'purple',
            icon: <FiMessageCircle />, 
            sender: `From ${searchResult.discussion.from}`,
            title: searchResult.discussion.decryptedHeader ? searchResult.discussion.decryptedHeader.subject : 'This is an unread PlexiMail',
            time: `Created At ${new Date(searchResult.discussion.sendDate).toLocaleString()}`
        }
    }
    if (searchResult.type === 'signatureRound') {
        return {
            type: '[Signature Round]', 
            color: 'purple',
            icon: <FiMessageCircle />, 
            sender: `Initiator ${searchResult.signatureRound.moderator}`,
            title: `${searchResult.contract.title || 'Unread Contract'} / ${searchResult.signatureRound.title || 'Unread Signature Round'}`,
            time: `Created At ${new Date(searchResult.signatureRound.created).toLocaleString()}`
        }
    }
    return {
        type: '[Unknown]', 
        color: 'gray',
        icon: <FiMessageCircle />, 
        sender: 'Unknown',
        title: `Unknown`,
        time: `Unknown`
    }
  }
  return (
    <List>
        {!isSearching && searchResults && searchResults.length > 0 && searchResults.map(searchResult => {
            const item = searchResultToItem(searchResult);
            return (
                <ListItem onClick={() => {
                    onSelected(searchResult)
                }} cursor={"pointer"}>
                <HStack borderBottom={"1px solid rgb(226, 232, 240)"} pt={"4px"} pb={"4px"}>
                  <Avatar icon={item.icon} background={"none"} textColor={"gray"} />
                  <VStack align={"left"}>
        
                    <HStack>
                        <Text fontSize={"xs"} fontWeight={"bold"} color={item.color}>
                            {item.type}
                        </Text>
                        <Text fontSize={"xs"} fontWeight={"bold"} wordBreak={"break-all"}>{item.title}</Text>
                    </HStack>
                    <Text fontSize={"xs"} color={"gray"}>{item.time}</Text>
                    <Text fontSize={"xs"} color={"gray"}>{item.sender}</Text>
                  </VStack>
                </HStack>
              </ListItem>)
        })
        }
        {!isSearching && (!searchResults || searchResults.length === 0) &&
                (<ListItem>
                  <Center>{Strings.app.dialog.search.noResults}</Center>
              </ListItem>)
        }
        {isSearching && 
        (<ListItem>
            <Center><HStack><Spinner size={"sm"} /> <Text>Searching...</Text></HStack></Center>
        </ListItem>)
        }
      {/* <ListItem onClick={() => {}} cursor={"pointer"}>
        <HStack borderBottom={"1px solid rgb(226, 232, 240)"} pt={"4px"} pb={"4px"}>
          <Avatar icon={<FiMail />} background={"none"} textColor={"gray"} />
          <VStack align={"left"}>

            <HStack>
                <Text fontSize={"xs"} fontWeight={"bold"} color={"blue"}>
                    [Message]
                </Text>
                <Text fontSize={"xs"} fontWeight={"bold"} wordBreak={"break-all"}>{`Subject XXXXXXXXX`}</Text>
            </HStack>
            <Text fontSize={"xs"} color={"gray"}>{`Sent At ${new Date().toLocaleString()}`}</Text>
            <Text fontSize={"xs"} color={"gray"}>{`From zhanleewo@gmail.com`}</Text>
          </VStack>
        </HStack>
      </ListItem>
      <ListItem onClick={() => {}} cursor={"pointer"}>
        <HStack borderBottom={"1px solid rgb(226, 232, 240)"} pt={"4px"} pb={"4px"}>
          <Avatar icon={<FiArchive />} background={"none"} textColor={"gray"} />
          <VStack align={"left"}>

            <HStack>
                <Text fontSize={"xs"} fontWeight={"bold"} color={"orange"}>
                    [Contract]
                </Text>
                <Text fontSize={"xs"} fontWeight={"bold"} wordBreak={"break-all"}>{`Subject XXXXXXXXX`}</Text>
            </HStack>
            <Text fontSize={"xs"} color={"gray"}>{`Created At ${new Date().toLocaleString()}`}</Text>
            <Text fontSize={"xs"} color={"gray"}>{`Initiator zhanleewo@gmail.com}`}</Text>
          </VStack>
        </HStack>
      </ListItem>
      <ListItem onClick={() => {}} cursor={"pointer"}>
        <HStack borderBottom={"1px solid rgb(226, 232, 240)"} pt={"4px"} pb={"4px"}>
          <Avatar icon={<FiZap />} background={"none"} textColor={"gray"} />
          <VStack align={"left"}>

            <HStack>
                <Text fontSize={"xs"} fontWeight={"bold"} color={"green"}>
                    [Contract]
                </Text>
                <Text fontSize={"xs"} fontWeight={"bold"} wordBreak={"break-all"}>{`Subject XXXXXXXXX`}</Text>
            </HStack>
            <Text fontSize={"xs"} color={"gray"}>{`Created At ${new Date().toLocaleString()}`}</Text>
            <Text fontSize={"xs"} color={"gray"}>{`Initiator zhanleewo@gmail.com}`}</Text>
          </VStack>
        </HStack>
      </ListItem>
      <ListItem onClick={() => {}} cursor={"pointer"}>
        <HStack borderBottom={"1px solid rgb(226, 232, 240)"} pt={"4px"} pb={"4px"}>
          <Avatar icon={<FiMessageCircle />} background={"none"} textColor={"gray"} />
          <VStack align={"left"}>

            <HStack>
                <Text fontSize={"xs"} fontWeight={"bold"} color={"purple"}>
                    [Discussion]
                </Text>
                <Text fontSize={"xs"} fontWeight={"bold"} wordBreak={"break-all"}>{`Subject XXXXXXXXX`}</Text>
            </HStack>
            <Text fontSize={"xs"} color={"gray"}>{`Sent At ${new Date().toLocaleString()}`}</Text>
            <Text fontSize={"xs"} color={"gray"}>{`From zhanleewo@gmail.com`}</Text>
          </VStack>
        </HStack>
      </ListItem>
      <ListItem onClick={() => {}} cursor={"pointer"}>
        <HStack borderBottom={"1px solid rgb(226, 232, 240)"} pt={"4px"} pb={"4px"}>
          <Avatar icon={<FiFileText />} background={"none"} textColor={"gray"} />
          <VStack align={"left"}>

            <HStack>
                <Text fontSize={"xs"} fontWeight={"bold"} color={"red"}>
                    [Signature Round]
                </Text>
                <Text fontSize={"xs"} fontWeight={"bold"} wordBreak={"break-all"}>{`Subject XXXXXXXXX`}</Text>
            </HStack>
            <Text fontSize={"xs"} color={"gray"}>{`Created At ${new Date().toLocaleString()}`}</Text>
            <Text fontSize={"xs"} color={"gray"}>{`Initiator zhanleewo@gmail.com}`}</Text>
          </VStack>
        </HStack>
      </ListItem> */}

    </List>
  );
};

const SearchModal = ({
  selectedAccount,
  disclosure,
}) => {
  // const { addToast } = useToasts()
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setSearching] = useState(false);

  const [inputError, setInputError] = useState(null);

  useEffect(() => {
    console.log("SearchModal useEffect with empty");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    console.log("SearchModal useEffect without param");
  }); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {}, [disclosure]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchMessages = (conditions) => {
    setSearching(true);
    window.mailService.searchMessages(conditions).then(result => {
        setSearching(false); 
        setSearchResults(result);
    }).catch(e => {
        console.error(e);
        setSearching(false);
        showError(e);
    })
  };

  const onSearchResultSelected = async(searchResult) => {
    if (!searchResult) {
        return;
    }

    if (searchResult.type === 'message') {
        window.showMessage(searchResult.message);
    } else if (searchResult.type === 'contract') {

        const threadContext = { 
            contract: searchResult.contract,
            location: searchResult.contract.id,
            level: ContractThreadLevel.Category,
            isArchived: false,
            contentOfMessage: null,
            editMode: 'new',
        };

        await window.setThreadContextAsync(threadContext);
    } else if (searchResult.type === 'discussion') {

        // newThreadContext.location += "/signatureRounds";
        // newThreadContext.location += "/discussions";

        const threadContext = { 
            contract: searchResult.contract,
            location: searchResult.contract.id + "/discussions/" + searchResult.discussion.uid,
            discussion: searchResult.discussion,
            level: ContractThreadLevel.Message,
            isArchived: false,
            contentOfMessage: null,
            editMode: 'new',
        };
        await window.setThreadContextAsync(threadContext);

    } else if (searchResult.type === 'signatureRound') {

        const threadContext = { 
            contract: searchResult.contract,
            location: searchResult.contract.id + "/signatureRounds/" + searchResult.signatureRound.id,
            signatureRound: searchResult.signatureRound,
            level: ContractThreadLevel.SignatureRound,
            isArchived: false,
            contentOfMessage: null,
            editMode: 'new',
        };
        await window.setThreadContextAsync(threadContext);
    } 
  }
  return (
    <Modal
      size={"xl"}
      isOpen={disclosure.isOpen}
      onClose={() => {
        setSearchResults([]);
        setSearching(false);
        setInputError(null);
        disclosure.onClose()
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
            {Strings.app.dialog.search.title}
            <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <SearchBar inputError={inputError} setInputError={setInputError} onSearch={(conditions) => {
            searchMessages(conditions);
          }} />

          <SearchResultList
            isSearching={isSearching}
            selectedAccount={selectedAccount}
            searchResults={searchResults}
            onSelected={(searchResult) => { onSearchResultSelected(searchResult).then(() => {
                disclosure.onClose();
            }).catch(e => {}) }}
          />
        </ModalBody>

        <ModalFooter>
          {/* <Button colorScheme='red' mr={3} onClick={composeDisclosure.onClose}>Close</Button> */}
          {/* <Button colorScheme='green' isLoading={isSending} onClick={sendMessage} disabled={isUploading}>Send</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SearchModal;
