import {
  Text,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  // IconButton,
} from "@chakra-ui/react";

import React, { useEffect } from "react";
import Strings from "../config/Strings";

export function LoadDraftAlert({ disclosure, onCompleted }) {
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {Strings.editor.load_draft.title}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>{Strings.editor.load_draft.description}</Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              mr={4}
              colorScheme={"red"}
              onClick={() => {
                disclosure.onClose();
                onCompleted(false);
              }}
            >
              {Strings.editor.load_draft.discard}
            </Button>
            <Button
              mr={4}
              colorScheme={"green"}
              onClick={() => {
                disclosure.onClose();
                onCompleted(true);
              }}
            >
              {Strings.editor.load_draft.recover}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export function SaveDraftAlert({ disclosure, onCompleted }) {
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {Strings.editor.save_draft.title}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>{Strings.editor.save_draft.description}</Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              mr={4}
              colorScheme={"red"}
              onClick={() => {
                disclosure.onClose();
                onCompleted(false);
              }}
            >
              {Strings.editor.save_draft.discard}
            </Button>
            <Button
              mr={4}
              colorScheme={"green"}
              onClick={() => {
                disclosure.onClose();
                onCompleted(true);
              }}
            >
              {Strings.editor.save_draft.save}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
