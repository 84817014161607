var TypeUtils = {
    concatTypedArrays: (a, b) => { // a, b TypedArray of same type
        var c = new (a.constructor)(a.length + b.length);
        c.set(a, 0);
        c.set(b, a.length);
        return c;
    },
    
    getInt64Bytes: (x) => {
        let y= Math.floor(x/2**32);
        return [y,(y<<8),(y<<16),(y<<24), x,(x<<8),(x<<16),(x<<24)].map(z=> z>>>24)
    },
    intFromBytes: (byteArr) => {
        return byteArr.reduce((a,c,i)=> a+c*2**(56-i*8),0)
    },
    
    uint8ArrayToString: (fileData) => {
        var dataString = "";
        for (var i = 0; i < fileData.length; i++) {
            dataString += String.fromCharCode(fileData[i]);
        }
        
        return dataString
    },
        
        
    stringToUint8Array: (str) => {
        var arr = [];
        for (var i = 0, j = str.length; i < j; ++i) {
            arr.push(str.charCodeAt(i));
        }
        
        var tmpUint8Array = new Uint8Array(arr);
        return tmpUint8Array
    },
    arrayBufferToFile: (buffer, filename) => {
        const blob = new Blob([buffer], { type: 'application/octet-stream' });
        return new File([blob], filename, { type: 'application/octet-stream' });
    },
    stringsToFile: (strings, filename) => {
        return new File([...strings], filename, { type: 'application/octet-stream' });
    },
    stringToFile: (string, filename) => {
        return TypeUtils.stringsToFile([string], filename);
    },
    isMobile: () => {
        const platform = window.navigator.platform?.toLowerCase();
        if (platform.indexOf('iphone') !== -1 || platform.indexOf('android') !== -1) {
            return true
        }
        return false;
    },
    isSafari: () => {
        const safari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
        return safari;
    },
    isMobileSafari: () => {
        const isSafari = TypeUtils.isSafari();
        const isMobile = TypeUtils.isMobile();
        return isMobile && isSafari;
    },
    messageSortDesc: (a, b) => {
        return b.sendDate - a.sendDate;
    }
}

export default TypeUtils;