import BaseRPCHandler from "./BaseRPCHandler";

export default class NetVersionHandler extends BaseRPCHandler {
    constructor() {
        super('net_version');
    }
    
    async handle(provider, request) {
        return '0x5';
    }
}