import {
  Text,
  Button,
  MenuItem,
  // FormLabel,
  Input,
  Stack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  Link,
  // IconButton,
} from "@chakra-ui/react";

import React, { useState, useEffect } from "react";
import { showError /*, showInfo*/, showSuccess } from "../common/errors";
import Strings from "../config/Strings";
import MailAddressUtils from "../utils/MailAddressUtils";
import { /*EthereumChains,*/ EthereumFaucets } from "../utils/Types";

// import { toast } from 'react-toastify';
// import { WalletUIStatus } from '../wallet/AFWalletUI';

function AFFaucetAlert({ disclosure, faucetCounter, selectedAccount }) {
  const isValidEmail = window.appConfig
    ? MailAddressUtils.isValidEmailAddress(
        window.appConfig.web3StorageApiToken || "",
        false
      )
    : false;
  const [email, setEmail] = useState(
    isValidEmail ? window.appConfig.web3StorageApiToken : null
  );
  const [isLoading, setLoading] = useState(false);
  const [faucetUrl, setFaucetUrl] = useState(EthereumFaucets[window.chainId]);
  useEffect(() => {
    const url = EthereumFaucets[window.chainId];
    setFaucetUrl(url);
  }, [faucetCounter]); // eslint-disable-line react-hooks/exhaustive-deps
  const requestTestCoins = async () => {
    return await window.mailService.requestTestCoins(email, selectedAccount);
  };

  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Request Test Coins
          </AlertDialogHeader>

          <AlertDialogBody>
            <Stack spacing={3}>
              {Strings.app.dialog.af_faucet.description && (
                <Text>{Strings.app.dialog.af_faucet.description}</Text>
              )}
              {/* <FormLabel>
                  {Strings.app.dialog.af_faucet.email}
                </FormLabel> */}
              <Input
                type={"email"}
                placeholder={Strings.app.dialog.af_faucet.email_placeholder}
                disabled={isLoading}
                defaultValue={email}
                onChange={(e) => {
                  const v = e.target.value;
                  if (v && MailAddressUtils.isValidEmailAddress(v, false)) {
                    setEmail(v);
                  } else {
                    setEmail(null);
                  }
                }}
              />
              <Text>{Strings.app.dialog.af_faucet.description_2}</Text>
              <Text color={"orange"}>
                {Strings.app.dialog.af_faucet.note}&nbsp;
                <Link color="#00aaff" href={faucetUrl} target={"_blank"}>
                  Faucet
                </Link>
              </Text>
            </Stack>
            <Text></Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              mr={4}
              colorScheme={"gray"}
              disabled={isLoading}
              onClick={() => {
                disclosure.onClose();
              }}
            >
              {Strings.app.dialog.af_faucet.cancel}
            </Button>

            <Button
              colorScheme={"green"}
              disabled={!email}
              isLoading={isLoading}
              onClick={() => {
                setLoading(true);
                requestTestCoins()
                  .then((res) => {
                    if (res) {
                    }
                    setLoading(false);
                    if (
                      !window.appConfig.web3StorageApiToken ||
                      window.appConfig.web3StorageApiToken.length === 0
                    ) {
                      window.appConfig.web3StorageApiToken = email;
                    }
                    disclosure.onClose();
                    showSuccess(
                      "Success, please check your email",
                      "top-center",
                      10000
                    );
                  })
                  .catch((e) => {
                    setLoading(false);
                    showError(e);
                  });
              }}
            >
              {Strings.app.dialog.af_faucet.request}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export function AFFaucetMenuItem({ icon, title, selectedAccount }) {
  const [faucetCounter, setFaucetCounter] = useState(0);
  const disclosure = useDisclosure({
    onOpen: () => {
      setFaucetCounter(faucetCounter + 1);
    },
  });

  const gotoFaucet = () => {
    disclosure.onOpen();

    // if (navigator && navigator.clipboard) {
    //   navigator.clipboard.writeText(selectedAccount).then(() => {
    //     // toast('Wallet address was copied to your clipboard.', {position: 'bottom-center'});
    //     disclosure.onOpen();
    //   }).catch(e => {
    //     console.error(e);
    //     showError(e);
    //   })
    // } else {
    //   disclosure.onOpen();
    // }
  };

  return (
    <>
      <MenuItem
        icon={icon}
        onClick={() => {
          if (window.wallet && window.wallet.asDefaultWallet) {
            window.wallet
              .isBackup()
              .then((isBackup) => {
                if (isBackup) {
                  gotoFaucet();
                } else {
                  window.wallet
                    .showBackup()
                    .then(() => {
                      gotoFaucet();
                    })
                    .catch((e) => {
                      console.error(e);
                    });

                  // window.wallet.showUI(WalletUIStatus.Backup).then(() => {

                  // }).catch(e => {
                  //   console.error(e);
                  //   showError(e);
                  // });
                }
              })
              .catch((e) => {
                console.error(e);
                showError(e);
              });
          }
        }}
      >
        {title}
      </MenuItem>
      <AFFaucetAlert
        disclosure={disclosure}
        faucetCounter={faucetCounter}
        selectedAccount={selectedAccount}
      />
    </>
  );
}

export function AFFaucetButton({ icon, title, selectedAccount }) {
  const [faucetCounter, setFaucetCounter] = useState(0);

  const disclosure = useDisclosure({
    onOpen: () => {
      setFaucetCounter(faucetCounter + 1);
    },
  });

  const gotoFaucet = () => {
    disclosure.onOpen();

    // if (navigator && navigator.clipboard) {
    //   navigator.clipboard.writeText(selectedAccount).then(() => {
    //     // toast('Wallet address was copied to your clipboard.', {position: 'bottom-center'});
    //     disclosure.onOpen();
    //   }).catch(e => {
    //     console.error(e);
    //     showError(e);
    //   })
    // } else {
    //   disclosure.onOpen();
    // }
  };
  return (
    <>
      <Button
        leftIcon={icon}
        colorScheme={"green"}
        onClick={() => {
          if (window.wallet && window.wallet.asDefaultWallet) {
            window.wallet
              .isBackup()
              .then((isBackup) => {
                if (isBackup) {
                  gotoFaucet();
                } else {
                  window.wallet
                    .showBackup(Strings.common.message.wallet_is_backup)
                    .then(() => {
                      gotoFaucet();
                    })
                    .catch((e) => {
                      console.error(e);
                    });

                  // window.wallet.showUI(WalletUIStatus.Backup).then(() => {

                  // }).catch(e => {
                  //   console.error(e);
                  //   showError(e);
                  // });
                }
              })
              .catch((e) => {
                console.error(e);
                showError(e);
              });
          }
        }}
      >
        {title}
        {/* {Strings.app.dialog.af_faucet.menu_item} */}
      </Button>
      <AFFaucetAlert
        disclosure={disclosure}
        faucetCounter={faucetCounter}
        selectedAccount={selectedAccount}
      />
    </>
  );
}
export default AFFaucetAlert;
