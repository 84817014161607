
import * as ethUtil from "ethereumjs-util";
import Strings from "../config/Strings";
import { ClientError } from "./errors";
import Web3 from 'web3';
// import { Magic } from "magic-sdk";
import { ethers } from "ethers";
import { EthereumChains } from "../utils/Types";

export default class Web3Helper {
    static #magic = null;
    static #afwallet_web3 = null;
    static #native_web3 = null;
    static #magic_web3 = null;
    static #browserProvider = null;
    static get web3() {
        if (window.wallet && window.wallet.asDefaultWallet) {
            // if (Web3Helper.#afwallet_web3) {
            //     return Web3Helper.#afwallet_web3;
            // }
            // const web3 = new Web3(window.wallet.rpcProvider);
            // Web3Helper.#afwallet_web3 = web3;
            // return web3;
            return Web3Helper.#afwallet_web3;
        }

        if (window.chainId === EthereumChains.Sepolia) {
            return Web3Helper.#native_web3;
        }

        if (Web3Helper.#magic_web3) {
            return Web3Helper.#magic_web3;
        }
        if (Web3Helper.#native_web3) {
            return Web3Helper.#native_web3;
        }
        return Web3Helper.#afwallet_web3;
    }

    static get browserProvider() {
        if (Web3Helper.#browserProvider) {
            return Web3Helper.#browserProvider;
        }

        if (window.wallet && window.wallet.asDefaultWallet) {
            const provider = new ethers.BrowserProvider(window.wallet.rpcProvider);
            Web3Helper.#browserProvider = provider;
            return Web3Helper.#browserProvider;
        }

        if (window.chainId === EthereumChains.Sepolia) {
            const provider = new ethers.BrowserProvider(window.ethereum);
            Web3Helper.#browserProvider = provider;
            return Web3Helper.#browserProvider;
        }

        const provider = new ethers.BrowserProvider(window.web3Helper.ethereum);
        Web3Helper.#browserProvider = provider;
        return Web3Helper.#browserProvider;
    }

    static get magic() {
        return Web3Helper.#magic;
    }

    static get ethereum() {
        if (window.wallet && window.wallet.asDefaultWallet) {
            return window.wallet.rpcProvider;
        }
        
        // if (window.chainId === EthereumChains.Sepolia) {
        //     return window.ethereum;
        // }

        // if (Web3Helper.magic) {
        //     return Web3Helper.magic.rpcProvider;
        // }
        
        if (window.ethereum) {
            return window.ethereum;
        }
        return window.wallet.rpcProvider;
    }

    // static async getProvider() {
    //     const provider = await window.magic.wallet.getProvider();
    //     return provider;
    // }

    static init(network="goerli") {
        // pk_live_4F1CE0167E72D173
        // const magic = new Magic("pk_live_EADABB9A1B43819F", {
        //     //network: "goerli",
        //     // network: "mainnet",
        //     network: network,
        //     locale: "en_US",
        //     extensions: [new ConnectExtension()]
        // });
        // if (magic) {
        //     const magicWeb3 = new Web3(magic.rpcProvider);
        //     window.magic = magic;
        //     Web3Helper.#magic_web3 = magicWeb3;
        //     console.log('------------------------- Magic was initialized!')
        // }

        // const provider = await magic.wallet.getProvider();
        // const web3 = new Web3(provider);// magic.rpcProvider
        

        if (window.ethereum) {
            Web3Helper.#native_web3 = new Web3(window.ethereum);
        }
        if (window.wallet) {
            if (Web3Helper.#afwallet_web3) {
                return Web3Helper.#afwallet_web3;
            }
            const web3 = new Web3(window.wallet.rpcProvider);
            Web3Helper.#afwallet_web3 = web3;
        }
    }

    static ecRecover({
        data,
        signature,
      }) {
        if (Web3Helper.#isNullish(data)) {
            throw ClientError.invalidParameterError(Strings.error.client.miss_param);
        } else if (Web3Helper.#isNullish(signature)) {
            throw ClientError.invalidParameterError(Strings.error.client.miss_signature);
        }

        const publicKey = Web3Helper.#getPublicKeyFor(data, signature);
        const sender = ethUtil.publicToAddress(publicKey);
        const senderHex = ethUtil.bufferToHex(sender);
        return senderHex;
    }

    /**
     * private helper functions
     */
    static #isNullish(value) {
        return value === null || value === undefined;
    }
    
    static #isHexString(value, length) {
        if (typeof(value) !== 'string' || !value.match(/^0x[0-9A-Fa-f]*$/)) {
          return false;
        }
      
        if (length && value.length !== 2 + 2 * length) { return false; }
      
        return true;
    }
    static #legacyToBuffer(value) {
        return typeof value === 'string' && !Web3Helper.#isHexString(value)
          ? Buffer.from(value)
          : ethUtil.toBuffer(value);
    }
    static #recoverPublicKey(
        messageHash,
        signature,
    ) {
        const sigParams = ethUtil.fromRpcSig(signature);
        return ethUtil.ecrecover(messageHash, sigParams.v, sigParams.r, sigParams.s);
    }
    static #getPublicKeyFor(message, signature) {
        const messageHash = ethUtil.hashPersonalMessage(Web3Helper.#legacyToBuffer(message));
        return Web3Helper.#recoverPublicKey(messageHash, signature);
    }
}