import {
  Text,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  // TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  // useDisclosure,
  Checkbox,
  HStack,
  // Stack,
  VStack,
  Box,
  Spinner,
  IconButton,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
// import * as zip from "@zip.js/zip.js";

// import { FaEthereum } from "react-icons/fa";
// import { AiFillFileZip, AiOutlineFileZip, AiOutlineScan } from "react-icons/ai";
import { QRCodeSourceChooseConfirmationButton } from "./QRCodeReaderModal";
// import { ethers } from 'ethers';
import { showError } from "../common/errors";
import { EthereumChains } from "../utils/Types";
import Strings from "../config/Strings";
import * as crypt from "crypto-browserify";
import * as CurrencyUtils from "../utils/CurrencyUtils";
import { /*FiDelete, FiTrash,*/ FiTrash2 } from "react-icons/fi";

// import { EthereumChains } from '../utils/Types';
// const STORE_SERVER = 'https://ai-fi.cc/store';
// const YOUR_STORE_SERVER = 'http://localhost:4242';
const STORE_SERVER =
  process.env.REACT_APP_STORE_SERVER || "https://ai-fi.cc/store";

function BuyEthModal({ selectedAccount, disclosure }) {
  const [items, setItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState({ dollar: 0, eth: 0 });
  var [currentPrice, setCurrentPrice] = useState(0.0);
  const [isLoading, setLoading] = useState(false);

  // const [tx, setTx] = useState(null);
  // const resultDisclosure = useDisclosure({
  //   defaultIsOpen: false,
  //   onClose: () => {},
  //   onOpen: () => {},
  // });
  const S = Strings.buy_eth;
  useEffect(() => {
    if (disclosure.isOpen) {
      queryCurrentPricePeriodically();
    }
  }, [disclosure]); // eslint-disable-line react-hooks/exhaustive-deps

  /*
    const parseTokenInfo = (info, m, s) => {
      try {
          const result = {};
          info.split(';').reduce((prev, current) => {
              if (!prev) {
                  prev = {};
              }
              const kv = current.trim().split(':');
              prev[kv[0]] = kv[1];
              return prev;
          }, result);

          if( Object.keys(result).length === 0 ) {
              return null;
          }
          const dates = result.E.split('-').map(d => {
              const ms = parseInt(d);
              return new Date(ms);
          });
          const tokenInfo = {
              decodedInfo: {
                  nominal_value: parseInt(result.V),
                  valid_from: dates[0],
                  valid_to: dates[1],
                  network: (result.N || 'sepolia').toLowerCase()
              },
              info: info,
              secret: m,
              signature: {rho: s.r, omega: s.o, sigma: s.s, delta: s.d}
          }
          return tokenInfo;
      } catch (e) {
        console.error(e);
        throw new Error('Failed to parse token info');
      }
  };
  */
  const isRedeemable = (items) => {
    if (!items || items.length === 0) {
      return false;
    }
    if (
      items.filter((item) => {
        return item.status === 10;
      }).length !== 0
    ) {
      return false;
    }
    const selectedItems = items.filter((item) => {
      return item.selected && item.status === 0;
    });
    return selectedItems && selectedItems.length > 0;
  };
  const calTokenHash = (item) => {
    const token = JSON.parse(item);
    const F = crypt.createHash("sha256").update(token.i + token.m);
    const hash = F.digest().toString("hex");
    return hash;
  };

  const queryTokenStatus = async (newItems) => {
    // 1. check validation
    // const tokenInfo = parseInfo(token.i);
    // const now = new Date().getTime();
    // if (now < tokenInfo.decodedInfo.valid_from.getTime() || now > tokenInfo.decodedInfo.valid_to.getTime()) {
    //     throw new PBTError(PBTError.ERROR_CODES.EC_TOKEN_INVALID_DATE, Strings.token_out_of_date);
    // }

    // 2. check signature
    // const verified = await this.wiSchnorrServer.VerifyWISchnorrBlindSignature(tokenInfo.signature, tokenInfo.info, tokenInfo.secret);
    // if (!verified) {
    //     throw new PBTError(PBTError.ERROR_CODES.EC_TOKEN_INVALID_SIG, Strings.token_signature_is_invalid);
    // }

    if (!newItems || newItems.length === 0) {
      throw new Error(S.messages.no_ecash);
    }
    const hashes = newItems.map((item) => {
      return item.hash;
    });
    let resp = null;
    try {
      // setTx(null);
      resp = await fetch(`${STORE_SERVER}/api/token/status`, {
        method: "POST",
        mode: "cors",
        headers: {
          // 'Authorization': 'Bearer ' + localStorage.getItem('admin.jwt'),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(hashes),
      });

      let result = await resp.json();
      if (!result) {
        if (resp.status === 200) {
          result = { code: -1, msg: "Invalid response" };
        } else {
          result = {
            code: resp.status > 0 ? -resp.status : resp.status,
            msg: resp.statusText,
          };
        }
      }

      if (result.code !== 0) {
        throw new Error(result.msg);
      }
      return result.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  const parseInfo = (info) => {
    const result = {};
    info.split(";").reduce((prev, current) => {
      if (!prev) {
        prev = {};
      }
      const kv = current.trim().split(":");
      prev[kv[0]] = kv[1];
      return prev;
    }, result);
    return Object.keys(result).length === 0 ? null : result;
  };
  const getCurrentPrice = async () => {
    // , 'A9KD227ZI1ZW745JFF8EMQ62ZGU76KZ8QH'
    console.log("BuyEthModal");
    // let etherscanProvider = new ethers.EtherscanProvider('mainnet');
    // const price = await etherscanProvider.getEtherPrice();
    // return price;

    return await CurrencyUtils.getCurrentPrice();
  };
  const calTotalPrice = async (items) => {
    // const chain = EthereumChains[window.appConfig.chainId].toLowerCase()
    // let etherscanProvider = ethers.getDefaultProvider('homestead');
    // let etherscanProvider = new ethers.providers.EtherscanProvider('homestead');
    // const price = await etherscanProvider.getEtherPrice();
    console.log("calTotalPrice: ", currentPrice);
    items = items.filter((item) => {
      return item.selected && item.status === 0;
    });

    const price = currentPrice || (await getCurrentPrice());
    const result = { dollar: 0, eth: 0 };
    items.reduce((prev, current) => {
      const item = JSON.parse(current.token);
      const info = parseInfo(item.i);
      prev.dollar += parseInt(info.V);
      return prev;
    }, result);
    result.eth = result.dollar / 100.0 / price;
    setTotalPrice(result);
  };

  const queryCurrentPricePeriodically = (interval = 15000) => {
    console.log("queryCurrentPricePeriodically:", interval);

    getCurrentPrice()
      .then((price) => {
        if (price !== currentPrice) {
          currentPrice = price;
          setCurrentPrice(price);
          return calTotalPrice(items);
        }
      })
      .then(() => {
        setTimeout(() => {
          // queryCurrentPricePeriodically(interval);
        }, interval);
      })
      .catch((e) => {
        console.error(e);
        setTimeout(() => {
          // queryCurrentPricePeriodically(interval);
        }, interval);
      });
  };
  const chainIdToNetwork = (chainId) => {
    if (chainId === EthereumChains.Goerli) {
      return "goerli";
    } else if (chainId === EthereumChains.Sepolia) {
      return "sepolia";
    } else if (chainId === EthereumChains.Mainnet) {
      return "mainnet";
    } else if (chainId === EthereumChains.Fuji) {
      return "fuji";
    } else if (chainId === EthereumChains.Avalanche) {
      return "mainnet";
    }
    return "mainnet";
  };

  const redeem = async () => {
    if (!items || items.length === 0) {
      throw new Error(S.messages.no_ecash);
    }
    const selectedItems = items.filter((item) => {
      return item.selected && item.status === 0;
    });
    if (selectedItems.length === 0) {
      throw new Error(S.messages.no_ecash);
    }
    const tokens = selectedItems.map((item) => {
      const o = JSON.parse(item.token);
      return o;
    });
    const reqForm = {
      to: selectedAccount,
      network: chainIdToNetwork(window.appConfig.chainId),
      tokens: tokens,
    };

    let resp = null;
    try {
      setLoading(true);
      // setTx(null);
      resp = await fetch(`${STORE_SERVER}/api/token/redeem`, {
        method: "POST",
        mode: "cors",
        headers: {
          // 'Authorization': 'Bearer ' + localStorage.getItem('admin.jwt'),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqForm),
      });

      let result = null;
      try {
        result = await resp.json();
        if (!result) {
          if (resp.status === 200) {
            result = { code: -1, msg: "Invalid response" };
          } else {
            result = {
              code: resp.status > 0 ? -resp.status : resp.status,
              msg: resp.statusText,
            };
          }
        }
      } catch (e) {
        console.log("resp.json:", e);
        if (resp.status === 200) {
          result = { code: -1, msg: "Invalid response" };
        } else {
          result = {
            code: resp.status > 0 ? -resp.status : resp.status,
            msg: resp.statusText,
          };
        }
      }
      if (result.code !== 0) {
        throw new Error(result.msg);
      }

      // setTx(result.data.hash);
      setLoading(false);

      let url = null;
      if (window.appConfig.chainId === EthereumChains.Goerli) {
        url = `https://goerli.etherscan.io/tx/${
          result.data.hash || result.data
        }`;
      } else if (window.appConfig.chainId === EthereumChains.Mainnet) {
        // url = `https://etherscan.io/tx/${result.data.hash || result.data}`
        url = `https://snowtrace.io/tx/${result.data.hash || result.data}`;
      } else if (window.appConfig.chainId === EthereumChains.Sepolia) {
        url = `https://sepolia.etherscan.io/tx/${
          result.data.hash || result.data
        }`;
      } else if (window.appConfig.chainId === EthereumChains.Fuji) {
        url = `https://testnet.snowtrace.io/tx/${
          result.data.hash || result.data
        }`;
      } else if (window.appConfig.chainId === EthereumChains.Avalanche) {
        url = `https://snowtrace.io/tx/${result.data.hash || result.data}`;
      }

      setItems([]);
      setTotalPrice({ dollar: 0, eth: 0 });
      setCurrentPrice(0.0);
      setLoading(false);

      disclosure.onClose();

      if (url) {
        // window.open(url, '_blank');
      }
      return result;
      // resultDisclosure.onOpen();
    } catch (e) {
      console.error(e);
      showError(e);
      setLoading(false);
      throw e;
    }
  };
  const removeToken = (hash) => {
    const newItems = [...items];
    for (let i = 0; i < newItems.length; i++) {
      const item = newItems[i];
      if (item.hash === hash) {
        newItems.splice(i, 1);
        break;
      }
    }
    setItems(newItems);
    calTotalPrice(newItems)
      .then(() => {})
      .catch((e) => {
        console.error(e);
        showError(e);
      });
  };
  const statusString = (status) => {
    if (status === 0) {
      return <Text color="green">{Strings.buy_eth.status.unused}</Text>;
    } else if (status === 1) {
      return (
        <Text color="red">
          <i>{Strings.buy_eth.status.redeemed}</i>
        </Text>
      );
    } else if (status === 2) {
      return (
        <Text color="red">
          <i>{Strings.buy_eth.status.refunded}</i>
        </Text>
      );
    } else if (status === 10) {
      return <Spinner size={"sm"} color="orange" />;
      // return <Stack><Spinner size={'sm'} color="orange" /><Text color="orange" size={"sm"}><i>Checking...</i></Text></Stack>;
    }
    return "Unknown";
  };
  const VTOName = (v) => {
    const value = parseInt((v || 0) / 100.0);
    if (value === 5) {
      return "FIVE PLEXIS";
    } else if (value === 1) {
      return "ONE PLEXI";
    }
    return value;
  };
  return (
    <Modal
      size={"xl"}
      isOpen={disclosure.isOpen}
      onClose={() => {
        setItems([]);
        setTotalPrice({ dollar: 0, eth: 0 });
        setCurrentPrice(0.0);
        setLoading(false);
        disclosure.onClose();
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>{S.title}</Text>
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <HStack
            spacing={{ base: "0", md: "6" }}
            py={{ base: 2, md: 2 }}
            justifyContent={"flex-end"}
          >
            {/* <Text fontSize={{base: "small", md: "xl"}} fontWeight={"bold"}>{S.head}</Text> */}

            <Flex>
              {/* <IconButton
                    mr={3}
                    isDisabled={false}
                    colorScheme={"purple"}
                    icon={<AiOutlineFileZip  />}
                    onClick={() => {}}
                    spacing={{ base: '6', md: '6' }} /> */}
              <QRCodeSourceChooseConfirmationButton
                asButton={true}
                isDisabled={isLoading}
                triger={null}
                title={S.qrcode.title}
                description={S.qrcode.description}
                onCompleted={(result) => {
                  console.log("eCash: ", result);

                  if (typeof result === "string") {
                    result = [result];
                  }

                  // const newItems = [...items];
                  const newItems = [];
                  for (const newItem of result) {
                    let exists = false;
                    for (const item of items) {
                      if (item.token === newItem) {
                        exists = true;
                        break;
                      }
                    }
                    if (!exists) {
                      const hash = calTokenHash(newItem);
                      newItems.push({
                        token: newItem,
                        selected: true,
                        hash: hash,
                        status: 10,
                      });
                    }
                  }

                  if (newItems.length > 0) {
                    const oldItems = [...items];
                    const checkItems = [...items, ...newItems];
                    setItems(checkItems);

                    queryTokenStatus(newItems)
                      .then((result) => {
                        newItems.forEach((item) => {
                          item.status = result[item.hash];
                          item.selected = item.status === 0;
                        });

                        setItems([]);
                        const promise = new Promise((resolve, reject) => {
                          setTimeout(() => {
                            resolve();
                          }, 0);
                        }).then(() => {});
                        return promise;
                      })
                      .then(() => {
                        const allItems = [...oldItems, ...newItems];
                        return calTotalPrice(allItems).then(() => {
                          return allItems;
                        });
                      })
                      .then((allItems) => {
                        setItems(allItems);
                      })
                      .catch((e) => {
                        console.error(e);
                        showError(e);
                      });
                  }
                }}
              />
              {/* <IconButton 
                    isDisabled={false}
                    icon={<AiOutlineScan  />}
                    onClick={() => {}}
                    spacing={{ base: '6', md: '6' }} /> */}
            </Flex>
          </HStack>
          <Box w={"100%"} justifyContent={"space-between"}>
            {S.description}
          </Box>

          {/* <TableContainer w={'100%'}> */}
          <Table mt={4} variant="simple" w={"100%"}>
            <TableCaption>
              <Text>
                {currentPrice === 0.0
                  ? S.messages.getting_current_price
                  : S.messages.current_price_1 +
                    currentPrice.toFixed(2) +
                    S.messages.current_price_2}
              </Text>
            </TableCaption>
            <Thead>
              <Tr>
                <Th>{S.table.head.col0}</Th>
                <Th>{S.table.head.col1}</Th>
                <Th isNumeric={true}>{S.table.head.col2}</Th>
                <Th>{S.table.head.col3}</Th>
                <Th>{S.table.head.col4}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items &&
                items.length > 0 &&
                items.map((item, index) => {
                  const itemObject = JSON.parse(item.token);
                  const info = parseInfo(itemObject.i);
                  return (
                    <Tr
                      key={item.hash}
                      textDecoration={
                        item.status === 0 ? "none" : "line-through red"
                      }
                    >
                      <Td>
                        <Checkbox
                          isDisabled={item.status !== 0}
                          defaultChecked={item.selected}
                          colorScheme={"green"}
                          onChange={(e) => {
                            console.log("is selected: ", e.target.checked);
                            items[index].selected = e.target.checked;
                            setItems([...items]);
                            calTotalPrice(items);
                          }}
                        />
                      </Td>
                      <Td>{VTOName(info.V)}</Td>
                      <Td isNumeric={true}>{(info.V / 100.0).toFixed(2)}</Td>
                      <Td>{statusString(item.status)}</Td>
                      <Td>
                        <IconButton
                          background={"none"}
                          color="orange"
                          icon={<FiTrash2 />}
                          onClick={() => {
                            removeToken(item.hash);
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}

              {(!items || items.length === 0) && (
                <Tr>
                  <Td colSpan={5} align="center" w={"100%"} maxW={"100%"}>
                    <VStack width={"100%"}>
                      <Text
                        color={"rgb(74, 85, 104)"}
                        width={"100%"}
                        textAlign="center"
                        algin={"center"}
                        fontWeight={"bold"}
                        fontSize={"md"}
                      >
                        {S.table.body.empty_title}
                      </Text>
                      <Text
                        color="gray"
                        fontSize="sm"
                        width={"100%"}
                        algin={"center"}
                        textAlign="center"
                      >
                        {S.table.body.empty}
                      </Text>
                    </VStack>
                  </Td>
                </Tr>
              )}
            </Tbody>

            <Tfoot>
              <Tr>
                <Th colSpan={5}>
                  <Flex justify={"flex-end"} mt={4} fontWeight={"bold"}>
                    <Text align="right" textAlign={"right"}>
                      {S.table.foot.prefix}
                      {(totalPrice.dollar / 100.0).toFixed(2)}&nbsp;(
                      {totalPrice.eth.toFixed(3) + S.table.foot.suffix})
                    </Text>
                  </Flex>
                </Th>
              </Tr>
            </Tfoot>
          </Table>
          {/* </TableContainer> */}

          {window.chainId !== EthereumChains.Mainnet && (
            <Text fontSize="sm" color="gray" padding={"10px"} w={"100%"}>
              <i>
                <span style={{ color: "red" }}>*</span> {S.messages.test_note}
              </i>
            </Text>
          )}
        </ModalBody>

        <ModalFooter>
          {/* <Button
              colorScheme="gray"
              mr={3}
              isDisabled={isLoading}
              onClick={() => {
                let a = document.getElementById('open-store-link')
                if (!a) {
                  a = window.document.createElement("a");
                  a.setAttribute("href", STORE_SERVER);
                  a.setAttribute("target", '_blank');
                  // `PlexiMailPlusPlusConfig_${selectedAccount}.cfg`
                  window.document.body.append(a);
                }
                a.click();
                // a.remove();
                // window.location.href = STORE_SERVER;
              }}
            >
              {S.foot.buy_ecash}
            </Button> */}
          <Button
            colorScheme="green"
            isLoading={isLoading}
            isDisabled={!isRedeemable(items)}
            onClick={() => {
              redeem()
                .then((result) => {
                  console.log("redeemed:", result);
                })
                .catch((e) => {
                  console.error(e);
                  showError(e);
                });
            }}
          >
            {S.foot.buy_eth}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default BuyEthModal;
