

import LighthouseWeb3Storage from '../w3ui/LighthouseWeb3Storage';
class ContractBackupService {

    #archives = null;

    async getMeta() {

        if (this.#archives === null) {
            const archives = await window.plexiMailService.getContractArchives(1);
            this.#archives = {}
            for (let archive of archives) {
                this.#archives[archive.cid] = archive;
            }
        }

        return this.#archives
    }

    async saveMeta(meta) {
        meta.createAt = (new Date()).getTime();
        
        const success = await window.plexiMailService.saveContractArchive(1, meta);
        if (success) {
            if (!this.#archives) {
                this.#archives = {}
            }
            this.#archives[meta.cid] = meta;
        }
        return success;

    }

    async deleteMeta() {
        const success = await window.plexiMailService.deleteContractArchives(1);
        if (success) {
            this.#archives = null;
        }
        return success;
    }
    
    async createPermanentlyLighthouseStorage() {
        const storage = new LighthouseWeb3Storage(window.appConfig.backupStorageProvider === 1);
        await storage.authenticate();
        return storage;
    }

    async save(meta, zipBlob) {
        // {name, createAt, moderator}
        const zipFile = new File([zipBlob], meta && meta.name)
        const client = window.appConfig.backupStorageProvider === 0 ? window.mailService.web3StorageClient : await this.createPermanentlyLighthouseStorage();


        const cid = await client.put([zipFile])
        const metaItem = {...meta, cid: cid};
        const success = await this.saveMeta(metaItem);
        if (success) {
            return metaItem;
        }
        throw new Error('failed to save meta');
    }

    async get(cid) {
        const meta = await this.getMeta();
        const metaItem = meta[cid]
        if (!metaItem) {
            return null;
        }
        const client = window.mailService.web3StorageClient;
        const zipFile = await client.get(cid);
        return {zip: zipFile, meta: metaItem};
    }

    async list() {
        const meta = await this.getMeta();
        const archives = Object.values(meta).sort((a, b) => {
            return b.createAt - a.createAt;
        });
        return archives;
    }
}

export default ContractBackupService;