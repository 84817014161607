import * as base64 from "base64-js";

export function encodeObject(obj) {
  if (obj === null || typeof obj === 'undefined') {
    return {type: 'basic', value: null};
  }

    let resultVO = {};
    if (obj.constructor === Uint8Array) {
        const vo = {};
        vo.type = "Uint8Array";
        vo.value = base64.fromByteArray(obj);
        // obj = vo;
        resultVO = vo;
    } else if (obj.constructor === ArrayBuffer) {
        const vo = {};
        vo.type = "ArrayBuffer";
        vo.value = base64.fromByteArray(new Uint8Array(obj));
        // obj = vo;
        resultVO = vo;
    } else if (obj.constructor === Object) {
        // vo.type = 'Object';
        // vo.value = encodeObject(obj[k]);

        const vo = {};
        vo.type = 'Object';
        const objectVO = {};
        for(let k in obj) {
            let cvo = {};
            // if (!obj[k]) {
            if (obj[k] === null || typeof obj[k] === 'undefined') {
              objectVO[k] = {type: 'basic', value: null};
            } else {
              if (obj[k].constructor === Uint8Array) {
                cvo.type = "Uint8Array";
                cvo.value = base64.fromByteArray(obj[k]);
              } else if (obj[k].constructor === ArrayBuffer) {
                cvo.type = "ArrayBuffer";
                cvo.value = base64.fromByteArray(new Uint8Array(obj[k]));
              } else if (obj[k].constructor === Object) {
                  // cvo.type = 'Object';
                  // cvo.value = encodeObject(obj[k]);
                  cvo = encodeObject(obj[k]);
              } else {
                cvo.type = "basic";
                cvo.value = obj[k];
              }
              // obj[k] = vo;
              objectVO[k] = cvo;
            }
        }
        // vo.value = obj;
        // obj = vo;
        vo.value = objectVO;
        resultVO = vo;

      } else {
        const vo = {};
        vo.type = "basic";
        vo.value = obj;
        // obj = vo;
        resultVO = vo;
    }

    // return obj;
    return resultVO;
}

export function decodeObject(obj) {

    if (obj.type === 'Uint8Array') {
        const value = base64.toByteArray(obj.value);
        return value;
    } else if (obj.type === 'ArrayBuffer') {
        const value = base64.toByteArray(obj.value).buffer;
        return value;
    } else if (obj.type === 'Object') {
      // vo.type = 'Object';
      // vo.value = encodeObject(obj[k]);
      let object = {}
      let vo = obj.value;
      for(let k in vo) {
          if (vo[k].type === 'Uint8Array') {
              object[k] = base64.toByteArray(vo[k].value);
          } else if (vo[k].type === 'ArrayBuffer') {
              object[k] = base64.toByteArray(vo[k].value).buffer;
          } else if (vo[k].type === 'Object') {
              object[k] = decodeObject(vo[k]);
          } else {
              object[k] = vo[k].value;
          }
      }
      return object;

    } else {
        return obj.value;
    }
}
/**
 * Export all data from an IndexedDB database
 * @param {IDBDatabase} idbDatabase - to export from
 * @param {function(Object?, string?)} cb - callback with signature (error, jsonString)
 */
export function exportToJsonString(idbDatabase, namespace, cb, asString=true) {
  const exportObject = {};
  const objectStoreNamesSet = new Set(idbDatabase.objectStoreNames);
  const size = objectStoreNamesSet.size;
  if (size === 0) {
    cb(null, JSON.stringify(exportObject));
  } else {
    const objectStoreNames = Array.from(objectStoreNamesSet);
    const transaction = idbDatabase.transaction(objectStoreNames, "readonly");
    transaction.onerror = (event) => cb(event, null);

    objectStoreNames.forEach((storeName) => {
      const allObjects = [];
      transaction.objectStore(storeName).openCursor().onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const kv = cursor.value;
          if (kv && kv.value) {
            //  || kv.id.lastIndexOf('.mail-draft') !== -1
            if (/*(namespace && namespace.length > 0 && !kv.id.startsWith(`${namespace}.`)) 
              || */(storeName === 'signal.misc' && (kv.id.lastIndexOf('.folder') !== -1))
              || (storeName === 'signal.misc' && (kv.id.lastIndexOf('.pleximail-state') !== -1))) {
              // console.log('*** export indexeddb ignore:', kv.id);
            } else {
              const vo = encodeObject(kv.value);
              kv.value = vo;
              allObjects.push(kv);
            }
          }
          // allObjects.push(cursor.value);
          cursor.continue();
        } else {
          exportObject[storeName] = allObjects;
          if (objectStoreNames.length === Object.keys(exportObject).length) {
            cb(null, asString ? JSON.stringify(exportObject) : exportObject);
          }
        }
      };
    });
  }
}

/**
 * Import data from JSON into an IndexedDB database. This does not delete any existing data
 *  from the database, so keys could clash.
 *
 * Only object stores that already exist will be imported.
 *
 * @param {IDBDatabase} idbDatabase - to import into
 * @param {string} jsonString - data to import, one key per object store
 * @param {function(Object)} cb - callback with signature (error), where error is null on success
 * @return {void}
 */
export function importFromJsonString(idbDatabase, jsonString, cb) {
  const objectStoreNamesSet = new Set(idbDatabase.objectStoreNames);
  const size = objectStoreNamesSet.size;
  if (size === 0) {
    cb(null);
  } else {
    const objectStoreNames = Array.from(objectStoreNamesSet);
    // const readtx = idbDatabase.transaction(objectStoreNames, "readonly");
    const transaction = idbDatabase.transaction(objectStoreNames, "readwrite");
    transaction.onerror = (event) => cb(event);

    const importObject = (typeof jsonString === 'string') ? JSON.parse(jsonString) : jsonString;
    // const importObject = JSON.parse(jsonString);

    // Delete keys present in JSON that are not present in database
    Object.keys(importObject).forEach((storeName) => {
      if (!objectStoreNames.includes(storeName)) {
        delete importObject[storeName];
      }
    });

    if (Object.keys(importObject).length === 0) {
      // no object stores exist to import for
      cb(null);
    }
    /*
    let decodedObject = {}
    objectStoreNames.forEach((storeName) => {
        let store = []
        const aux = Array.from(importObject[storeName] || []);
  
        if (importObject[storeName] && aux.length > 0) {
          aux.forEach((toAdd) => {
            toAdd.value = decodeObject(toAdd.value);
            store.push(toAdd);
          });
        }
        decodedObject[storeName] = store;
    });
    importObject = decodeObject;
    */
    
    objectStoreNames.forEach((storeName) => {
      let count = 0;

      const aux = Array.from(importObject[storeName] || []);

      if (importObject[storeName] && aux.length > 0) {
        
        const keyRequest = transaction.objectStore(storeName).getAllKeys();
        keyRequest.onsuccess = (e) => {
          const keys = keyRequest.result || [];

          aux.forEach((toAdd) => {

            if(storeName === 'signal.misc' && toAdd.id.lastIndexOf('.folder') !== -1) {
              console.log('*** ignore folder cache');
              return;
            }

            if (keys.includes(toAdd.id)) {
              count++;

              if (count === importObject[storeName].length) {
                // added all objects for this store
                delete importObject[storeName];
                if (Object.keys(importObject).length === 0) {
                  // added all object stores
                  cb(null);
                }
              }
              return;
            }
            
            toAdd.value = decodeObject(toAdd.value);
            const addRequest = transaction.objectStore(storeName).add(toAdd);
            // const request = transaction.objectStore(storeName).add(toAdd);
            addRequest.onsuccess = () => {
              count++;
              if (count === importObject[storeName].length) {
                // added all objects for this store
                delete importObject[storeName];
                if (Object.keys(importObject).length === 0) {
                  // added all object stores
                  cb(null);
                }
              }
            };
            addRequest.onerror = (event) => {
              console.log(event);
            };
          });
        }
        keyRequest.onerror =(e) => {
          
        }
      } else {
        if (importObject[storeName]) {
          delete importObject[storeName];
          if (Object.keys(importObject).length === 0) {
            // added all object stores
            cb(null);
          }
        }
      }
    });
    
  }
}

/**
 * Clears a database of all data.
 *
 * The object stores will still exist but will be empty.
 *
 * @param {IDBDatabase} idbDatabase - to delete all data from
 * @param {function(Object)} cb - callback with signature (error), where error is null on success
 * @return {void}
 */
export function clearDatabase(idbDatabase, cb) {
  const objectStoreNamesSet = new Set(idbDatabase.objectStoreNames);
  const size = objectStoreNamesSet.size;
  if (size === 0) {
    cb(null);
  } else {
    const objectStoreNames = Array.from(objectStoreNamesSet);
    const transaction = idbDatabase.transaction(objectStoreNames, "readwrite");
    transaction.onerror = (event) => cb(event);

    let count = 0;
    objectStoreNames.forEach(function (storeName) {
      transaction.objectStore(storeName).clear().onsuccess = () => {
        count++;
        if (count === size) {
          // cleared all object stores
          cb(null);
        }
      };
    });
  }
}

export function clearDatabaseWithKeyPrefix(idbDatabase, prefix, cb) {
  prefix = prefix ? prefix.toLowerCase() : '';
  const objectStoreNamesSet = new Set(idbDatabase.objectStoreNames);
  const size = objectStoreNamesSet.size;
  if (size === 0) {
    cb(null);
  } else {
    const objectStoreNames = Array.from(objectStoreNamesSet);
    const transaction = idbDatabase.transaction(objectStoreNames, "readwrite");
    transaction.onerror = (event) => cb(event);

    let count = 0;
    objectStoreNames.forEach(function (storeName) {
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.getAllKeys();
      request.onsuccess = () => {
        const keys = (request.result || []).filter((k) => {
          return (k.indexOf(prefix+ '.') === 0);
        });

        for (const key of keys) {

          const delReq = objectStore.delete(key);
          delReq.onerror = function(event) {
              console.error('delete event:', event, key);
          };
    
          delReq.onsuccess = function(event) {
          };
        }
        count++;
        if (count === size) {
          // cleared all object stores
          cb(null);
        }
      };
    });
  }
}



/**
 * Export all data from an IndexedDB database
 * @param {IDBDatabase} idbDatabase - to export from
 * @param {function(Object?, string?)} cb - callback with signature (error, jsonString)
 */
export function exportInMemoryDBToJsonString(objectStoreNames, db, cb, asString=true) {
  const exportObject = {};

  objectStoreNames.forEach((storeName) => {
    const allObjects = []
    const store = db[storeName].dataSet;
    for(const id in store) {
      if (storeName === 'signal.misc' && id.lastIndexOf('.folder') !== -1) {
        console.log('*** ignore folder cache');
      } else {
        const vo = encodeObject(store[id].value);
        const kv = {id: id, value: vo}
        allObjects.push(kv);
      }
    }
    exportObject[storeName] = allObjects;
  });

  if (objectStoreNames.length === Object.keys(exportObject).length) {
    cb(null, asString ? JSON.stringify(exportObject) : exportObject);
  }
}

/**
 * Import data from JSON into an IndexedDB database. This does not delete any existing data
 *  from the database, so keys could clash.
 *
 * Only object stores that already exist will be imported.
 *
 * @param {IDBDatabase} idbDatabase - to import into
 * @param {string} jsonString - data to import, one key per object store
 * @param {function(Object)} cb - callback with signature (error), where error is null on success
 * @return {void}
 */
export function importInMemoryDBFromJsonString(objectStoreNames, db, jsonString, cb) {

    const importObject = (typeof jsonString === 'string') ? JSON.parse(jsonString) : jsonString;
    // const importObject = JSON.parse(jsonString);

    // Delete keys present in JSON that are not present in database
    Object.keys(importObject).forEach((storeName) => {
      if (!objectStoreNames.includes(storeName)) {
        delete importObject[storeName];
      }
    });

    if (Object.keys(importObject).length === 0) {
      // no object stores exist to import for
      cb(null);
    }
    
    objectStoreNames.forEach((storeName) => {
      let count = 0;

      const aux = Array.from(importObject[storeName] || []);

      if (importObject[storeName] && aux.length > 0) {
        db[storeName] = {keyPath: 'id', dataSet: {}};

        aux.forEach((toAdd) => {
          if(storeName === 'signal.misc' && toAdd.id.lastIndexOf('.folder') !== -1) {
            console.log('*** ignore folder cache');
            return;
          }
          toAdd.value = decodeObject(toAdd.value);
          db[storeName].dataSet[toAdd.id] = toAdd;
          count++;
          if (count === importObject[storeName].length) {
            // added all objects for this store
            delete importObject[storeName];
            if (Object.keys(importObject).length === 0) {
              // added all object stores
              cb(null);
            }
          }
        });
      } else {
        if (importObject[storeName]) {
          delete importObject[storeName];
          if (Object.keys(importObject).length === 0) {
            // added all object stores
            cb(null);
          }
        }
      }
    });
}

/**
 * Clears a database of all data.
 *
 * The object stores will still exist but will be empty.
 *
 * @param {IDBDatabase} idbDatabase - to delete all data from
 * @param {function(Object)} cb - callback with signature (error), where error is null on success
 * @return {void}
 */
export function clearInMemoryDatabase(db, cb) {
  const keys = Object.keys(db);
  for(const key of keys) {
    delete db[key];
  }
}
