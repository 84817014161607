import {
  Text,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  // CheckboxGroup,
  Input,
  FormControl,
  FormLabel,
  OrderedList,
  ListItem,
  UnorderedList,
  Center,
  // TableContainer,
  // IconButton,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
// import Strings from '../../config/Strings';
import {ReactComponent as ICPIcon} from './icp.svg';

export default function InternetIdentityAuthAlert({ onCompleted, disclosure }) {
  return (
    <AlertDialog
      // size={""}
      size={"lg"}
      
      isOpen={disclosure.isOpen}
      closeOnOverlayClick={true}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Sign In with Internet Identity
          </AlertDialogHeader>

          <AlertDialogBody fontSize={"sm"}>
            <Center paddingY={4}>
            <ICPIcon width={"64px"} />
            </Center>

            <Text fontWeight={"bold"}>
            Before you continue, you'll be redirected to the ICP blockchain's Internet Identity management screen.
            </Text>

            <br />
            <UnorderedList>
              <ListItem>
                <Text>
            If this is your first time: Choose the "create" option to set up a new Passkey. This Passkey is essential for interacting with PlexiMail as a Smart Contract.
                </Text>
              </ListItem>

            </UnorderedList>

          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              mr={4}
              colorScheme={"green"}
              onClick={() => {
                onCompleted(false);
                disclosure.onClose();
              }}
            >
              Next
            </Button>

            <Button
              mr={4}
              colorScheme={"red"}
              onClick={() => {
                onCompleted(true);
                disclosure.onClose();
              }}
            >
              Don't show this again
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
