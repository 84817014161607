import localforage from "localforage";

/**
 * @template T
 * @typedef {import('./types.js').Driver<T>} Driver
 */
const STORE_NAME = "AccessStore";
const DATA_ID = 1;
/**
 * Driver implementation for the browser.
 *
 * Usage:
 *
 * ```js
 * import { IndexedDBDriver } from '@web3-storage/access/drivers/indexeddb'
 * ```
 *
 * @template T
 * @implements {Driver<T>}
 */
export default class W3upStoreIndexedDB {
  /** @type {string} */
  #dbName;
  /** @type {number|undefined} */
  #dbVersion;
  /** @type {string} */
  #dbStoreName;
  /** @type {IDBDatabase|undefined} */
  #db;
  /** @type {boolean} */
  #autoOpen;
  /**
   * @param {string} dbName
   * @param {object} [options]
   * @param {number} [options.dbVersion]
   * @param {string} [options.dbStoreName]
   * @param {boolean} [options.autoOpen]
   */
  constructor(dbName, options = {}) {
    this.#dbName = dbName;
    this.#dbVersion = options.dbVersion;
    this.#dbStoreName = options.dbStoreName ?? STORE_NAME;
    this.#autoOpen = options.autoOpen ?? true;
  }
  /** @returns {Promise<IDBDatabase>} */
  async #getOpenDB() {
    if (!this.#db) {
      if (!this.#autoOpen) throw new Error("Store is not open");
      await this.open();
    }
    // @ts-expect-error open sets this.#db
    return this.#db;
  }
  async open() {
    const db = this.#db;
    if (db) return;
    this.#db = localforage.createInstance({
      driver: localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
      name: this.#dbName,
      version: 3,
      // size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
      storeName: STORE_NAME, // Should be alphanumeric, with underscores.
      description: "w3up context data",
    });
  }
  async close() {
    const db = this.#db;
    if (!db) throw new Error("Store is not open");
    // db.dropInstance()
    this.#db = undefined;
  }
  /** @param {T} data */
  async save(data) {
    const db = await this.#getOpenDB();

    await db.setItem(DATA_ID + "", data);
  }
  async load() {
    const db = await this.#getOpenDB();
    const data = await db.getItem(DATA_ID + "");
    return data;
  }
  async reset() {
    const db = await this.#getOpenDB();
    await db.clear();
  }
}
