// import './App.css';
import React from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
} from "@chakra-ui/react";
import Strings from "../config/Strings";

function KickOutAlert({ disclosure }) {
  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {Strings.app.dialog.kickout.title}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>{Strings.app.dialog.kickout.message}</Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              colorScheme={"green"}
              onClick={() => {
                window.forceQuit = true;
                // window.location.reload();
                setTimeout(function () {
                  window.location.reload(true);
                });
              }}
            >
              {Strings.app.dialog.kickout.quit}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
export default KickOutAlert;
