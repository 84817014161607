import {
  Box,
  Button,
  Flex,
  IconButton,
  VStack,
  Text,
  List,
  ListItem,
  HStack,
  Avatar,
  useColorModeValue,
  useDisclosure,
  Tooltip,
  Heading,
  Link,
  FormLabel,
  Input,
  ModalContent,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  PopoverFooter,
  Select,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import * as ReactIcons from "react-icons/fa";
// import axios from "axios";

import { Facebook } from "react-content-loader";
// import './App.css';
import React, { useState, useEffect, createRef } from "react";
import MailEditor from "./MailEditor";
import "./MailPreviewer.css";
import {
  IdentityKeyStatus,
  MailEncryptionType,
  SignalMessageType,
} from "../utils/Types";
import {
  ClientError,
  showError,
  showInfo,
  showSuccess,
} from "../common/errors";
// import { FingerprintGenerator } from "@privacyresearch/libsignal-protocol-typescript";
import MailAddressUtils from "../utils/MailAddressUtils";
import { toast } from "react-toastify";
import {
  BACKUP_FOLDER,
  INBOX_FOLDER,
  SENT_FOLDER,
  TRASH_FOLDER,
  CONTRACT_FOLDER,
  mailAddressSuffix,
} from "../common/constants";
import Strings from "../config/Strings";
import { GoUnverified, GoVerified } from "react-icons/go";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { ethers } from "ethers";
import TypeUtils from "../utils/TypeUtils";
import AskForPasswordAlert from "./AskForPasswordAlert";

// let controllerFetchDownload = new AbortController();
// const downloadTimers = {};
const FolderTree = ({ currentFolder }) => {
  //const excludeInbox = (currentFolder === INBOX_FOLDER);
  const folders = window.mailService.flatFolders(currentFolder);

  if (folders && folders.length > 0) {
    return (
      <>
        {folders &&
          folders.filter(folder => {
            if (folder && folder.path && folder.path !== CONTRACT_FOLDER) {
              return true;
            }
            return false;
          }).map((flatedFolder) => (
            <option
              key={flatedFolder.key + "-" + flatedFolder.path}
              value={flatedFolder.path}
            >
              {flatedFolder.name}
            </option>
          ))}
      </>
    );
  } else {
    return <option value="">No Folders</option>;
  }
};

const TOFUPanel = ({
  selectedAccount,
  fromAddress,
  fromDeviceId,
  preKeyMessage,
  onTrust,
}) => {
  const [title, setTitle] = useState("");
  // const [fingerprint, setFingerprint] = useState('');
  const [isLoading, setLoading] = useState(false);

  const [keyBundle, setKeyBundle] = useState(null);
  // const [sender, setSender] = useState(null);
  const [updateCounter, setUpdateCounter] = useState(0);
  useEffect(() => {
    // try {
    //     const s = MailAddressUtils.parseOneAddress(fromAddress);
    //     if (s) {
    //         setSender(s);
    //     }
    // } catch (e) {
    //     console.error('From address is invalid');
    // }

    setLoading(true);
    getIdentityKeyBundle()
      .then((keyBundle) => {
        setKeyBundle(keyBundle);
      })
      .then(() => {
        return display();
      })
      .then(() => {
        setLoading(false);
        console.log("TOFU is displayed");
      })
      .catch((e) => {
        setKeyBundle(null);
        setLoading(false);
        console.error(e);
        showError(e);
      });
  }, [fromAddress]); // eslint-disable-line react-hooks/exhaustive-deps

  // async function getVerifiedInfo(retry=5) {
  //     if (!window.appConfig.mailSenderNeedToBeVerified) {
  //         return true;
  //     }

  //     if (retry === 0) {
  //         throw ClientError.timeoutError('Getting identity key bundle timeout');
  //     }
  //     try {
  //         const verified = await window.mailService.getVerifiedInfo(fromAddress);
  //         return verified;
  //     } catch (e) {
  //         const promise = new Promise((resolve, reject) => {
  //             setTimeout(() => {
  //                 resolve();
  //             }, 1000);
  //         }).then(() => {
  //             return getVerifiedInfo(retry - 1)
  //         })
  //         return promise;
  //     }
  // }

  async function verifyIdentitySignature(address, identityKey, signature) {
    const keyBuf = ethers.decodeBase64(identityKey);
    const keyHex = ethers.hexlify(keyBuf);

    const recoveredAddr = window.web3Helper.ecRecover({
      data: keyHex,
      signature: signature,
    });
    return recoveredAddr.toLowerCase() === address;
  }
  async function getIdentityKeyBundle(retry = 5) {
    if (!window.appConfig.mailSenderNeedToBeVerified) {
      return true;
    }

    if (retry === 0) {
      throw ClientError.timeoutError("Getting identity key bundle timeout");
    }
    try {
      const identityKeyBundle = await window.mailService.getIdentityKeyBundle(
        fromAddress
      );
      let signed = identityKeyBundle.identityKeyIsVerified;
      if (
        !identityKeyBundle.identityKeyIsVerified &&
        identityKeyBundle.signature
      ) {
        signed = await verifyIdentitySignature(
          fromAddress,
          identityKeyBundle.identityKey,
          identityKeyBundle.signature
        );
      }
      identityKeyBundle.signed = signed;
      return identityKeyBundle;
    } catch (e) {
      const promise = new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, 1000);
      }).then(() => {
        return getIdentityKeyBundle(retry - 1);
      });
      return promise;
    }
  }
  async function display() {
    // FingerprintGenerator
    const remoteIdentityKey =
      window.mailService.extractIdentityKeyFromPreKeyMessage(
        preKeyMessage.signal.ciphertext.body
      );
    const status =
      await window.mailService.signalService.signalProtocolStore.identityKeyStatus(
        fromAddress,
        remoteIdentityKey
      );
    const title =
      status === IdentityKeyStatus.Changed
        ? Strings.previewer.message.tofu_changed
        : Strings.previewer.message.tofu_new;

    // const localIdentifier = selectedAccount;
    // const localIdentityKey = await window.mailService.getLocalIdentityKey();

    // const fp = await (new FingerprintGenerator(1)).createFor(localIdentifier, localIdentityKey, fromAddress, remoteIdentityKey);

    setTitle(title);
    // setFingerprint(fp);
  }

  if (isLoading) {
    // return (<VStack mt={10}>
    //     <Spinner />
    //     </VStack>)
    return (
      <Box mt={4}>
        <Facebook />
      </Box>
    );
  } else {
    const truncateString = (s, limit = 0) => {
      if (limit !== 0 && s.length > limit) {
        s = s.substring(0, limit) + "...";
      }
      return s;
    };
    const b64ToHex = (s, limit = 0) => {
      const a = ethers.decodeBase64(s);
      const h = ethers.hexlify(a);
      return truncateString(h, limit);
    };
    return (
      <VStack mt={10}>
        {window.appConfig.mailSenderNeedToBeVerified &&
          keyBundle &&
          keyBundle.identityKeyIsVerified && (
            <>
              <GoVerified size={"48"} color="green" />
              <Text color="green" fontWeight={"bold"}>
                {Strings.app.dialog.address_verify_popup.verified.title}
                {/*&nbsp;by&nbsp;{keyBundle.verifiedBy}*/}
              </Text>
              <Text color="green">
                {
                  Strings.app.dialog.address_verify_popup.verified
                    .sender_message
                }
              </Text>
            </>
          )}
        {window.appConfig.mailSenderNeedToBeVerified &&
          keyBundle &&
          !keyBundle.identityKeyIsVerified &&
          fromAddress && (
            <>
              <GoUnverified size={"48"} color="orange" />
              <Text color="orange" fontWeight={"bold"}>
                {
                  Strings.app.dialog.address_verify_popup.unverified
                    .sender_message
                }
              </Text>

              <Text fontSize={"sm"} mt={4} align="left" width="100%">
                {title}
              </Text>
              {Strings.previewer.tofu.verify}
              {/* {Strings.previewer.tofu.steps} */}

              {/* <CopyToClipboard text={fromAddress} cursor={"pointer"} onCopy={() => {
            toast(`${Strings.previewer.tofu.address} is ${Strings.previewer.tofu.copied}`);
            setUpdateCounter(updateCounter + 1)
        }}>
        <Text fontSize={"sm"} align="left" width="100%" px={10}><strong>{Strings.previewer.tofu.sender}:</strong>&nbsp;{fromAddress}mailAddressSuffix()</Text>
        </CopyToClipboard>

        {keyBundle && !keyBundle.identityKeyIsVerified &&
        <CopyToClipboard text={b64ToHex(keyBundle.identityKey)} cursor={"pointer"} onCopy={() => {
            toast(`${Strings.previewer.tofu.idkey} is ${Strings.previewer.tofu.copied}`);
            setUpdateCounter(updateCounter + 1)
        }}>
        <Text fontSize={"sm"} align="left" width="100%" px={10}><strong>{Strings.previewer.tofu.idkey}:</strong>&nbsp;{b64ToHex(keyBundle.identityKey, 32)}</Text>
        </CopyToClipboard>
        }

        {keyBundle.signature &&
        <CopyToClipboard text={keyBundle.signature} cursor={"pointer"} onCopy={() => {
            toast(`${Strings.previewer.tofu.signature} is ${Strings.previewer.tofu.copied}`);
            setUpdateCounter(updateCounter + 1)
        }}>
        <Text fontSize={"sm"} align="left" width="100%" px={10}>
            <strong>{Strings.previewer.tofu.signature}:</strong>&nbsp;{truncateString(keyBundle.signature, 32)}&nbsp;{keyBundle.signed ? Strings.previewer.tofu.correct : Strings.previewer.tofu.incorrect}
        </Text>
        </CopyToClipboard>
        }
        {(!keyBundle.signed && (!keyBundle.signature || keyBundle.signature.length === 0)) &&
        <Text fontSize={"sm"}t align="left" width="100%" px={10}>
            <strong>{Strings.previewer.tofu.signature}:</strong>&nbsp;{Strings.previewer.tofu.miss}
        </Text>
        } */}
            </>
          )}

        {window.appConfig.mailSenderNeedToBeVerified &&
          keyBundle &&
          !keyBundle.identityKeyIsVerified &&
          fromAddress && (
            <HStack py={5} align="left" justifyContent={"space-between"}>
              {/* <Flex> */}
              <Avatar name={fromAddress} textColor={"white"} />
              <VStack ml={2} verticalAlign={"middle"} align={"left"}>
                <CopyToClipboard
                  text={fromAddress}
                  cursor={"pointer"}
                  onCopy={() => {
                    toast(
                      `${Strings.previewer.tofu.address} is ${Strings.previewer.tofu.copied}`
                    );
                    setUpdateCounter(updateCounter + 1);
                  }}
                >
                  <Text fontSize={"sm"} align="left" width="100%" px={10}>
                    <strong>{Strings.previewer.tofu.sender}:</strong>&nbsp;
                    {fromAddress}
                    {mailAddressSuffix()}
                  </Text>
                </CopyToClipboard>

                {keyBundle && !keyBundle.identityKeyIsVerified && (
                  <CopyToClipboard
                    text={b64ToHex(keyBundle.identityKey)}
                    cursor={"pointer"}
                    onCopy={() => {
                      toast(
                        `${Strings.previewer.tofu.idkey} is ${Strings.previewer.tofu.copied}`
                      );
                      setUpdateCounter(updateCounter + 1);
                    }}
                  >
                    <Text fontSize={"sm"} align="left" width="100%" px={10}>
                      <strong>{Strings.previewer.tofu.idkey}:</strong>&nbsp;
                      {b64ToHex(keyBundle.identityKey, 32)}
                    </Text>
                  </CopyToClipboard>
                )}

                {keyBundle.signature && (
                  <CopyToClipboard
                    text={keyBundle.signature}
                    cursor={"pointer"}
                    onCopy={() => {
                      toast(
                        `${Strings.previewer.tofu.signature} is ${Strings.previewer.tofu.copied}`
                      );
                      setUpdateCounter(updateCounter + 1);
                    }}
                  >
                    <Text fontSize={"sm"} align="left" width="100%" px={10}>
                      <strong>{Strings.previewer.tofu.signature}:</strong>&nbsp;
                      {truncateString(keyBundle.signature, 32)}&nbsp;
                      {keyBundle.signed
                        ? Strings.previewer.tofu.correct
                        : Strings.previewer.tofu.incorrect}
                    </Text>
                  </CopyToClipboard>
                )}
              </VStack>
              {/* </Flex> */}
              {false && keyBundle.identityKeyIsVerified && (
                <Popover>
                  <PopoverTrigger>
                    <IconButton
                      icon={<GoVerified size={"20"} />}
                      background={"none"}
                      color="green"
                    />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader color={"green"} fontWeight={"bold"}>
                      {Strings.app.dialog.address_verify_popup.verified.title}
                      {/* &nbsp;by&nbsp;{recipient.verifiedBy} */}
                    </PopoverHeader>
                    <PopoverBody color={"green"}>
                      {
                        Strings.app.dialog.address_verify_popup.verified
                          .sender_message
                      }
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}

              {false && !keyBundle.identityKeyIsVerified && (
                <Popover>
                  <PopoverTrigger>
                    <IconButton
                      icon={<GoUnverified size={"20"} />}
                      background={"none"}
                      color="orange"
                    />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader color={"orange"} fontWeight={"bold"}>
                      {Strings.app.dialog.address_verify_popup.unverified.title}
                    </PopoverHeader>
                    <PopoverBody color={"orange"}>
                      {
                        Strings.app.dialog.address_verify_popup.unverified
                          .sender_message
                      }
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              )}
            </HStack>
          )}

        <Button colorScheme="red" onClick={onTrust}>
          Trust &amp; Decrypt
        </Button>
      </VStack>
    );
  }
};

const AddressView = ({ selectedAccount, label, addresses, onSelected }) => {
  const [formatedAddresses, setFormatedAddresses] = useState([]);
  useEffect(() => {
    Promise.all(
      addresses.map(async (address) => {
        try {
          const addr = await window.mailService.mapContact(
            address,
            selectedAccount
          );
          return addr;
        } catch (e) {
          console.error(e);
        }
        return { name: null, address: address };
      })
    )
      .then((result) => {
        setFormatedAddresses([...result]);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [addresses]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!addresses || addresses.length === 0) {
    return <></>;
  }

  if (typeof addresses === "string") {
    addresses = [addresses];
  }

  const addressClicked = (address) => {
    console.log(address);
    onSelected(address);
  };

  // Promise.all( addresses.map(async () => {

  // }))

  return (
    <Box>
      <strong style={{ fontSize: "14px" }}>{label} </strong>
      {formatedAddresses.map((address, index) => {
        // const addressObj = MailAddressUtils.parseOneAddress(address);
        const displayAddress = address.name ? address.name : address.address;
        const string =
          index + 1 !== addresses.length
            ? displayAddress + ",&nbsp;"
            : displayAddress;
        if (TypeUtils.isMobile()) {
          return (
            <Link
              wordBreak={"break-all"}
              fontSize={"14px"}
              key={address.address}
              onClick={() => {
                addressClicked(address);
              }}
              title={address.address}
            >
              <Text
                wordBreak={"break-all"}
                as={"span"}
                dangerouslySetInnerHTML={{ __html: string }}
              ></Text>
            </Link>
          );
        }
        return (
          <Link
            fontSize={"14px"}
            key={address.address}
            onClick={() => {
              addressClicked(address);
            }}
            title={address.address}
          >
            <Text
              as={"span"}
              dangerouslySetInnerHTML={{ __html: string }}
            ></Text>
          </Link>
        );
      })}
    </Box>
  );
};

const MailPreviewer = ({
  selectedAccount,
  setFolders,
  setMessages,
  message,
  setMessage,
}) => {
  console.log(">> MailPreviewer");
  const [contentOfMessage, setContentOfMessage] = useState(null);
  const [messageIsReady, setMessageIsReady] = useState(false);
  const [showMailEditor, setShowMailEditor] = useState(false);
  const [loadError, setLoadError] = useState(null);
  const [isDeleting, setDeleting] = useState(false);
  const [isMoving, setMoving] = useState(false);
  const [showTOFUPanel, setShowTOFUPanel] = useState(false);
  const [preKeyMessage, setPreKeyMessage] = useState(null);
  const [moveButtonDisabled, setMoveButtonDisabled] = useState(true);

  const addressEditorDisclosure = useDisclosure();
  const askForPasscodeDisclosure = useDisclosure();
  const [askForPasswordTask, setAskForPasswordTask] = useState(null);

  const moveDisclosure = useDisclosure();
  const destFolderRef = createRef();

  const [selectedEmailAddress, setSelectedEmailAddress] = useState("");
  const addressEditorNameRef = createRef();
  const addressEditorEmailRef = createRef();
  const [idleTimeout, setIdleTimeout] = useState(false)

  const getCurrentFolder = () => {
    return window.mailService
      ? window.mailService.currentFolder || INBOX_FOLDER
      : INBOX_FOLDER;
  };
  const addressEditorSave = () => {
    const name = addressEditorNameRef.current.value;
    const email = addressEditorEmailRef.current.value;
    if (!name || name.length === 0) {
      const error = ClientError.invalidParameterError(
        Strings.error.client.name_empty
      );
      showError(error);
      return;
    }
    if (email.indexOf("@") === -1) {
      const error = ClientError.invalidParameterError(
        Strings.error.client.email_empty
      );
      showError(error);
      return;
    }

    window.mailService
      .contactIsExisted(email)
      .then((isExisted) => {
        if (isExisted) {
          const error = ClientError.invalidParameterError(
            Strings.error.client.contact_already_existed
          );
          showError(error);
          return;
        }
        window.mailService
          .saveContact({ name, email }, true)
          .then(() => {
            addressEditorDisclosure.onClose();
            setSelectedEmailAddress(null);
          })
          .catch((e) => {
            console.error(e);
            showError(e);
          });
      })
      .catch((e) => {
        console.error(e);
        showError(e);
      });
  };
  const onAddressSelected = (address) => {
    // const addressObject = MailAddressUtils.parseOneAddress(address);
    if (
      address &&
      address.local &&
      selectedAccount.toLowerCase() === address.local.toLowerCase()
    ) {
      return;
    }

    window.mailService
      .contactIsExisted(address.address)
      .then((isExisted) => {
        if (isExisted) {
          return;
        }
        setSelectedEmailAddress(address);
        addressEditorDisclosure.onOpen();
      })
      .catch((e) => {
        console.error(e);
        showError(e);
      });
  };

  // const [isMoving, setMoving] = useState(false);

  // let editMode = 'reply';
  const composeDisclosure = useDisclosure({
    defaultIsOpen: false,
    onOpen: () => {
      console.log("Show Mail Editor");
      window.setIdleTimeout = setIdleTimeout;

      window.mailService.getPlexiMailUIState().then(uiState => {
        if (!uiState) {
          uiState = {
            currentFolder: window.mailService.currentFolder,
            editorIsOpened: true,
            threadContext: null,
          }
        } else {
          uiState.editorIsOpened = true;
        }
        return window.mailService.savePlexiMailUIState(uiState)
      }).then(() => {
      }).catch(e => {
        console.error(e);
      })
    },
    onClose: () => {
      console.log("Close Mail Editor");
      setShowMailEditor(false);
      delete window.setIdleTimeout;


      window.mailService.getPlexiMailUIState().then(uiState => {
        if (!uiState) {
          uiState = {
            currentFolder: window.mailService.currentFolder,
            editorIsOpened: false,
            threadContext: null,
          }
        } else {
          uiState.editorIsOpened = false;
        }
        return window.mailService.savePlexiMailUIState(uiState)
      }).then(() => {
      }).catch(e => {
        console.error(e);
      })
    },
  });
  useEffect(() => {
    setLoadError(null);
    window.addEventListener("beforeunload", (ev) => {
      // controllerFetchDownload.abort();
      // controllerFetchDownload = new AbortController();
      window.mailService.cancel();
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log("message changed");

    setLoadError(null);
    if (!message) {
      setMessageIsReady(false);
      return;
    }

    if (contentOfMessage) {
      if (
        contentOfMessage.header &&
        message.uid === contentOfMessage.header.uid
      ) {
        if (
          message.decryptedHeader &&
          message.decryptedHeader.cid === contentOfMessage.header.cid
        ) {
          return;
        } else {
          reset();
        }
      } else {
        reset();
      }
    }
    if (
      message.type === MailEncryptionType.password &&
      getCurrentFolder() !== SENT_FOLDER
    ) {
      // reset();
      decryptPasswordEncryptionMessage(message)
        .then((contentOfMessage) => {
          setMessageIsReady(true);
          setContentOfMessage(contentOfMessage);
          console.log("contentOfMessage: ", contentOfMessage);
        })
        .catch((e) => {
          console.error(e);
          const cancelled =
            e.code === "ERR_CANCELED" || e.code === DOMException.ABORT_ERR;
          if (cancelled) {
            return;
          }
          setShowTOFUPanel(false);
          setContentOfMessage(null);
          setMessageIsReady(true);
          setLoadError(e);
        });
      return;
    }

    checkIfNeedTOFU(message)
      .then((needTOFU) => {
        if (needTOFU) {
          getPreKeyMessage(message)
            .then((preKeyMessage) => {
              setPreKeyMessage(preKeyMessage);
              setMessageIsReady(true);
              setShowTOFUPanel(true);
            })
            .catch((e) => {
              console.error(e);
              setShowTOFUPanel(false);
              setContentOfMessage(null);
              setMessageIsReady(true);
              setLoadError(e);
            });
        } else {
          processSignalMessageAndLoadMessageContent(message)
            .then((contentOfMessage) => {
              setMessageIsReady(true);
              setContentOfMessage(contentOfMessage);
              console.log("contentOfMessage: ", contentOfMessage);
            })
            .catch((e) => {
              console.error(e);
              const cancelled =
                e.code === "ERR_CANCELED" || e.code === DOMException.ABORT_ERR;
              if (cancelled) {
                return;
              }
              setShowTOFUPanel(false);
              setContentOfMessage(null);
              setMessageIsReady(true);
              setLoadError(e);
            });
        }
      })
      .catch((e) => {
        console.error(e);
        setShowTOFUPanel(false);
        setContentOfMessage(null);
        setMessageIsReady(true);
        setLoadError(e);
      });
    // window.mailService.getMessage(message.decryptedHeader.cid, message.uid).then(([contentOfMessage, _a, _b]) => {
    //     setMessageIsReady(true);
    //     setContentOfMessage(contentOfMessage)
    //     console.log('contentOfMessage: ', contentOfMessage);
    // }).catch((reason) => {
    //     setMessageIsReady(true);
    //     console.error(reason);
    //     setLoadError(reason);
    // });
  }, [message]); // eslint-disable-line react-hooks/exhaustive-deps
  

  function niceBytes(x) {
    const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
  }

  function reset() {
    setContentOfMessage(null);
    setMessageIsReady(false);
    setShowMailEditor(false);
    setLoadError(null);
    setDeleting(false);
    setShowTOFUPanel(false);
    setPreKeyMessage(null);
    setSelectedEmailAddress(null);
  }

  function showMessages() {
    window.mailService.cancel();
    reset();
    setMessage(null);
  }

  async function doDecryptPasswordEncryptionMessage(message, password) {
    await window.mailService.processPasswordMessage(message, password);

    const [contentOfMessage] = await window.mailService.getMessage(
      message.decryptedHeader.cid,
      message.uid,
      message.mid,
      message.am,
      isFromMe(),
      null
    );

    contentOfMessage.verified = false;
    contentOfMessage.verifiedBy = "";
    return contentOfMessage;
  }

  async function askForPassword() {
    const task = {};
    const promise = new Promise((resolve, reject) => {
      task.resolve = resolve;
      task.reject = reject;
    });
    task.promise = promise;
    setAskForPasswordTask(task);

    askForPasscodeDisclosure.onOpen();

    return await task.promise
  }

  async function decryptPasswordEncryptionMessage(message) {
    const keyBundle =
      message.ref && message.ref.length > 0
        ? await window.mailService.signalService.getEncryptionKeyBundle(
            message.ref
          )
        : null;
    if (keyBundle && keyBundle.pwd && keyBundle.pwd.length > 0) {
      return await doDecryptPasswordEncryptionMessage(message, keyBundle.pwd);
    } else {
      const password = await askForPassword();
      if (!password) {
        showMessages();
        return;
      }
      return await doDecryptPasswordEncryptionMessage(message, password);
    }
  }
  function extractMyAccount(message) {
    if (message.localSignalAddress) {
      return message.localSignalAddress;
    }
    if (message.signal && message.signal.toAddress) {
      // return message.signal.accounts[0].account;
      const myAccount = message.signal.toAddress;
      return myAccount;
    }
    return window.appConfig.recentActiveAccount;
    // throw new Error('Invalid message');
  }
  
  async function checkIfNeedTOFU(message) {
    if (message && message.type === MailEncryptionType.password) {
      return false;
    }

    const encryptionKey =
      await window.mailService.signalService.getEncryptionKeyBundle(
        message.uid
      );
    if (encryptionKey) {
      return false;
    }
    if (window.mailService.currentFolder === SENT_FOLDER) {
      return false;
    }

    const fromAddress = message.signal.fromAddress;
    const fromDeviceId =
      typeof message.signal.fromDeviceId === "string"
        ? parseInt(message.signal.fromDeviceId)
        : message.signal.fromDeviceId;
    if (
      message.signal.ciphertext.type === SignalMessageType.PreKeyWhisperMessage
    ) {
      //
      const remoteIdentityKey =
        window.mailService.extractIdentityKeyFromPreKeyMessage(
          message.signal.ciphertext.body
        );
      if (remoteIdentityKey) {
        const buffer =
          remoteIdentityKey.constructor === Uint8Array
            ? remoteIdentityKey.buffer
            : remoteIdentityKey;
        const status =
          await window.mailService.signalService.signalProtocolStore.identityKeyStatus(
            fromAddress,
            buffer
          );
        if (status === IdentityKeyStatus.Unchanged) {
          return false;
        }
      }

      return true;
      // setPreKeyMessage(message);
      // setShowTOFUPanel(true);

      // const identityKey = window.mailService.extractIdentityKeyFromPreKeyMessage(message.signal.ciphertext.body);
      // const isTrust = await window.mailService.signalService.signalProtocolStore.isTrustedIdentity(fromAddress, identityKey);
    } else if (
      message.signal.ciphertext.type === SignalMessageType.WhisperMessage
    ) {
      const myAccount = extractMyAccount(message);
      const isReady = await window.mailService.sessionIsReady(
        fromAddress,
        fromDeviceId,
        myAccount
      );
      if (isReady) {
        return false;
      }
      return true;
    }
    throw ClientError.invalidParameterError(
      Strings.error.client.invalid_signal_message
    );

    // const message = await window.mailService.getPreKeyMessage(fromAddress, fromDeviceId);
    // if (message) {
    //     setPreKeyMessage(message);
    //     setShowTOFUPanel(true);
    // } else {
    //     setShowTOFUPanel(false);
    // }
  }

  async function getPreKeyMessage(message) {
    if (message && message.type === MailEncryptionType.password) {
      throw ClientError.invalidParameterError(
        Strings.error.client.invalid_message_type
      );
    }
    const fromAddress = message.signal.fromAddress;
    const fromDeviceId =
      typeof message.signal.fromDeviceId === "string"
        ? parseInt(message.signal.fromDeviceId)
        : message.signal.fromDeviceId;
    if (
      message.signal.ciphertext.type === SignalMessageType.PreKeyWhisperMessage
    ) {
      return message;
    } else if (
      message.signal.ciphertext.type === SignalMessageType.WhisperMessage
    ) {
      const preKeyMessage = await window.mailService.getPreKeyMessage(
        fromAddress,
        fromDeviceId,
        message.sendDate
      );
      if (!preKeyMessage) {
        throw ClientError.invalidParameterError(
          Strings.error.client.prekey_message_not_found
        );
      }
      return preKeyMessage;
    }
  }
  function isFromMe(aMessage) {
    if (!aMessage) {
      aMessage = message;
    }
    const addressObject = MailAddressUtils.parseOneAddress(aMessage.from);

    const fromMe = selectedAccount === addressObject.local;
    return fromMe;
  }
  async function getFromVerifiedInfo() {
    if (!window.appConfig.mailSenderNeedToBeVerified) {
      return true;
    }
    if (message === null) {
      return false;
    }

    const fromAddress = MailAddressUtils.parseOneAddress(message.from) || null;
    if (!fromAddress || !fromAddress.local || fromAddress.local.length === 0) {
      return false;
    }

    return await getVerifiedInfo(fromAddress.address);
  }

  async function getVerifiedInfo(fromAddress, retry = 5) {
    if (retry === 0) {
      throw ClientError.timeoutError("Getting identity key bundle timeout");
    }
    try {
      const verified = await window.mailService.getVerifiedInfo(fromAddress);
      return verified;
    } catch (e) {
      const promise = new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, 1000);
      }).then(() => {
        return getVerifiedInfo(fromAddress, retry - 1);
      });
      return promise;
    }
  }

  async function processSignalMessageAndLoadMessageContent(message) {
    const verifiedInfo = await getFromVerifiedInfo();

    await window.mailService.processSignalMessage(message, isFromMe(message));
    // const [contentOfMessage, _a, _b] = await window.mailService.getMessage(message.decryptedHeader.cid, message.uid, message.mid, message.am, isFromMe(), null);
    const [contentOfMessage] = await window.mailService.getMessage(
      message.decryptedHeader.cid,
      message.uid,
      message.mid,
      message.am,
      isFromMe(),
      null
    );

    // setMessageIsReady(true);
    // setContentOfMessage(contentOfMessage)
    contentOfMessage.verified = verifiedInfo.verified;
    contentOfMessage.verifiedBy = verifiedInfo.verifiedBy;
    return contentOfMessage;

    /*
        window.mailService.processSignalMessage(message).then(() => {
            window.mailService.getMessage(message.decryptedHeader.cid, message.uid).then(([contentOfMessage, _a, _b]) => {
                setMessageIsReady(true);
                setContentOfMessage(contentOfMessage)
                console.log('contentOfMessage: ', contentOfMessage);
            }).catch((reason) => {
                setMessageIsReady(true);
                console.error(reason);
                setLoadError(reason);
            });
        }).catch((reason) => {
            setMessageIsReady(true);
            console.error(reason);
            setLoadError(reason);
        });
        */
  }

  async function trustPreKeyMessage(preKeyMessage) {
    try {
      await window.mailService.processSignalMessage(
        preKeyMessage,
        isFromMe(preKeyMessage)
      );
      // await window.mailService.processSignalMessage(message);
      setShowTOFUPanel(false);
      setMessageIsReady(false);
    } catch (reason) {
      console.error(reason);
      setShowTOFUPanel(false);
      setMessageIsReady(true);
      setLoadError(reason);
    }
  }

  function onTrustClick() {
    // setShowTOFUPanel(false);
    trustPreKeyMessage(preKeyMessage).then(() => {
      processSignalMessageAndLoadMessageContent(message)
        .then((contentOfMessage) => {
          setMessageIsReady(true);
          setContentOfMessage(contentOfMessage);
          console.log("contentOfMessage: ", contentOfMessage);
        })
        .catch((e) => {
          console.error(e);
          setMessageIsReady(true);
          setLoadError(e);
        });
    });
  }

  // function sendMessage() {
  //     const message = {
  //         cid: "k51qzi5uqu5dj3qwz4efoaom0n1cw61fymh0r9t0ibc96rw0kuo4wbeqqdrvi4",
  //         from: "0x6775a090b20f56578d30fd751e55ddf91f8be89c",
  //         to: ["0x6775a090b20f56578d30fd751e55ddf91f8be89c"],
  //         subject: "This is a test message",
  //         sendDate: "2022-07-15 11:11:12"
  //     }
  //     // let mailService = new MailService()

  //     console.log('MailPreviewer new MailService')
  //     // let mailService = new MailService()
  //     window.mailService.sendMessage(message)
  // }

  /*
    async function downloadAttachment(attachment) {
        console.log('download attachment: ', attachment.cid, ', name: ', attachment.name)
        // let mailService = new MailService()
        let att = await window.mailService.downloadAttachment(message.mid, message.uid, attachment.id, attachment.cid, attachment.name);
        console.log('attachment: ', att);
        
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(att);
        link.download = att.name;
        link.click();
        window.URL.revokeObjectURL(link.href);
        link.remove();
    }
    async function downloadAttachmentByBrowser(attachment) {
        window.open('https://' + attachment.cid + '.ipfs.dweb.link/' + attachment.name);
    }
    function humanFileSize(bytes, si=false, dp=1) {
        const thresh = si ? 1000 : 1024;
      
        if (Math.abs(bytes) < thresh) {
          return bytes + ' B';
        }
      
        const units = si 
          ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
          : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10**dp;
      
        do {
          bytes /= thresh;
          ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
      
      
        return bytes.toFixed(dp) + ' ' + units[u];
    }
    */
  async function downloadAttachmentWithAxios(attachment) {
    // let info = await axios.get('https://' + attachment.cid + '.ipfs.dweb.link/',
    // {
    //     signal: controllerFetchDownload.signal,
    //     // responseType: "arraybuffer",
    //     responseType: 'text',
    //     maxContentLength: 10000000,
    //     onDownloadProgress: (event) => {
    //         console.log('Download of attachment in progress... ' + event.loaded + '/' + event.total);
    //         document.getElementById('downloadProgress-' + attachment.cid).innerText =  humanFileSize(event.loaded) + ' Downloaded';
    //     }
    // });
    const S = Strings.previewer.message.attachment.download;
    const progressElt = document.getElementById(
      "downloadProgress-" + attachment.cid
    );
    if (progressElt) {
      progressElt.innerHTML = S.downloading + " ...";
    }
    const attachmentElt = document.getElementById(
      "attachment-" + attachment.cid
    );
    if (attachmentElt) {
      if (attachmentElt.isDownloading) {
        alert(S.is_in_progress);
        return;
      }
      attachmentElt.isDownloading = true;
    }
    let i = 0;
    const dots = [" .", " ..", " ...", " ....", " .....", " ......"];
    let interval = setInterval(() => {
      const dot = dots[i++ % 6];
      progressElt.innerHTML = S.downloading + dot;
    }, 500);
    // if (downloadTimers[attachment.cid] && downloadTimers[attachment.cid] > 0) {
    //     clearInterval(downloadTimers[attachment.cid]);
    //     delete downloadTimers[attachment.cid];
    // }
    // downloadTimers[attachment.cid] = interval;

    //
    window.mailService
      .downloadAttachmentWithAxios(
        message.mid,
        message.uid,
        attachment.id,
        attachment.cid,
        attachment.name,
        message.am,
        isFromMe(message),
        (event) => {
          // window.mailService.downloadAttachmentWithoutCache(message.mid, message.uid, attachment.id, attachment.cid, attachment.name, (event) => {
          // console.log('Download of attachment in progress... ' + event.loaded + '/' + event.total);

          // if (downloadTimers[attachment.cid] && downloadTimers[attachment.cid] > 0) {
          //     clearInterval(downloadTimers[attachment.cid]);
          //     delete downloadTimers[attachment.cid];
          // }

          if (interval > 0) {
            clearInterval(interval);
            interval = -1;
          }

          if (event.total) {
            if (progressElt) {
              progressElt.innerText =
                S.progress.paragraph1_1 +
                " " +
                niceBytes(event.loaded) +
                " " +
                S.progress.paragraph1_2 +
                " " +
                niceBytes(event.total);
            }
          } else {
            if (progressElt) {
              progressElt.innerText =
                niceBytes(event.loaded) + " " + S.progress.paragraph2;
            }
          }
        }
      )
      .then((decryptedBlob) => {
        // if (downloadTimers[attachment.cid] && downloadTimers[attachment.cid] > 0) {
        //     clearInterval(downloadTimers[attachment.cid]);
        //     delete downloadTimers[attachment.cid];
        // }

        if (progressElt) {
          progressElt.innerText = "";
        }

        if (interval > 0) {
          clearInterval(interval);
          interval = -1;
        }

        if (attachmentElt) {
          attachmentElt.isDownloading = false;
        }

        let url = URL.createObjectURL(decryptedBlob);
        let a = window.document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", attachment.name);
        window.document.body.append(a);
        a.click();
        window.window.URL.revokeObjectURL(url);
        a.remove();
      })
      .catch((err) => {
        // if (downloadTimers[attachment.cid] && downloadTimers[attachment.cid] > 0) {
        //     clearInterval(downloadTimers[attachment.cid]);
        //     delete downloadTimers[attachment.cid];
        // }

        if (progressElt) {
          progressElt.innerText = "";
        }

        if (interval > 0) {
          clearInterval(interval);
          interval = -1;
        }

        if (attachmentElt) {
          attachmentElt.isDownloading = false;
        }
        const cancelled =
          err.code === "ERR_CANCELED" || err.code === DOMException.ABORT_ERR;
        if (cancelled) {
          console.error("Error during downloading attachment: cancelled");
          return;
        }

        if (
          err.message &&
          err.message.startsWith("block with cid") &&
          err.message.endsWith("no found")
        ) {
          toast.warning(
            <div style={{ color: "#000" }}>
              {Strings.previewer.dialog.cid_not_found.title} {attachment.name}
              <br />
              <br />
              {Strings.previewer.dialog.cid_not_found.message}
            </div>,
            {
              position: "top-center",
              autoClose: false,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              pauseOnFocusLoss: false,
            }
          );
          return;
        }
        if (err.message === "cancelled") {
          console.error("Error during downloading attachment: cancelled");
        } else {
          console.error("Error during downloading attachment: ", err);
          if (progressElt) {
            progressElt.innerText = err;
          }
          showError(err);
        }
      });

    /*
        axios.get(
            'https://' + attachment.cid + '.ipfs.dweb.link/' + attachment.name,
            {
                signal: controllerFetchDownload.signal,
                // responseType: "arraybuffer",
                responseType: 'blob',
                // maxContentLength: 10000000,
                onDownloadProgress: (event) => {
                    console.log('Download of attachment in progress... ' + event.loaded + '/' + event.total);
                    if (event.total) {
                        document.getElementById('downloadProgress-' + attachment.cid).innerText =  'Download ' + niceBytes(event.loaded) + ' of ' + niceBytes(event.total);
                    } else {
                        document.getElementById('downloadProgress-' + attachment.cid).innerText =  niceBytes(event.loaded) + ' Download';
                        
                    }
                }
            })
            .then((response) => {
                const blob = response.data;

                window.mailService.decryptAttachment(message.uid, blob).then(decryptedBlob => {

                    let url = URL.createObjectURL(decryptedBlob);
                    let a = window.document.createElement("a");
                    a.setAttribute("href", url);
                    a.setAttribute("download", attachment.name);
                    window.document.body.append(a);
                    a.click();
                    window.window.URL.revokeObjectURL(url);
                    a.remove();
                    document.getElementById('downloadProgress-' + attachment.cid).innerText =  '';

                }).catch(err => {
                    document.getElementById('downloadProgress-' + attachment.cid).innerText = err;
                    showError(err);
                });
            })
            .catch((err) => {
                if (err.message !== "cancelled") {
                    console.console.error('Error during downloading attachment: ', err);
                    document.getElementById('downloadProgress-' + attachment.cid).innerText = err;
                }
            });
            */
  }
  function doReply() {
    window.mailService.editMode = "reply";
    //composeDisclosure.onOpen();

    composeDisclosure.onOpen();
    setShowMailEditor(true);
  }
  function doReplyAll() {
    window.mailService.editMode = "reply-all";
    composeDisclosure.onOpen();
    setShowMailEditor(true);
  }
  function doForward() {
    window.mailService.editMode = "forward";
    composeDisclosure.onOpen();
    setShowMailEditor(true);
  }
  function doMove() {
    const destFolder = destFolderRef.current
      ? destFolderRef.current.value
      : null;
    if (!destFolder || destFolder === "") {
      showInfo(SP.message.select_folder);
      return;
    }
    setMoving(true);
    window.mailService
      .moveMessage(message, destFolder)
      .then(() => {
        setMoving(false);
        moveDisclosure.onClose();
        showSuccess(SP.message.moved);

        const messages = Object.values(
          window.mailService.folders[getCurrentFolder()].messages
        )
          .sort((a, b) => {
            // return ((new Date(b.sendDate)).getTime() - (new Date(a.sendDate)).getTime());
            return b.sendDate - a.sendDate;
          })
          .filter((message) => {
            return !window.mailService.messageIsDeleted(message.uid);
          });
        setTimeout(() => {
          setMessages([...messages]);
        }, 10);
      })
      .catch((e) => {
        console.error(e);
        setMoving(false);
        moveDisclosure.onClose();
        showError(e);
      });
  }

  // function doRecover() {

  // }

  async function doDelete() {
    try {
      if (getCurrentFolder() !== TRASH_FOLDER) {
        setDeleting(true);
        // await window.mailService.moveMessage(message, getCurrentFolder(), TRASH_FOLDER);
        await window.mailService.moveMessage(message, TRASH_FOLDER);
        showSuccess(SP.message.moved_to_trash);

        // setDeleting(false)
        // setFolders({});
        // setFolders({...window.mailService.folders});
      } else {
        setDeleting(true);
        // await window.mailService.deleteMessage(message, getCurrentFolder());
        await window.mailService.deleteMessage(message);
        showSuccess(SP.message.deleted);

        // setDeleting(false)
        // setFolders({});
        // setFolders({...window.mailService.folders});
      }
      setDeleting(false);
      showMessages();

      const folders = { ...window.mailService.folders };
      delete folders[BACKUP_FOLDER];
      setFolders(folders);

      const messages = Object.values(
        window.mailService.folders[getCurrentFolder()].messages
      )
        .sort((a, b) => {
          // return ((new Date(b.sendDate)).getTime() - (new Date(a.sendDate)).getTime());
          return b.sendDate - a.sendDate;
        })
        .filter((message) => {
          return !window.mailService.messageIsDeleted(message.uid);
        });
      setTimeout(() => {
        setMessages([...messages]);
      }, 10);
    } catch (reason) {
      console.error(reason);
      setDeleting(false);
      alert(reason.message);
    }
  }

  function doReload() {
    
    reset();

    //let mailService = new MailService()
    console.log("MailPreviewer new MailService");

    checkIfNeedTOFU(message)
      .then((needTOFU) => {
        if (needTOFU) {
          getPreKeyMessage(message)
            .then((preKeyMessage) => {
              setPreKeyMessage(preKeyMessage);
              setShowTOFUPanel(true);
            })
            .catch((e) => {
              console.error(e);
              setShowTOFUPanel(false);
              setMessageIsReady(true);
              showError(e);
            });
        } else {
          processSignalMessageAndLoadMessageContent(message)
            .then((contentOfMessage) => {
              setMessageIsReady(true);
              setContentOfMessage(contentOfMessage);
              console.log("contentOfMessage: ", contentOfMessage);
            })
            .catch((e) => {
              console.error(e);
              setMessageIsReady(true);
              setLoadError(e);
            });
        }
      })
      .catch((e) => {
        console.error(e);
        setMessageIsReady(true);
        setLoadError(e);
      });

    /*
        window.mailService.processSignalMessage(message).then(() => {
            window.mailService.getMessage(message.decryptedHeader.cid, message.uid).then(([contentOfMessage, _a, _b]) => {
                setMessageIsReady(true);
                setContentOfMessage(contentOfMessage)
                console.log('contentOfMessage: ', contentOfMessage);
            }).catch((reason) => {
                setMessageIsReady(true);
                console.error(reason);
                setLoadError(reason);
            });
        }).catch(reason => {
            setMessageIsReady(true);
            console.error(reason);
            setLoadError(reason);

        })
        */

    /*
        window.mailService.getMessage(message.decryptedHeader.cid, message.uid).then(([contentOfMessage, _a, _b]) => {
            setMessageIsReady(true);
            setContentOfMessage(contentOfMessage)
            console.log('contentOfMessage: ', contentOfMessage);
        }).catch((reason) => {
            setMessageIsReady(true);
            console.error(reason);
            setLoadError(reason);
        });
        */
  }

  function replaceLineBreakToBr(message) {
    if (!message) {
      return "";
    }
    return message.replace("\r\n", "\n").replace("\n", "<br />");
  }
  const SP = Strings.previewer;
  const S = SP.menu;

  return (
    <Box hidden={!Boolean(message)}>
      {showMailEditor && (
        <MailEditor
          selectedAccount={selectedAccount}
          setFolders={setFolders}
          composeDisclosure={composeDisclosure}
          editMode={window.mailService.editMode}
          contentOfMessage={contentOfMessage}
          idleTimeout={idleTimeout}
        />
      )}
      <AskForPasswordAlert disclosure={askForPasscodeDisclosure} onCompleted={(passcode) => {
        if (askForPasswordTask) {
          askForPasswordTask.resolve(passcode);
        }
      }} />
      <Flex
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="left"
        bg={useColorModeValue("white", "gray.900")}
        borderBottomWidth="1px"
        borderBottomColor={useColorModeValue("gray.200", "gray.700")}
        justifyContent={{ base: "start", md: "start" }}
      >
        <HStack spacing={{ base: "0", md: "6" }}>
          {/* <IconButton
          icon={<ReactIcons.FaAngleLeft onClick={showMessages} />}
          spacing={{ base: '6', md: '6' }}
        /> */}
          <Button
            leftIcon={<ReactIcons.FaAngleLeft />}
            position="unset"
            onClick={showMessages}
            spacing={{ base: "6", md: "6" }}
          >
            {SP.menu.back}
          </Button>
          {(getCurrentFolder() !== SENT_FOLDER || true) && (
            <>
              <Tooltip label={S.reply}>
                <IconButton
                  position="unset"
                  isDisabled={!messageIsReady || loadError != null}
                  icon={<ReactIcons.FaReply />}
                  onClick={doReply}
                  spacing={{ base: "6", md: "6" }}
                />
              </Tooltip>

              {/* <Button leftIcon={<ReactIcons.FaReply />} onClick={doReply}>Reply</Button> */}

              <Tooltip label={S.reply_all}>
                <IconButton
                  position="unset"
                  isDisabled={!messageIsReady || loadError != null}
                  icon={<ReactIcons.FaReplyAll />}
                  onClick={doReplyAll}
                  spacing={{ base: "6", md: "6" }}
                />
              </Tooltip>
              {/* <Button leftIcon={<ReactIcons.FaReplyAll />} onClick={doReplyAll}>Reply All</Button> */}

              <Tooltip label={S.forward}>
                {/* <Button leftIcon={<ReactIcons.FaShare />} onClick={doForward}>Forward</Button> */}
                <IconButton
                  position="unset"
                  isDisabled={!messageIsReady || loadError != null}
                  icon={<ReactIcons.FaShare />}
                  onClick={doForward}
                  spacing={{ base: "6", md: "6" }}
                />
              </Tooltip>

              {/* <Tooltip label="Move" hidden={currentFolder === SENT_FOLDER}>
            <IconButton
            hidden={currentFolder === SENT_FOLDER}
            isDisabled={!messageIsReady || loadError != null}
            icon={<ReactIcons.FaFolder />}
            onClick={doMove}
            spacing={{ base: '6', md: '6' }}
            />
        </Tooltip> */}

              <Popover
                placement="bottom-end"
                isOpen={moveDisclosure.isOpen}
                onClose={moveDisclosure.onClose}
              >
                <PopoverTrigger>
                  {/* <Button leftIcon={<ReactIcons.FaShare />} onClick={doForward}>Forward</Button> */}
                  <Tooltip
                    label={S.move}
                    hidden={getCurrentFolder() === SENT_FOLDER}
                  >
                    <IconButton
                      position="unset"
                      hidden={getCurrentFolder() === SENT_FOLDER}
                      isDisabled={!messageIsReady || loadError != null}
                      icon={<ReactIcons.FaFolder />}
                      onClick={moveDisclosure.onToggle}
                      spacing={{ base: "6", md: "6" }}
                    />
                  </Tooltip>
                </PopoverTrigger>

                <PopoverContent>
                  <PopoverHeader>{SP.dialog.move_message.title}</PopoverHeader>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody>
                    <Select
                      ref={destFolderRef}
                      placeholder="Move to..."
                      onChange={(e) => {
                        console.log(e.target.value);
                        if (!e.target.value || e.target.value === "") {
                          setMoveButtonDisabled(true);
                        } else {
                          setMoveButtonDisabled(false);
                        }
                      }}
                    >
                      <FolderTree currentFolder={getCurrentFolder()} />
                    </Select>
                  </PopoverBody>
                  <PopoverFooter>
                    <HStack justifyContent={"right"}>
                      <Button
                        colorScheme="red"
                        mr={3}
                        isDisabled={isMoving}
                        onClick={moveDisclosure.onClose}
                      >
                        {SP.dialog.move_message.button.cancel}
                      </Button>
                      <Button
                        colorScheme="green"
                        isLoading={isMoving}
                        disabled={moveButtonDisabled}
                        onClick={doMove}
                      >
                        {SP.dialog.move_message.button.move}
                      </Button>
                    </HStack>
                  </PopoverFooter>
                </PopoverContent>
              </Popover>
            </>
          )}
          {/* {(currentFolder === SENT_FOLDER) &&
            <Tooltip label="Edit">
                <IconButton
                isDisabled={!messageIsReady || loadError != null}
                icon={<ReactIcons.FaEdit />}
                onClick={doEdit}
                spacing={{ base: '6', md: '6' }}
                />
            </Tooltip>
        } */}

          <Tooltip label={S.delete}>
            <IconButton
              position="unset"
              isDisabled={!messageIsReady}
              // hidden={true}
              icon={<ReactIcons.FaTrash />}
              onClick={doDelete}
              isLoading={isDeleting}
              spacing={{ base: "6", md: "6" }}
            />
          </Tooltip>

          <Tooltip label={S.reload}>
            {/* <Button leftIcon={<ReactIcons.FaShare />} onClick={doForward}>Forward</Button> */}
            <IconButton
              position="unset"
              isDisabled={!messageIsReady}
              icon={<ReactIcons.FaRedoAlt />}
              onClick={doReload}
              spacing={{ base: "6", md: "6" }}
            />
          </Tooltip>
          {/* <Button onClick={doMove}>Move</Button>
        <Button onClick={doDelete}>Delete</Button> */}
        </HStack>
      </Flex>

      {showTOFUPanel && message && (
        <TOFUPanel
          selectedAccount={selectedAccount}
          fromAddress={message.signal.fromAddress}
          fromDeviceId={message.signal.fromDeviceId}
          preKeyMessage={preKeyMessage}
          onTrust={onTrustClick}
        />
      )}

      {!showTOFUPanel && messageIsReady && loadError && (
        <Box textAlign="center" py={10} px={6}>
          <Box display="inline-block">
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              bg={"red.500"}
              rounded={"50px"}
              w={"55px"}
              h={"55px"}
              textAlign="center"
            >
              <CloseIcon boxSize={"20px"} color={"white"} />
            </Flex>
          </Box>
          <Heading as="h2" size="xl" mt={6} mb={2}>
            {SP.message.failed_to_load}
          </Heading>

          <Text color={"black"}>{loadError.message}</Text>
          <Text
            dangerouslySetInnerHTML={{
              __html: replaceLineBreakToBr(loadError.stack),
            }}
            color={"gray.500"}
            textAlign="left"
          ></Text>
        </Box>
      )}
      {!showTOFUPanel && messageIsReady && !loadError && (
        <VStack mt={2} align={"left"}>
          {/* <AddToContactModal disclosure={addressEditorDisclosure} /> */}
          <Modal
            size={"xl"}
            isOpen={addressEditorDisclosure.isOpen}
            onClose={addressEditorDisclosure.onClose}
            closeOnOverlayClick={false}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                {SP.dialog.add_contact.title}
                <ModalCloseButton />
              </ModalHeader>

              <ModalBody>
                <VStack align={"left"}>
                  <FormLabel>{SP.dialog.add_contact.content.name} </FormLabel>
                  <Input
                    ref={addressEditorNameRef}
                    defaultValue={
                      selectedEmailAddress && selectedEmailAddress.name
                    }
                  />
                  <FormLabel>{SP.dialog.add_contact.content.email} </FormLabel>
                  <Input
                    ref={addressEditorEmailRef}
                    defaultValue={
                      selectedEmailAddress && selectedEmailAddress.address
                    }
                  />
                </VStack>
              </ModalBody>

              <ModalFooter>
                <Button
                  colorScheme="red"
                  mr={3}
                  onClick={addressEditorDisclosure.onClose}
                >
                  {SP.dialog.add_contact.button.cancel}
                </Button>
                <Button colorScheme="green" onClick={addressEditorSave}>
                  {SP.dialog.add_contact.button.add}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          <Text fontSize={"22px"}>
            <strong>
              {contentOfMessage && contentOfMessage.header.subject}
            </strong>
          </Text>

          <Text fontSize={"14px"}>
            <strong>Send Date:&nbsp;</strong>{contentOfMessage && new Date(contentOfMessage.header.sendDate).toLocaleString()}
          </Text>
          {contentOfMessage && (
            <Flex>
              <AddressView
                selectedAccount={selectedAccount}
                addresses={contentOfMessage.header.from}
                onSelected={onAddressSelected}
                label={"From: "}
              />
              {window.appConfig.mailSenderNeedToBeVerified &&
                getCurrentFolder() !== SENT_FOLDER &&
                contentOfMessage.verified && (
                  <Popover>
                    <PopoverTrigger>
                      <IconButton
                        ml={2}
                        icon={<GoVerified size={16} />}
                        size={20}
                        background={"none"}
                        color="green"
                      />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader color={"green"} fontWeight={"bold"}>
                        {Strings.app.dialog.address_verify_popup.verified.title}
                        {/*&nbsp;by&nbsp;{contentOfMessage.verifiedBy}*/}
                      </PopoverHeader>
                      <PopoverBody color={"green"}>
                        <Text>
                          {
                            Strings.app.dialog.address_verify_popup.verified
                              .sender_message
                          }
                        </Text>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                )}
              {/* {window.appConfig.mailSenderNeedToBeVerified &&
                getCurrentFolder() !== SENT_FOLDER &&
                !contentOfMessage.verified && (
                  <Popover>
                    <PopoverTrigger>
                      <IconButton
                        ml={2}
                        icon={<GoUnverified size={16} />}
                        size={20}
                        background={"none"}
                        color="orange"
                      />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader color={"orange"} fontWeight={"bold"}>
                        {
                          Strings.app.dialog.address_verify_popup.unverified
                            .title
                        }
                      </PopoverHeader>
                      <PopoverBody color={"orange"}>
                        {
                          Strings.app.dialog.address_verify_popup.unverified
                            .sender_message
                        }
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                )} */}

                {window.appConfig.mailSenderNeedToBeVerified &&
                getCurrentFolder() !== SENT_FOLDER &&
                !contentOfMessage.verified && (
                  <Popover>
                  <PopoverTrigger>
                    <IconButton
                      ml={2}
                      icon={<GoVerified size={16} />}
                      size={20}
                      background={"none"}
                      color="green"
                    />
                  </PopoverTrigger>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverHeader color={"green"} fontWeight={"bold"}>
                      {Strings.app.dialog.address_verify_popup.verified.title}
                      {/*&nbsp;by&nbsp;{contentOfMessage.verifiedBy}*/}
                    </PopoverHeader>
                    <PopoverBody color={"green"}>
                      <Text>
                        {
                          Strings.app.dialog.address_verify_popup.verified
                            .sender_message
                        }
                      </Text>
                    </PopoverBody>
                  </PopoverContent>
                  </Popover>
                )}
            </Flex>
          )}
          {contentOfMessage && contentOfMessage.header.to && (
            <AddressView
              selectedAccount={selectedAccount}
              addresses={contentOfMessage.header.to}
              onSelected={onAddressSelected}
              label={"To: "}
            />
          )}
          <hr />
          <Box
            as={"div"}
            fontSize={"13px"}
            className="mail-previewer-container"
            dangerouslySetInnerHTML={
              contentOfMessage && { __html: contentOfMessage.body }
            }
          />

          {contentOfMessage &&
            contentOfMessage.header &&
            contentOfMessage.header.attachments &&
            contentOfMessage.header.attachments.length > 0 && (
              <VStack align={"left"}>
                <hr />
                <Text>{SP.message.attachment.title}</Text>
                <List spacing={3} textColor="gray.600" fontSize={"12px"}>
                  {contentOfMessage &&
                    contentOfMessage.header &&
                    contentOfMessage.header.attachments &&
                    contentOfMessage.header.attachments.map((attachment) => (
                      <ListItem
                        id={"attachment-" + attachment.cid}
                        key={attachment.cid}
                        cursor={"pointer"}
                        onClick={() => {
                          downloadAttachmentWithAxios(attachment);
                          // downloadAttachment(attachment)
                        }}
                      >
                        <HStack borderBottom={"1px solid lightGray"}>
                          <Avatar name={attachment.name} textColor={"white"} />
                          <VStack align={"left"}>
                            <Text fontWeight={"bold"}>{attachment.name}</Text>
                            <Text>{niceBytes(attachment.size)}</Text>
                            <Text
                              id={"downloadProgress-" + attachment.cid}
                            ></Text>
                            {/* <Progress colorScheme='green' size='md' value={20} /> */}
                          </VStack>
                        </HStack>
                      </ListItem>
                    ))}

                  {/* <ListItem>
                            <HStack borderBottom={"1px solid lightGray"}>
                                <Avatar name='Kola Tioluwani' textColor={"white"} />
                                <VStack align={"left"}>
                                    <HStack>
                                        <Text fontWeight={"bold"}>Kola Tioluwani</Text>
                                        <Text>10/10/2012 13:10</Text>
                                    </HStack> 
                                    <Text fontWeight={"bold"}>Device authorization needed</Text>
                                    <Text>Hello DESKTOP-MC9AD8L, Your account was used to sign in on this device recently: 2022-06-13 09:30:13 UTC TeamViewer ID: 552707324</Text>
                                </VStack>
                            </HStack>
                        </ListItem> */}
                </List>
              </VStack>
            )}
        </VStack>
      )}
      {!messageIsReady && (
        <Box mt={4}>
          <Facebook />
        </Box>
      )}
    </Box>
  );
};

export default MailPreviewer;
