import AFWalletRPCError from "../AFWalletRPCError";
import BaseRPCHandler from "./BaseRPCHandler";

export default class AFCryptoServiceHandler extends BaseRPCHandler {
    constructor() {
        super('af_cryptoService');
    }
    
    async getW3UPSignKey(provider, params) {
        // params is empty;
        const path = "m/81'/0'/0'";

        console.log('AFEncryptHandler - getCryptonKeyPair: ', params, path);
        
        const key = await provider.wallet.hashAtKeyPath(path);
        return key;
    }
    async getCryptonKeyPair(provider, params) {
        // params is empty;
        const path = "m/83'/0'/0'";

        console.log('AFEncryptHandler - getCryptonKeyPair: ', params, path);
        
        const key = await provider.wallet.keyAtPath(path);
        return key;
    }
    
    async getMailEncryptionKey(provider, params) {
        const [aid, mid, pid] = params;
        const path = "m/84'/" + aid + "'/" + mid + "'/" + pid +"'";
        console.log('AFEncryptHandler - createCrypton: ', params, path);

        const key = await provider.wallet.keyAtPath(path, true);
        return key;
    }
    async getEnterpriseContactIpnsSignKey(provider, params) {
        const [aid] = params;
        const path = "m/85'/7'/" + aid +"'";
        console.log('AFEncryptHandler - createCrypton: ', params, path);

        const key = await provider.wallet.keyAtPath(path);
        return key;
    }
    
    async getFolderIpnsSignKey(provider, params) {
        const [aid] = params;
        const path = "m/85'/0'/" + aid +"'";
        console.log('AFEncryptHandler - createCrypton: ', params, path);

        const key = await provider.wallet.keyAtPath(path);
        return key;
    }
    async getFolderEncryptionKey(provider, params) {
        const [aid] = params;
        const path = "m/85'/1'/" + aid + "'";
        console.log('AFEncryptHandler - createCrypton: ', params, path);

        const key = await provider.wallet.keyAtPath(path, true);
        return key;

    }
    async getIndexedDBEncryptionKey(provider, params) {
        // params is empty
        const path = "m/86'/0'/0'";
        console.log('AFEncryptHandler - createCrypton: ', params, path);

        const key = await provider.wallet.keyAtPath(path, true);
        return key;
    }

    async handle(provider, request) {
        
        const [method, ...args] = request.params;
        switch(method) {
        case 'getW3UPSignKey':
            return await this.getW3UPSignKey(provider, args);
        case 'getCryptonKeyPair':
            return await this.getCryptonKeyPair(provider, args);
        case 'getMailEncryptionKey':
            return await this.getMailEncryptionKey(provider, args);
        case 'getEnterpriseContactIpnsSignKey':
            return await this.getEnterpriseContactIpnsSignKey(provider, args);
        case 'getFolderIpnsSignKey':
            return await this.getFolderIpnsSignKey(provider, args);
        case 'getFolderEncryptionKey':
            return await this.getFolderEncryptionKey(provider, args);
        case 'getIndexedDBEncryptionKey':
            return await this.getIndexedDBEncryptionKey(provider, args);
        default:
            throw AFWalletRPCError.invalidRequest;
        }
            
    }
    
}