// import React, { useEffect, useState } from 'react'
// import W3UpWeb3Storage from "./W3UpWeb3Storage";

import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Container,
  Button,
  SimpleGrid,
  Avatar,
  AvatarGroup,
  useBreakpointValue,
  Icon,
  HStack,
  Link,
  Input,
  Spinner,
  FormLabel,
} from "@chakra-ui/react";
// Here we have used react-icons package for the icons
// And react-slick as our Carousel Lib
// import {
//   // encrypt,
//   recoverPersonalSignature,
//   // recoverTypedSignatureLegacy,
//   // recoverTypedSignature,
//   // recoverTypedSignature_v4 as recoverTypedSignatureV4,
// } from 'eth-sig-util';

// import { recoverPersonalSignature } from 'eth-sig-util';

import { AiOutlineSound, AiFillSound } from "react-icons/ai";
// import { randomBytes } from 'ethers/lib/utils';
import { useState, useEffect } from "react";
import Strings from "../../config/Strings";
import MailAddressUtils from "../../utils/MailAddressUtils";
import { TutorialModalLink } from "../TutorialModalTrigger";
import { showError } from "../../common/errors";
import { StorageProvider } from "../../utils/Types";

const avatars = [
  {
    name: "Ryan Florence",
    url: "avatar/ryan-florence.jpeg",
  },
  {
    name: "Segun Adebayo",
    url: "avatar/prosper-baba.jpeg",
  },
  {
    name: "Kent Dodds",
    url: "avatar/kent-c-dodds.jpeg",
  },
  {
    name: "Prosper Otemuyiwa",
    url: "avatar/girl.jpeg",
  },
  {
    name: "Christian Nwamba",
    url: "avatar/code-beast.jpeg",
  },
];

function W3UpAuthenticator({ children }) {
  const [resendMessage, setResentMessage] = useState(null);
  const [web3Storage, setWeb3Storage] = useState(null);
  const [isRegistered, setRegistered] = useState(false);

  const [email, setEmail] = useState(null);
  const [isAuthenticating, setAuthenticating] = useState(false);
  const [isPlaying, setPlaying] = useState(false);
  const [createSpaceStep, setCreateSpaceStep] = useState(1);

  let size = useBreakpointValue({ base: "md", md: "lg" });
  let width = useBreakpointValue({ base: "44px", md: "60px" });
  let height = useBreakpointValue({ base: "44px", md: "60px" });

  useEffect(() => {
    if (window.appConfig.storageProvider === StorageProvider.LightHouse) {

      return;
    }
    // const web3Storage = new W3UpWeb3Storage();
    window.mailService.web3StorageClient
      .loadAgent()
      .then(() => {
        setWeb3Storage(web3Storage);
        return window.mailService.web3StorageClient.isAuthenticated();
      })
      .then((authenticated) => {
        if (authenticated) {
          return window.mailService.web3StorageClient.isSpaceRegistered();
        }
        return false;
      })
      .then((registered) => {
        setRegistered(registered);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  if (window.appConfig.storageProvider !== StorageProvider.Web3Storage) {
    return children;
  }
  if (isRegistered) {
    return children;
  }
  const resend = () => {
    setResentMessage(null);
    setTimeout(() => {
      handleRegisterSubmit();
    }, 300);
  };

  const handleRegisterSubmit = async (e) => {
    let interval = -1;
    let counter = 0;

    if (e) {
      e.preventDefault();
    }
    try {
      setAuthenticating(true);
      setCreateSpaceStep(1);

      const client = await window.mailService.web3StorageClient.loadAgent();
      console.log("w3up client:", client);

      const isAuthenticated =
        await window.mailService.web3StorageClient.isAuthenticated();
      if (isAuthenticated) {
        const isSpaceRegistered =
          window.mailService.web3StorageClient.isSpaceRegistered();
        if (!isSpaceRegistered) {
          const spaces = window.mailService.web3StorageClient.spaces();
          if (!spaces || spaces.length === 0) {
            const space =
              await window.mailService.web3StorageClient.createSpace(
                window.appConfig.recentActiveAccount,
                (step, msg) => {
                  if (step !== 1 && interval > 0) {
                    clearInterval(interval);
                    interval = -1;
                  }
                  setCreateSpaceStep(step);
                  setResentMessage(msg);
                }
              );
            window.mailService.web3StorageClient.setCurrentSpace(space.did());
            await window.mailService.web3StorageClient.register(space);
          } else {
            const space =
              window.mailService.web3StorageClient.getSpaceByName(
                window.appConfig.recentActiveAccount
              ) || spaces[0];
            await window.mailService.web3StorageClient.checkSpace(
              space,
              (step, msg) => {
                if (step !== 1 && interval > 0) {
                  clearInterval(interval);
                  interval = -1;
                }
                setCreateSpaceStep(step);
                setResentMessage(msg);
              }
            );
            window.mailService.web3StorageClient.setCurrentSpace(space.did());
            await window.mailService.web3StorageClient.register(space);
          }
        }

        setAuthenticating(false);
        setRegistered(true);
        return;
      }

      setResentMessage(
        `${Strings.web3.w3ui.verify.message_3} ${60 - counter} ${
          Strings.web3.w3ui.verify.message_4
        }`
      );
      interval = setInterval(() => {
        counter += 5;
        if (counter >= 60) {
          clearInterval(interval);
          setResentMessage(
            <>
              <Link
                href="#"
                fontWeight={"bold"}
                color={"orange"}
                textDecoration={"underline"}
                onClick={() => {
                  resend();
                  return false;
                }}
              >
                {Strings.web3.w3ui.verify.message_5}
              </Link>
              &nbsp;{Strings.web3.w3ui.verify.message_6}
            </>
          );
        } else {
          setResentMessage(
            `${Strings.web3.w3ui.verify.message_3} ${60 - counter} ${
              Strings.web3.w3ui.verify.message_4
            }`
          );
        }
      }, 5000);

      await window.mailService.web3StorageClient.authenticate(email);

      const isSpaceRegistered =
        window.mailService.web3StorageClient.isSpaceRegistered();
      if (!isSpaceRegistered) {
        const spaces = window.mailService.web3StorageClient.spaces();
        if (!spaces || spaces.length === 0) {
          const space = await window.mailService.web3StorageClient.createSpace(
            window.appConfig.recentActiveAccount,
            (step, msg) => {
              if (step !== 1 && interval > 0) {
                clearInterval(interval);
                interval = -1;
              }
              setCreateSpaceStep(step);
              setResentMessage(msg);
            }
          );
          window.mailService.web3StorageClient.setCurrentSpace(space.did());
          await window.mailService.web3StorageClient.register(space);
        } else {
          const space =
            window.mailService.web3StorageClient.getSpaceByName(
              window.appConfig.recentActiveAccount
            ) || spaces[0];
          await window.mailService.web3StorageClient.checkSpace(
            space,
            (step, msg) => {
              if (step !== 1 && interval > 0) {
                clearInterval(interval);
                interval = -1;
              }
              setCreateSpaceStep(step);
              setResentMessage(msg);
            }
          );
          window.mailService.web3StorageClient.setCurrentSpace(space.did());
          await window.mailService.web3StorageClient.register(space);
        }
      }
      setRegistered(true);
    } catch (err) {
      console.error(err);
      showError(err);
    } finally {
      setAuthenticating(false);
    }
  };

  const endPlaying = () => {
    setPlaying(false);
  };

  const startPlaying = () => {
    setPlaying(true);
  };

  const play = () => {
    let audio = document.getElementById("aifi_audio");
    audio.play();
  };

  return (
    <Box position={"relative"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack spacing={{ base: 10, md: 20 }}>
          <Heading
            lineHeight={1.1}
            fontSize={{ base: "3xl", sm: "4xl", md: "4xl", lg: "4xl" }}
          >
            <HStack alignItems="start">
              <Text>{Strings.home.promotion.title}</Text>{" "}
              <Link onClick={play} disabled={isPlaying}>
                {isPlaying && <AiFillSound size={24} />}
                {!isPlaying && <AiOutlineSound size={24} />}
              </Link>
              <audio id="aifi_audio" onEnded={endPlaying} onPlay={startPlaying}>
                <source src="pronounce.mp3" type="audio/mp3" />
                {/* <span style="font-size: 0.4rem; color: ">Your browser does not support HTML5 audio element.<span> */}
              </audio>
            </HStack>
            <Text
              mt={4}
              fontSize={{ base: "3xl", sm: "3xl", md: "3xl", lg: "3xl" }}
            >
              {Strings.home.promotion.content.paragraph1}
            </Text>
            <Text fontSize={{ base: "3xl", sm: "3xl", md: "3xl", lg: "3xl" }}>
              {Strings.home.promotion.content.paragraph2}
            </Text>
            <Text fontSize={{ base: "3xl", sm: "3xl", md: "3xl", lg: "3xl" }}>
              {Strings.home.promotion.content.paragraph3}
            </Text>
            <Text fontSize={{ base: "3xl", sm: "3xl", md: "3xl", lg: "3xl" }}>
              {/* <Text
                as={'span'}
                bgGradient="linear(to-r, red.400,pink.400)"
                bgClip="text">
                 *
              </Text>  */}
              {Strings.home.promotion.content.paragraph4}
            </Text>
          </Heading>
          <Stack direction={"row"} spacing={4} align={"center"}>
            <AvatarGroup>
              {avatars.map((avatar) => (
                <Avatar
                  key={avatar.name}
                  name={avatar.name}
                  src={avatar.url}
                  size={size}
                  position={"relative"}
                  zIndex={2}
                  _before={{
                    content: '""',
                    width: "full",
                    height: "full",
                    rounded: "full",
                    transform: "scale(1.125)",
                    bgGradient: "linear(to-bl, red.400, pink.400)",
                    position: "absolute",
                    zIndex: -1,
                    top: 0,
                    left: 0,
                  }}
                />
              ))}
            </AvatarGroup>
            <Text fontFamily={"heading"} fontSize={{ base: "4xl", md: "6xl" }}>
              +
            </Text>
            <Flex
              align={"center"}
              justify={"center"}
              fontFamily={"heading"}
              fontSize={{ base: "sm", md: "lg" }}
              bg={"gray.800"}
              color={"white"}
              rounded={"full"}
              width={width}
              height={height}
              position={"relative"}
              _before={{
                content: '""',
                width: "full",
                height: "full",
                rounded: "full",
                transform: "scale(1.125)",
                bgGradient: "linear(to-bl, orange.400,yellow.400)",
                position: "absolute",
                zIndex: -1,
                top: 0,
                left: 0,
              }}
            >
              YOU
            </Flex>
          </Stack>
        </Stack>

        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
        >
          <Stack spacing={4}>
            <Heading
              color={"gray.800"}
              lineHeight={1.1}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              <Text
                as={"span"}
                bgGradient="linear(to-r, red.400,pink.400)"
                bgClip="text"
              >
                Renew Web3.Storage Authorization
                {/* {Strings.home.unlock.title} */}
              </Text>
            </Heading>

            <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
              Access to Web3.Storage is authorized for a limited time and
              requires regular renewal. Your authorization has recently expired
              and needs to be renewed.
            </Text>
          </Stack>

          <Stack spacing={1}>
            <FormLabel htmlFor="emailAddress">{"Email Address"}</FormLabel>
            <Input
              id={"emailAddress"}
              placeholder={"Enter your E-mail address here"}
              defaultValue={email}
              bg={"gray.100"}
              border={0}
              color={"gray.500"}
              // onChange={SetupWizardState.RestoreWeb3Storage === setupWizardState ? onChange : () => {}}
              _placeholder={{
                color: "gray.500",
              }}
              onChange={(e) => {
                if (
                  e.target.value &&
                  MailAddressUtils.isValidEmailAddress(e.target.value, false)
                ) {
                  setEmail(e.target.value);
                } else {
                  setEmail(null);
                }
              }}
            />

            {isAuthenticating && (
              <>
                <HStack>
                  <Spinner color={"#00aaff"} size={"sm"} />
                  <Text
                    color={"#00aaff"}
                    fontWeight={"bold"}
                    fontSize={{ base: "lg", sm: "lg" }}
                  >
                    {Strings.web3.w3ui.verify.title}
                  </Text>
                </HStack>
                {createSpaceStep === 1 && (
                  <Text color={"#00aaff"} fontSize={{ base: "sm", sm: "sm" }}>
                    {Strings.web3.w3ui.verify.message_1}&nbsp;{email}&nbsp;
                    {Strings.web3.w3ui.verify.message_2}&nbsp;{resendMessage}
                  </Text>
                )}
                {createSpaceStep !== 1 && (
                  <Text color={"#00aaff"} fontSize={{ base: "sm", sm: "sm" }}>
                    {resendMessage}
                  </Text>
                )}
              </>
            )}

            <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
              {Strings.web3.config.content.paragraph3}&nbsp;
              {/* <Link style={{color: '#00aaff'}} onClick={tutorialDisclosure.onOpen()} target={"_blank"}>Learn more</Link> */}
              {/* <Link style={{color: '#00aaff'}} href={HELP_DOC_URL} target={"_blank"}>Learn more</Link> */}
              <TutorialModalLink
                title={"Learn more"}
                anchor={"private-web3-storage"}
              />
            </Text>
          </Stack>

          <Box as={"form"} mt={10}>
            <Button
              fontFamily={"heading"}
              mt={4}
              w={"full"}
              bgGradient="linear(to-r, red.400,pink.400)"
              color={"white"}
              onClick={handleRegisterSubmit}
              isDisabled={!email}
              isLoading={isAuthenticating}
              _hover={{
                bgGradient: "linear(to-r, red.400,pink.400)",
                boxShadow: "xl",
              }}
            >
              {"Sign In"}
            </Button>
          </Box>
        </Stack>
      </Container>
      <Blur
        position={"absolute"}
        top={-10}
        left={-10}
        style={{ filter: "blur(70px)" }}
      />
    </Box>
  );
}

export const Blur = (props) => {
  return (
    <Icon
      width={useBreakpointValue({ base: "100%", md: "40vw", lg: "30vw" })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  );
};

/**
 * Wrapping a component with this HoC ensures an identity exists.
 */
export function withIdentity(ChildComponent) {
  return (props) => (
    <W3UpAuthenticator>
      <ChildComponent {...props} />
    </W3UpAuthenticator>
  );
}

export function W3UpAuthenticateProvider({ children }) {
  return <W3UpAuthenticator>{children}</W3UpAuthenticator>;
}
