


export class PlexiIdentityService {
    
    #actor = null;

    constructor(actor) {
        this.#actor = actor;
    }

    async sendVerificationEmail(address, email) {
        const tx = await this.#actor.sendVerificationEmail(address, email);
        return tx;
    }
    
    async sendVerificationSMS(address, phone) {
        const tx = await this.#actor.sendVerificationSMS(address, phone);
        return tx;
    }
    
    async verifyVerificationEmail(address, tx, verificationCode) {
        await this.#actor.verifyVerificationEmail(address, tx, verificationCode)

    }
    
    async verifyVerificationSMS(address, tx, verificationCode) {
        await this.#actor.verifyVerificationSMS(address, tx, verificationCode);
    }

    async resetProofOfIdentity(address) {
        await this.#actor.resetProofOfIdentity(address);
    }

    async getProofOfIdentity(address) {
        const ii = await this.#actor.getProofOfIdentity(address);
        return ii;
    }

    async getSignedProofOfIdentity(address, hash) {
        const ii = await this.#actor.getSignedProofOfIdentity(address, hash);
        return ii;
    }
};