import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Checkbox,
  MenuItem,
  ModalCloseButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { createRef, useState } from "react";
import Strings from "../config/Strings";

export default function ConfirmationButton({
  mr,
  buttonTitle,
  title,
  message,
  enableCheckbox,
  checkboxTitle,
  onClick,
  colorScheme,
  cancelable,
}) {
  const [disabled, setDisabled] = useState(enableCheckbox || false);
  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => {
      setDisabled(true);
    },
  });
  if (!colorScheme) {
    colorScheme = "green";
  }
  const buttonRef = createRef();
  return (
    <>
      <Button mr={mr || 0} colorScheme={colorScheme} onClick={onOpen}>
        {buttonTitle}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>
                {message}
                <br />
                <br />
              </Text>
              <Checkbox
                onChange={(e) => {
                  setDisabled(!e.target.checked);
                }}
              >
                {checkboxTitle && checkboxTitle.length > 0
                  ? checkboxTitle
                  : Strings.home.dialog.confirm.checkbox.title}
              </Checkbox>
            </AlertDialogBody>

            <AlertDialogFooter>
              {cancelable && (
                <Button
                  colorScheme={"gray"}
                  onClick={() => {
                    onClose();
                  }}
                  ml={3}
                >
                  {"Cancel"}
                </Button>
              )}
              <Button
                ref={buttonRef}
                disabled={disabled}
                colorScheme={colorScheme}
                onClick={() => {
                  onClose();
                  onClick();
                }}
                ml={3}
              >
                {buttonTitle}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export function ConfirmationMenuItem({
  icon,
  buttonTitle,
  title,
  message,
  cancelable,
  onClick,
  colorScheme,
}) {
  const [disabled, setDisabled] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => {
      setDisabled(true);
    },
  });
  if (!colorScheme) {
    colorScheme = "green";
  }
  const buttonRef = createRef();
  return (
    <>
      <MenuItem icon={icon} onClick={onOpen}>
        {buttonTitle}
      </MenuItem>

      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {title}
            </AlertDialogHeader>

            {cancelable && <AlertDialogCloseButton />}
            <AlertDialogBody>
              <Text>
                {message}
                <br />
                <br />
              </Text>
              <Checkbox
                onChange={(e) => {
                  setDisabled(!e.target.checked);
                }}
              >
                {Strings.home.dialog.confirm.checkbox.title}
              </Checkbox>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={buttonRef}
                disabled={disabled}
                colorScheme={colorScheme}
                onClick={() => {
                  onClose();
                  onClick();
                }}
                ml={3}
              >
                {buttonTitle}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export function ResetAlert({ disclosure, onClick }) {
  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {Strings.app.dialog.exit_setup.title}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>{Strings.app.dialog.exit_setup.message}</Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              mr={"4"}
              colorScheme={"gray"}
              onClick={() => {
                disclosure.onClose();
              }}
            >
              {Strings.app.dialog.exit_setup.cancel}
            </Button>
            <Button
              colorScheme={"red"}
              onClick={() => {
                disclosure.onClose();
                onClick();
              }}
              ml={3}
            >
              {Strings.app.dialog.exit_setup.exit}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
export function ConfirmationCloseButton({
  children,
  fontFamily,
  mt,
  w,
  bgColor,
  color,
  isDisabled,
  onClick,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => {},
  });
  const buttonRef = createRef();
  return (
    <>
      <Button
        fontFamily={fontFamily}
        mt={mt}
        w={w}
        bgColor={bgColor}
        // bgGradient="linear(to-r, red.400,pink.400)"
        color={color}
        isDisabled={isDisabled || false}
        onClick={(e) => {
          e.preventDefault();
          onOpen();
        }}
      >
        {children}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {Strings.app.dialog.exit_setup.title}
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>{Strings.app.dialog.exit_setup.message}</Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                mr={"4"}
                colorScheme={"gray"}
                onClick={() => {
                  onClose();
                }}
              >
                {Strings.app.dialog.exit_setup.cancel}
              </Button>
              <Button
                ref={buttonRef}
                colorScheme={"red"}
                onClick={() => {
                  onClose();
                  onClick();
                }}
                ml={3}
              >
                {Strings.app.dialog.exit_setup.exit}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
export function ConfirmationModalCloseButton({ onClick }) {
  const { isOpen, onOpen, onClose } = useDisclosure({
    onOpen: () => {},
  });
  const buttonRef = createRef();
  return (
    <>
      <ModalCloseButton
        onClick={(e) => {
          e.preventDefault();
          onOpen();
        }}
      ></ModalCloseButton>

      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {Strings.app.dialog.exit_setup.title}
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>{Strings.app.dialog.exit_setup.message}</Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                mr={"4"}
                colorScheme={"gray"}
                onClick={() => {
                  onClose();
                }}
              >
                {Strings.app.dialog.exit_setup.cancel}
              </Button>
              <Button
                ref={buttonRef}
                colorScheme={"red"}
                onClick={() => {
                  onClose();
                  onClick();
                }}
                ml={3}
              >
                {Strings.app.dialog.exit_setup.exit}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
