import { ClientError } from "../common/errors";
import Strings from "../config/Strings";
import { SyncDirection } from "../utils/Types";
import { CryptoService } from "./CryptoService";
// import SecuritySignalProtocolStoreIndexedDB from "./SecuritySignalProtocolStoreIndexedDB";
// import SignalProtocolStoreIndexedDB from "./SignalProtocolStoreIndexedDB";

const IS_RUNNING_IN_IPFS = (window.location.href.indexOf('ipns') !== -1 || window.location.href.indexOf('ipfs') !== -1);
const PUSH_SERVER_PROTOCOL = (IS_RUNNING_IN_IPFS) ? 'https:' : window.location.protocol;
const PUSH_SERVER_HOST = (IS_RUNNING_IN_IPFS) ? 'ai-fi.cc' : window.location.hostname;

const PUSH_SERVER_PORT = '';
// const PUSH_SERVER_ROOT_PATH = '/PlexiMail'; // window.location.pathname;
const PUSH_SERVER_ROOT_PATH = ''; // window.location.pathname;
const PUSH_SERVER_BASE_URL = PUSH_SERVER_PROTOCOL + '//' + PUSH_SERVER_HOST + PUSH_SERVER_PORT + PUSH_SERVER_ROOT_PATH;
// const PUSH_SERVER_BASE_URL = 'http://192.1.1.104';
const PUSH_SERVER_WS_ADDRESS = ((PUSH_SERVER_PROTOCOL === 'https:') ? 'wss://' : 'ws://') + PUSH_SERVER_HOST + PUSH_SERVER_PORT + PUSH_SERVER_ROOT_PATH + '/api/messaging';

class SyncService {
    socket = null;
    direction = SyncDirection.Export;
    account = null;
    deviceId = 0;
    pairKey = null;
    cancelled = false;
    isRunning = false;

    ackNumber = 0;
    expectedAckNumber = 0;
    keepaliveIsRunning = false;
    keepaliveTimer = -1;
    closeManually = false;
    static get push_server_base_url() {
        return PUSH_SERVER_BASE_URL;
    }
    constructor(direction, account, deviceId) {
        this.direction = direction;
        this.account = account;
        this.deviceId = deviceId || 0;
    }

    async doExport(param) {
        // const str = await window.mailService.exportMnemonic(this.account);
        const str = await window.mailService.exportConfig(this.account);
        return str;
    }

    async doImport(param) {
        const plaintext = await window.mailService.decrypt(this.account, param);

        const config = JSON.parse(plaintext);
        Object.keys(config.localStorage).forEach(function (key) {
        // Object.keys(config).forEach(function (key) {
            localStorage.setItem(key, config.localStorage[key]);
            // localStorage.setItem(key, config[key]);
        });
        
    }

    async generateConnectionInfo() {
        const self = this;
        const cryptoService = new CryptoService();
        self.pairKey = await cryptoService.generatePairKey();
        // self.pairKey = 'FHFkZ1Pbc6ttdmZmu_ik5FGOUYuzXdmDDyWr3-vl_rM';
    }

    keepalive() {
        const self = this;
        if (!self.keepaliveIsRunning) {
            return;
        }

        self.keepaliveTimer = setTimeout(()=> {
            if (self.keepaliveIsRunning) {
                if ((self.expectedAckNumber - self.ackNumber) >= 1) {
                    console.log('Timeout: closing...');
                    if (self.socket) {
                        console.log('Socket closing...');
                        self.expectedAckNumber = 0;
                        self.ackNumber = 0;
                        self.socket.close();
                    }
                } else {
                    if (self.socket) {
                        self.expectedAckNumber = self.expectedAckNumber + 1;
                        // console.log('echo request: ', self.expectedAckNumber, ', lastAckNumber: ', self.ackNumber);
                        self.socket.send(JSON.stringify({type: 'echo', data: self.expectedAckNumber}));
                    }
                    self.keepalive();
                }
            }
        }, 10000)
    }
    startKeepalive() {
        const self = this;
        self.keepaliveIsRunning = true;
        self.keepalive();
        /*
        self.keepaliveTimer = setTimeout(()=> {
            if (self.socket) {
                self.socket.send(JSON.stringify({type: 'echo'}));
            }
        }, 10000)
        */
    }

    stopKeepalive() {
        const self = this;
        self.keepaliveIsRunning = false;
        self.expectedAckNumber = 0;
        self.ackNumber = 0;
        if (self.keepaliveTimer !== -1) {
            clearTimeout(self.keepaliveTimer);
            self.keepaliveTimer = -1;
        }
    }

    sendAck(account, envelopes) {
        const self = this;
        self.socket.send(JSON.stringify({type: 'ack', data: {account, envelopes}}));
    }

    async start({onConnected, onSuccess, onRestart, onError}) {
        const self = this;
        self.isRunning = true;

        await self.generateConnectionInfo();
        
        self.socket = new WebSocket(PUSH_SERVER_WS_ADDRESS + '?token=' + window.appConfig.pushApiToken +'&pairKey=' + self.pairKey + '&deviceId=' + self.deviceId);

        self.socket.onopen = function(e) {
            console.log("[open] Messaging Connection established");

            onConnected({
                dir: self.direction,
                url: PUSH_SERVER_BASE_URL,
                account: self.account,
                deviceId: self.deviceId,
                pairKey: self.pairKey
            });
            self.startKeepalive();

        };

        self.socket.onmessage = function(event) {

            // console.log(`[message] Data received from server: ${event.data}`);
            const msgObj = JSON.parse(event.data);
            if (msgObj.type === 'echo') {
                self.ackNumber = msgObj.data;

            } else if (msgObj.type === 'import') {

                if (self.direction !== SyncDirection.Import) {
                    self.socket.send(JSON.stringify({
                        type: 'import', 
                        data: {
                            code: -1,
                            msg: 'unsupported import'
                        }
                    }));
                    // onError(ClientError(-1, 'Unsupported import'));
                    return;
                }
                self.doImport.bind(self)(msgObj.data).then(() => {
                    self.socket.send(JSON.stringify({
                        type: 'import', 
                        data: {
                            code: 0,
                            msg: 'success'
                        }
                    }));

                    self.isRunning = false;
                    onSuccess();
                }).catch(e => {
                    self.isRunning = false;
                    onError(e);
                    self.stop();
                });
            } else if (msgObj.type === 'export') {
                
                if (self.direction !== SyncDirection.Export) {
                    self.socket.send(JSON.stringify({
                        type: 'export', 
                        data: {
                            code: -1,
                            msg: 'unsupported export'
                        }
                    }));
                    // onError(ClientError(-1, 'Unsupported export'));
                    return;
                }
                
                self.doExport.bind(self)(msgObj.data).then((data) => {
                    self.socket.send(JSON.stringify({
                        type: 'export', 
                        data: {
                            code: 0,
                            msg: 'success',
                            data: data
                        }
                    }))
                    self.isRunning = false;
                    onSuccess();

                }).catch(e => {
                    self.isRunning = false;
                    onError(e);
                    self.stop();
                });
            } else {
                self.isRunning = false;
                // const error = ClientError.invalidParameterError('Unknown command')
                // onError(error);
                self.socket.send(JSON.stringify({
                    code: -1,
                    msg: 'unsupported command'
                }));
            }
        };

        self.socket.onclose = function(event) {
            self.isRunning = false;
            if (event.wasClean) {
                console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
            } else {
                // e.g. server process killed or network down
                // event.code is usually 1006 in this case
                console.log('[close] Connection died');
            }

            if (!self.closeManually) {

                self.stopKeepalive();
                self.socket = null;
                setTimeout(() => {
                    onRestart();
                    self.start({onConnected, onSuccess, onRestart, onError});
                }, 10000);
            }
        };
        self.socket.onerror = function(error) {
            console.error('Websocket error: ', error);
            //onError(error);
            onError(ClientError.networkError(Strings.error.client.lost_conn));
        }
    }

    async stop() {
        console.log('[close] stop messaging Connection');
        const self = this;
        if (self.socket) {
            self.closeManually = true;
            self.stopKeepalive();
            self.socket.close();
            self.socket = null;
        }
    }

}

export default SyncService;