// import './App.css';
import React, { useState, useEffect, createRef } from "react";

import {
  HStack,
  VStack,
  Link,
  Button,
  List,
  ListItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormLabel,
  InputLeftElement,
  InputGroup,
  Avatar,
  Text,
  // IconButton,
  // Box,
  Spinner,
} from "@chakra-ui/react";

// import {
//   FiTrash
// } from 'react-icons/fi';

import * as ReactIcons from "react-icons/fa";
import Strings from "../config/Strings";
import MailAddressUtils from "../utils/MailAddressUtils";
import { showError } from "../common/errors";
import { QRCodeSourceChooseConfirmationButton } from "./QRCodeReaderModal";

// import { useToasts } from 'react-toast-notifications'

const ContactList = ({
  selectedAccount,
  setViewIndex,
  setSelectedContact,
  contacts,
}) => {
  const S = Strings.contacts;
  const [filteredContacts, setFilteredContacts] = useState(contacts);
  useEffect(() => {
    console.log("ContactModal useEffect with empty");
    setFilteredContacts(contacts);
  }, [contacts]); // eslint-disable-line react-hooks/exhaustive-deps

  const newContact = () => {
    setSelectedContact(null);
    setViewIndex(1);
    setFilteredContacts(contacts);
  };
  const editContact = (contact) => {
    setSelectedContact(contact);
    setViewIndex(1);
    setFilteredContacts(contacts);
  };
  const filterContacts = (e) => {
    const keyword = e.target.value;
    if (!keyword || keyword === "") {
      setFilteredContacts(contacts);
      return;
    }

    const result = contacts.filter((c) => {
      if (c.name.toLowerCase().startsWith(keyword)) {
        return true;
      }
      return c.email.toLowerCase().startsWith(keyword);
    });

    setFilteredContacts(result);
  };
  return (
    <>
      <HStack mb={2}>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<ReactIcons.FaSearch color="gray.300" />}
          />
          <Input
            placeholder={S.content.list.search.placeholder}
            onChange={filterContacts}
          />
          {/* <InputRightElement pointerEvents="none" children={<ReactIcons.FaPlus color="gray.300" />}></InputRightElement> */}
        </InputGroup>
        <Button
          leftIcon={<ReactIcons.FaPlus color="gray.300" />}
          onClick={newContact}
        >
          {S.content.list.new_contact}
        </Button>
      </HStack>
      <List>
        {(!filteredContacts || filteredContacts.length === 0) && (
          <ListItem>
            <Link>{S.content.list.empty}</Link>
          </ListItem>
        )}

        {filteredContacts &&
          filteredContacts.length > 0 &&
          filteredContacts.map((contact) => {
            // contact.uuid + paddingTop={1} paddingBottom={1}
            return (
              <ListItem
                key={contact.uuid}
                cursor={"pointer"}
                onClick={() => {
                  editContact(contact);
                }}
              >
                <HStack
                  paddingBottom={1}
                  paddingTop={1}
                  borderBottom={"1px solid lightGray"}
                >
                  <Avatar name={contact.name} textColor={"white"} />
                  <VStack align={"left"}>
                    <Text fontSize={12} fontWeight={"bold"}>
                      {contact.name}
                    </Text>
                    <Text fontSize={12} wordBreak={"break-all"}>
                      {contact.email}
                    </Text>
                  </VStack>
                  {/* <IconButton color={"red"} icon={<FiTrash />}></IconButton> */}
                </HStack>
              </ListItem>
            );
          })}
      </List>
    </>
  );
};

const ContactEditor = ({
  selectedAccount,
  contacts,
  setContacts,
  selectedContact,
  setViewIndex,
}) => {
  const nameRef = createRef();
  const emailRef = createRef();
  const S = Strings.contacts;
  const saveContact = () => {
    let email = emailRef.current.value;
    if (email) {
      email = email.toLowerCase();
    }
    const contact = {
      name: nameRef.current.value,
      email: email,
      uuid: selectedContact ? selectedContact.uuid : null,
    };
    if (!contact.name || contact.name.length === 0) {
      showError(S.message.empty_name);
      return;
    }
    if (!contact.email || contact.email.length === 0) {
      showError(S.message.empty_email);
      return;
    }
    if (!MailAddressUtils.isValidEmailAddress(contact.email)) {
      showError(S.message.invalid_email);
      return;
    }

    const mailAddress = '"' + contact.name + '" <' + contact.email + ">";
    const addr = MailAddressUtils.parseOneAddress(mailAddress);
    if (!addr) {
      showError(S.message.invalid_name);
      return;
    }

    window.mailService.saveContact(contact).then(() => {
      // contacts.push(contact);
      window.mailService.getAllContacts().then((contacts) => {
        setContacts([...contacts]);
        setViewIndex(0);
      });
    });
  };

  const cancel = () => {
    setViewIndex(0);
  };

  const deleteContact = () => {
    if (!selectedContact) {
      return;
    }
    window.mailService.deleteContact(selectedContact.uuid).then(() => {
      window.mailService.getAllContacts().then((contacts) => {
        setContacts([...contacts]);
        setViewIndex(0);
      });
    });
  };
  
  return (
    <VStack align={"left"}>
      <HStack alignItems={"center"} justifyContent={"space-between"}>
        <FormLabel>{S.content.editor.name} </FormLabel>
        {!selectedContact && (
          <QRCodeSourceChooseConfirmationButton
            onCompleted={(result) => {
              try {
                const address1 = MailAddressUtils.parseOneAddress(result);
                if (!address1) {
                  return;
                }
                if (address1.name && address1.name.length > 0) {
                  nameRef.current.value = address1.name;
                }
                emailRef.current.value = address1.address;
              } catch (e) {
                console.error(e);
              }
            }}
          />
        )}
      </HStack>
      <Input
        ref={nameRef}
        defaultValue={selectedContact ? selectedContact.name : ""}
      />
      <FormLabel>{S.content.editor.email} </FormLabel>
      <Input
        ref={emailRef}
        defaultValue={selectedContact ? selectedContact.email : ""}
      />
      <Button colorScheme="green" onClick={saveContact}>
        {S.content.editor.save}
      </Button>
      <Button colorScheme="gray" mr={3} onClick={cancel}>
        {S.content.editor.back}
      </Button>
      {selectedContact &&
        !selectedContact.email
          .toLowerCase()
          .startsWith(selectedAccount.toLowerCase() + "@") && (
          <Button colorScheme="red" mt={10} mr={3} onClick={deleteContact}>
            {S.content.editor.delete}
          </Button>
        )}
    </VStack>
  );
};

const ContactPreviewer = ({ setViewIndex }) => {
  const S = Strings.contacts;
  const cancel = () => {
    setViewIndex(0);
  };
  return (
    <VStack>
      <FormLabel>{S.content.preview.name} </FormLabel>
      <Input />
      <FormLabel>{S.content.preview.email} </FormLabel>
      <Input />
      <Button colorScheme="red" mr={3} onClick={cancel}>
        {S.content.preview.back}
      </Button>
    </VStack>
  );
};

const ContactModal = ({ selectedAccount, composeDisclosure }) => {
  // const { addToast } = useToasts()
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [viewIndex, setViewIndex] = useState(0);
  const [selectedContact, setSelectedContact] = useState(null);
  const S = Strings.contacts;
  useEffect(() => {
    console.log("ContactModal useEffect with empty");

    if (!isLoading && contacts.length === 0) {
      setIsLoading(true);
      window.mailService.getAllContacts().then((contacts) => {
        if (contacts && contacts.length > 0) {
          setContacts(contacts);
        }
        setIsLoading(false);
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    console.log("ContactModal useEffect without param");
  }); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      size={"xl"}
      isOpen={composeDisclosure.isOpen}
      onClose={() => {
        setIsSaving(true);
        window.mailService
          .syncContactsIfNeeded()
          .then(() => {
            setIsSaving(false);
            composeDisclosure.onClose();
          })
          .catch((e) => {
            console.error(e);
            setIsSaving(false);
            composeDisclosure.onClose();
          });
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <VStack
          zIndex={10000}
          hidden={!isSaving}
          height={"100%"}
          width={"100%"}
          align={"center"}
          justifyContent="center"
          borderRadius={"var(--chakra-radii-md)"}
          position={"absolute"}
          backgroundColor={"rgb(0, 0, 0, 0.8)"}
        >
          <HStack>
            <Spinner color={"white"} />{" "}
            <Text color={"white"} fontWeight={"bold"}>
              Syncing...
            </Text>
          </HStack>
        </VStack>

        <ModalHeader>
          {viewIndex === 0 && S.title}
          {viewIndex === 1 && selectedContact && S.editTitle}
          {viewIndex === 1 && !selectedContact && S.addTitle}
          {viewIndex === 2 && S.previewTitle}
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          {viewIndex === 0 && (
            <ContactList
              selectedAccount={selectedAccount}
              contacts={contacts}
              setViewIndex={setViewIndex}
              setSelectedContact={setSelectedContact}
            />
          )}

          {viewIndex === 1 && (
            <ContactEditor
              selectedAccount={selectedAccount}
              setViewIndex={setViewIndex}
              contacts={contacts}
              selectedContact={selectedContact}
              setContacts={setContacts}
            />
          )}

          {viewIndex === 2 && <ContactPreviewer setViewIndex={setViewIndex} />}
        </ModalBody>

        <ModalFooter>
          {/* <Button colorScheme='red' mr={3} onClick={composeDisclosure.onClose}>Close</Button> */}
          {/* <Button colorScheme='green' isLoading={isSending} onClick={sendMessage} disabled={isUploading}>Send</Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ContactModal;
