import {
    Text,
    Button,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    // CheckboxGroup,
    Input,
    FormControl,
    FormLabel,
    // TableContainer,
    // IconButton,
  } from "@chakra-ui/react";
  
  import React, { useEffect, useState } from "react";
  // import Strings from '../../config/Strings';
  
import Strings from "../config/Strings";
  
  export default function AskForPasswordAlert({
    disclosure,
    onCompleted,
  }) {
    const [passcode, setPasscode] = useState(null);
    useEffect(() => {
        setPasscode(null);
    }, [])
    return (
      <AlertDialog
        size={"xl"}
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {Strings.app.dialog.ask_pwd.title}
            </AlertDialogHeader>
  
            <AlertDialogBody>
                <Text>{Strings.app.dialog.ask_pwd.description}</Text>
                <FormControl mt={4}>
                    <FormLabel>
                    {Strings.app.dialog.ask_pwd.passcode_label}
                    </FormLabel>
                    <Input placeholder={Strings.app.dialog.ask_pwd.passcode_placeholder} value={passcode || ""} onChange={(e) => {
                        setPasscode(e.target.value ? e.target.value.trim() : null);
                    }} />
                </FormControl>
            </AlertDialogBody>
  
            <AlertDialogFooter>
              <Button
                mr={4}
                colorScheme={"red"}
                onClick={() => {
                    onCompleted(null)
                    disclosure.onClose();
                }}
              >
                {Strings.app.dialog.ask_pwd.cancel}
              </Button>
  
              <Button
                mr={4}
                isDisabled={!passcode || passcode.length === 0}
                colorScheme={"green"}
                onClick={() => {
                    onCompleted(passcode)
                    disclosure.onClose();
                }}
              > 
              {Strings.app.dialog.ask_pwd.next}
                
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );
  }
  