import // Box,
// Stack,
// Heading,
// Text,
// Container,
// Input,
// Button,
// SimpleGrid,
// FormLabel,
// InputGroup,
// InputRightElement,
// IconButton,
// Progress,
// AlertDialog,
// AlertDialogOverlay,
// AlertDialogContent,
// AlertDialogHeader,
// AlertDialogBody,
// AlertDialogFooter,
// useDisclosure,
// Link,
"@chakra-ui/react";
import React, { Suspense, useEffect } from "react";

// import { useState } from 'react';
// import {MnemonicBackupMethod, SetupWizardState} from '../utils/Types';
// import Strings from '../config/Strings';
// import { TutorialModalLink } from './TutorialModalTrigger';
// import { FaEye, FaEyeSlash } from 'react-icons/fa';
// import { showError } from '../common/errors';
// import zxcvbn from 'zxcvbn';
import { CONTRACT_FOLDER } from "../common/constants";
import MailList from "./MailList";

import ContentLoader from "react-content-loader";

const ContractFolderExplorer = React.lazy(() =>
  import("./contract/ContractFolderExplorer.js")
);
// import ContractFolderExplorer from './contract/ContractFolderExplorer';

export default function MessageContainer({
  folderVersion,
  setFolderVersion,
  selectedAccount,
  currentFolder,
  folders,
  setFolders,
  messages,
  message,
  setMessage,
  searchDisclosure
}) {
  useEffect(() => {

    console.log('** MessageContainer - Current Folder: ', currentFolder, ', FolderVersion: ', folderVersion);
    // if (currentFolder === CONTRACT_FOLDER) {
    //   setFolderVersion(folderVersion + 1);
    // }
  }, [currentFolder, folderVersion])

  if (currentFolder === CONTRACT_FOLDER) {
    return (
      <Suspense fallback={<ContentLoader />}>
        <ContractFolderExplorer
          searchDisclosure={searchDisclosure}
          folderVersion={folderVersion}
          setFolderVersion={setFolderVersion}
          selectedAccount={selectedAccount}
          folders={folders}
          setFolders={setFolders}
          messages={messages}
          message={message}
          setMessage={setMessage}
        />
      </Suspense>
    );
    // return <ContractFolderExplorer folderVersion={folderVersion} setFolderVersion={setFolderVersion} selectedAccount={selectedAccount} folders={folders} setFolders={setFolders} messages={messages} message={message} setMessage={setMessage} />;
  }
  return (
    <MailList
      searchDisclosure={searchDisclosure}
      folderVersion={folderVersion}
      setFolderVersion={setFolderVersion}
      selectedAccount={selectedAccount}
      folders={folders}
      setFolders={setFolders}
      messages={messages}
      message={message}
      setMessage={setMessage}
    />
  );
}
