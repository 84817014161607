

export default class AFWalletRPCError extends Error {

    constructor(code, message, meaning=null, category=null) {
        super(message);
        this.code = code;
        this.meaning = meaning;
        this.category = category;
    }

    static get parseError() {
        return new AFWalletRPCError(-32700, 'Parse error', 'Invalid JSON', 'standard');
    }
    static get invalidRequest() {
        return new AFWalletRPCError(-32600, 'Invalid request', 'JSON is not a valid request object', 'standard');
    }
    static get methodNotFound() {
        return new AFWalletRPCError(-32601, 'Method not found', 'Invalid method parameters', 'standard');
    }
    static get invalidParams() {
        return new AFWalletRPCError(-32602, 'Invalid params', 'Invalid method parameters', 'standard');
    }
    static get internalError() {
        return new AFWalletRPCError(-32603, 'Internal error', 'Internal JSON-RPC error', 'standard');
    }
    static get invalidInput() {
        return new AFWalletRPCError(-32000, 'Invalid input', 'Missing or invalid parameters', 'non-standard');
    }
    static get resourceNotFound() {
        return new AFWalletRPCError(-32001, 'Resource not found', 'Requested resource not found', 'non-standard');
    }
    static get resourceUnavailable() {
        return new AFWalletRPCError(-32002, 'Resource unavailable', 'Requested resource not available', 'non-standard');
    }
    static get transactionRejected() {
        return new AFWalletRPCError(-32003, 'Transaction rejected', 'Transaction creation failed', 'non-standard');
    }
    static get methodNotSupported() {
        return new AFWalletRPCError(-32004, 'Method not supported', 'Method is not implemented', 'non-standard');
    }
    static get limitExceeded() {
        return new AFWalletRPCError(-32005, 'Limit exceeded;', 'Request exceeds defined limit', 'non-standard');
    }
    static get jsonrpcVersionNotSupported() {
        return new AFWalletRPCError(-32006, 'JSON-RPC version not supported;', 'Version of JSON-RPC protocol is not supported', 'non-standard');
    }
    static get userRejectedRequest() {
        return new AFWalletRPCError(4001, 'User Rejected Request', 'The user rejected the request', 'provider');
    }
    static get unauthorized() {
        return new AFWalletRPCError(4100, 'Unauthorized', 'The requested method and/or account has not been authorized by the user', 'provider');
    }
    static get unsupportedMethod() {
        return new AFWalletRPCError(4200, 'Unsupported Method', 'The Provider does not support the requested method', 'provider');
    }
    static get disconnected() {
        return new AFWalletRPCError(4900, 'Disconnected', 'The Provider is disconnected from all chains', 'provider');
    }
    static get chainDisconnected() {
        return new AFWalletRPCError(4901, 'Chain Disconnected', 'The Provider is not connected to the requested chain', 'provider');
    }
    static get chainIDNotSupported() {
        return new AFWalletRPCError(9001, 'Chain ID is not supported', 'Chain ID is not supported', 'provider');
    }
}