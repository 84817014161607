import BaseRPCHandler from "./BaseRPCHandler";

export default class WalletGetPermissionsHandler extends BaseRPCHandler {
    constructor() {
        super('wallet_getPermissions');
    }
    
    async handle(provider, request) {

    }
}
