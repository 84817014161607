// import './App.css';
import React, { useState, useEffect } from "react";

import {
  Input,
  // IconButton,
  // useDisclosure,
  // Box,
  // HStack,
  // Flex,
  Button,
  FormLabel,
  FormControl,
  Flex,
} from "@chakra-ui/react";

import { showError, showSuccess } from "../../common/errors";
import EnterpriseStrings from "./EnterpriseStrings";
import { FiSave } from "react-icons/fi";
// import { FiTrash2, FiCopy, FiPlus } from "react-icons/fi";
// import AccountManager from "./AccountManager";

const EnterpriseProfileContent = ({
  selectedAccount,
  selectedIndex,
  setSelectedIndex,
  disclosure,
}) => {
  const [isCreating, setCreating] = useState(false);
  const [enterpriseProfile, setEnterpriseProfile] = useState(null);

  useEffect(() => {
    console.log("EnterpriseProfileContent:", selectedIndex);
    if (!disclosure.isOpen) {
      return;
    }
    if (selectedIndex !== 0) {
      return;
    }

    window.mailService
      .getEnterpriseProfile()
      .then((profile) => {
        setEnterpriseProfile(profile);
      })
      .catch((e) => {
        console.error(e);
        showError(e);
      });
  }, [disclosure.isOpen, selectedIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const createEnterprise = async () => {
    try {
      setCreating(true);

      await window.mailService.saveEnterpriseProfile(enterpriseProfile);
      await window.mailService.syncFoldersFile();

      // disclosure.onClose();
      showSuccess("Saved");
    } catch (e) {
      console.error(e);
      showError(e);
    } finally {
      setCreating(false);
    }
  };

  return (
    <>
      <FormControl>
        <FormLabel>{EnterpriseStrings.profile.name} </FormLabel>
        <Input
          autoComplete="off"
          placeholder={EnterpriseStrings.profile.name_placeholder}
          type={"text"}
          defaultValue={enterpriseProfile && enterpriseProfile.name}
          onChange={(e) => {
            if (enterpriseProfile) {
              enterpriseProfile.name = e.target.value;
              setEnterpriseProfile({ ...enterpriseProfile });
            } else {
              setEnterpriseProfile({ name: e.target.value, note: null });
            }
          }}
        />
      </FormControl>
      {/* <FormControl>
        <FormLabel>{EnterpriseStrings.profile.notes} </FormLabel>
        <Input
          autoComplete="off"
          placeholder={EnterpriseStrings.profile.notes_placeholder}
          type={"text"}
          defaultValue={enterpriseProfile && enterpriseProfile.notes}
          onChange={(e) => {
            if (enterpriseProfile) {
              enterpriseProfile.notes = e.target.value;
              setEnterpriseProfile({ ...enterpriseProfile });
            } else {
              setEnterpriseProfile({ name: null, notes: e.target.value });
            }
          }}
        />
      </FormControl> */}
      <Flex justifyContent={"flex-end"} mt={5}>
        <Button
          leftIcon={<FiSave />}
          isDisabled={
            !enterpriseProfile ||
            !enterpriseProfile.name ||
            enterpriseProfile.name.length === 0
          }
          isLoading={isCreating}
          colorScheme={"green"}
          onClick={() => {
            createEnterprise().then((e) => {});
          }}
        >
          {EnterpriseStrings.profile.save}
        </Button>
      </Flex>
    </>
  );
};

export default EnterpriseProfileContent;
