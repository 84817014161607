// import './App.css';

import React, { useEffect, useState } from "react";
import {
  HStack,
  Text,
  ListItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Flex,
  Spinner,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";

// import appConfig from '../config/AppConfig';
import { ClientError, showError } from "../common/errors";
import { FiFrown } from "react-icons/fi";
import { FaCheck, FaEthereum, FaGlobeAmericas, FaStore } from "react-icons/fa";
import Strings from "../config/Strings";

import { ConfirmationMenuItem } from "./ConfirmationButton";

import { EthereumChains, EthereumFaucets } from "../utils/Types";
import { ChevronDownIcon } from "@chakra-ui/icons";

import { AFFaucetMenuItem } from "./AFFaucetAlert";

import { AiFillMail } from "react-icons/ai";
import { toast } from "react-toastify";

const RunModeSwitchPhase = {
  Init: 0,
  SelectNetwork: 1,
  CheckBalance: 2,
  SaveIdentityKeyBundle: 3,
  WaitForTransactionReceipt: 4,
};

const MinimumBalanceRequirement =
  localStorage["MinimumBalanceRequirement"] || "1000000000000000";
const MinimalRequiredBalance =
  parseInt(MinimumBalanceRequirement) || 1000000000000000;

function RMSInitialPanel({ disclosure, upgradeOnly, setPhase }) {
  const nextClicked = () => {
    if (upgradeOnly) {
      if (window.appConfig.chainId !== EthereumChains.Avalanche) {
        window.oldChainId = window.chainId;
        window.appConfig.recentChainId = EthereumChains.Avalanche;
        window.appConfig.chainId = EthereumChains.Avalanche;
        window.chainId = EthereumChains.Avalanche;
      }
      setPhase(RunModeSwitchPhase.CheckBalance);
    } else {
      setPhase(RunModeSwitchPhase.CheckBalance);
      // setPhase(RunModeSwitchPhase.SelectNetwork);
    }
  };

  return (
    <ModalContent>
      <ModalHeader>
        {Strings.app.dialog.activate_account.get_start.title}
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <Stack spacing={2}>
          {/* <Heading
                    color={'gray.800'}
                    lineHeight={1.1}
                    fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                    Get Started
                </Heading> */}
          {upgradeOnly && (
            <Flex width={"100%"} direction={"column"} align="left">
              {
                Strings.app.dialog.activate_account.get_start
                  .description_upgrade
              }
            </Flex>
          )}
          {!upgradeOnly && (
            <Flex width={"100%"} direction={"column"} align="left">
              {Strings.app.dialog.activate_account.get_start.description}
            </Flex>
          )}
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button
          colorScheme="gray"
          mr={3}
          onClick={() => {
            disclosure.onClose();
          }}
        >
          {Strings.app.dialog.activate_account.get_start.button.cancel}
        </Button>

        {upgradeOnly && (
          <Button
            colorScheme="green"
            onClick={() => {
              nextClicked();
            }}
          >
            {Strings.app.dialog.activate_account.get_start.button.upgrade}
          </Button>
        )}
        {!upgradeOnly && (
          <Button
            colorScheme="green"
            onClick={() => {
              nextClicked();
            }}
          >
            {Strings.app.dialog.activate_account.get_start.button.next}
          </Button>
        )}
      </ModalFooter>
    </ModalContent>
  );
}

function SelectNetworkPanel({ setPhase, onChainIdChanged }) {
  const [network, setNetwork] = useState(window.chainId);

  // const selectNetwork = (network) => {

  //     window.oldChainId = window.chainId;
  //     window.appConfig.recentChainId = network;
  //     window.appConfig.chainId = network;
  //     window.chainId = network;
  //     setPhase(RunModeSwitchPhase.CheckBalance)
  // };

  const nextClicked = () => {
    if (window.chainId === network) {
      return;
    }
    window.oldChainId = window.chainId;
    window.appConfig.recentChainId = network;
    window.appConfig.chainId = network;
    window.chainId = network;
    if (onChainIdChanged) {
      onChainIdChanged(network);
    }
    setPhase(RunModeSwitchPhase.CheckBalance);
  };

  return (
    <ModalContent>
      <ModalHeader>
        {Strings.app.dialog.activate_account.select_network.title}
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <Stack spacing={2}>
          {/* <Heading
                    color={'gray.800'}
                    lineHeight={1.1}
                    fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                    Get Started
                </Heading> */}
          {Strings.app.dialog.activate_account.select_network.description &&
            Strings.app.dialog.activate_account.select_network.description
              .length > 0 && (
              <Flex width={"100%"} direction={"column"} align="left">
                <Text mt={"20px"} fontSize={"xs"} color={"gray.600"}>
                  {
                    Strings.app.dialog.activate_account.select_network
                      .description
                  }
                </Text>
              </Flex>
            )}

          <Flex width={"100%"} direction={"column"} align="left">
            <RadioGroup
              defaultValue={network}
              onChange={(value) => {
                setNetwork(value);
              }}
            >
              <Stack>
                <Radio value={EthereumChains.Avalanche} color="green">
                  {
                    Strings.app.dialog.activate_account.select_network
                      .mainnet_note
                  }
                </Radio>
                <Radio value={EthereumChains.Fuji} color="orange">
                  {
                    Strings.app.dialog.activate_account.select_network
                      .testnet_note
                  }
                </Radio>
              </Stack>
            </RadioGroup>
          </Flex>
          {/* <Flex width={"100%"} direction={"column"} align="left">
                    <Button w={"100%"} mt={5} colorScheme={"green"} onClick={() => { 
                        selectNetwork(EthereumChains.Avalanche)
                    }}>{Strings.app.dialog.activate_account.select_network.mainnet}</Button>
                    <Text mt={2} fontWeight="bold" fontSize={"xs"} color={"gray.600"}>
                    {Strings.app.dialog.activate_account.select_network.mainnet_note}
                    </Text>
                </Flex>
                <Flex width={"100%"} direction={"column"} align="left">
                    <Button w={"100%"} mt={3} colorScheme={"orange"} onClick={() => { 
                        selectNetwork(EthereumChains.Fuji)
                    }}>{Strings.app.dialog.activate_account.select_network.testnet}</Button>
                    <Text mt={2} fontWeight="bold" fontSize={"xs"} color={"gray.600"}>
                    {Strings.app.dialog.activate_account.select_network.testnet_note}
                    </Text>
                </Flex> */}
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button
          colorScheme="gray"
          mr={3}
          onClick={() => {
            if (window.oldChainId) {
              window.appConfig.recentChainId = window.oldChainId;
              window.appConfig.chainId = window.oldChainId;
              window.chainId = window.oldChainId;
              delete window.oldChainId;
            }
            setPhase(RunModeSwitchPhase.Init);
          }}
        >
          {Strings.app.dialog.activate_account.select_network.button.back}
        </Button>

        <Button
          colorScheme="green"
          onClick={() => {
            nextClicked();
          }}
        >
          {Strings.app.dialog.activate_account.select_network.button.next}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}
var RunModeSwitcherModal_kStopCheckingBalancePeriodically = false;
var RunModeSwitcherModal_kCheckingBalanceIsRunning = false;

function FundWalletMenu({ selectedAccount, buyDisclosure }) {
  const buttonTitle =
    window.appConfig && window.appConfig.chainId === EthereumChains.Mainnet
      ? Strings.app.dialog.activate_account.check_balance.menu.items.exchange
      : Strings.app.dialog.activate_account.check_balance.menu.items.email;

  const gotoExchange = () => {
    const url = EthereumFaucets[window.appConfig.chainId];
    toast("Wallet address is copied to the clipboard.");
    // window.open(url, '_blank');

    let a = document.getElementById("open-eth-exchange-link");
    if (!a) {
      a = window.document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("target", "_blank");
      // `PlexiMailPlusPlusConfig_${selectedAccount}.cfg`
      window.document.body.append(a);
    }
    a.click();
  };

  if (window.appConfig.chainId === EthereumChains.Mainnet) {
    return (
      <Menu>
        <MenuButton
          leftIcon={<FaEthereum />}
          colorScheme={"orange"}
          as={Button}
          rightIcon={<ChevronDownIcon />}
        >
          {Strings.app.dialog.activate_account.check_balance.menu.trigger}
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<FaStore />}
            onClick={() => {
              buyDisclosure.onToggle();
            }}
          >
            {Strings.app.dialog.activate_account.check_balance.menu.items.token}
          </MenuItem>

          <ConfirmationMenuItem
            icon={<FaGlobeAmericas />}
            cancelable={true}
            buttonTitle={buttonTitle}
            title={buttonTitle}
            message={
              Strings.app.dialog.activate_account.check_balance.message.back
            }
            onClick={() => {
              gotoExchange();
            }}
          />
        </MenuList>
      </Menu>
    );
  } else {
    return (
      <Menu>
        <MenuButton
          leftIcon={<FaEthereum />}
          colorScheme={"orange"}
          as={Button}
          rightIcon={<ChevronDownIcon />}
        >
          {Strings.app.dialog.activate_account.check_balance.menu.trigger}
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={<FaStore />}
            onClick={() => {
              buyDisclosure.onToggle();
            }}
          >
            {Strings.app.dialog.activate_account.check_balance.menu.items.token}
          </MenuItem>

          <AFFaucetMenuItem
            icon={<AiFillMail />}
            title={
              Strings.app.dialog.activate_account.check_balance.menu.items.email
            }
            selectedAccount={selectedAccount}
          />
        </MenuList>
      </Menu>
    );
  }
}
function RMSCheckBalancePanel({
  upgradeOnly,
  selectedAccount,
  setPhase,
  buyDisclosure /*, faucetDisclosure*/,
}) {
  const FillWalletPhase = {
    CheckBalance: 0,
    FillWallet: 1,
    Ready: 2,
  };
  const [message, setMessage] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [fillWalletPhase, setFillWalletPhase] = useState(
    FillWalletPhase.CheckBalance
  );
  // 0: check balance
  // 1: fill wallet
  // 2: next

  const checkBalanceClicked = () => {
    checkBalance()
      .then(() => {
        // setPhase(RunModeSwitchPhase.SaveIdentityKeyBundle);
      })
      .catch((e) => {
        showError(e);
      });
  };

  const nextClicked = () => {
    setPhase(RunModeSwitchPhase.SaveIdentityKeyBundle);
  };

  const checkBalance = async () => {
    try {
      setLoading(true);
      const web3 = window.web3Helper.web3;
      const balance = await web3.eth.getBalance(selectedAccount);
      if (balance <= MinimalRequiredBalance) {
        // faucetDisclosure.onOpen();
        // await window.wallet.showUI()
        setMessage({
          color: "orange",
          message:
            Strings.app.dialog.activate_account.check_balance.message
              .no_enough_eth,
        });
        setFillWalletPhase(FillWalletPhase.FillWallet);
        startCheckingBalance();
      } else {
        setMessage({
          color: "#00aaff",
          message:
            Strings.app.dialog.activate_account.check_balance.message.ready,
        });
        setFillWalletPhase(FillWalletPhase.Ready);

        // setPhase(RunModeSwitchPhase.SaveIdentityKeyBundle);

        // setMessage({
        //     color: 'orange',
        //     message:Strings.app.dialog.activate_account.check_balance.message.no_enough_eth
        // });
        // setFillWalletPhase(FillWalletPhase.FillWallet);
      }

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
      showError(e);
    }
  };

  const doCheckBalance = async () => {
    const web3 = window.web3Helper.web3;
    const balance = await web3.eth.getBalance(selectedAccount);
    console.log("balance: ", balance);
    return balance;
  };

  const stopCheckingBalance = () => {
    RunModeSwitcherModal_kCheckingBalanceIsRunning = false;
    RunModeSwitcherModal_kStopCheckingBalancePeriodically = true;
  };

  const startCheckingBalance = () => {
    if (RunModeSwitcherModal_kCheckingBalanceIsRunning) {
      return;
    }
    RunModeSwitcherModal_kStopCheckingBalancePeriodically = false;
    checkBalancePeriodically();
  };
  const checkBalancePeriodically = () => {
    const MinimumBalanceRequirement =
      localStorage["MinimumBalanceRequirement"] || "1000000000000000";
    const MinimalRequiredBalance =
      parseInt(MinimumBalanceRequirement) || 1000000000000000;

    doCheckBalance()
      .then((balance) => {
        if (balance > MinimalRequiredBalance) {
          if (!RunModeSwitcherModal_kStopCheckingBalancePeriodically) {
            //   faucetDisclosure.onClose();
            //   setPhase(RunModeSwitchPhase.SaveIdentityKeyBundle);

            setMessage({
              color: "00aaff",
              message:
                Strings.app.dialog.activate_account.check_balance.message.ready,
            });
            setFillWalletPhase(FillWalletPhase.Ready);
          }
        } else {
          if (!RunModeSwitcherModal_kStopCheckingBalancePeriodically) {
            setTimeout(() => {
              checkBalancePeriodically();
            }, 10000);
          }
        }
      })
      .catch((e) => {
        console.error(e);
        if (!RunModeSwitcherModal_kStopCheckingBalancePeriodically) {
          setTimeout(() => {
            checkBalancePeriodically();
          }, 10000);
        }
      });
  };

  return (
    <ModalContent>
      <ModalHeader>
        {Strings.app.dialog.activate_account.check_balance.title}
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <Stack spacing={2}>
          {/* <Heading
                    color={'gray.800'}
                    lineHeight={1.1}
                    fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                    Get Started
                </Heading> */}

          <Flex width={"100%"} direction={"column"} align="left">
            {Strings.app.dialog.activate_account.check_balance.description}
          </Flex>

          {isLoading && (
            <Flex width={"100%"} direction={"column"} align="left">
              <HStack>
                <Spinner size={"sm"} color={"orange"} />
                <Text mt={"20px"} fontSize={"xs"} color={"orange"}>
                  {Strings.common.message.checking_balance}
                </Text>
              </HStack>
            </Flex>
          )}

          {message && (
            <Flex width={"100%"} direction={"column"} align="left">
              <Text mt={"20px"} fontSize={"xs"} color={message.color}>
                {message.message}
              </Text>
            </Flex>
          )}

          {fillWalletPhase === FillWalletPhase.FillWallet && (
            <Flex width={"100%"} direction={"column"} align="left">
              <HStack>
                <Spinner size={"sm"} color={"orange"} />
                <Text mt={"20px"} fontSize={"xs"} color={"orange"}>
                  {Strings.common.message.wait_funds}
                </Text>
              </HStack>
            </Flex>
          )}
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button
          colorScheme="gray"
          isDisabled={isLoading}
          mr={3}
          onClick={() => {
            // if (window.oldChainId) {
            //     window.appConfig.recentChainId = window.oldChainId;
            //     window.appConfig.chainId = window.oldChainId;
            //     window.chainId = window.oldChainId;
            //     delete window.oldChainId;
            // }

            if (upgradeOnly) {
              setPhase(RunModeSwitchPhase.Init);
            } else {
              // setPhase(RunModeSwitchPhase.SelectNetwork);

              setPhase(RunModeSwitchPhase.Init);
            }
            setMessage(null);
            stopCheckingBalance();
          }}
        >
          {Strings.app.dialog.activate_account.check_balance.button.back}
        </Button>

        {fillWalletPhase === FillWalletPhase.CheckBalance && (
          <Button
            colorScheme="red"
            isLoading={isLoading}
            onClick={() => {
              checkBalanceClicked();
            }}
          >
            {Strings.app.dialog.activate_account.check_balance.button.check}
          </Button>
        )}

        {fillWalletPhase === FillWalletPhase.FillWallet && (
          <FundWalletMenu
            buyDisclosure={buyDisclosure}
            selectedAccount={selectedAccount}
          />
        )}

        {fillWalletPhase === FillWalletPhase.Ready && (
          <Button
            colorScheme="green"
            onClick={() => {
              nextClicked();
            }}
          >
            {Strings.app.dialog.activate_account.check_balance.button.next}
          </Button>
        )}
      </ModalFooter>
    </ModalContent>
  );
}

function RMSSaveIdentityKeyBundlePanel({ setPhase }) {
  const [isLoading, setLoading] = useState(false);

  const nextClicked = () => {
    saveIdentityKeyBundle()
      .then(() => {
        setPhase(RunModeSwitchPhase.WaitForTransactionReceipt);
      })
      .catch((e) => {
        showError(e);
      });
  };

  const saveTxHash = async (txhash) => {
    localStorage.setItem("rms-tx-hash", txhash);
  };

  const saveIdentityKeyBundle = async () => {
    try {
      setLoading(true);
      const result =
        await window.mailService.storeIdentityKeyIntoSmartContract();
      await saveTxHash(result.hash);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
      showError(e);
    }
  };

  return (
    <ModalContent>
      <ModalHeader>
        {Strings.app.dialog.activate_account.save_idkey.title}
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <Stack spacing={2}>
          {/* <Heading
                    color={'gray.800'}
                    lineHeight={1.1}
                    fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                    Get Started
                </Heading> */}

          <Flex width={"100%"} direction={"column"} align="left">
            {Strings.app.dialog.activate_account.save_idkey.description}
          </Flex>
          {isLoading && (
            <Flex width={"100%"} direction={"column"} align="left">
              <HStack>
                <Spinner />
                <Text mt={"20px"} fontSize={"xs"} color={"orange"}>
                  {Strings.common.message.save_idkey}
                </Text>
              </HStack>
            </Flex>
          )}
        </Stack>
      </ModalBody>

      <ModalFooter>
        <Button
          colorScheme="gray"
          mr={3}
          onClick={() => {
            setPhase(RunModeSwitchPhase.Init);
          }}
        >
          {Strings.app.dialog.activate_account.save_idkey.button.previous}
        </Button>

        <Button
          colorScheme="green"
          onClick={() => {
            nextClicked();
          }}
        >
          {Strings.app.dialog.activate_account.save_idkey.button.next}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}

function WaitForTransactionReceiptPanel({ disclosure, setPhase }) {
  let [isLoading, setLoading] = useState(false);
  const [isConfirmed, setConfirmed] = useState(false);
  const [txError, setTxError] = useState(null);

  useEffect(() => {
    waitForTransactionReceipt()
      .then(() => {
        window.appConfig.mailAddressNeedToBeVerified = true;
        setConfirmed(true);
      })
      .catch((e) => {
        setConfirmed(false);
        setTxError(e);
        showError(e);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const doneClicked = () => {
    localStorage.removeItem("rms-tx-hash");
    disclosure.onClose();
    window.forceQuit = true;
    // window.location.reload();
    setTimeout(function () {
      window.location.reload(true);
    });
  };
  const loadTxHash = async () => {
    const txhash = localStorage.getItem("rms-tx-hash") || "";
    if (!txhash || txhash.length === 0) {
      throw ClientError.invalidParameterError("Transaction not found");
    }
    return txhash;
  };
  const waitForTransactionReceipt = async () => {
    if (isLoading) {
      return;
    }
    try {
      isLoading = true;
      setLoading(true);
      setTxError(null);
      setConfirmed(false);
      const txn = await loadTxHash(); // TODO:
      await window.mailService.waitVerifyTransactionReceiptMined(txn);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
      showError(e);
    }
  };

  return (
    <ModalContent>
      <ModalHeader>
        {Strings.app.dialog.activate_account.wait_receipt.title}
        <ModalCloseButton />
      </ModalHeader>

      <ModalBody>
        <Stack spacing={2}>
          {/* <Heading
                    color={'gray.800'}
                    lineHeight={1.1}
                    fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                    Get Started
                </Heading> */}

          <Flex width={"100%"} direction={"column"} align="left">
            {Strings.app.dialog.activate_account.wait_receipt.description}
          </Flex>

          {window.appConfig.privacyLevelIsNormal && (
            <Flex width={"100%"} direction={"column"} align="left">
              <Text color={"orange"}>
                {
                  Strings.app.dialog.activate_account.wait_receipt
                    .high_privacy_warning
                }
              </Text>
            </Flex>
          )}

          {isLoading && (
            <Flex width={"100%"} direction={"column"} align="left">
              <HStack>
                <Spinner />
                <Text mt={"20px"} fontSize={"xs"} color={"orange"}>
                  {
                    Strings.app.dialog.activate_account.wait_receipt.message
                      .checking
                  }
                </Text>
              </HStack>
            </Flex>
          )}

          {txError && (
            <Flex width={"100%"} direction={"column"} align="left">
              <HStack>
                <FiFrown />
                <Text color={"red.500"} fontSize={{ base: "sm", sm: "md" }}>
                  {
                    Strings.app.dialog.activate_account.wait_receipt.message
                      .failed
                  }
                </Text>
              </HStack>
            </Flex>
          )}

          {isConfirmed && (
            <Flex width={"100%"} direction={"column"} align="left">
              <HStack>
                <FaCheck color={"green"} />
                <Text mt={"20px"} fontSize={"xs"} color={"green"}>
                  {
                    Strings.app.dialog.activate_account.wait_receipt.message
                      .confirmed
                  }
                </Text>
              </HStack>
            </Flex>
          )}
        </Stack>
      </ModalBody>

      <ModalFooter>
        {!isConfirmed && (
          <Button
            isDisabled={isLoading}
            colorScheme="gray"
            mr={3}
            onClick={() => {
              setPhase(RunModeSwitchPhase.SaveIdentityKeyBundle);
            }}
          >
            {Strings.app.dialog.activate_account.wait_receipt.button.previous}
          </Button>
        )}

        <Button
          colorScheme="green"
          isDisabled={!isConfirmed}
          onClick={() => {
            doneClicked();
          }}
        >
          {Strings.app.dialog.activate_account.wait_receipt.button.next}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
}

export function RunModeSwitcherModal({
  disclosure,
  upgradeOnly,
  selectedAccount,
  buyDisclosure,
  onChainIdChanged,
  // faucetDisclosure
}) {
  const stopCheckingBalance = () => {
    RunModeSwitcherModal_kCheckingBalanceIsRunning = false;
    RunModeSwitcherModal_kStopCheckingBalancePeriodically = true;
  };
  const [phase, setPhase] = useState(RunModeSwitchPhase.Init);

  return (
    <Modal
      size={"sm"}
      isOpen={disclosure.isOpen}
      onClose={() => {
        disclosure.onClose();
        stopCheckingBalance();
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      {phase === RunModeSwitchPhase.Init && (
        <RMSInitialPanel
          disclosure={disclosure}
          upgradeOnly={upgradeOnly}
          setPhase={setPhase}
        />
      )}
      {phase === RunModeSwitchPhase.SelectNetwork && (
        <SelectNetworkPanel
          onChainIdChanged={onChainIdChanged}
          setPhase={setPhase}
        />
      )}
      {phase === RunModeSwitchPhase.CheckBalance && (
        <RMSCheckBalancePanel
          upgradeOnly={upgradeOnly}
          setPhase={setPhase}
          selectedAccount={selectedAccount}
          buyDisclosure={buyDisclosure}
        />
      )}
      {/* faucetDisclosure={faucetDisclosure} */}
      {phase === RunModeSwitchPhase.SaveIdentityKeyBundle && (
        <RMSSaveIdentityKeyBundlePanel setPhase={setPhase} />
      )}
      {phase === RunModeSwitchPhase.WaitForTransactionReceipt && (
        <WaitForTransactionReceiptPanel
          disclosure={disclosure}
          setPhase={setPhase}
        />
      )}
    </Modal>
  );
}

export function RunModeSwitchButton({
  children,
  selectedAccount,
  buyDisclosure /*, faucetDisclosure*/,
}) {
  const disclosure = useDisclosure({
    onOpen: () => {},
  });

  return (
    <>
      <Button
        colorScheme="green"
        onClick={(e) => {
          e.preventDefault();
          disclosure.onOpen();
        }}
      >
        {children}
      </Button>
      <RunModeSwitcherModal
        upgradeOnly={false}
        disclosure={disclosure}
        selectedAccount={selectedAccount}
        buyDisclosure={buyDisclosure}
      />
      {/* faucetDisclosure={faucetDisclosure}  */}
    </>
  );
}

export function UpgradeListItem({
  selectedAccount,
  onChainIdChanged,
  buyDisclosure,
  children,
}) {
  const disclosure = useDisclosure({
    onOpen: () => {},
  });

  return (
    <>
      <ListItem
        cursor={"pointer"}
        onClick={(e) => {
          e.preventDefault();
          disclosure.onOpen();
        }}
      >
        {children}
      </ListItem>
      <RunModeSwitcherModal
        upgradeOnly={true}
        onChainIdChanged={onChainIdChanged}
        disclosure={disclosure}
        selectedAccount={selectedAccount}
        buyDisclosure={buyDisclosure}
      />
      {/* faucetDisclosure={faucetDisclosure}  */}
    </>
  );
}
