// import './App.css';
import React, { useState, useEffect } from "react";

import {
  VStack,
  Text,
  // Modal,
  // ModalOverlay,
  // ModalContent,
  // ModalHeader,
  // ModalFooter,
  // ModalBody,
  ModalCloseButton,
  useDisclosure,
  //   Link,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Link,
  MenuItem,
} from "@chakra-ui/react";

// import {CopyToClipboard} from 'react-copy-to-clipboard';
import toast, { Toaster } from "react-hot-toast";
import { QRCodeCanvas } from "qrcode.react";
import { SyncDirection } from "../../utils/Types";
// import { showError, showInfo, showSuccess } from '../common/errors';
import EnterpriseRemoteService from "./EnterpriseRemoteService";
import Strings from "../../config/Strings";
// import { showError } from '../common/errors';

import { AiOutlineDownload, AiOutlineUpload } from "react-icons/ai";

// import { useToasts } from 'react-toast-notifications'
window.mobileCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};
window.mobileAndTabletCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export function EnterpriseRemoteModal({
  disclosure,
  syncService,
  direction,
  autoClose,
}) {
  // const { addToast } = useToasts()
  const [inProgress, setInProgress] = useState(true);
  const [message, setMessage] = useState("");
  const [connectionInfo, setConnectionInfo] = useState({});
  const [isReady, setReady] = useState(false);
  const S = Strings.settings.dialog.sync;

  useEffect(() => {
    // didUpdate
    if (!disclosure.isOpen) {
      return;
    }
    setInProgress(true);

    setMessage("Connecting...");

    syncService
      .start({
        onConnected: (connectInfo) => {
          setReady(true);
          setMessage("Connected!");

          setConnectionInfo({ ...connectInfo });
        },
        onSuccess: () => {
          setMessage("Succeed!");

          setInProgress(false);
          if (autoClose) {
            disclosure.onClose();
          }
        },
        onRestart: () => {
          setMessage("Re-connecting...");
        },
        onError: (e) => {
          setReady(false);
          setInProgress(false);
          //toast(e.message || e);

          setMessage("Error: " + (e.message || e));
        },
      })
      .then(() => {})
      .catch((e) => {
        console.error(e);
        setInProgress(false);
        toast(e.message || e);
      });
  }, [disclosure.isOpen]); // eslint-disable-line react-hooks/exhaustive-deps
  // useEffect(() => {// didMount
  //     console.log('ProfileModal useEffect with [] ' + state)
  // }, [])

  return (
    <AlertDialog
      size={"xl"}
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>
          {direction === SyncDirection.Export
            ? "Export Selected Users to Your Mobile"
            : "Import Users from Your Mobile"}
          <ModalCloseButton />
        </AlertDialogHeader>

        <AlertDialogBody>
          <VStack>
            <Toaster position="bottom-center" />
            <QRCodeCanvas
              value={JSON.stringify(connectionInfo)}
              hidden={!isReady}
              // imageSettings={{
              //     src: 'favicon-32x32.png',
              //     excavate: true,
              //     width: 40,
              //     height: 40
              // }}
            />
            <Text cursor={"pointer"}>{S.content.paragraph1}</Text>
            <Text fontSize={10} color="gray">
              {message}
            </Text>

            <Link
              color={"#00aaff"}
              href="https://ai-fi.cc/index.html#downloads"
              target={"_blank"}
            >
              {S.content.paragraph2}
            </Link>

            {/* <Text cursor={'pointer'}>{JSON.stringify(connectionInfo)} </Text> */}
          </VStack>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={() => {
              syncService.cancelled = true;
              disclosure.onClose();
            }}
          >
            {S.button.cancel}
          </Button>
          {!autoClose && (
            <Button
              colorScheme="green"
              disabled={!inProgress}
              onClick={disclosure.onClose}
            >
              {S.button.done}
            </Button>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export function ImportContactsMenuItem({ selectedAccount, onCompleted }) {
  const [syncService, setSyncService] = useState(
    new EnterpriseRemoteService(SyncDirection.Import, selectedAccount, 0)
  );
  useEffect(() => {
    // setSyncService(new SyncService(SyncDirection.Import, selectedAccount, 0));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const disclosure = useDisclosure({
    onClose: () => {
      if (syncService) {
        syncService.stop();
        setSyncService(
          new EnterpriseRemoteService(SyncDirection.Import, selectedAccount, 0)
        );
      }
      if (onCompleted) {
        onCompleted(syncService.cancelled);
      }
    },
  });

  return (
    <>
      <MenuItem
        icon={<AiOutlineUpload />}
        onClick={() => {
          disclosure.onOpen();
        }}
      >
        {"Import Contacts"}
      </MenuItem>
      <EnterpriseRemoteModal
        disclosure={disclosure}
        syncService={syncService}
        direction={SyncDirection.Import}
        autoClose={true}
      />
    </>
  );
}

export function ExportContactsMenuItem({
  selectedAccount,
  selectedUsers,
  onCompleted,
}) {
  const [syncService, setSyncService] = useState(
    new EnterpriseRemoteService(SyncDirection.Export, selectedAccount, 0)
  );
  useEffect(() => {
    // setSyncService(new SyncService(SyncDirection.Import, selectedAccount, 0));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const disclosure = useDisclosure({
    onClose: () => {
      if (syncService) {
        syncService.stop();
        setSyncService(
          new EnterpriseRemoteService(SyncDirection.Export, selectedAccount, 0)
        );
      }
      if (onCompleted) {
        onCompleted(syncService.cancelled);
      }
    },
  });

  return (
    <>
      <MenuItem
        icon={<AiOutlineDownload />}
        onClick={() => {
          syncService.selectedUsers = selectedUsers;
          disclosure.onOpen();
        }}
      >
        {"Export Contacts"}
      </MenuItem>
      <EnterpriseRemoteModal
        disclosure={disclosure}
        syncService={syncService}
        direction={SyncDirection.Export}
        autoClose={true}
      />
    </>
  );
}

// export default SyncModal;

/*
export function ShowModalButton({title, colorScheme, selectedAccount, direction, autoClose, alwaysCallOnClick, onClick, onCompleted}) {

  // const syncService = new SyncService(direction, selectedAccount, 0);
  const [syncService, setSyncService] = useState(new EnterpriseRemoteService(direction, selectedAccount, 0)); 
  useEffect(() => { 
    // setSyncService(new SyncService(SyncDirection.Import, selectedAccount, 0));
  }, [])// eslint-disable-line react-hooks/exhaustive-deps
  
  const disclosure = useDisclosure({
      onClose: () => {
          if (syncService) {
              syncService.stop();
              setSyncService(new EnterpriseRemoteService(SyncDirection.Import, selectedAccount, 0));
          }
          if ((!syncService.cancelled || alwaysCallOnClick) && onClick) {
              onClick();
          }
          if (onCompleted) {
              onCompleted(syncService.cancelled);
          }
      },
      onOpen: () => {
          // onClick();
      }
  });
  if (!colorScheme) {
      colorScheme = 'green';
  }
  const doExport = () => {
    // window.mailService.exportMnemonic(selectedAccount, 'hex').then((config) => {
    window.mailService.exportConfig(selectedAccount, 'hex').then((config) => {
      const cb = encodeURIComponent(EnterpriseRemoteService.push_server_base_url);

      window.open('pmauth://add-config?account=' + selectedAccount + '&config=' + config + '&callback=' + cb);
    }).catch(e => {
      console.error(e);
      showError(e);
    })
  }
  const doImport = () => {
    window.open('pmauth://get-config?account=' + selectedAccount);
  }

  return (<>
      <Button colorScheme={colorScheme} onClick={() => {
          // onClick();
          if (window.mobileAndTabletCheck()) {
            if (direction === SyncDirection.Export) {
              doExport();
            } else if (direction === SyncDirection.Import) {
              doImport();
            }
          } else {
            disclosure.onOpen();
          }
          
      }}>{title}</Button>
      <EnterpriseRemoteModal disclosure={disclosure} syncService={syncService} direction={direction} autoClose={autoClose} />
  </>)
}

export function ImportContactsButton({mt, title, disabled, selectedAccount, onCompleted}) {
  // const syncService = new SyncService(SyncDirection.Import, selectedAccount, 0);
  const [syncService, setSyncService] = useState(new EnterpriseRemoteService(SyncDirection.Import, selectedAccount, 0)); 
  useEffect(() => { 
    // setSyncService(new SyncService(SyncDirection.Import, selectedAccount, 0));
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  const disclosure = useDisclosure({
      onClose: () => {
          if (syncService) {
            syncService.stop();
            setSyncService(new EnterpriseRemoteService(SyncDirection.Import, selectedAccount, 0));
          }
          if (onCompleted) {
              onCompleted(syncService.cancelled);
          }
      }
  });
  return (<>
      <Button
          fontFamily={'heading'}
          mt={mt}
          w={'full'}
          bgGradient="linear(to-r, red.400,pink.400)"
          color={'white'}
          _hover={{
              bgGradient: 'linear(to-r, red.400,pink.400)',
              boxShadow: 'xl',
          }}
          isDisabled={disabled}
          onClick={()=> {
              disclosure.onOpen();
          }}>
          {title}
      </Button>
      <EnterpriseRemoteModal disclosure={disclosure} syncService={syncService} direction={SyncDirection.Import} autoClose={true} />
      </>)
}

function ConfirmationAlert({trigger, disclosure, selectedAccount, alwaysCallOnClick, onDownload, onCompleted}) {
  
  const autoClose = ((typeof onCompleted !== 'undefined') && onCompleted !== null);
  const buttonRef = createRef();
  const S = Strings.settings.dialog.export_confirm;
      
  return (
    <>
      {trigger}
      <AlertDialog
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {S.title}
            </AlertDialogHeader>

            <AlertDialogBody>
            <Text>{S.content}</Text>
            </AlertDialogBody>

            <AlertDialogFooter>

              <Button ref={buttonRef} colorScheme={"orange"} mr={4} onClick={() => {
                disclosure.onClose();
                onDownload();
              }} ml={3}>
              {S.button.download}
              </Button>
              <ShowModalButton 
                  title={S.button.sync} 
                  colorScheme={"green"} 
                  selectedAccount={selectedAccount} 
                  direction={SyncDirection.Export} 
                  autoClose={autoClose} 
                  alwaysCallOnClick={alwaysCallOnClick}
                  onClick={()=> {
                      disclosure.onClose();
                  }}
                  onCompleted={onCompleted} />
              
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export function SyncConfirmationButton({mt, title, selectedAccount, onDownload, onCompleted}) {
    const disclosure = useDisclosure({
      onOpen: ()=>{
      }
    })
    const trigger = (<Button
        fontFamily={'heading'}
        mt={mt}
        w={'full'}
        bgGradient="linear(to-r, red.400,pink.400)"
        color={'white'}
        _hover={{
            bgGradient: 'linear(to-r, red.400,pink.400)',
            boxShadow: 'xl',
        }} onClick={() => {
            disclosure.onOpen();
        }}>
      {title}
      </Button>);

    return (<ConfirmationAlert 
        trigger={trigger} 
        disclosure={disclosure}
        selectedAccount={selectedAccount} 
        alwaysCallOnClick={false}
        onDownload={onDownload} 
        onCompleted={onCompleted} />)
}

*/
