// import './App.css';
import React, {
  useState /*useState, useEffect, createRef, Suspense*/,
} from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  // Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";

import { FiBook, FiUsers, FiGlobe, FiMail } from "react-icons/fi";
// import ContentLoader from "react-content-loader";

// const UserContent = React.lazy(() => import("./UserContent.js"));
// const AddressBookContent = React.lazy(() => import("./AddressBookContent.js"));
// const ProfileContent =
import UserContent from "./UserContent";
import AddressBookContent from "./AddressBookContent";
import EnterpriseProfileContent from "./EnterpriseProfileContent";
import NotifyContent from "./NotifyContent";
import EnterpriseStrings from "./EnterpriseStrings";

const EnterpriseModal = ({
  selectedAccount,
  composeDisclosure,
  setSelectedRecipients,
  disclosure,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  // return (
  //   <Suspense fallback={<ContentLoader />}>
  //     <UserContent
  //       selectedAccount={selectedAccount}
  //       zone={zone}
  //       disclosure={disclosure}
  //     />
  //   </Suspense>
  // );
  return (
    <Modal
      size={"full"}
      scrollBehavior={"inside"}
      isOpen={disclosure.isOpen}
      onClose={() => {
        setSelectedIndex(0);
        disclosure.onClose();
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Enterprise Dashboard
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Tabs
            /*orientation={"vertical"} align='end' variant='enclosed'*/ onChange={(
              index
            ) => {
              setSelectedIndex(index);
            }}
          >
            <TabList>
              <Tab>
                <FiGlobe size={"12"} />
                &nbsp;{EnterpriseStrings.tabs.profile}
              </Tab>
              <Tab>
                <FiUsers size={"12"} />
                &nbsp;{EnterpriseStrings.tabs.users}
              </Tab>
              <Tab>
                <FiBook size={"12"} />
                &nbsp;{EnterpriseStrings.tabs.address_book}
              </Tab>
              <Tab>
                <FiMail size={"12"} />
                &nbsp;{EnterpriseStrings.tabs.customized_notification}
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <EnterpriseProfileContent
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  selectedAccount={selectedAccount}
                  disclosure={disclosure}
                />
              </TabPanel>
              <TabPanel>
                <UserContent
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  selectedAccount={selectedAccount}
                  composeDisclosure={composeDisclosure}
                  setSelectedRecipients={setSelectedRecipients}
                  disclosure={disclosure}
                />
              </TabPanel>
              <TabPanel>
                <AddressBookContent
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  selectedAccount={selectedAccount}
                  composeDisclosure={composeDisclosure}
                  setSelectedRecipients={setSelectedRecipients}
                  disclosure={disclosure}
                />
              </TabPanel>

              <TabPanel>
                <NotifyContent
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                  selectedAccount={selectedAccount}
                  composeDisclosure={composeDisclosure}
                  setSelectedRecipients={setSelectedRecipients}
                  disclosure={disclosure}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>

        <ModalFooter>
          {/* <Button
          colorScheme={"red"}
          mr={4}
          onClick={() => {
            setSelectedIndex(0);
            disclosure.onClose();
          }}
        >
          {EnterpriseStrings.profile.close}
        </Button> */}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EnterpriseModal;
