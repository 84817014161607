import {
  VStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  //   Link,
  Button,
  ListItem,
  HStack,
  Avatar,
  Link,
  Flex,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { FiAlertTriangle, FiChevronRight } from "react-icons/fi";
import { toast } from "react-toastify";
import { showError } from "../common/errors";
import Strings from "../config/Strings";

const PrivateKeyDisplayModal = ({ disclosure }) => {
  // const { addToast } = useToasts()
  const [state, setState] = useState(0);
  const [privateKeys, setPrivateKeys] = useState(null);
  const S = Strings.settings.dialog.privateKeys;

  useEffect(() => {
    // didUpdate
  }, []);
  const showMnemonic = () => {
    const keys = {
      mail: window.wallet.activatedWallet.privateKey,
      backup: window.wallet.backupWallet.privateKey
    };
    setPrivateKeys(keys);
  };
  const hideMnemonic = () => {
    setPrivateKeys(null);
  };
  // useEffect(() => {// didMount
  //     console.log('ProfileModal useEffect with [] ' + state)
  // }, [])

  return (
    <Modal
      size={"xl"}
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {S.title}
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <VStack>
            {(!privateKeys) && (
              <>
                <Link
                  backgroundColor={"#f5f5f5"}
                  minHeight={"128px"}
                  width={"100%"}
                  textAlign={"center"}
                  lineHeight={"128px"}
                  onClick={showMnemonic}
                >
                  {S.button.display}
                </Link>
                <Text color={"gray"}>{S.content.paragraph2}</Text>
              </>
            )}
            {privateKeys && (
              <>
                <CopyToClipboard
                  text={privateKeys.mail}
                  onCopy={() => {
                    setState(state + 1);
                    toast(S.message.copied);
                    //hideMnemonic()
                  }}
                >
                  {/* <Text color={'#00aaff'} cursor={'pointer'}>{mnemonic}</Text> */}
                  <Link
                    backgroundColor={"#f5f5f5"}
                    minHeight={"128px"}
                    width={"100%"}
                    padding={3}
                  >
                    Lighthouse for Storing PlexiMail: {privateKeys.mail}
                  </Link>
                </CopyToClipboard>


                <CopyToClipboard
                  text={privateKeys.backup}
                  onCopy={() => {
                    setState(state + 1);
                    toast(S.message.copied);
                    //hideMnemonic()
                  }}
                >
                  {/* <Text color={'#00aaff'} cursor={'pointer'}>{mnemonic}</Text> */}
                  <Link
                    backgroundColor={"#f5f5f5"}
                    minHeight={"128px"}
                    width={"100%"}
                    padding={3}
                  >
                    Lighthouse for Backup PlexiMail: {privateKeys.backup}
                  </Link>
                </CopyToClipboard>

                <Text color={"gray"}>{S.content.paragraph1}</Text>
                <Link color={"#00aaff"} onClick={hideMnemonic}>
                  {S.button.hide}
                </Link>
              </>
            )}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={() => {
              disclosure.onClose();
            }}
          >
            {S.button.close}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export function PrivateKeyDisplayListItem({ title, description }) {
  const disclosure = useDisclosure({
    onOpen: () => {},
  });

  const trigger = (
    <ListItem
      onClick={() => {
        disclosure.onOpen();
      }}
      cursor={"pointer"}
    >
      <HStack borderBottom={"1px solid lightGray"} pt={"4px"} pb={"4px"}>
        <Avatar
          icon={<FiAlertTriangle />}
          background={"none"}
          textColor={"gray"}
        />
        <VStack align={"left"}>
          {/* <Text fontWeight={"bold"}>Export Config</Text> */}
          <Text fontSize={14} color="black">
            {title}
          </Text>
          {description && description.length > 0 && (
            <Text fontSize={10}>{description}</Text>
          )}
        </VStack>
        {/* <Flex background={"red"} justify={"end"}>
            <FiChevronRight />
            </Flex> */}
      </HStack>
    </ListItem>
  );

  return (
    <>
      {trigger}
      <PrivateKeyDisplayModal disclosure={disclosure} />
    </>
  );
}
