// import { WalletUIStatus } from "../AFWalletUI";
import BaseRPCHandler from "./BaseRPCHandler";

export default class EthAccountsHandler extends BaseRPCHandler {
    constructor() {
        super('eth_accounts');
    }

    async handle(provider, request) {
        // console.log('EthAccountsHandler:', request);
        if (provider.wallet.accounts) {
            return provider.wallet.accounts;
        }
        if (provider.wallet.getOrderedSelectedAddresses) {
            const addresses = await provider.wallet.getOrderedSelectedAddresses();
            return addresses;
        }
        return [];
    }
}