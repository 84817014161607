export const idlFactory = ({ IDL }) => {
  const DNSResult = IDL.Record({
    'msg' : IDL.Text,
    'code' : IDL.Int32,
    'data' : IDL.Vec(IDL.Text),
  });
  const CreateSessionResult = IDL.Record({
    'msg' : IDL.Text,
    'code' : IDL.Int32,
    'data' : IDL.Record({ 'id' : IDL.Text, 'url' : IDL.Text }),
  });
  const BusinessEntity = IDL.Record({
    'tel' : IDL.Text,
    'tex' : IDL.Text,
    'zip' : IDL.Text,
    'features' : IDL.Vec(IDL.Text),
    'domain' : IDL.Text,
    'name' : IDL.Text,
    'email' : IDL.Text,
    'address' : IDL.Text,
    'capacity' : IDL.Text,
  });
  const IndexServerStatistics = IDL.Record({
    'prim' : IDL.Record({
      'rts_max_live_size' : IDL.Nat,
      'rts_stable_memory_size' : IDL.Nat,
      'rts_memory_size' : IDL.Nat,
      'rts_total_allocation' : IDL.Nat,
      'rts_max_stack_size' : IDL.Nat,
      'rts_logical_stable_memory_size' : IDL.Nat,
      'rts_heap_size' : IDL.Nat,
      'rts_reclaimed' : IDL.Nat,
      'rts_version' : IDL.Text,
    }),
  });
  const Statistics = IDL.Record({
    'bridge' : IDL.Record({ 'incoming' : IDL.Nat64, 'outgoing' : IDL.Nat64 }),
    'prim' : IDL.Record({
      'rts_max_live_size' : IDL.Nat,
      'rts_stable_memory_size' : IDL.Nat,
      'rts_memory_size' : IDL.Nat,
      'rts_total_allocation' : IDL.Nat,
      'rts_max_stack_size' : IDL.Nat,
      'rts_logical_stable_memory_size' : IDL.Nat,
      'rts_heap_size' : IDL.Nat,
      'rts_reclaimed' : IDL.Nat,
      'rts_version' : IDL.Text,
    }),
    'pleximail' : IDL.Record({
      'incoming' : IDL.Nat64,
      'outgoing' : IDL.Nat64,
    }),
    'account' : IDL.Record({
      'premium' : IDL.Int,
      'free' : IDL.Int,
      'lite' : IDL.Int,
      'count' : IDL.Int,
    }),
  });
  const CanisterStatistics = IDL.Record({
    'canisterId' : IDL.Text,
    'statistics' : Statistics,
  });
  const PartitionStatistics = IDL.Record({
    'pk' : IDL.Text,
    'canisterCount' : IDL.Int,
    'profile' : IDL.Opt(BusinessEntity),
    'statistics' : IDL.Vec(CanisterStatistics),
  });
  const AllStatistics = IDL.Record({
    'partitions' : IDL.Vec(PartitionStatistics),
    'index' : IndexServerStatistics,
  });
  const StripeSubscription = IDL.Record({
    'id' : IDL.Text,
    'name' : IDL.Text,
    'description' : IDL.Text,
    'price' : IDL.Text,
  });
  const CheckSessionResult = IDL.Record({
    'msg' : IDL.Text,
    'code' : IDL.Int32,
    'data' : IDL.Record({
      'id' : IDL.Text,
      'status' : IDL.Text,
      'payment_status' : IDL.Text,
    }),
  });
  const CreateSessionResult1 = IDL.Record({
    'msg' : IDL.Text,
    'code' : IDL.Nat,
    'data' : IDL.Record({ 'id' : IDL.Text, 'url' : IDL.Text }),
  });
  const HttpHeader = IDL.Record({ 'value' : IDL.Text, 'name' : IDL.Text });
  const HttpResponsePayload = IDL.Record({
    'status' : IDL.Nat,
    'body' : IDL.Vec(IDL.Nat8),
    'headers' : IDL.Vec(HttpHeader),
  });
  const TransformArgs = IDL.Record({
    'context' : IDL.Vec(IDL.Nat8),
    'response' : HttpResponsePayload,
  });
  const CanisterHttpResponsePayload = IDL.Record({
    'status' : IDL.Nat,
    'body' : IDL.Vec(IDL.Nat8),
    'headers' : IDL.Vec(HttpHeader),
  });
  const InterCanisterActionResult = IDL.Variant({
    'ok' : IDL.Null,
    'err' : IDL.Text,
  });
  const IndexCanister = IDL.Service({
    'autoScalePlexiMailServiceCanister' : IDL.Func([IDL.Text], [IDL.Text], []),
    'beginVerifyingDomain' : IDL.Func(
        [IDL.Text],
        [
          IDL.Record({
            'msg' : IDL.Text,
            'code' : IDL.Int32,
            'data' : IDL.Text,
          }),
        ],
        [],
      ),
    'chargeCanister' : IDL.Func([IDL.Text], [IDL.Nat], []),
    'checkDNSTxtRecord' : IDL.Func([IDL.Text], [IDL.Opt(DNSResult)], []),
    'createCheckoutSession' : IDL.Func([IDL.Text], [IDL.Text], []),
    'createPaymentSession' : IDL.Func(
        [IDL.Text, IDL.Text],
        [CreateSessionResult],
        [],
      ),
    'createPlexiMailServiceCanisterByGroup' : IDL.Func(
        [IDL.Text],
        [IDL.Opt(IDL.Text)],
        [],
      ),
    'deleteServiceCanisterById' : IDL.Func([IDL.Text, IDL.Text], [], []),
    'getBusinessEntity' : IDL.Func(
        [IDL.Text],
        [IDL.Opt(BusinessEntity)],
        ['composite_query'],
      ),
    'getCanistersByPK' : IDL.Func([IDL.Text], [IDL.Vec(IDL.Text)], ['query']),
    'getCheckoutSessions' : IDL.Func([], [IDL.Text], ['query']),
    'getIndexStatistics' : IDL.Func(
        [],
        [IndexServerStatistics],
        ['composite_query'],
      ),
    'getPartitions' : IDL.Func([], [IDL.Vec(IDL.Text)], ['composite_query']),
    'getStatistics' : IDL.Func([], [AllStatistics], ['composite_query']),
    'getStatisticsByCanister' : IDL.Func(
        [IDL.Text],
        [CanisterStatistics],
        ['composite_query'],
      ),
    'getStatisticsByPK' : IDL.Func(
        [IDL.Text],
        [PartitionStatistics],
        ['composite_query'],
      ),
    'getSubscriptions' : IDL.Func([], [IDL.Vec(StripeSubscription)], ['query']),
    'retrieveCheckoutSession' : IDL.Func([IDL.Text], [CheckSessionResult], []),
    'saveBusinessEntity' : IDL.Func([IDL.Text, BusinessEntity], [IDL.Bool], []),
    'subscribe' : IDL.Func(
        [IDL.Text],
        [
          IDL.Record({
            'msg' : IDL.Text,
            'code' : IDL.Int32,
            'data' : IDL.Opt(IDL.Text),
          }),
        ],
        [],
      ),
    'testJson' : IDL.Func([IDL.Text], [IDL.Opt(CreateSessionResult1)], []),
    'transform_dns_query' : IDL.Func(
        [TransformArgs],
        [CanisterHttpResponsePayload],
        ['query'],
      ),
    'upgradeServiceCanisterByPk' : IDL.Func(
        [IDL.Text, IDL.Vec(IDL.Nat8), IDL.Vec(IDL.Principal)],
        [IDL.Vec(IDL.Tuple(IDL.Text, InterCanisterActionResult))],
        [],
      ),
    'verifyDomain' : IDL.Func(
        [IDL.Text],
        [IDL.Record({ 'msg' : IDL.Text, 'code' : IDL.Int32 })],
        [],
      ),
  });
  return IndexCanister;
};
export const init = ({ IDL }) => { return []; };
