import BaseRPCHandler from "./BaseRPCHandler";

export default class WalletAddEthereumChainHandler extends BaseRPCHandler {
    constructor() {
        super('wallet_addEthereumChain');
    }
    
    async handle(provider, request) {

    }
    
}