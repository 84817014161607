import BaseRPCHandler from "./BaseRPCHandler";

export default class WalletRegisterOnboardingHandler extends BaseRPCHandler {
    constructor() {
        super('wallet_registerOnboarding');
    }
    
    async handle(provider, request) {

    }
}
