import { StorageProvider } from "../../utils/Types";

export class BaseWeb3Storage {
  constructor() {
    console.log('BaseWeb3Storage - constructor');
  }
  async get(cid, options, name) {
    const opts = options || {};
    const withName = opts.withName || false;
    let url = null;
    // const lighthouse = cid.startWith('Qm');

    // if (window.appConfig && window.appConfig.storageProvider === StorageProvider.LightHouse) {
    const isLighthouse =  (window.appConfig && window.appConfig.storageProvider === StorageProvider.LightHouse);
      
    const isV0 = cid.startsWith('Qm');
    if (isLighthouse || isV0) {
      url = `https://gateway.lighthouse.storage/ipfs/${cid}`
    } else {
      url = withName
      ? `https://${cid}.ipfs.w3s.link/${name}`
      : `https://${cid}.ipfs.w3s.link`;
    }
    const response = await fetch(url);

    const reader = response.body.getReader();

    // Step 2: get total length
    const contentLength = response.headers.get("Content-Length");

    // Step 3: read the data
    let receivedLength = 0; // received that many bytes at the moment
    const chunks = []; // array of received binary chunks (comprises the body)
    while (true) {
      const { done, value } = await reader.read();

      if (done) {
        break;
      }

      chunks.push(value);
      receivedLength += value.length;
      if (opts.progress) {
        opts.progress({ total: contentLength, loaded: receivedLength });
      }
    }
    console.log(
      "content-length:",
      contentLength,
      ", received-length:",
      receivedLength
    );
    const file = new File(chunks, name || "Unknown.dat");
    return { response, files: [file] };
  }

  async authenticate(email = null) {
    return null;
  }

  async isAuthenticated() {
    return false;
  }

  async loadAgent() {}

  async unloadAgent() {}

  async reset() {}

  spaces() {}

  currentSpace() {}

  setCurrentSpace(did) {}

  async createSpace(name) {}

  isSpaceRegistered() {}

  async put(files, options) {}

  async list(options) {}

  async remove(cid, options) {}
}
