import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  RadioGroup,
  Stack,
  Radio,
  Button,
  Text,
  Box,
  // IconButton,
} from "@chakra-ui/react";

// import React from 'react';
import Strings from "../config/Strings";
import AppConfig from "../config/AppConfig";
import { useState } from "react";
// import { TutorialModalLink } from './TutorialModalTrigger';

function PrivacyLevelSelectorAlert({ disclosure, onComplete }) {
  const [privacyLevel, setPrivacyLevel] = useState(
    window.torInfo && !window.torInfo.isIndexedDBSupported
      ? AppConfig.PrivacyLevel.High + ""
      : AppConfig.PrivacyLevel.Default + ""
  );
  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {Strings.app.dialog.privacy_level.title}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>
              {Strings.app.dialog.privacy_level.description}
              {/* &nbsp;<TutorialModalLink title={"Learn more"} /> */}
            </Text>
            {window.torInfo && !window.torInfo.isIndexedDBSupported && (
              <Text mt={3} color={"orange"}>
                {Strings.app.dialog.privacy_level.normal_warning}
              </Text>
            )}
            <RadioGroup
              mt={3}
              onChange={(e) => {
                setPrivacyLevel(e);
              }}
              defaultValue={privacyLevel}
            >
              <Stack direction="column">
                <Box
                  p={1}
                  style={{ border: "1px solid orange", borderRadius: 5 }}
                >
                  <Radio
                    value={AppConfig.PrivacyLevel.Normal + ""}
                    isDisabled={
                      window.torInfo && !window.torInfo.isIndexedDBSupported
                    }
                  >
                    <Text fontWeight={"bold"}>
                      {Strings.app.dialog.privacy_level.level.standard.title}
                    </Text>
                    <Text>
                      {
                        Strings.app.dialog.privacy_level.level.standard
                          .description
                      }
                    </Text>
                  </Radio>
                </Box>

                <Box
                  p={1}
                  style={{ border: "1px solid green", borderRadius: 5 }}
                >
                  <Radio value={AppConfig.PrivacyLevel.High + ""}>
                    <Text fontWeight={"bold"}>
                      {Strings.app.dialog.privacy_level.level.high.title}
                    </Text>
                    <Text>
                      {Strings.app.dialog.privacy_level.level.high.description}
                    </Text>
                  </Radio>
                </Box>
              </Stack>
            </RadioGroup>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              colorScheme="gray"
              mr={3}
              onClick={() => {
                disclosure.onClose();
                onComplete(
                  new Error(Strings.app.dialog.privacy_level.error.cancelled)
                );
              }}
            >
              {Strings.app.dialog.privacy_level.button.cancel}
            </Button>

            <Button
              colorScheme="green"
              onClick={() => {
                // const pl = parseInt(privacyLevel);
                // if (pl === AppConfig.PrivacyLevel.High) {
                //   window.forceQuit = false;
                // }

                window.appConfig.privacyLevel = parseInt(privacyLevel);
                disclosure.onClose();
                onComplete(null);
              }}
            >
              {Strings.app.dialog.privacy_level.button.confirm}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default PrivacyLevelSelectorAlert;
