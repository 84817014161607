// import './App.css';
import React, { useState, useEffect } from "react";

import {
  Button,
  // Tfoot,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Input,
  FormLabel,
  FormControl,
  FormHelperText,
  // FormErrorMessage,
  InputGroup,
  InputRightElement,
  IconButton,
  
  // Text,
  Checkbox,
  TableContainer,
  Table,
  // TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text
} from "@chakra-ui/react";

import { FaEye, FaEyeSlash } from "react-icons/fa";
import { showError } from "../common/errors";

import WalletStrings from './WalletStrings';

const ImportWalletAlert = ({ wallet, onComplete, disclosure }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState(null);
  const [entropySalt, setEntropySalt] = useState(null);
  const [crypton, setCrypton] = useState(null);
  const [isLoading, setLoading] = useState(false);
  
  const [selectedWallets, setSelectedWallets] = useState([]);

  useEffect(() => {
    if (!disclosure.isOpen) {
      return;
    }
    // getDecodedCrypton().then(() => {}).catch(e => {})

  }, [disclosure.isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  function verifyPassword(e) {
    const password = (e.target.value || "").trim();
    if (password === "") {
      setPassword(null);
      return false;
    }
    setPassword(password);
  }

  function getDecodedCrypton() {
    setLoading(true);

    window.wallet
      .getDecodedCrypton(entropySalt, password)
      .then((token) => {
        setLoading(false);
        setCrypton(token);
      })
      .catch((e) => {
        setLoading(false);
        console.error(e);
        showError(e);
      });
  }

  function importSelectedWallets() {
    crypton.wallet = selectedWallets;

    setLoading(true);
    window.wallet.importWalletsFromCrypton(entropySalt, password, crypton).then(() => {
      setLoading(false);
      setShowPassword(false);
      setPassword(null);
      setEntropySalt(null);
      setCrypton(null);
      onComplete();
    }).catch(e => {
      setLoading(false);
      console.error(e);
      showError(e);
    })
  }
  function walletIfExistingAndRepeated(wallet) {
    let existing = false;
    let repeated = false;
    let tooLong = wallet.name.length > 100;
    if (tooLong) {

      return {tooLong, existing: false, repeated: false};
    }
    for(const w of crypton.wallets) {

      if (w.address === wallet.address) {
        existing = true;
        break;
      }
      if (w.name.toLowerCase() === wallet.name.toLowerCase()) {
        repeated = true;
      }
      if (repeated && existing) {
        break;
      }
    }
    return {tooLong, existing, repeated};
  };
  function isImportable() {
    if (!selectedWallets || selectedWallets.length === 0) {
      return false;
    }
    for(const wallet of selectedWallets) {
      for(const w of crypton.wallets) {

        if (w.name.toLowerCase() === wallet.name.toLowerCase()) {
          return false
        }
      }
    }
    return true;
  }
  
  return (
    <AlertDialog
    size={"lg"}
      isOpen={disclosure.isOpen}
      onClose={() => { 
        setShowPassword(false);
        setPassword(null);
        setEntropySalt(null);
        setCrypton(null);
        setLoading(false);
        disclosure.onClose()}}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
          {WalletStrings.ui.import.title}
          </AlertDialogHeader>

          <AlertDialogBody>
            {(!crypton || crypton.isIdentical) && (
              <>
                <FormControl>
                  <FormLabel>{WalletStrings.ui.import.entropy_salt} </FormLabel>
                  <Input
                    autoComplete="off"
                    autoFocus={true}
                    placeholder={WalletStrings.ui.import.entropy_salt_placeholder}
                    type={"text"}
                    defaultValue={entropySalt}
                    onChange={(e) => {
                      if (crypton) {
                        setCrypton(null)
                      }
                      setEntropySalt(e.target.value);
                    }}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>{WalletStrings.ui.import.passphrase} </FormLabel>
                  <InputGroup>
                    <Input
                      autoComplete="off"
                      placeholder={WalletStrings.ui.import.passphrase_placeholder}
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => {
                        if (crypton) {
                          setCrypton(null)
                        }
                        verifyPassword(e)
                      }}
                    />

                    <InputRightElement>
                      {showPassword && (
                        <IconButton icon={<FaEye />} onClick={togglePassword} />
                      )}
                      {!showPassword && (
                        <IconButton
                          icon={<FaEyeSlash />}
                          onClick={togglePassword}
                        />
                      )}
                    </InputRightElement>
                  </InputGroup>
                </FormControl>

                {crypton && crypton.isIdentical &&
                <Text color="red" mt={2}>
                  {WalletStrings.ui.import.identical}
                </Text>
                }
              </>
            )}

            {/*  */}
            {crypton && !crypton.isIdentical && (
              <TableContainer w={'100%'}>
              <Table mt={4} variant="simple" w={"100%"}>
                {/* <TableCaption>
                  <Text>{WalletStrings.ui.import.import_wallets}</Text>
                </TableCaption> */}
                <Thead>
                  <Tr>
                    <Th>
                      <Checkbox disabled={true} />
                    </Th>
                    <Th>{WalletStrings.ui.import.wallet}</Th>
                    {/* <Th>{"Status"}</Th> */}
                    {/* <Th>{"Address"}</Th> */}
                  </Tr>
                </Thead>
                <Tbody>
                  {/* textDecoration={item.status === 0 ? 'none': 'line-through red'} */}
                  {crypton.wallet &&
                    crypton.wallet.length > 0 &&
                    crypton.wallet.map((w) => {
                      const {tooLong, existing, repeated} = walletIfExistingAndRepeated(w); 
                      const isSelected = selectedWallets.filter((selectedWallet) => {
                        return (
                          selectedWallet.address === w.address
                        );
                      }).length > 0;

                      return (
                        <Tr key={w.address}>
                          <Td>
                            <Checkbox
                              defaultChecked={false}
                              disabled={existing}
                              fontSize={"sm"}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  const newSelectedWallets = [
                                    ...selectedWallets,
                                    w,
                                  ];
                                  setSelectedWallets(newSelectedWallets);
                                } else {
                                  const newSelectedUsers =
                                    selectedWallets.filter((selectedWallet) => {
                                      return (
                                        selectedWallet.address !== w.address
                                      );
                                    });
                                  setSelectedWallets([...newSelectedUsers]);
                                }
                              }}
                            />
                          </Td>
                          <Td>
                          <FormControl>
                            <Input width={"100%"} fontSize={"sm"} value={w.name}
                              disabled={existing || !isSelected} onChange={(e) => {
                              const newName = e.target.value;
                              if (newName && newName.length > 0) {
                                w.name = newName;
                              }

                              const newSelectedUsers =
                              [...selectedWallets.filter((selectedWallet) => {
                                return (
                                  selectedWallet.address !== w.address
                                );
                              }), w];

                              setSelectedWallets(newSelectedUsers);
                            }} />
                            
                            <FormHelperText textDecorationColor={existing ? 'red' : ''} textDecoration={existing ? 'line-through' : ''} fontSize={"xs"} color={"dark"}>
                            {w.address}
                            </FormHelperText>
                            {(isSelected && tooLong) &&
                            <FormHelperText fontSize={"sm"} color="red">
                            {WalletStrings.ui.import.name_too_long}
                            </FormHelperText>
                            }
                            {(existing) &&
                            <FormHelperText fontSize={"sm"}>
                              {WalletStrings.ui.import.already_imported}
                            </FormHelperText>
                            }
                            
                            {(isSelected && !existing && repeated) &&
                            <FormHelperText fontSize={"sm"} color="red">
                              {WalletStrings.ui.import.name_taken}
                            </FormHelperText>
                            }

                            {(isSelected && !tooLong && !existing && !repeated) &&
                            <FormHelperText fontSize={"sm"} color="green">
                              {WalletStrings.ui.import.name_available}
                            </FormHelperText>
                            }
                          </FormControl>
                            
                            </Td>
                          {/* <Td>{w.address}</Td> */}
                          {/* <Td>{existing ? <Text fontSize={"sm"} color={"gray"} textDecoration={'line-through'} textDecorationColor={'red'}>Imported</Text> : <Text fontSize={"sm"} color={'green'}>Not Imported</Text>}</Td> */}
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
              </TableContainer>
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              colorScheme={"gray"}
              mr={4}
              onClick={() => {

                setShowPassword(false);
                setPassword(null);
                setEntropySalt(null);
                setCrypton(null);
                setLoading(false);
                disclosure.onClose();
              }}
              ml={3}
            >
              {WalletStrings.ui.import.cancel}
            </Button>
            
              {(!crypton || crypton.isIdentical) &&
            <Button
              isDisabled={!entropySalt || !password || entropySalt.length === 0 || password.length === 0 || (crypton && crypton.isIdentical)}
              isLoading={isLoading}
              colorScheme={"green"}
              onClick={() => {
                getDecodedCrypton();
              }}
            >
              {WalletStrings.ui.import.next}
            </Button>
            }
            {crypton && !crypton.isIdentical &&
            <Button
              isDisabled={!isImportable()}
              isLoading={isLoading}
              colorScheme={"green"}
              onClick={() => {
                importSelectedWallets();
              }}
            >
              {WalletStrings.ui.import.import}
            </Button>
            }
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ImportWalletAlert;
