// import './App.css';
// https://www.npmjs.com/package/jsqrcode
import React, { useState, useEffect } from "react";

import {
  VStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  //   Link,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Link,
  IconButton,
  Box,
  HStack,
  InputGroup,
  InputRightElement,
  Input,
  Image,
} from "@chakra-ui/react";

import toast, { Toaster } from "react-hot-toast";
import { AiOutlineScan } from "react-icons/ai";
// import { QrReader } from "react-qr-reader";
// import { BrowserQRCodeReader } from '@zxing/browser';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Strings from "../config/Strings";
import { showError } from "../common/errors";
import { BrowserQRCodeReader } from "@zxing/browser";
// import QrcodeDecoder from 'qrcode-decoder';
import * as zip from "@zip.js/zip.js";

const PasswordAlert = ({ disclosure, qrcodeDiclosure, onCompleted }) => {
  // Encode your PlexiMail Address into a picture or QR code for passing along to your friends and partners.

  // Optionally enter a password for extra protection.

  const [verified, setVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState(null);

  const S = Strings.qrcode.pwd;

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const verifyPassword = (e) => {
    const password = e.target.value || "";
    if (password === "") {
      setPassword(null);
      setVerified(false);
      return;
    }
    setPassword(password);
    setVerified(true);
  };
  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {S.title}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>{S.message}</Text>
            <InputGroup mt={2}>
              <Input
                autoComplete="off"
                placeholder={S.input}
                type={showPassword ? "text" : "password"}
                onChange={verifyPassword}
              />
              <InputRightElement>
                {showPassword && (
                  <IconButton icon={<FaEye />} onClick={togglePassword} />
                )}
                {!showPassword && (
                  <IconButton icon={<FaEyeSlash />} onClick={togglePassword} />
                )}
              </InputRightElement>
            </InputGroup>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              colorScheme={"gray"}
              mr={4}
              onClick={() => {
                qrcodeDiclosure.onClose();
                // disclosure.onClose();
              }}
              ml={3}
            >
              {S.button.cancel}
            </Button>
            <Button
              disabled={!verified}
              colorScheme={"green"}
              onClick={() => {
                // disclosure.onClose();
                qrcodeDiclosure.onClose();
                onCompleted(password);
              }}
              ml={3}
            >
              {S.button.next}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

const QRCodeScannerModal = ({ disclosure, onCompleted }) => {
  const [message, setMessage] = useState("");
  const S = Strings.qrcode.scanner;

  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps
  const onError = (error) => {
    if (error) {
      setMessage(error);
      return;
    }
  };
  const onScanned = (result) => {
    if (result) {
      disclosure.onClose();
      onCompleted(result.text);
      console.log("Scanned: ", result.text);
    }
  };

  return (
    <Modal
      id={"qrcode-scanner"}
      size={"xl"}
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {S.title}
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <VStack>
            <Toaster position="bottom-center" />
            <Box width="300px" height={"300px"}>
              {/* <QrReader
                // scanDelay={false}
                onError={onError}
                onResult={onScanned}
                style={{ width: "100%" }}
              /> */}
            </Box>
            <Text cursor={"pointer"}>{S.message}</Text>
            <Text fontSize={10} color="gray">
              {message}
            </Text>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={() => {
              disclosure.onClose();
            }}
          >
            {S.button.cancel}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export function QRCodeScannerModalButton({ onCompleted }) {
  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps
  const S = Strings.qrcode.button;
  const [enableVideo, setEnableVideo] = useState(false);
  const disclosure = useDisclosure({
    onClose: () => {
      setEnableVideo(false);
    },
    onOpen: () => {
      // setEnableVideo(true);
    },
  });

  return (
    <>
      <Button
        fontFamily={"heading"}
        mt={0}
        ml={0}
        bgGradient="linear(to-r, blue.400,blue.400)"
        color={"white"}
        _hover={{
          bgGradient: "linear(to-r, green.400,blue.400)",
          boxShadow: "xl",
        }}
        onClick={() => {
          disclosure.onOpen();
          setEnableVideo(true);
        }}
      >
        {S.scan}
      </Button>
      {enableVideo && (
        <QRCodeScannerModal disclosure={disclosure} onCompleted={onCompleted} />
      )}
    </>
  );
}

const UnzipAlert = ({ disclosure, zipFile, onCompleted }) => {
  // Encode your PlexiMail Address into a picture or QR code for passing along to your friends and partners.

  // Optionally enter a password for extra protection.

  const [verified, setVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const S = Strings.qrcode.zip;

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };
  const verifyPassword = (e) => {
    const password = e.target.value || "";
    if (password === "") {
      setPassword(null);
      setVerified(false);
      return;
    }
    setPassword(password);
    setVerified(true);
  };

  const unzip = async (file, password) => {
    const fileToBlob = async (file) => {
      if (file instanceof Blob) {
        return file;
      }
      const buffers = [new Uint8Array(await file.arrayBuffer())];
      const blob = new Blob(buffers, { type: file.type });
      return blob;
    };
    const zipBlob = await fileToBlob(file);

    const zipBlobReader = new zip.BlobReader(zipBlob);
    const zipReader = new zip.ZipReader(zipBlobReader, { password });
    const entries = await zipReader.getEntries();
    const urls = [];

    for (const entry of entries) {
      // const writer = new zip.Uint8ArrayWriter();
      // const data = await entry.getData(writer);
      // const data = await writer.getData();
      // console.log('data', data.length);

      const writer = new zip.BlobWriter();
      const blob = await entry.getData(writer);
      const url = URL.createObjectURL(blob);
      urls.push(url);
    }
    return urls;
  };

  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {S.title}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>{S.message}</Text>
            <InputGroup mt={2}>
              <Input
                placeholder={S.input}
                type={showPassword ? "text" : "password"}
                onChange={verifyPassword}
              />
              <InputRightElement>
                {showPassword && (
                  <IconButton icon={<FaEye />} onClick={togglePassword} />
                )}
                {!showPassword && (
                  <IconButton icon={<FaEyeSlash />} onClick={togglePassword} />
                )}
              </InputRightElement>
            </InputGroup>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              colorScheme={"gray"}
              mr={4}
              onClick={() => {
                disclosure.onClose();
                // disclosure.onClose();
              }}
              ml={3}
            >
              {S.button.cancel}
            </Button>
            <Button
              disabled={!verified}
              isLoading={isLoading}
              colorScheme={"green"}
              onClick={() => {
                // disclosure.onClose();
                // onCompleted(password);
                setLoading(true);
                unzip(zipFile, password)
                  .then((urls) => {
                    setLoading(false);
                    disclosure.onClose();

                    onCompleted(urls, null);
                  })
                  .catch((e) => {
                    setLoading(false);
                    showError(e);
                  });
              }}
              ml={3}
            >
              {S.button.next}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export function OpenQRCodeFileButton({ onCompleted }) {
  const unzipDisclosure = useDisclosure({
    onOpen: () => {},
    onClose: () => {},
  });
  const [zipFile, setZipFile] = useState(null);

  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps
  const S = Strings.qrcode.button;

  const handleFileUpload = (event) => {
    const files = event.target.files;
    const file = files && files.length > 0 ? files[0] : null;
    if (file) {
      console.log("file to process:", file.name, ", type:", file.type);
      // .zip    application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip

      if (
        file.type === "application/zip" ||
        file.type === "application/x-zip-compressed"
      ) {
        console.log(`${file.name} is zip file`);
        setZipFile(file);
        unzipDisclosure.onOpen();
      } else if (
        file.type === "image/apng" ||
        file.type === "image/avif" ||
        file.type === "image/gif" ||
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml" ||
        file.type === "image/webp"
      ) {
        // let reader = new FileReader();
        // reader.onload = function (e) {
        //     const url = e.target.result;
        //     const codeReader = new BrowserQRCodeReader(null, {delayBetweenScanAttempts: 500});
        //     codeReader.decodeFromImageUrl(url).then(result => {
        //         console.log(result);
        //         onCompleted(result.text);
        //     }).catch(e => {
        //         console.error(e);

        //     });
        // };
        // reader.readAsDataURL(file);

        // let reader = new FileReader();
        // reader.onload = function (e) {
        //     try {
        //     const url = e.target.result;
        //     reader.readAsDataURL(file);
        //     const img = document.createElement('img');
        //     img.src = url;

        //     const qr = new QrcodeDecoder();

        //     qr.decodeFromImage(img).then((res) => {
        //         console.log(res);
        //         onCompleted(null)
        //     });
        //     } catch (e) {
        //         console.error(e);
        //         onCompleted(null);
        //     }
        // };
        // reader.readAsDataURL(file);

        try {
          const url = URL.createObjectURL(file);
          // setImgUrl(url);
          onCompleted([url], null);

          // const img = document.getElementById('qrcode-file-decoder-img');
          // img.src = url;
          // img.style.display = 'inline-block'
          // var qr = new window.QrcodeDecoder();
          // qr.decodeFromImage(img).then((res) => {
          //     onCompleted(res.data);
          // }).catch(e => {
          //     console.error(e);
          //     onCompleted(null);
          // })
        } catch (e) {
          console.error(e);
          onCompleted(null, e);
        }

        // const url = URL.createObjectURL(file);
        // const codeReader = new BrowserQRCodeReader(null, {delayBetweenScanAttempts: 500});
        // codeReader.decodeFromImageUrl(url).then(result => {
        //     console.log(result);
        //     onCompleted(result.text);
        // }).catch(e => {
        //     console.error(e);

        // });
      } else {
        showError("Unsupported file type: ", file.type);
      }
    } else {
      toast("please select a file");
    }
  };

  return (
    <>
      <input
        id="qrcode-file"
        onChange={handleFileUpload}
        type="file"
        style={{ display: "none" }}
        multiple={"multiple"}
        accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff, .zip"
        // multiple={false}
      />

      <Button
        fontFamily={"heading"}
        mt={0}
        ml={4}
        bgGradient="linear(to-r, red.400,pink.400)"
        color={"white"}
        _hover={{
          bgGradient: "linear(to-r, red.400,pink.400)",
          boxShadow: "xl",
        }}
        onClick={() => {
          document.getElementById("qrcode-file").click();
        }}
      >
        {S.open}
      </Button>
      <UnzipAlert
        disclosure={unzipDisclosure}
        zipFile={zipFile}
        onCompleted={(urls, error) => {
          // setImgUrl(urls);
          onCompleted(urls, error);
        }}
      />
    </>
  );
}

function QRCodeSourceChooseConfirmationAlert({
  title,
  discription,
  disclosure,
  onCompleted,
}) {
  const [imgUrls, setImgUrls] = useState([]);

  const decodeQrCode = async (img) => {
    const qr = new window.QrcodeDecoder();
    const res = await qr.decodeFromImage(img);
    if (res) {
      return res.data;
    } else {
      const codeReader = new BrowserQRCodeReader(null, {
        delayBetweenScanAttempts: 500,
      });
      const result = await codeReader.decodeFromImageElement(img);
      return result.text;
    }
  };

  const decodeQrCodes = async () => {
    const imgs = document.getElementsByName("qrcode-file-decoder-img");
    const qrcodes = [];
    for (const img of imgs) {
      const qrcode = await decodeQrCode(img);
      qrcodes.push(qrcode);
    }
    return qrcodes;
  };
  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
      scrollBehavior="inside"
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title || "Input PlexiMail Addresses"}
          </AlertDialogHeader>

          <AlertDialogBody>
            {(!imgUrls || imgUrls.length === 0) && (
              <Text>{Strings.qrcode.alert.scan_message}</Text>
            )}
            {imgUrls && imgUrls.length > 0 && (
              <>
                <Text>{Strings.qrcode.alert.decode_message}</Text>
                <VStack>
                  {imgUrls &&
                    imgUrls.length > 0 &&
                    imgUrls.map((imgUrl) => {
                      return (
                        <Image
                          key={imgUrl}
                          src={imgUrl}
                          name="qrcode-file-decoder-img"
                          width={"100%"}
                        />
                      );
                    })}
                </VStack>
              </>
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <HStack
              width={"100%"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Button
                colorScheme={"gray"}
                onClick={() => {
                  setImgUrls([]);
                  disclosure.onClose();
                }}
              >
                {Strings.qrcode.alert.cancel}
              </Button>

              {(!imgUrls || imgUrls.length === 0) && (
                <Box>
                  <QRCodeScannerModalButton
                    onCompleted={(result) => {
                      disclosure.onClose();
                      onCompleted([result], null);
                    }}
                  />
                  <OpenQRCodeFileButton
                    onCompleted={(urls, error) => {
                      setImgUrls(urls);
                      if (error) {
                        disclosure.onClose();
                        onCompleted(null, error);
                      }
                    }}
                  />
                </Box>
              )}
              {imgUrls && imgUrls.length > 0 && (
                <Button
                  colorScheme={"green"}
                  onClick={() => {
                    decodeQrCodes()
                      .then((qrcodes) => {
                        onCompleted(qrcodes, null);
                        setImgUrls([]);
                      })
                      .catch((e) => {
                        console.error(e);
                        onCompleted(null, e);
                        setImgUrls([]);
                        disclosure.onClose();
                      });
                  }}
                >
                  {Strings.qrcode.alert.next}
                </Button>
              )}
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export function QRCodeSourceChooseConfirmationButton({
  trigger,
  title,
  isDisabled = false,
  withoutBackground,
  asButton = false,
  onCompleted,
}) {
  const disclosure = useDisclosure({
    onOpen: () => {},
    onClose: () => {
      alertDisclosure.onClose();
    },
  });

  const alertDisclosure = useDisclosure({
    onOpen: () => {},
  });
  const [qrcode, setQRCode] = useState("");
  const decrypt = async (password, qrcode) => {
    qrcode = qrcode.substring(4);
    const address = await window.mailService.cryptoService.decryptMnemonic(
      password,
      qrcode
    );
    return address;
  };
  return (
    <>
      {trigger && trigger.length > 0 && (
        <Link
          isDisabled={isDisabled}
          onClick={() => {
            disclosure.onOpen();
          }}
        >
          {title}
        </Link>
      )}
      {(!trigger || trigger.length === 0) && !asButton && withoutBackground && (
        <IconButton
          icon={<AiOutlineScan />}
          aria-label={Strings.qrcode.alert.trigger}
          title={Strings.qrcode.alert.trigger}
          background="none"
          isDisabled={isDisabled}
          onClick={() => {
            disclosure.onOpen();
          }}
        />
      )}

      {(!trigger || trigger.length === 0) && asButton && (
        <Button
          leftIcon={<AiOutlineScan />}
          isDisabled={isDisabled}
          colorScheme={"purple"}
          onClick={() => {
            disclosure.onOpen();
          }}
        >
          {Strings.qrcode.alert.trigger}
        </Button>
      )}
      {(!trigger || trigger.length === 0) && !asButton && !withoutBackground && (
        <IconButton
          icon={<AiOutlineScan />}
          aria-label={Strings.qrcode.alert.trigger}
          title={Strings.qrcode.alert.trigger}
          isDisabled={isDisabled}
          onClick={() => {
            disclosure.onOpen();
          }}
        />
      )}
      <QRCodeSourceChooseConfirmationAlert
        title={title}
        disclosure={disclosure}
        onCompleted={(qrcodes, error) => {
          if (error) {
            showError(error);
            return;
          }
          if (qrcodes && qrcodes.length === 1) {
            const qrcode = qrcodes[0];
            if (qrcode.indexOf("enc:") === 0) {
              setQRCode(qrcode);
              alertDisclosure.onOpen();
            } else {
              onCompleted(qrcode);
              disclosure.onClose();
            }
          } else {
            onCompleted(qrcodes);
            disclosure.onClose();
          }
        }}
      />

      <PasswordAlert
        disclosure={alertDisclosure}
        qrcodeDiclosure={disclosure}
        onCompleted={(password) => {
          if (password) {
            decrypt(password, qrcode)
              .then((address) => {
                onCompleted(address);
              })
              .catch((e) => {
                showError(e);
              });
          }
        }}
      />
    </>
  );
}
