// import './App.css';
import React, { useState, useEffect } from "react";

import {
  Button,
  // Tfoot,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Input,
  FormLabel,
  FormControl,
  FormHelperText
} from "@chakra-ui/react";
import WalletStrings from './WalletStrings';

const CreateWalletAlert = ({ onComplete, disclosure }) => {
  const [name, setName] = useState(null);
  const [wallets, setWallets] = useState(null)
  useEffect(() => {
    if (!disclosure.isOpen) {
      return;
    }
    window.wallet.getWalletList().then(wallets => {
      setWallets(wallets);
      return window.wallet.generateWalletName();
    }).then(name => {
      setName(name);
    }).catch(e => {
      setName('Account 1');
    });

  }, [disclosure.isOpen]);// eslint-disable-line react-hooks/exhaustive-deps
  const walletNameIsTaken = () => {
    if (!name || name.length === 0) {
      return false;
    }

    return wallets.filter(w => {
      return w.name.toLowerCase() === name.toLowerCase();
    }).length > 0;
  }
  const walletNameIsTooLong = () => {

    if (!name || name.length === 0) {
      return false;
    }
    if (name.length > 100) {
      return true
    } 
    return false;
  }
  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {WalletStrings.ui.create.title}
          </AlertDialogHeader>

          <AlertDialogBody>
            <FormControl>
              <FormLabel>{WalletStrings.ui.create.name} </FormLabel>
              <Input
                autoComplete="off"
                autoFocus={true}
                
                placeholder={WalletStrings.ui.create.name_placeholder}
                type={"text"}
                defaultValue={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                onFocus={(e) => e.currentTarget.select()}
                onKeyDown={(e) => {
                  if (e.key === "Escape") {
                    disclosure.onClose();
                  } else if (e.key === "Enter") {
                    onComplete(name);
                    disclosure.onClose();
                  }
                }}
              />

              {walletNameIsTaken() &&
                <FormHelperText fontSize={"xs"} color={"red"}>
                  { WalletStrings.ui.create.name_taken}
                </FormHelperText>
              }
              {walletNameIsTooLong() &&
                <FormHelperText fontSize={"xs"} color={"red"}>
                  { WalletStrings.ui.create.name_too_long}
                </FormHelperText>
                }
            </FormControl>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              colorScheme={"gray"}
              mr={4}
              onClick={() => {
                disclosure.onClose();
              }}
              ml={3}
            >
              {WalletStrings.ui.create.cancel}
            </Button>
            <Button
              isDisabled={(!name || name.length === 0) || walletNameIsTaken() || walletNameIsTooLong()}
              colorScheme={"green"}
              onClick={() => {
                onComplete(name);
                disclosure.onClose();
              }}
            >
              {WalletStrings.ui.create.create}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default CreateWalletAlert;
