// import AFWalletRPCError from "../AFWalletRPCError";
import BaseRPCHandler from "./BaseRPCHandler";


// const fromHexString = (hexString) => {
//     return Uint8Array.from(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));
// }

// const toHexString = (bytes) => {
//     return bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '');
// }

export default class PersonalSignHandler extends BaseRPCHandler {
    constructor() {
        super('personal_sign');
    }
    
    async handle(provider, request) {
        console.log('PersonalSignHandler request: ', request);
        const signature = await provider.wallet.confirm(request);
        return signature;
        // const confirmed = await provider.wallet.confirm(request);
        
        // if (!confirmed) {
        //     throw AFWalletRPCError.userRejectedRequest;
        // }
        
        // const {params} = request;
        // const [messageInHex, address] = params;
        // let messageWithoutPrefix = null;
        // if (messageInHex.indexOf('0x') === 0 || messageInHex.indexOf('0X') === 0) {
        //     messageWithoutPrefix = messageInHex.substring(2);
        // } else {
        //     messageWithoutPrefix = messageInHex;
        // }
        // const message = fromHexString(messageWithoutPrefix);
        // const signature = await provider.wallet.sign(message, address);
        // return signature;
    }
}