import * as rfc5322 from 'email-addresses';
import Web3 from 'web3';
// import * as rfc2822 from 'address-rfc2822';

const parseOneAddress = (address) => {
    if (!address) {
        return null;
    }
    
    const addr = rfc5322.parseOneAddress(address);
    return addr;
}

const parseAddressArray = (addresses) => {
    if (!addresses || addresses.length === 0) {
        return [];
    }
    
    const addressArray = addresses.map((address) => {
        const addr = rfc5322.parseOneAddress(address);
        return addr;
    });

    return addressArray;
}

const parseAddressList = (addresses) => {
    if (!addresses || addresses.length === 0) {
        return [];
    }
    const addressArray = rfc5322.parseAddressList(addresses);
    return addressArray;
}

const validateEmail = (email) => {
    // return email.match(
    //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // );
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email); 
}

const isValidEthereumAddress = (address) => {
    return Web3.utils.isAddress(address);
}

const isValidEmailAddress = (address, onlyPlexi=true) => {
    if (!address) {
        return false;
    }

    if (typeof address === 'string') {
        const addr = rfc5322.parseOneAddress(address);
        if (!addr) {
            return false;
        }
        if (onlyPlexi) {
            if (!Web3.utils.isAddress(addr.local)) {
                return false;
            }
        }
        return validateEmail(addr.address);
    }
    if (!address.address) {
        return false;
    }

    if (onlyPlexi) {
        if (!Web3.utils.isAddress(address.local)) {
            return false;
        }
    }
    return validateEmail(address.address);
}
const formatAddress = (address) => {
    if (!address.name || address.name.length === 0) {
        return address.address;
    }
    return '"' + address.name + '" <' + address.address + '>';
}

const formatAddresses = (addresses) => {
    const formatedAddresses = addresses.map((address) => {
        return formatAddress(address);
    });

    // const str = formatedAddresses.join(', ');
    return formatedAddresses;
}

const MailAddressUtils = {
    validateEmail,
    formatAddress,
    formatAddresses,
    parseOneAddress,
    parseAddressArray,
    parseAddressList,
    isValidEmailAddress,
    isValidEthereumAddress
}

export default MailAddressUtils;