import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Button,
  SimpleGrid,
  Spinner,
  HStack,
  Link,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import {
  SetupWizardState,
  EthereumChains,
  SignalInitStep,
  PlexiMailStatus,
} from "../utils/Types";
import Strings from "../config/Strings";
// import { HELP_DOC_URL } from '../common/constants';
// import { TutorialModalLink } from './TutorialModalTrigger';
import { ClientError, showError } from "../common/errors";
import AFWallet from "../wallet/AFWalletV2";

export default function OneClickCreateScreen({
  selectedAccount,
  setAccounts,
  setSelectedAccount,
  setupWizardState,
  setSetupWizardState,
}) {
  // const [isRunning, setRunning] = useState(false);

  const [isInitializing, setInitializing] = useState(false);
  const [message, setMessage] = useState(null);
  const [createSpaceStep, setCreateSpaceStep] = useState(1);
  let [resentInterval, setResentInterval] = useState(-1);

  useEffect(() => {
    create()
      .then(() => {
        console.log("Created");
      })
      .catch((e) => {
        console.error(e);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function changeNetwork(newChainId) {
    window.chainId = newChainId;

    if (newChainId === EthereumChains.Goerli) {
      window.web3Helper.init("goerli");
    } else if (newChainId === EthereumChains.Mainnet) {
      window.web3Helper.init("mainnet");
    } else if (newChainId === EthereumChains.Fuji) {
      window.web3Helper.init("fuji");
    } else if (newChainId === EthereumChains.Avalanche) {
      window.web3Helper.init("mainnet");
    } else if (newChainId === EthereumChains.Sepolia) {
      // window.web3Helper.init('sepolia');

      const sepOpts = {
        // rpcUrl: 'https://rpc.sepolia.org', // your ethereum, polygon, or optimism mainnet/testnet rpc URL
        // rpcUrl: 'https://polygon-mumbai-infura.wallet.coinbase.com?targetName=ethereum-sepolia',
        rpcUrl:
          "https://eth-sepolia.g.alchemy.com/v2/tApGyKU4AgHcem9l6xFsuMCdVe6mah6P",
        // rpcUrl: 'https://sepolia.rpc.thirdweb.com',
        chainId: 11155111,
      };
      window.web3Helper.init(sepOpts);
    } else {
      window.web3Helper.init("fuji");
    }
  }

  async function prepareToken(account) {
    try {
      await window.mailService.checkToken(window.appConfig.pushApiToken);
    } catch (e) {
      console.error(e);
      await authenticate(account);
    }
  }

  async function getNonce(account) {
    // const nonce = randomBytes(32);
    // const nonceInHex = bufferToHex(nonce);
    // return 'Hi there! Your special nonce: ' + nonceInHex;
    // // return fetch()
    const nonce = await window.mailService.getNonce(account);
    return nonce;
  }

  async function authenticate(account) {
    const message = await getNonce(account);
    // const msg = `0x${Buffer.from(exampleMessage, 'utf8').toString('hex')}`;
    // personal_sign
    const pwd =
      window.wallet && window.wallet.asDefaultWallet
        ? "--sign-the-message-silently--"
        : "";
    const web3 = window.web3Helper.web3;
    const signatureHex = await web3.eth.personal.sign(message, account, pwd);
    // const signatureHex = await window.web3Helper.ethereum.request({
    //   method: "personal_sign",
    //   params: [
    //     message,                    // 待签名的数据
    //     account
    //   ]
    // });
    const recoveredAddr = window.web3Helper.ecRecover({
      data: message,
      signature: signatureHex,
    });
    if (recoveredAddr.toLowerCase() === account) {
      window.appConfig.pushApiToken = await window.mailService.login({
        account: account,
        signature: signatureHex,
      });
    } else {
      throw ClientError.invalidParameterError(
        Strings.error.client.invalid_signature
      );
    }
  }

  const connectWallet = async () => {
    if (!window.web3Helper.ethereum) {
      throw ClientError.invalidParameterError("No account in your wallet");
      // return null;
    }

    // await changeNetwork(chainId);
    if (window.appConfig.chainId && window.appConfig.chainId.length >= 0) {
      if (window.appConfig.recentChainId !== window.appConfig.chainId) {
        window.appConfig.recentChainId = window.appConfig.chainId;
      }
    } else {
      if (
        window.appConfig.recentChainId &&
        window.appConfig.recentChainId.length > 0
      ) {
        window.appConfig.chainId = window.appConfig.recentChainId;
      }
    }
    // await changeNetwork(window.appConfig.recentChainId);

    console.log("HomeScreen - connectWallet");
    let accounts = null;
    let rpcProviderType = window.wallet.rpcProviderType();
    if (rpcProviderType === AFWallet.RPCProviderType.Unset) {
      rpcProviderType = await window.wallet.changeRpcProviderType();
    }
    if (rpcProviderType === AFWallet.RPCProviderType.AFWallet) {
      accounts = await window.web3Helper.web3.eth.requestAccounts();
    } else {
      accounts = await window.web3Helper.web3.eth.requestAccounts();
      // if (chainId === EthereumChains.Sepolia) {
      //   accounts = await window.magic.wallet.connectToMetaMask();
      // } else {
      //   accounts = await window.magic.wallet.connectWithUI();
      // }
    }

    // const accounts = await window.web3Helper.web3.eth.requestAccounts();

    const selectedAddress =
      accounts.length > 0 ? accounts[0].toLowerCase() : "";
    if (!selectedAddress || selectedAddress.length === 0) {
      throw ClientError.invalidParameterError("No account in your wallet");
    }

    window.appConfig.recentActiveAccount = selectedAddress;
    window.appConfig.migrateIfNeeded(selectedAddress);
    // window.appConfig.chainId = chainId;

    if (window.appConfig.recentChainId !== window.appConfig.chainId) {
      window.appConfig.chainId = window.appConfig.recentChainId;
      await changeNetwork(window.appConfig.recentChainId);
    }

    if (
      window.appConfig.tempMasterKey &&
      window.appConfig.tempMasterKey.length > 0 &&
      (!window.appConfig.masterKey || window.appConfig.masterKey === "")
    ) {
      window.appConfig.masterKey = window.appConfig.tempMasterKey;
    }
    if (
      window.appConfig.tempMnemonic &&
      window.appConfig.tempMnemonic.length > 0 &&
      (!window.appConfig.mnemonic || window.appConfig.mnemonic === "")
    ) {
      window.appConfig.mnemonic = window.appConfig.tempMnemonic;
    }

    const accountIndex = window.appConfig.assignAccountIndex(selectedAddress);
    window.mailService.selectedAccountIndex = accountIndex;
    window.mailService.selectedAccount = selectedAddress;

    if (window.appConfig.isPushApiTokenConfigured) {
      await prepareToken(selectedAddress);
    } else {
      await authenticate(selectedAddress);
    }

    setAccounts(accounts);
    setSelectedAccount(selectedAddress);
    // await window.mailService.initCryptServiceIfNeeded();
    // window.appConfig.tempMnemonic = window.appConfig.mnemonic;
    // await window.mailService.initWeb3StorageClient(window.appConfig.web3StorageApiToken);
  };

  const resend = () => {
    setMessage(null);
    setTimeout(() => {
      create()
        .then(() => {})
        .catch((e) => {
          console.error(e);
          showError(e);
        });
    }, 300);
  };

  async function w3Authenticate() {
    let interval = -1;
    let counter = 0;
    try {
      setCreateSpaceStep(1);
      const client = await window.mailService.web3StorageClient.loadAgent();
      console.log("w3up client:", client);

      const isAuthenticated =
        await window.mailService.web3StorageClient.isAuthenticated();
      if (isAuthenticated) {
        const isSpaceRegistered =
          window.mailService.web3StorageClient.isSpaceRegistered();
        if (!isSpaceRegistered) {
          const spaces = window.mailService.web3StorageClient.spaces();
          if (!spaces || spaces.length === 0) {
            const space =
              await window.mailService.web3StorageClient.createSpace(
                selectedAccount,
                (step, msg) => {
                  if (step !== 1 && interval > 0) {
                    clearInterval(interval);
                    setResentInterval(-1);
                    interval = -1;
                    resentInterval = 1;
                  }

                  setCreateSpaceStep(step);
                  setMessage(msg);
                }
              );
            window.mailService.web3StorageClient.setCurrentSpace(space.did());
            await window.mailService.web3StorageClient.register(space);
          } else {
            const space =
              window.mailService.web3StorageClient.getSpaceByName(
                selectedAccount
              ) || spaces[0];
            await window.mailService.web3StorageClient.checkSpace(
              space,
              (step, msg) => {
                if (step !== 1 && interval > 0) {
                  clearInterval(interval);
                  setResentInterval(-1);
                  interval = -1;
                  resentInterval = 1;
                }

                setCreateSpaceStep(step);
                setMessage(msg);
              }
            );
            window.mailService.web3StorageClient.setCurrentSpace(space.did());
            await window.mailService.web3StorageClient.register(space);
          }
        }
        return;
      }

      setMessage(
        `${Strings.web3.w3ui.verify.message_3} ${60 - counter} ${
          Strings.web3.w3ui.verify.message_4
        }`
      );
      interval = setInterval(() => {
        counter += 5;
        if (counter >= 60) {
          clearInterval(interval);
          setResentInterval(-1);
          setMessage(
            <>
              <Link
                href="#"
                fontWeight={"bold"}
                color={"orange"}
                textDecoration={"underline"}
                onClick={() => {
                  resend();
                  return false;
                }}
              >
                {Strings.web3.w3ui.verify.message_5}
              </Link>
              &nbsp;{Strings.web3.w3ui.verify.message_6}
            </>
          );
        } else {
          setMessage(
            `${Strings.web3.w3ui.verify.message_3} ${60 - counter} ${
              Strings.web3.w3ui.verify.message_4
            }`
          );
        }
      }, 5000);
      resentInterval = interval;
      setResentInterval(interval);

      await window.mailService.web3StorageClient.authenticate(
        window.appConfig.web3StorageApiToken
      );

      const isSpaceRegistered =
        window.mailService.web3StorageClient.isSpaceRegistered();
      if (!isSpaceRegistered) {
        const spaces = window.mailService.web3StorageClient.spaces();
        if (!spaces || spaces.length === 0) {
          const space = await window.mailService.web3StorageClient.createSpace(
            selectedAccount,
            (step, msg) => {
              if (step !== 1 && interval > 0) {
                clearInterval(interval);
                setResentInterval(-1);
                interval = -1;
                resentInterval = 1;
              }
              setCreateSpaceStep(step);
              setMessage(msg);
            }
          );
          window.mailService.web3StorageClient.setCurrentSpace(space.did());
        } else {
          const space =
            window.mailService.web3StorageClient.getSpaceByName(
              selectedAccount
            ) || spaces[0];
          await window.mailService.web3StorageClient.checkSpace(
            space,
            (step, msg) => {
              if (step !== 1 && interval > 0) {
                clearInterval(interval);
                setResentInterval(-1);
                interval = -1;
                resentInterval = 1;
              }

              setCreateSpaceStep(step);
              setMessage(msg);
            }
          );
          window.mailService.web3StorageClient.setCurrentSpace(space.did());
        }
      }
    } catch (err) {
      console.error(err);
      console.log("failed to register: ", err.message || err);
      throw err;
      // if (err.message === 'No space selected') {
      //   try {
      //     await actions.createSpace('pleximail')
      //     await actions.registerSpace(window.appConfig.web3StorageApiToken)
      //   } catch (err) {
      //     throw new Error('failed to register', { cause: err })
      //   }
      // } else if (err.message === 'Space already registered with web3.storage.') {
      //   console.log(err.message)
      // } else if (err.message.indexOf('no proofs available for resource') !== -1) {
      //   console.error(err);
      // } else {
      //   throw new Error('failed to register', { cause: err })
      // }
    } finally {
      if (interval !== -1) {
        clearTimeout(interval);
        interval = -1;
      }
    }
  }

  const initWeb3Storage = async () => {
    await w3Authenticate();
    if (resentInterval !== -1) {
      clearInterval(resentInterval);
      setResentInterval(-1);
    }
    setMessage(null);

    await window.mailService.initWeb3StorageClient(
      window.appConfig.web3StorageApiToken
    );
    await window.mailService.initFoldersIfNeeded(false);
  };

  const reset = () => {
    window.mailService
      .reset(true)
      .then(() => {
        return window.wallet.reset(true);
      })
      .then(() => {
        setSetupWizardState(SetupWizardState.ConnectWallet);
        window.appConfig.setupWizardState = SetupWizardState.ConnectWallet;
        window.forceQuit = true;
        setTimeout(function () {
          window.location.reload(true);
        });
      })
      .catch((e) => {
        console.error(e);
        showError(e);
      });
  };

  function backClicked() {
    reset();
  }

  const create = async () => {
    try {
      setInitializing(true);
      if (window.mailService.isCreating) {
        return;
      } else {
        window.mailService.isCreating = true;
      }
      setInitializing(true);

      await connectWallet();

      await initWeb3Storage();

      await initialSignalContext();

      await window.wallet.showReBackup();

      await gotoMain();
    } catch (e) {
      console.error(e);
      setMessage(null);
      await window.mailService.resetSignalServiceFor(selectedAccount);
      showError(e);
    } finally {
      window.mailService.isCreating = false;
      setInitializing(false);
    }
  };

  async function initialSignalContext() {
    const isInitialized =
      window.mailService.signalIsInitialized(selectedAccount);
    if (isInitialized) {
      await window.mailService.resetSignalServiceFor(selectedAccount);
    }

    await window.mailService.initSignalService(selectedAccount, (step) => {
      switch (step) {
        case SignalInitStep.CheckPreKeyCount:
          setMessage(Strings.common.message.supply_prekeys);
          break;
        case SignalInitStep.GenerateKeys:
          setMessage(Strings.common.message.generate_keys);
          break;
        case SignalInitStep.SavePreKeyBundle:
          setMessage(Strings.common.message.save_prekey);
          break;
        case SignalInitStep.SaveIdentityKey:
          setMessage(Strings.common.message.save_idkey);
          break;
        // case SignalInitStep.WaitTransactionReceipt:
        //     setProgress(Strings.common.message.wait_tx_receipt);
        //     break;
        case SignalInitStep.SaveSignalProtocolStore:
          setMessage(Strings.common.message.save_signal);
          break;
        case SignalInitStep.Completed:
          setMessage(Strings.common.message.completed);
          break;
        default:
          showError(ClientError.invalidParameterError("Invalid step"));
          break;
      }
    });
  }

  const gotoMain = async () => {
    window.appConfig.removeTempMasterKey();
    window.appConfig.removeTempMnemonic();
    window.appConfig.removeTxHash();
    window.appConfig.removeCouponInfo();
    window.appConfig.removeTempCryptonInfo();
    window.appConfig.removeSetupFromRecovery();
    window.appConfig.isNewCreatedUser = false;
    window.appConfig.plexiMailStatus = PlexiMailStatus.Ready;
    window.wallet.removePinFromSessionStorageIfNeeded();
    setSetupWizardState(SetupWizardState.Completed);
    window.isUnlocked = true;

    await window.mailService.syncFoldersFile();
  };

  return (
    <Box position={"relative"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
        >
          <Stack spacing={4}>
            <Heading
              color={"gray.800"}
              lineHeight={1.1}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              {/* <Text
                            as={'span'}
                            bgGradient="linear(to-r, red.400, pink.400)"
                            bgClip="text">
                                {Strings.wait.default.step} &nbsp;
                        </Text>  */}

              {Strings.on_click_create.title}
            </Heading>
            {/* <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
                            {Strings.one_click_resume.description}
                        </Text>
                        <Text mt={5} color={'orange'} fontSize={{ base: 'sm', sm: 'md' }}>
                            {Strings.one_click_resume.note}
                        </Text> */}

            {isInitializing && (
              <>
                <HStack>
                  <Spinner color={"#00aaff"} size={"sm"} />
                  <Text
                    color={"#00aaff"}
                    fontWeight={"bold"}
                    fontSize={{ base: "lg", sm: "lg" }}
                  >
                    {Strings.on_click_create.loading}
                  </Text>
                </HStack>
                {createSpaceStep === 1 && (
                  <Text color={"gray"} fontSize={{ base: "sm", sm: "sm" }}>
                    {message}
                  </Text>
                )}
                {createSpaceStep !== 1 && (
                  <Text color={"gray"} fontSize={{ base: "sm", sm: "sm" }}>
                    {message}
                  </Text>
                )}
              </>
            )}

            {/* <HStack>
                  <Spinner color={"#00aaff"} size={"sm"} />
                  <Text
                    color={"#00aaff"}
                    fontWeight={"bold"}
                    fontSize={{ base: "lg", sm: "lg" }}
                  >
                    {"Newly created account is initializing"}
                  </Text>
                </HStack> */}

            {/* <Text color={"gray"} fontSize={{ base: "sm", sm: "sm" }}>
                  {message}
                </Text> */}
          </Stack>
          <Box as={"form"} mt={10}>
            {/* <Button
                            fontFamily={'heading'}
                            mt={8}
                            w={'full'}
                            bgGradient="linear(to-r, red.400,pink.400)"
                            color={'white'}
                            _hover={{
                                bgGradient: 'linear(to-r, red.400,pink.400)',
                                boxShadow: 'xl',
                            }}
                            disabled={isRunning}
                            onClick={retryClicked}>
                            Retry
                        </Button> */}

            <Button
              fontFamily={"heading"}
              mt={4}
              w={"full"}
              bgColor={"gray.200"}
              disabled={isInitializing}
              // bgGradient="linear(to-r, red.400,pink.400)"
              color={"black"}
              // _hover={{
              //     bgGradient: 'linear(to-r, red.400,pink.400)',
              //     boxShadow: 'xl',
              // }}
              onClick={() => {
                backClicked();
              }}
            >
              {Strings.on_click_create.button.back}
            </Button>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
