import { ethers } from "ethers";
import { ClientError } from "../common/errors";
import {MailEncryptionType} from '../utils/Types';
import { v4 as uuidv4 } from 'uuid';
// import { bufferToHex } from "ethereumjs-util";
import Strings from "../config/Strings";
import { HDKey } from "ethereum-cryptography/hdkey";
import { wordlist } from "ethereum-cryptography/bip39/wordlists/english";
import * as bip39 from "ethereum-cryptography/bip39";
// import { keys, PrivateKey, PublicKey } from 'libp2p-crypto';
import { keys } from 'libp2p-crypto';

 
// import * as CryptoJS from "crypto-js";
/*
export class CryptoService {
    
    constructor() {
        console.log('CryptoService');    
    }

    // wordArrayToUint8Array(wordArray) {                                                                                       
    //     const l = wordArray.sigBytes;                                                                                                        
    //     const words = wordArray.words;                                                                                                       
    //     const result = new Uint8Array(l);                                                                                                    
    //     var i=0, j=0;
    //     while(true) {
    //         // here i is a multiple of 4
    //         if (i===l)
    //             break;
    //         var w = words[j++];
    //         result[i++] = (w & 0xff000000) >>> 24;
    //         if (i===l)
    //             break;
    //         result[i++] = (w & 0x00ff0000) >>> 16;                                                                                            
    //         if (i===l)                                                                                                                        
    //             break;                                                                                                                       
    //         result[i++] = (w & 0x0000ff00) >>> 8;
    //         if (i===l)
    //             break;
    //         result[i++] = (w & 0x000000ff);                                                                                                  
    //     }
    //     return result;
    // }

    async encryptFolder() {

    }
    async decryptFolder() {

    }
    
    async encryptMailPart(part) {
        if (typeof part === 'undefined') {
            throw ClientError.invalidDataTypeError('data to encrypt could not be undefined');
        } else if (part === null) {
            throw ClientError.invalidDataTypeError('data to encrypt could not be null');
        } else if (typeof part === 'string') {

        } else if (part.constructor === ArrayBuffer) {

        } else if (part.constructor === Uint8Array) {

        } else if (part.constructor === Array) {

        }
        
    }

    async encrypt(key, plaintext) {

    }
    async decrypt(key, ciphertext) {

    }
}
*/
export class CryptoService {
    static get KEY_USAGE() {
        return {
            encrypt: 'encrypt',
            decrypt: 'decrypt',
            sign: 'sign',
            verify: 'verify',
            deriveKey: 'deriveKey',
            deriveBits: 'deriveBits',
            wrapKey: 'wrapKey',
            unwrapKey: 'unwrapKey'
        };
    }
    static get EXTRACTABLE() {
        return true;
    }
    static get KEY_FORMAT() {
        return {
            jwk: 'jwk',
            raw: 'raw'
        };
    }
    static get PBKDF2() {
        return 'PBKDF2';
    }
    static get ITERATIONS() {
        return 10000;
    }
    static get SHA_256() {
        return 'SHA-256';
    }
    static get AES_GCM() {
        return 'AES-GCM';
    }
    static get BITS_96() {
        return 96;
    }
    static get BITS_128() {
        return 128;
    }
    static get BITS_192() {
        return 192;
    }
    static get BITS_256() {
        return 256;
    }
    static get BYTES_12() {
        return CryptoService.BITS_96 / 8;
    }
    static get BYTES_16() {
        return CryptoService.BITS_128 / 8;
    }
    static get BYTES_32() {
        return CryptoService.BITS_256 / 8;
    }
    hdkey = null;
    zero_aid() {
        return 0;
    }
    async #walletCryptoServiceCall(params) {
        const res = await window.wallet.rpcProvider.request({method: 'af_cryptoService', params: params});
        return res;
    }

    async init(mnemonic) {
        if (!window.wallet || !window.wallet.asDefaultWallet) {
            const hdkey = await this.hdKeyFromMnemonic(mnemonic);
            this.hdkey = hdkey;
        }
    }

    get isInitialized() {
        if (window.wallet && window.wallet.asDefaultWallet) {
            return true;
        }
        
        return this.hdkey !== null;
    }

    async generateMnemonic() {
        const promise = new Promise((resolve, reject) => {
            try {
                const mnemonic = bip39.generateMnemonic(wordlist, 256);
                resolve(mnemonic);
            } catch (e) {   
                reject(e);
            }

        });
        return promise;
    }
    async validateMnemonic(mnemonic) {
        const promise = new Promise((resolve, reject) => {
            try {
                const validated = bip39.validateMnemonic(mnemonic, wordlist);
                resolve(validated);
            } catch (e) {   
                reject(e);
            }
        });
        return promise;
    }
    
    async mnemonicToSeed(mnemonic) {
        return bip39.mnemonicToSeed(mnemonic)
    }
    

    async hdKeyFromMnemonic(mnemonic) {
        const seed = await this.mnemonicToSeed(mnemonic);
        const promise = new Promise((resolve, reject) => {
            try {
                const hdkey = HDKey.fromMasterSeed(seed);
                resolve(hdkey);
            } catch (e) {   
                reject(e);
            }
        });
        return promise;
    }
    async generateKeyV2(aid, mid, pid=0) {
        aid = this.zero_aid(aid);

        if (window.wallet && window.wallet.asDefaultWallet) {
            return await this.#walletCryptoServiceCall(['getMailEncryptionKey', aid, mid, pid]);
        }
        if (!this.hdkey) {
            throw ClientError.invalidParameterError('HDKey not ready');
        }
        const hdkey = this.hdkey.derive("m/84'/" + aid + "'/" + mid + "'/" + pid +"'");
        const key = await window.crypto.subtle.digest('SHA-256', hdkey.privateKey);
        const aesKey = await window.crypto.subtle.importKey('raw', key, 'AES-GCM', true, ['encrypt', 'decrypt']);
        // console.log(key);
        return aesKey;
    }

    async enterpriseContactIpnsSignKey(aid) {
        aid = this.zero_aid(aid);
        if (window.wallet && window.wallet.asDefaultWallet) {
            return await this.#walletCryptoServiceCall(['getEnterpriseContactIpnsSignKey', aid]);
        }

        if (!this.hdkey) {
            throw ClientError.invalidParameterError('HDKey not ready');
        }
        const hdkey = this.hdkey.derive("m/85'/7'/" + aid +"'");
        const seedBuffer = await window.crypto.subtle.digest('SHA-256', hdkey.privateKey);
        const seed = new Uint8Array(seedBuffer);

        const key = await keys.generateKeyPairFromSeed('Ed25519', seed, 1024);
        return key;
    }

    async mailFoldersIpnsSignKey(aid) {
        aid = this.zero_aid(aid);
        if (window.wallet && window.wallet.asDefaultWallet) {
            return await this.#walletCryptoServiceCall(['getFolderIpnsSignKey', aid]);
        }

        if (!this.hdkey) {
            throw ClientError.invalidParameterError('HDKey not ready');
        }
        const hdkey = this.hdkey.derive("m/85'/0'/" + aid +"'");
        const seedBuffer = await window.crypto.subtle.digest('SHA-256', hdkey.privateKey);
        const seed = new Uint8Array(seedBuffer);

        const key = await keys.generateKeyPairFromSeed('Ed25519', seed, 1024);
        return key;
    }

    async mailFoldersEncryptionKey(aid) {
        aid = this.zero_aid(aid);
        if (window.wallet && window.wallet.asDefaultWallet) {
            return await this.#walletCryptoServiceCall(['getFolderEncryptionKey', aid]);
        }
        if (!this.hdkey) {
            throw ClientError.invalidParameterError('HDKey not ready');
        }
        const hdkey = this.hdkey.derive("m/85'/1'/" + aid + "'");
        const key = await window.crypto.subtle.digest('SHA-256', hdkey.privateKey);
        const aesKey = await window.crypto.subtle.importKey('raw', key, 'AES-GCM', true, ['encrypt', 'decrypt']);
        // console.log(key);
        return aesKey;
    }
    
    async indexedDBEncryptionKey() {
        if (window.wallet && window.wallet.asDefaultWallet) {
            return await this.#walletCryptoServiceCall(['getIndexedDBEncryptionKey']);
        }

        if (!this.hdkey) {
            throw ClientError.invalidParameterError('HDKey not ready');
        }
        const hdkey = this.hdkey.derive("m/86'/0'/0'");
        const key = await window.crypto.subtle.digest('SHA-256', hdkey.privateKey);
        const aesKey = await window.crypto.subtle.importKey('raw', key, 'AES-GCM', true, ['encrypt', 'decrypt']);
        // console.log(key);
        return aesKey;
    }
    
    async generateUUID() {
        const uuid = uuidv4();
        // const uuid = window.crypto.randomUUID();
        return uuid;
    }
    generateUUIDSync() {
        const uuid = uuidv4();
        // const uuid = window.crypto.randomUUID();
        return uuid;
    }
    async generateSalt() {
        const CS = CryptoService;
        const salt = new Uint8Array(CS.BYTES_16);
        window.crypto.getRandomValues(salt);
        return salt;
    }

    async generateKey() {
        const CS = CryptoService;
        const KU = CS.KEY_USAGE;
        const key = await window.crypto.subtle.generateKey(
            {
                name: CS.AES_GCM,
                length: CS.BITS_256, //can be  128, 192, or 256
            },
            CS.EXTRACTABLE, //whether the key is extractable (i.e. can be used in exportKey)
            [KU.encrypt, KU.decrypt] //can be "encrypt", "decrypt", "wrapKey", or "unwrapKey"
        );
        return key;
    }

    async generatePairKey() {
        const CS = CryptoService;
        const pairKey = new Uint8Array(CS.BYTES_32);
        window.crypto.getRandomValues(pairKey);
        const hex = Buffer.from(pairKey.buffer, pairKey.byteOffset, pairKey.byteLength).toString('hex');
        return hex;
    }

    async getBaseKey(password) {
        const CS = CryptoService;
        const KU = CS.KEY_USAGE;
        const enc = new TextEncoder();
        const key = await window.crypto.subtle.importKey(
            CS.KEY_FORMAT.raw,
            enc.encode(password),
            CS.PBKDF2,
            false,
            [KU.deriveBits, KU.deriveKey],
        );
        return key;
    }

    async deriveKey(password, salt) {
        const CS = CryptoService;
        const KU = CS.KEY_USAGE;
        const baseKey = await this.getBaseKey(password);
        const key = await window.crypto.subtle.deriveKey(
            {
                name: CS.PBKDF2,
                salt: salt,
                iterations: CS.ITERATIONS,
                hash: CS.SHA_256,
            },
            baseKey,
            { 
                name: CS.AES_GCM, 
                length: CS.BITS_256 
            },
            CS.EXTRACTABLE,
            [KU.encrypt, KU.decrypt],
        );
        return key;
    }

    async importKey(jwk) {
        const CS = CryptoService;
        const KU = CS.KEY_USAGE;
        const key = await window.crypto.subtle.importKey(
            CS.KEY_FORMAT.jwk, 
            jwk, 
            CS.AES_GCM, 
            CS.EXTRACTABLE, 
            [KU.encrypt, KU.decrypt]
        );
        return key;
    }

    async exportKey(key) {
        const CS = CryptoService;
        const jwk = await window.crypto.subtle.exportKey(CS.KEY_FORMAT.jwk, key);
        return jwk;
    }

    async encryptMnemonic(password, mnemonic) {
        const key = await this.createEncryptionKey(0, 0, 0, password, null);
        if (key.type !== MailEncryptionType.password) {
            throw ClientError.invalidParameterError('invalid key, key is not derive with kdf');
        }
        const encoder = new TextEncoder();
        const plaintext = encoder.encode(mnemonic);
        
        const ciphertext = await this.encrypt(key.key, plaintext);
        const mnemonicObj = {
            iv: ethers.encodeBase64(ciphertext.iv),
            ciphertext: ethers.encodeBase64(ciphertext.ciphertext)
        };

        const result = {
            kdf: {
                salt: key.salt
            },
            mnemonic: mnemonicObj
        }
        const jsonResult = JSON.stringify(result);
        return jsonResult;
    }

    async decryptMnemonic(password, encryptedMnemonic) {
        const result = JSON.parse(encryptedMnemonic);
        const key = await this.createEncryptionKey(0, 0, 0, password, result.kdf.salt);
        const mnemonicObj = {
            iv: ethers.decodeBase64(result.mnemonic.iv),
            ciphertext: ethers.decodeBase64(result.mnemonic.ciphertext),
        };
        const mnemonic = await this.decryptMailPart(key.key, mnemonicObj, true);
        return mnemonic;
    }

    async createEncryptionKey(aid, mid, pid=0, password=null, salt=null) {
        aid = this.zero_aid(aid);
        const CS = CryptoService;
        if (password) {
            let saltBase64 = null;
            if(salt) {
                if (typeof salt === 'string') {
                    saltBase64 = salt;
                    salt = ethers.decodeBase64(salt);
                } else {
                    saltBase64 = ethers.encodeBase64(salt);
                }
            } else {
                salt = new Uint8Array(CS.BYTES_16);
                window.crypto.getRandomValues(salt);
                saltBase64 = ethers.encodeBase64(salt);
            }
            const key = await this.deriveKey(password, salt);
            return {type: MailEncryptionType.password, key: key, salt: saltBase64};
        } else {
            const key = await this.generateKeyV2(aid, mid, pid);
            return {type: MailEncryptionType.signal, key: key};
        }
    }

    async encryptFolders(key, folders) {
        return folders;
    }

    async decryptFolders(key, foldersCiphertext) {
        return foldersCiphertext;
    }
    
    async encryptMailPart(key, part) {
        const self = this;
        let plaintext = null;
        if (typeof part === 'undefined') {
            throw ClientError.invalidParameterError(Strings.error.client.enc_data_undefined);
        } else if (part === null) {
            throw ClientError.invalidParameterError(Strings.error.client.enc_data_null);
        } else if (typeof part === 'string') {
            const encoder = new TextEncoder();
            plaintext = encoder.encode(part);
        } else if (part.constructor === ArrayBuffer) {
            plaintext = new Uint8Array(part);
        } else if (part.constructor === Uint8Array) {
            plaintext = part;
        } else if (part.constructor === Array) {
            plaintext = Uint8Array.from(part);
        } else {
            throw ClientError.invalidDataTypeError(Strings.error.client.unsupported_data_type_1 + part + Strings.error.client.unsupported_data_type_2);
        }
        
        if (plaintext == null) {
            throw ClientError.invalidParameterError(Strings.error.client.plaintext_null);
        }

        const res = await self.encrypt(key, plaintext);
        return res;

    }

    async decryptMailPart(key, partCiphertext, asString=false) {
        const self = this;
        const plaintext = await self.decrypt(key, partCiphertext);
        if (asString) {
            const decoder = new TextDecoder();
            const plaintextString = decoder.decode(plaintext);
            return plaintextString;
        }
        return plaintext;
    }

    async encrypt(key, plaintext) {
        const CS = CryptoService;
        // window.crypto.subtle.exportKey('jwk|raw', aKey).then(k => console.log(k))
        // window.crypto.subtle.importKey('jwk|raw', aKey, 'AES-CBC', true, ['encrypt', 'decrypt']).then(k => aKey)
        // window.crypto.subtle.generateKey({ name: "AES-CBC", length: 256}, true, ["encrypt", "decrypt", "wrapKey", "unwrapKey"])
        // const iv = window.crypto.getRandomValues(new Uint8Array(16));
        const iv = window.crypto.getRandomValues(new Uint8Array(CS.BYTES_12));
        // {name: 'AES-GCM', iv: iv, tagLength: 128, additionalData: ArrayBuffer,}
        const ciphertext = await window.crypto.subtle.encrypt(
            {
                name: CS.AES_GCM, 
                iv: iv, 
                tagLength: CS.BITS_128
            }, 
            key, 
            plaintext
        );
        
        return {
            iv,
            ciphertext: new Uint8Array(ciphertext)
        };
    }

    async decrypt(key, {iv, ciphertext}) {
        const CS = CryptoService;
        if (ciphertext.constructor === Uint8Array) {
            ciphertext = ciphertext.buffer;
        }
        
        const plaintext = await window.crypto.subtle.decrypt(
            {
                name: CS.AES_GCM, 
                iv: iv, 
                tagLength: CS.BITS_128
            }, 
            key, 
            ciphertext
        );
        return plaintext;
    }
}