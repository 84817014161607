import BaseRPCHandler from "./BaseRPCHandler";

export default class AFEncryptMnemonicHandler extends BaseRPCHandler {
    constructor() {
        super('af_encryptMnemonic');
    }
    
    async handle(provider, request) {
        /**
         * createCrypton
         * generateKeyV2
         * mailFoldersIpnsSignKey
         * mailFoldersEncryptionKey
         * indexedDBEncryptionKey
         */
    }
    
}