// import './App.css';
import React, { useEffect, useState /*, useCallback, useEffect*/ } from "react";

import { List, ListItem, Avatar, HStack, VStack, Text, useColorModeValue, Flex, Button, Checkbox } from "@chakra-ui/react";

// import * as ReactIcons from 'react-icons/fa';
// import mailService from '../services/MailService';
import ContentLoader from "react-content-loader";
//import MailAddressUtils from '../utils/MailAddressUtils';
import { MessageFlag, MailEncryptionType } from "../utils/Types";
import { INBOX_FOLDER, SENT_FOLDER } from "../common/constants";
import { FiEyeOff, FiEye, FiSearch } from "react-icons/fi";
import { showError, /*showInfo, */ showSuccess } from "../common/errors";
// import MailAddressUtils from "../utils/MailAddressUtils";
import CopyToClipboard from "react-copy-to-clipboard";
import WelcomeScreen from "./WelcomeScreen";
import Strings from "../config/Strings";
// import copy from 'copy-to-clipboard'

const MailListLoader = (props) => (
  <ContentLoader
    speed={2}
    width={400}
    height={150}
    viewBox="0 0 400 150"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="10" cy="20" r="8" />
    <rect x="25" y="15" rx="5" ry="5" width="300" height="10" />
    <circle cx="10" cy="50" r="8" />
    <rect x="25" y="45" rx="5" ry="5" width="300" height="10" />
    <circle cx="10" cy="80" r="8" />
    <rect x="25" y="75" rx="5" ry="5" width="300" height="10" />
    <circle cx="10" cy="110" r="8" />
    <rect x="25" y="105" rx="5" ry="5" width="300" height="10" />
  </ContentLoader>
);
const MailList = ({
  selectedAccount,
  setFolders,
  messages,
  message,
  setMessage,
  searchDisclosure
}) => {
  // const mailService = new MailService();
  // mailService.initFolders().then((cid) => {
  // });
  /*
    cid: 'bafybeieslzbdzryrmxivo65gd4lhpxpijb27sfrvd3bdakscpsyizf2bwa',
    from: 'support' + mailAddressSuffix(),
    sendDate: '2022-10-11 10:21:22',
    flags: 0,
    subject: 'Welcome to PlexiMail', 
  */
  console.log(">> MailList");
  const [password, setPassword] = useState(null);
  const [withPlexiSignMessages, setWithPlexiSignMessages] = useState(false);

  const filteredMessages = withPlexiSignMessages ? messages : messages.filter((message) => {
    return typeof message.ct === 'undefined' || message.ct === null || !message.ct || message.ct.length === 0;
  });
  
  useEffect(() => {
    window.showMessage = showMessage;
  }, [])// eslint-disable-line react-hooks/exhaustive-deps
  const getCurrentFolder = () => {
    return window.mailService
      ? window.mailService.currentFolder || INBOX_FOLDER
      : INBOX_FOLDER;
  };

  function showMessage(message) {
    setMessage(message);
  }

  function fontWeightWithMessageFlags(message) {
    if (typeof message.flags !== "undefined") {
      const flags = message.flags || MessageFlag.MessageFlagNone;
      const seen =
        (flags & MessageFlag.MessageFlagSeen) === MessageFlag.MessageFlagSeen;
      return seen ? "normal" : "bold";
    }
    return message.copied ? "normal" : "bold";
  }
  const bg = useColorModeValue("white", "gray.900");
  const borderBottomColor = useColorModeValue("gray.200", "gray.700")
  if (
    filteredMessages &&
    filteredMessages.length === 0 
    // && getCurrentFolder() === INBOX_FOLDER
  ) {
    return <>
    
    <Flex
      hidden={Boolean(message)}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="left"
      bg={bg}
      borderBottomWidth="1px"
      borderBottomColor={borderBottomColor}
      justifyContent={{ base: "start", md: "start" }}>
        <HStack
          spacing={{ base: "0", md: "6" }}
          w={"100%"}
          justifyContent={"flex-end"}
        >
          {(getCurrentFolder() === SENT_FOLDER) &&<Checkbox isChecked={withPlexiSignMessages} colorScheme="green" fontSize={"sm"} onChange={(e) => {
              setWithPlexiSignMessages(e.target.checked);
            }}>
            <Text fontSize={"sm"} textDecoration={'underline'} color="gray">Includes PlexiSign Messages</Text>
          </Checkbox>}
          <Button
            variant={"outline"}
            leftIcon={<FiSearch />}
            colorScheme={"green"}
            isDisabled={true}
            // leftIcon={<ReactIcons.FaArchive  />}
            onClick={() => {
              searchDisclosure.onOpen()
            }}
          >
            {Strings.app.dialog.search.search}
          </Button>
        </HStack>
    </Flex>
    <WelcomeScreen />;
    </>
  }
  return (
    <>
    <Flex
      hidden={Boolean(message)}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="left"
      bg={bg}
      borderBottomWidth="1px"
      borderBottomColor={borderBottomColor}
      justifyContent={{ base: "start", md: "start" }}>
        <HStack
          spacing={{ base: "0", md: "6" }}
          w={"100%"}
          justifyContent={"flex-end"}
        >
          {(getCurrentFolder() === SENT_FOLDER) &&<Checkbox isChecked={withPlexiSignMessages} colorScheme="green" fontSize={"sm"}  onChange={(e) => {
              setWithPlexiSignMessages(e.target.checked);
            }}>
            <Text fontSize={"sm"}>Includes PlexiSign Messages</Text>
          </Checkbox>}
          <Button
            variant={"outline"}
            leftIcon={<FiSearch />}
            colorScheme={"green"}
            // leftIcon={<ReactIcons.FaArchive  />}
            onClick={() => {
              searchDisclosure.onOpen()
            }}
          >
            {Strings.app.dialog.search.search}
          </Button>
        </HStack>
    </Flex>
    <List
      hidden={Boolean(message)}
      spacing={0}
      textColor="gray.600"
      fontSize={"12px"}
      id={"plexi-mail-list"}
    >
      {filteredMessages &&
        filteredMessages.map((message) => {
          let address = null;
          let hasMoreAddress = false;
          let name = "";
          if (getCurrentFolder() === SENT_FOLDER) {
            if (message.to && message.to.length > 0) {
              // address = MailAddressUtils.formatAddress(message.to[0]);
              //const to = message.to[0]; 
              
              if (
                message.decryptedHeader &&
                message.decryptedHeader.to &&
                message.decryptedHeader.to.length > 0
              ) {
                address = message.decryptedHeader.to[0];
                if (message.decryptedHeader.to.length > 1) {
                  hasMoreAddress = true;
                }
              } else {
                const to = message.to[0]; 
                address = to[0];
                if (to.length > 1) {
                  hasMoreAddress = true;
                }
              }
            } else {
              if (
                message.decryptedHeader &&
                message.decryptedHeader.to &&
                message.decryptedHeader.to.length > 0
              ) {
                address = message.decryptedHeader.to[0];
                if (message.decryptedHeader.to.length > 1) {
                  hasMoreAddress = true;
                }
              }
            }
          } else {
            address = message.from;
          }
          if (address) {
            name = window.mailService.contactNameSync(address, selectedAccount);
            // name = window.mailService.mapContact(address, selectedAccount)
            // const addressObj = MailAddressUtils.parseOneAddress(address);
            // name = addressObj.name ? addressObj.name : addressObj.address;
            if (hasMoreAddress) {
              name += "...";
            }
          }
          return (
            <ListItem
              key={message.uid}
              paddingTop={1}
              paddingBottom={1}
              borderBottom={"1px solid lightGray"}
              cursor={"pointer"}
              onClick={() => {
                showMessage(message);
              }}
            >
              <HStack>
                <Avatar position={"unset"} name={name} textColor={"white"} />
                <VStack align={"left"}>
                  <HStack>
                    <Text
                      wordBreak={"break-all"}
                      fontWeight={fontWeightWithMessageFlags(message)}
                    >
                      {name}
                    </Text>
                    <Text>{new Date(message.sendDate).toLocaleString()}</Text>
                  </HStack>
                  <Text fontWeight={fontWeightWithMessageFlags(message)}>
                    {message.decryptedHeader
                      ? message.decryptedHeader.subject
                      : "This is an unread PlexiMail"}
                  </Text>
                  {message.type !== MailEncryptionType.signal && (
                    <>
                      {password &&
                        password.uid === message.uid &&
                        password.password &&
                        password.password.length > 0 && (
                          <HStack
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                          >
                            <CopyToClipboard
                              text={password.password}
                              onCopy={() => {
                                showSuccess("Copied");
                              }}
                            >
                              <Text
                                color={"black"}
                                background={"gray.100"}
                                // onClick={(e) => {
                                //     e.stopPropagation();
                                //     e.preventDefault();
                                //     copy(password.password, {onCopy: () => {
                                //         showSuccess('Copied')
                                //     }})
                                // }}
                              >
                                {password.password}
                              </Text>
                            </CopyToClipboard>
                            <FiEyeOff
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setPassword(null);
                              }}
                            />
                          </HStack>
                        )}
                      {getCurrentFolder() === SENT_FOLDER &&
                        (!password ||
                          password.uid !== message.uid ||
                          !password.password ||
                          password.password.length === 0) && (
                          <HStack>
                            <Text>******</Text>
                            <FiEye
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                window.mailService.signalService
                                  .getEncryptionKeyBundle(message.uid)
                                  .then((keyBundle) => {
                                    setPassword({
                                      uid: message.uid,
                                      password: keyBundle.pwd,
                                    });
                                  })
                                  .catch((e) => {
                                    setPassword(null);
                                    console.error(e);
                                    showError(e);
                                  });
                              }}
                            />
                          </HStack>
                        )}
                    </>
                  )}

                  {/* <Text>Hello DESKTOP-MC9AD8L, Your account was used to sign in on this device recently: 2022-06-13 09:30:13 UTC TeamViewer ID: 552707324</Text> */}
                </VStack>
              </HStack>
            </ListItem>
          );
        })}
      {!filteredMessages && <MailListLoader />}
    </List>
    </>
  );
};

export default MailList;
