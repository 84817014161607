// import './App.css';
import React, { useState } from "react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Input,
  Spinner,
  Text,
} from "@chakra-ui/react";

import { ClientError, showError, showInfo } from "../common/errors";
import Strings from "../config/Strings";

function CouponCodeAlert({ disclosure, onComplete }) {
  const [processing, setProcessing] = useState(false);
  const [couponCode, setCouponCode] = useState(null);
  const [statusString, setStatusString] = useState(null);
  const handleCouponCode = () => {
    setProcessing(true);
    setStatusString(Strings.app.dialog.promo_code.redeeming);
    window.mailService
      .useCouponCode(couponCode)
      .then((result) => {
        setStatusString(Strings.app.dialog.promo_code.waiting);
        return window.mailService
          .waitFaucetTransactionReceiptMined(result.ethTx.hash)
          .then((success) => {
            if (!success) {
              throw ClientError.txError(Strings.app.dialog.promo_code.rejected);
            }
            return result;
          });
      })
      .then((result) => {
        // setStatusString('Waiting for transaction receipt ...');
        // setStatusString('Completed!');
        window.appConfig.couponStorage = result.web3.token;
        window.appConfig.couponTx = result.ethTx.hash;

        setProcessing(false);
        setCouponCode(null);
        setStatusString(null);
        disclosure.onClose();
        if (onComplete) {
          onComplete();
        }
        showInfo(Strings.app.dialog.promo_code.redeemed);
      })
      .catch((e) => {
        setProcessing(false);
        setCouponCode(null);
        setStatusString(null);
        showError(e);
      });
  };
  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {Strings.app.dialog.promo_code.title}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>{Strings.app.dialog.promo_code.description}</Text>
            <Input
              mt={3}
              onChange={(e) => {
                setCouponCode(e.target.value);
              }}
              placeholder={"Please enter promo code here"}
              type={"text"}
            />
            {statusString && (
              <HStack mt={3}>
                <Spinner />
                <Text
                  as={"span"}
                  color={"gray.500"}
                  fontSize={{ base: "sm", sm: "md" }}
                >
                  {statusString}
                </Text>
              </HStack>
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              disabled={processing}
              colorScheme={"gray"}
              mr={3}
              onClick={() => {
                setProcessing(false);
                setCouponCode(null);
                disclosure.onClose();
                // onCompleted(null);
              }}
              ml={3}
            >
              {Strings.app.dialog.promo_code.cancel}
            </Button>
            <Button
              disabled={processing || !couponCode || couponCode === ""}
              isLoading={processing}
              colorScheme={"green"}
              onClick={() => {
                handleCouponCode();
              }}
            >
              {Strings.app.dialog.promo_code.redeem}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default CouponCodeAlert;
