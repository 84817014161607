export default class TorUtils {
    

    static async isIndexedDBSupported() {
        

        try {
            const promise = new Promise((resolve, reject) => {
                const openRequest = indexedDB.open('test_db', 1);
                openRequest.onupgradeneeded = function() {
                    const db = openRequest.result;
                    try {
                        if (!db.objectStoreNames.contains('store_1')) {
                            const objectStore = db.createObjectStore('store_1', {keyPath: 'id'}); // 创造它
                            if (objectStore == null) {
                                throw new Error('failed to create object store');
                            }
                        }
                    } catch (e) {
                        console.error('error: ', e);
                        reject(e);
                    }
                };
                
                openRequest.onerror = function() {
                    console.error("Error", openRequest.error);
                    reject(openRequest.error);
                };
            
                openRequest.onsuccess = function() {
                    let db = openRequest.result;
                    db.onversionchange = function() {
                        db.close();
                        reject(new Error("Database is outdated, please reload the page."));
                    };
                    resolve();
                };
            
                openRequest.onblocked = function() {
                    reject('request was blocked');
                };
            });
            await promise;
            return true;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    static async detect() {
        const res = await TorUtils.isIndexedDBSupported();
        if (res) {
            return {isTorBrowser: false, isIndexedDBSupported: true};
        }
        const isFirefox = (window.navigator.userAgent && window.navigator.userAgent.toLocaleLowerCase().indexOf('firefox') !== -1);
        return {isTorBrowser: isFirefox, isIndexedDBSupported: false};
    }

}