import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Button,
  SimpleGrid,
  Link,
  Spinner,
  HStack,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { SetupWizardState, PlexiMailStatus } from "../utils/Types";
import Strings from "../config/Strings";
// import { HELP_DOC_URL } from '../common/constants';
import { TutorialModalLink } from "./TutorialModalTrigger";
import { FiFrown } from "react-icons/fi";
import { FaCheck } from "react-icons/fa";

export default function WaitForTransactionReceiptScreen({
  selectedAccount,
  setSetupWizardState,
}) {
  console.log(">> WaitForTransactionReceiptScreen");

  let [isRunning, setRunning] = useState(false);
  const [txError, setTxError] = useState(null);
  useEffect(() => {
    waitForTransactionReceiptScreen();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const waitForTransactionReceiptScreen = () => {
    if (isRunning) {
      return;
    }
    isRunning = true;
    setRunning(true);
    setTxError(null);

    window.mailService
      .waitTransactionReceiptMined(selectedAccount)
      .then(() => {
        isRunning = false;
        setRunning(false);
        window.mailService.waitTransactionReceiptTimer = setTimeout(() => {
          doneClicked();
        }, 10000);
      })
      .catch((e) => {
        if (
          window.mailService.waitTransactionReceiptTimer &&
          window.mailService.waitTransactionReceiptTimer > 0
        ) {
          clearInterval(window.mailService.waitTransactionReceiptTimer);
          window.mailService.waitTransactionReceiptTimer = null;
        }
        console.error(e);
        isRunning = false;
        setRunning(false);
        setTxError(e);
      });
  };

  const retryClicked = () => {
    waitForTransactionReceiptScreen();
  };

  const doneClicked = () => {
    if (
      window.mailService.waitTransactionReceiptTimer &&
      window.mailService.waitTransactionReceiptTimer > 0
    ) {
      clearInterval(window.mailService.waitTransactionReceiptTimer);
      window.mailService.waitTransactionReceiptTimer = null;
    }

    if (window.appConfig.setupWizardState === SetupWizardState.Completed) {
      return;
    }

    window.appConfig.removeTempMasterKey();
    window.appConfig.removeTempMnemonic();
    window.appConfig.removeTxHash();
    window.appConfig.removeCouponInfo();
    window.appConfig.removeTempCryptonInfo();
    window.appConfig.removeSetupFromRecovery();
    window.appConfig.isNewCreatedUser = false;
    window.appConfig.plexiMailStatus = PlexiMailStatus.Ready;

    window.wallet.removePinFromSessionStorageIfNeeded();

    window.isUnlocked = true;

    window.mailService
      .syncFoldersFile()
      .then(() => {
        setSetupWizardState(SetupWizardState.Completed);
      })
      .catch((e) => {});
      
  };

  return (
    <Box position={"relative"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
        >
          <Stack spacing={4}>
            <Heading
              color={"gray.800"}
              lineHeight={1.1}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              {/* <Text
                            as={'span'}
                            bgGradient="linear(to-r, red.400,pink.400)"
                            bgClip="text">
                                {Strings.wait.default.step} &nbsp;
                        </Text>  */}
              {Strings.wait.default.title}
            </Heading>
            <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
              {Strings.wait.default.content.paragraph1}&nbsp;
              <TutorialModalLink title={"Learn more"} anchor={"architecture"} />
              {/* <Link style={{color: '#00aaff'}} href={HELP_DOC_URL} target={"_blank"}>Learn more</Link> */}
            </Text>
            {txError && (
              <HStack>
                <FiFrown />
                <Text color={"red.500"} fontSize={{ base: "sm", sm: "md" }}>
                  {Strings.wait.default.content.paragraph3}
                </Text>
              </HStack>
            )}

            {!txError && isRunning && (
              <HStack>
                <Spinner />
                <Text
                  as={"span"}
                  color={"gray.500"}
                  fontSize={{ base: "sm", sm: "md" }}
                >
                  {Strings.wait.default.content.paragraph2}
                </Text>
              </HStack>
            )}

            {!txError && !isRunning && (
              <HStack>
                <FaCheck />
                <Text
                  as={"span"}
                  color={"gray.500"}
                  fontSize={{ base: "sm", sm: "md" }}
                >
                  {Strings.wait.default.content.paragraph4} or{" "}
                  <Link onClick={doneClicked} color={"#00aaff"}>
                    click here
                  </Link>
                </Text>
              </HStack>
            )}
          </Stack>
          <Box as={"form"} mt={10}>
            {txError && (
              <Button
                fontFamily={"heading"}
                mt={8}
                w={"full"}
                bgGradient="linear(to-r, red.400,pink.400)"
                color={"white"}
                _hover={{
                  bgGradient: "linear(to-r, red.400,pink.400)",
                  boxShadow: "xl",
                }}
                onClick={retryClicked}
              >
                {Strings.wait.default.button.retry.title}
              </Button>
            )}

            {/* {(!txError && !isRunning) &&
                        <Button
                            fontFamily={'heading'}
                            mt={8}
                            w={'full'}
                            bgGradient="linear(to-r, red.400,pink.400)"
                            color={'white'}
                            _hover={{
                                bgGradient: 'linear(to-r, red.400,pink.400)',
                                boxShadow: 'xl',
                            }}
                            disabled={isRunning}
                            onClick={doneClicked}>
                            {Strings.wait.default.button.enter.title}
                        </Button>
                        } */}
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
