/*
{
    chakra-ui-color-mode: "light"
    mail.folders-sign-key: "CAESQDq/dZ1UWDJmbWyW/WY3VHlskleHmLHA+3YeI5bdc2qNa2cPIyqbMsZhEIRjL36HiWWxo943FaNcQZZAZaXOPjw="
    mail.prekeys-sign-key: "CAESQE+Np11BRtZPXBH/DGw1oRV7KO9RWJSAQ3rGWo514RBiw9gR+rgQG7JEBfFkQuN66MncTe5eRk+etHflENB6WlE="
    mail.signal-context-sign-key: "CAESQEwmM2hToqbIZqXqVw6YawjXCliAtLW2cLTsXNGywbP3xZqnqM/uuQ6Y8UHeN6DVnvxkWXPdDpfN+5XIPhK0hfU="
    web3.storage-token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDVBMTMzNTJBNzcwYkE4M2M2YUU4ZTQwOWIyMTU4NGY1YjJhZmI3OGMiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2NTUyNjUzOTcwNDAsIm5hbWUiOiJFeGFtcGxlIn0.o89ztC7cQ3lyvqgI77phbL7Vz3_pZK0IbKIA-clZ-X4"
}
*/
import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  FormLabel,
  InputGroup,
  InputRightElement,
  IconButton,
  Progress,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  // Link,
} from "@chakra-ui/react";

import { useState } from "react";
import { MnemonicBackupMethod, SetupWizardState } from "../utils/Types";
import Strings from "../config/Strings";
import { TutorialModalLink } from "./TutorialModalTrigger";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { showError } from "../common/errors";
import zxcvbn from "zxcvbn";

function CoverConfirmationAlert({ disclosure, onCompleted }) {
  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {Strings.pwd.override.title}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>{Strings.pwd.override.message}</Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              colorScheme={"green"}
              onClick={() => {
                disclosure.onClose();
                onCompleted(false);
              }}
            >
              {Strings.pwd.override.button.no}
            </Button>
            <Button
              ml={4}
              colorScheme={"red"}
              onClick={() => {
                disclosure.onClose();
                onCompleted(true);
              }}
            >
              {Strings.pwd.override.button.yes}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

function MasterKeyConfigurator({
  setSetupWizardState,
  setupWizardState,
  selectedAccount,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(null);
  const [isInitializing, setInitializing] = useState(false);
  const [password, setPassword] = useState(null);
  const [confirmed, setConfirmed] = useState(false);
  const disclosure = useDisclosure({
    onOpen: () => {},
  });
  const colors = ["gray", "red", "pink", "orange", "green"];

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  function verifyPassword(e) {
    const password = e.target.value || "";
    if (password === "") {
      setPasswordStrength(null);
      setPassword(null);
      return;
    }
    // if (setupWizardState === SetupWizardState.EnterPassword) {
    //     setPassword(password);
    //     return;
    // }
    const r = zxcvbn(password);
    setPasswordStrength(r);
    setPassword(password);
  }

  function confirmPassword(e) {
    const confirm = e.target.value || "";
    setConfirmed(confirm === password);
  }

  async function savePassword(e, covered = false) {
    try {
      if (
        setupWizardState === SetupWizardState.EnterPassword &&
        window.appConfig.mnemonicBackupMethod ===
          MnemonicBackupMethod.Web3Storage
      ) {
        setInitializing(true);
        const existed = await window.mailService.checkCryptonToken(
          selectedAccount,
          password
        );
        setInitializing(false);
        if (!existed) {
          showError(
            "Token does not exist, please try again later with another password."
          );
          return;
        }
      } else if (
        setupWizardState === SetupWizardState.ConfigPassword &&
        window.appConfig.mnemonicBackupMethod ===
          MnemonicBackupMethod.Web3Storage
      ) {
        if (!covered) {
          setInitializing(true);
          const existed = await window.mailService.checkCryptonToken(
            selectedAccount,
            password
          );
          setInitializing(false);
          if (existed) {
            disclosure.onOpen();
            // showError("Token already existed, please try again with another password.");
            return;
          }
        }
      }

      window.appConfig.masterKey = password;
      window.appConfig.tempMasterKey = password;
      if (setupWizardState === SetupWizardState.EnterPassword) {
        setSetupWizardState(SetupWizardState.RestoreMnemonic);
      } else {
        setSetupWizardState(SetupWizardState.GenerateMnemonic);
      }
    } catch (e) {
      setInitializing(false);
      console.error(e);
      showError(e);
    }
  }
  function backClicked() {
    window.appConfig.removeTempMasterKey();
    if (setupWizardState === SetupWizardState.EnterPassword) {
      setSetupWizardState(SetupWizardState.SelectRecoveryMethod);
    } else {
      setSetupWizardState(SetupWizardState.SelectBackupMethod);
    }
  }

  return (
    <>
      <Stack spacing={4}>
        <Heading
          color={"gray.800"}
          lineHeight={1.1}
          fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
        >
          <Text
            as={"span"}
            bgGradient="linear(to-r, red.400,pink.400)"
            bgClip="text"
          >
            {setupWizardState === SetupWizardState.EnterPassword &&
              Strings.pwd.initial.step_restore}{" "}
            {setupWizardState === SetupWizardState.ConfigPassword &&
              Strings.pwd.initial.step}{" "}
            &nbsp;
          </Text>{" "}
          {setupWizardState === SetupWizardState.EnterPassword &&
            Strings.pwd.initial.recover_title}{" "}
          {setupWizardState === SetupWizardState.ConfigPassword &&
            Strings.pwd.initial.title}
        </Heading>

        <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
          {setupWizardState === SetupWizardState.ConfigPassword &&
            Strings.pwd.initial.content.paragraph1_1}
          {setupWizardState === SetupWizardState.EnterPassword &&
            Strings.pwd.initial.content.paragraph1_2}
        </Text>

        {setupWizardState === SetupWizardState.ConfigPassword && (
          <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
            {Strings.pwd.initial.content.paragraph2}&nbsp;
            <TutorialModalLink title={"Learn more"} />
          </Text>
        )}
      </Stack>
      <Box as={"form"} mt={10}>
        <Stack>
          <FormLabel htmlFor="country">
            {Strings.pwd.initial.tf.pwd.title}
          </FormLabel>
          <InputGroup>
            <Input
              autoComplete="off"
              type={showPassword ? "text" : "password"}
              onChange={verifyPassword}
            />
            <InputRightElement>
              {showPassword && (
                <IconButton icon={<FaEye />} onClick={togglePassword} />
              )}
              {!showPassword && (
                <IconButton icon={<FaEyeSlash />} onClick={togglePassword} />
              )}
            </InputRightElement>
          </InputGroup>
          {passwordStrength && (
            <>
              <Progress
                value={(passwordStrength.score + 1) * 20}
                colorScheme={colors[passwordStrength.score]}
                size="xs"
              />
              <Text
                fontSize={"12px"}
                align={"right"}
                color={colors[passwordStrength.score]}
              >
                {passwordStrength.feedback.warning}
              </Text>
            </>
          )}
        </Stack>
        <Stack
          mt={4}
          hidden={passwordStrength === null ? true : passwordStrength.score < 4}
        >
          <FormLabel htmlFor="country">
            {Strings.pwd.initial.tf.confirm.title}
          </FormLabel>
          <InputGroup>
            <Input
              autoComplete="off"
              type={showPassword ? "text" : "password"}
              onChange={confirmPassword}
            />
            <InputRightElement>
              {showPassword && (
                <IconButton icon={<FaEye />} onClick={togglePassword} />
              )}
              {!showPassword && (
                <IconButton icon={<FaEyeSlash />} onClick={togglePassword} />
              )}
            </InputRightElement>
          </InputGroup>
        </Stack>
        <Button
          fontFamily={"heading"}
          mt={8}
          w={"full"}
          bgGradient="linear(to-r, red.400,pink.400)"
          color={"white"}
          disabled={!confirmed}
          isLoading={isInitializing}
          _hover={{
            bgGradient: "linear(to-r, red.400,pink.400)",
            boxShadow: "xl",
          }}
          onClick={savePassword}
        >
          {Strings.pwd.initial.button.next.title}
        </Button>

        <Button
          fontFamily={"heading"}
          mt={8}
          w={"full"}
          bgColor={"gray.200"}
          color={"black"}
          onClick={backClicked}
        >
          {Strings.pwd.initial.button.back.title}
        </Button>
        <CoverConfirmationAlert
          disclosure={disclosure}
          onCompleted={(covered) => {
            if (covered) {
              savePassword(null, covered);
            } else {
              showError(
                "Token already exists, please try again later with another password."
              );
            }
          }}
        />
      </Box>
    </>
  );
}

export default function MasterKeyConfigScreen({
  setSetupWizardState,
  setupWizardState,
  selectedAccount,
}) {
  return (
    <Box position={"relative"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
        >
          <MasterKeyConfigurator
            setSetupWizardState={setSetupWizardState}
            setupWizardState={setupWizardState}
            selectedAccount={selectedAccount}
          />
        </Stack>
      </Container>
    </Box>
  );
}
