import BaseRPCHandler from "./BaseRPCHandler";

export default class EthSignHandler extends BaseRPCHandler {
    constructor() {
        super('eth_sign');
    }
    
    async handle(provider, request) {

    }
}
