import AFWalletRPCError from './AFWalletRPCError';
import RPCHandlers from './handlers/RPCHandlers';

export default class AFWalletRPCProvider extends EventTarget {
    wallet = null;
    constructor(wallet) {
        super();
        this.wallet = wallet;
    }
    get isAFWallet() {
        return true;
    }
    get networkVersion() {
        return "5";
    }
    get selectedAddress() {
        return null;
    }

    isConnected() {
        return this.wallet.isConnected();
    }

    async request(reqArgs) {
        console.log('AFWalletRPCProvider request method: ', reqArgs.method, ', params: ', reqArgs.params);
        const handler = RPCHandlers[reqArgs.method];
        if (!handler) {
            const res = await this.wallet.etherProvider.send(reqArgs.method, reqArgs.params);
            return res;
            // switch(reqArgs.method) {
            //     case 'eth_getBalance':
            //     case 'eth_call':
            //     case 'eth_chainId':
            //     case 'eth_blockNumber':
            //     case 'eth_estimateGas':
            //     case 'eth_getTransactionByHash':
            //         const res = await this.wallet.etherProvider.send(reqArgs.method, reqArgs.params);
            //         return res;
            //     default:
            //         throw AFWalletRPCError.unsupportedMethod;
            // }
        }
        const result = await handler.handle(this, reqArgs);
        return result;
    }

    addListener() {

    }
    removeListener() {

    }
    removeAllListener() {

    }

    on(eventName, callback) { 
        // connect 
        // disconnect 
        // accountsChanged 
        // chainChanged 
        // message
        this.addEventListener(eventName, (e) => {
            console.log('on ' + eventName + ':', e);
            callback(e.detail);
        });
    }

    emit(eventName, params) {
        let event = new CustomEvent(eventName, { detail: params });
        this.dispatchEvent(event);
    }
}
