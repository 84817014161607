// task { id, execute(), done() }
/*
for (let i=0; i<10; i++) {
const tasks = [{
    execute: (id) => {
        console.log('execute task #' + id);
    },
    done: (task, error) => {
        if (error) {
            console.log('done task #' + task.id, ', error: ', error);
        } else {

            console.log('done task #' + task.id);
        }
    }
},
{
    execute: (id) => {
        console.log('execute task #' + id);
        throw new Error('I just want');
    },
    done: (task, error) => {
        if (error) {
            console.log('done task #' + task.id, ', error: ', error);
        } else {

            console.log('done task #' + task.id);
        }
    }
}];
mailService.taskQueue.add(tasks);
}
const tasks = [{
    execute: (id) => {
        console.log('execute task #' + id);
    },
    done: (task, error) => {
        if (error) {
            console.log('done task #' + task.id, ', error: ', error);
        } else {

            console.log('done task #' + task.id);
        }
        throw new Error('throw by done')
    }
},
{
    execute: (id) => {
        console.log('execute task #' + id);
        throw new Error('I just want');
    },
    done: (task, error) => {
        if (error) {
            console.log('done task #' + task.id, ', error: ', error);
        } else {

            console.log('done task #' + task.id);
        }
    }
}];
mailService.taskQueue.add(tasks);
 */
// import { AwaitQueue, AwaitQueueTask, AwaitQueueDumpItem } from 'awaitqueue';
import { AwaitQueue } from 'awaitqueue';

/*
class IdGenerator {
    currentId = 0;
    next() {
        return ++this.currentId;
    }
}

class TaskQueueV1 {
    tasks = [];
    isRunning = false;
    idGenerator = null;

    constructor() {
        this.tasks = [];
        this.isRunning = false;
        this.idGenerator = new IdGenerator();
    }

    start() {
        this.isRunning = true;
        this.run().then(() => {}).catch(e => {console.error('run task queue: ', e)});
    }
    
    stop() {
        this.isRunning = false;
    }
    clear() {
        this.tasks = [];
    }
    status() {
        return {length: this.tasks.length}
    }

    add(task) {
        if (task.constructor === Array) {
            for(const t of task) {
                t.id = this.idGenerator.next();
                this.tasks.push(t);
            }
        } else {
            task.id = this.idGenerator.next();
            this.tasks.push(task);
        }
        if (!this.isRunning) {
            this.start();
        }
    }
    
    async run() {
        if (!this.isRunning) {
            return;
        }
        if (this.tasks.length <= 0) {
            this.stop();
            return;
        }

        const task = this.tasks[0];
        let res = null;
        try {
            await task.execute(task);
        } catch(e) {
            res = e;
        } finally {
            try {
                task.done(task, res);
            } catch (e) {
                console.warn('task #', task.id, ' done with error: ', e)
            }
            this.tasks.shift();
            if (this.tasks.length === 0) {
                this.stop();
            } else {
                await this.run();
            }
        }
    }
}
*/

export default class TaskQueue {
    queue=null;
    
    constructor() {
        this.queue = new AwaitQueue();
    }
    // awaitQueue.close(): void
    // Closes the queue. Pending tasks will be rejected with an instance of ClosedErrorClass. The AwaitQueue instance is no longer usable (this method is terminal).
    close() {
        this.queue.close();
    }
    // awaitQueue.stop(): void
    // Make ongoing pending tasks reject with an instance of StoppedErrorClass. The AwaitQueue instance is still usable for future tasks added via push() method.
    stop() {
        this.queue.stop();
    }
    
    async add(task) {
        for(let i=this.queue.size; i>1; i--) {
            this.queue.removeTask(i-1);
        }
        await this.queue.push(task);
    }

}