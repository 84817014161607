import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Button,
  SimpleGrid,
  Link,
  HStack,
  Spinner,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import {
  SetupWizardState,
  SignalInitStep,
  PlexiMailStatus,
} from "../utils/Types";
import { ClientError, showError } from "../common/errors";
import Strings from "../config/Strings";
// import { HELP_DOC_URL } from '../common/constants';
import { TutorialModalLink } from "./TutorialModalTrigger";
import { CONFIG_PLEXIMAIL_AUTOMATICALLY } from "../common/constants";
// import { WalletUIStatus } from '../wallet/AFWalletUI';

const MinimumBalanceRequirement =
  localStorage["MinimumBalanceRequirement"] || "1000000000000000";
const MinimalRequiredBalance =
  parseInt(MinimumBalanceRequirement) || 1000000000000000;

export default function InitialSignalContextScreen({
  selectedAccount,
  setSetupWizardState,
  faucetDisclosure,
}) {
  console.log(">> InitialSignalContextScreen");

  const [isRunning, setRunning] = useState(false);

  const [progress, setProgress] = useState("");
  const [checking, setChecking] = useState(false);
  useEffect(() => {
    if (window.appConfig.mailAddressNeedToBeVerified) {
      showFaucetIfNeeded().then(() => {});
    } else {
      setChecking(false);
    }

    if (CONFIG_PLEXIMAIL_AUTOMATICALLY) {
      initialSignalContextButtonClicked().then(() => {
        console.log('Signal Context initialized');
      }).catch(e => {
        console.error(e);
        showError(e);
      })
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function showFaucetIfNeeded() {
    window.appConfig.mailAddressNeedToBeVerified = false;

    if (
      window.appConfig.mailAddressNeedToBeVerified &&
      window.wallet &&
      window.wallet.asDefaultWallet
    ) {
      try {
        setChecking(true);

        const web3 = window.web3Helper.web3;
        const balance = await web3.eth.getBalance(selectedAccount);
        if (balance <= MinimalRequiredBalance) {
          faucetDisclosure.onOpen();
        }

        // const isBackup = await window.wallet.isBackup();
        // if (isBackup) {
        //     const web3 = window.web3Helper.web3;
        //     const balance = await web3.eth.getBalance(selectedAccount);
        //     if (balance <= MinimalRequiredBalance) {
        //         faucetDisclosure.onOpen();
        //     }
        // } else {
        //     const web3 = window.web3Helper.web3;
        //     const balance = await web3.eth.getBalance(selectedAccount);
        //     if (balance <= MinimalRequiredBalance) {
        //         faucetDisclosure.onOpen();
        //     }
        // }
        setChecking(false);
      } catch (e) {
        setChecking(false);
        setProgress("");
        console.error(e);
        showError(e);
      }
    }
  }
  async function initialSignalContext() {
    // addBinding(foldersIpnsRecord, prekeysIpnsRecord, signedPrekeySignature)
    // await window.mailService.resetSignalService();
    const isInitialized =
      window.mailService.signalIsInitialized(selectedAccount);
    if (isInitialized) {
      await window.mailService.resetSignalServiceFor(selectedAccount);
    }

    // trigger state machine
    try {
      setRunning(true);

      await window.mailService.syncFoldersFile();
      await window.mailService.initSignalService(selectedAccount, (step) => {
        switch (step) {
          case SignalInitStep.CheckPreKeyCount:
            setProgress(Strings.common.message.supply_prekeys);
            break;
          case SignalInitStep.GenerateKeys:
            setProgress(Strings.common.message.generate_keys);
            break;
          case SignalInitStep.SavePreKeyBundle:
            setProgress(Strings.common.message.save_prekey);
            break;
          case SignalInitStep.SaveIdentityKey:
            setProgress(Strings.common.message.save_idkey);
            break;
          // case SignalInitStep.WaitTransactionReceipt:
          //     setProgress(Strings.common.message.wait_tx_receipt);
          //     break;
          case SignalInitStep.SaveSignalProtocolStore:
            setProgress(Strings.common.message.save_signal);
            break;
          case SignalInitStep.Completed:
            setProgress(Strings.common.message.completed);
            break;
          default:
            showError(ClientError.invalidParameterError("Invalid step"));
            break;
        }
      });
      setRunning(false);
      if (window.appConfig.mailAddressNeedToBeVerified) {
        setSetupWizardState(SetupWizardState.WaitForTransactionReceipt);
      } else {
        window.appConfig.removeTempMasterKey();
        window.appConfig.removeTempMnemonic();
        window.appConfig.removeTxHash();
        window.appConfig.removeCouponInfo();
        window.appConfig.removeTempCryptonInfo();
        window.appConfig.removeSetupFromRecovery();
        window.appConfig.isNewCreatedUser = false;
        window.appConfig.plexiMailStatus = PlexiMailStatus.Ready;
        window.wallet.removePinFromSessionStorageIfNeeded();
        setSetupWizardState(SetupWizardState.Completed);
        window.isUnlocked = true;
        await window.mailService.syncFoldersFile();
      }
    } catch (e) {
      console.error(e);
      await window.mailService.resetSignalServiceFor(selectedAccount);
      setProgress("");
      setRunning(false);
      showError(e);
    }
  }

  async function initialSignalContextButtonClicked() {
    if (window.wallet && window.wallet.asDefaultWallet) {
      try {
        setRunning(true);
        const isBackup = await window.wallet.isBackup();
        if (isBackup) {
          await initialSignalContext();
        } else {
          if (window.appConfig.mailAddressNeedToBeVerified) {
            const web3 = window.web3Helper.web3;
            const balance = await web3.eth.getBalance(selectedAccount);
            if (balance <= MinimalRequiredBalance) {
              faucetDisclosure.onOpen();
            } else {
              // await window.wallet.showBackup(
              //   Strings.common.message.wallet_safe_smart_contract
              // );

              setProgress(Strings.common.message.backup_wallet);
              await window.wallet.backupWalletSilently();
              await initialSignalContext();
            }
          } else {
            // await window.wallet.showBackup(
            //   Strings.common.message.wallet_safe_smart_contract
            // );

            setProgress(Strings.common.message.backup_wallet);
            await window.wallet.backupWalletSilently();
            await initialSignalContext();
          }
        }

        setRunning(false);
      } catch (e) {
        console.error(e);
        setRunning(false);
        showError(e);
      }
    } else {
      await initialSignalContext();
    }
  }

  function backClicked() {
    if (window.mailService) {
      window.mailService.resetSignalServiceFor(selectedAccount);
      setSetupWizardState(SetupWizardState.ConfigWeb3Storage);
    } else {
      setSetupWizardState(SetupWizardState.ConfigWeb3Storage);
    }
  }
  return (
    <Box position={"relative"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
        >
          <Stack spacing={4}>
            <Heading
              color={"gray.800"}
              lineHeight={1.1}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              {/* <Text
                as={"span"}
                bgGradient="linear(to-r, red.400,pink.400)"
                bgClip="text"
              >
                {Strings.signal.initial.step} &nbsp;
              </Text> */}
              {Strings.signal.initial.title}
            </Heading>
            <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
              {Strings.signal.initial.content.paragraph1}&nbsp;
              <TutorialModalLink
                title={"Learn more"}
                anchor={"signal-runtime"}
              />
              {/* <Link style={{color: '#00aaff'}} href={HELP_DOC_URL} target={"_blank"}>Learn more</Link> */}
            </Text>
            {/* <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
              {Strings.signal.initial.content.paragraph2}&nbsp;
              <TutorialModalLink
                title={"Learn more"}
                anchor={"signal-runtime"}
              />
            </Text> */}
            {progress && progress.length > 0 && (
              <HStack>
                <Spinner size={"sm"} color={"orange"} />
                <Text
                  as={"span"}
                  color={"orange"}
                  fontSize={{ base: "sm", sm: "md" }}
                >
                  {progress}
                </Text>
              </HStack>
            )}
            {checking && (
              <HStack>
                <Spinner size={"sm"} color={"orange"} />
                <Text
                  as={"span"}
                  color={"orange"}
                  fontSize={{ base: "sm", sm: "md" }}
                >
                  {Strings.common.message.checking_balance}
                </Text>
              </HStack>
            )}
            <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}></Text>
          </Stack>
          <Box as={"form"} mt={10}>
            <Stack spacing={4}>
              <Link href="https://signal.org/docs/" target={"_blank"}>
                {Strings.signal.initial.content.paragraph3}
              </Link>
            </Stack>
            {/* <Button
              fontFamily={"heading"}
              mt={8}
              w={"full"}
              bgGradient="linear(to-r, red.400,pink.400)"
              color={"white"}
              isDisabled={checking}
              isLoading={isRunning}
              _hover={{
                bgGradient: "linear(to-r, red.400,pink.400)",
                boxShadow: "xl",
              }}
              onClick={initialSignalContextButtonClicked}
            >
              {Strings.signal.initial.button.next.title}
            </Button>

            <Button
              fontFamily={"heading"}
              mt={8}
              w={"full"}
              isDisabled={isRunning || checking}
              bgColor={"gray.200"}
              // bgGradient="linear(to-r, red.400,pink.400)"
              color={"black"}
              // _hover={{
              //     bgGradient: 'linear(to-r, red.400,pink.400)',
              //     boxShadow: 'xl',
              // }}
              onClick={backClicked}
            >
              {Strings.signal.initial.button.back.title}
            </Button> */}

          <Button
            fontFamily={"heading"}
            mt={8}
            w={"full"}
            bgColor={"gray.200"}
            // bgGradient="linear(to-r, red.400,pink.400)"
            color={"black"}
            // _hover={{
            //     bgGradient: 'linear(to-r, red.400,pink.400)',
            //     boxShadow: 'xl',
            // }}
            isDisabled={isRunning || checking}
            disabled={isRunning || checking}
            onClick={initialSignalContextButtonClicked}
          >
            {"Retry"}
          </Button>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
