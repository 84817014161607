import {
    // StorageType,
    // Direction,
    // SessionRecordType,
    SignalProtocolAddress,
    // PreKeyPairType,
    // SignedPreKeyPairType,
    // KeyHelper,
} from '@privacyresearch/libsignal-protocol-typescript';
import { ethers } from 'ethers';
import { ClientError } from '../common/errors';
import { IdentityKeyStatus } from '../utils/Types';
import Strings from '../config/Strings';
import * as DBUtils from '../utils/DBUtils';

/*
import {
    KeyHelper,
    SignedPublicPreKeyType,
    SignalProtocolAddress,
    SessionBuilder,
    PreKeyType,
    SessionCipher,
    MessageType }
from '@privacyresearch/libsignal-protocol-typescript';
*/



// Type guards
function isKeyPairType(kp) {
    return (kp.privKey && kp.pubKey)
}

function isPreKeyType(pk) {
    return typeof pk.keyId === 'number' && isKeyPairType(pk.keyPair)
}

function isSignedPreKeyType(spk) {// eslint-disable-line
    return spk.signature && isPreKeyType(spk)
}

/*
interface KeyPairType {
    pubKey: ArrayBuffer
    privKey: ArrayBuffer
}

interface PreKeyType {
    keyId: number
    keyPair: KeyPairType
}
interface SignedPreKeyType extends PreKeyType {
    signature: ArrayBuffer
}
*/

function isArrayBuffer(thing) {
    const t = typeof thing
    return !!thing && t !== 'string' && t !== 'number' && 'byteLength' in (thing)
}


export function arrayBufferToString(b) {
    return uint8ArrayToString(new Uint8Array(b))
}

export function uint8ArrayToString(arr) {
    const end = arr.length
    let begin = 0
    if (begin === end) return ''
    let chars = []
    const parts = []
    while (begin < end) {
        chars.push(arr[begin++])
        if (chars.length >= 1024) {
            parts.push(String.fromCharCode(...chars))
            chars = []
        }
    }
    return parts.join('') + String.fromCharCode(...chars)
}

class StoreObject {
    _db = null;
    _name = null;
    constructor(db, name) {
        this._db = db;
        this._name = name;
    }
    get #store() {
        return this._db[this._name];
    }

    async get(key) {
        const store = this.#store;

        // const keyPath = store.keyPath;
        const value = store.dataSet[key + ''];
        return value ? value.value : value;
    }
    async getAll() {
        const store = this.#store;
        const results = Object.values(store.dataSet)
        /*
        if (!results) {
            return results;
        }
        return results.map((res) => {
            return res.value;
        });
        */
       return results;
    }
    
    async delete(key) {
        const store = this.#store;
        delete store.dataSet[key + ''];
    }

    async put({id, value}) {
        const store = this.#store;
        // const keyPath = store.keyPath;
        store.dataSet[id + ''] = {id, value};
    }
};

class SecuritySignalProtocolStoreInMemoryDB {
    static #StorePrefix = 'signal.';
    static #StoreNames = {
        Misc: SecuritySignalProtocolStoreInMemoryDB.#StorePrefix + 'misc',
        Identities: SecuritySignalProtocolStoreInMemoryDB.#StorePrefix + 'identities',
        PreKeys: SecuritySignalProtocolStoreInMemoryDB.#StorePrefix + 'preKeys',
        Sessions: SecuritySignalProtocolStoreInMemoryDB.#StorePrefix + 'sessions',
        SignedPreKeys: SecuritySignalProtocolStoreInMemoryDB.#StorePrefix + 'signedPreKeys',
        EncryptionKeys: SecuritySignalProtocolStoreInMemoryDB.#StorePrefix + 'encryptionKeys',
        Contacts: SecuritySignalProtocolStoreInMemoryDB.#StorePrefix + 'contacts',
        Users: SecuritySignalProtocolStoreInMemoryDB.#StorePrefix + 'users',
        Delegations: SecuritySignalProtocolStoreInMemoryDB.#StorePrefix + 'delegations'
    };
    static #DBName = 'smailDB';
    static #DBVersion = 5;
    
    _db = null;
    _account = '';
    _encryptionKey = null;
    constructor(account = '', encryptionKey = null) {
        this._account = account;
        this._encryptionKey = encryptionKey;
        console.log('SecuritySignalProtocolStoreInMemoryDB - constructor');
        this.openSync();
    }
    get objectStoreNames() {
        return Object.values(SecuritySignalProtocolStoreInMemoryDB.#StoreNames);
    }
    init(account = '', encryptionKey = null) {
        this._account = account;
        this._encryptionKey = encryptionKey;
        console.log('SecuritySignalProtocolStoreIndexedDB - init');
        this.openSync();
    }
    openSync() {
        console.log('SecuritySignalProtocolStoreInMemoryDB - openSync');
        
        const self = this;
        if (self._db) {
            return;
        }

        self._db = {}
        self._db[SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc] = {
            keyPath: 'id',
            dataSet: {}
        };
        self._db[SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Identities] = {
            keyPath: 'id',
            dataSet: {}
        };
        self._db[SecuritySignalProtocolStoreInMemoryDB.#StoreNames.PreKeys] = {
            keyPath: 'id',
            dataSet: {}
        };
        self._db[SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Sessions] = {
            keyPath: 'id',
            dataSet: {}
        };
        self._db[SecuritySignalProtocolStoreInMemoryDB.#StoreNames.SignedPreKeys] = {
            keyPath: 'id',
            dataSet: {}
        };
        self._db[SecuritySignalProtocolStoreInMemoryDB.#StoreNames.EncryptionKeys] = {
            keyPath: 'id',
            dataSet: {}
        };
        self._db[SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Contacts] = {
            keyPath: 'id',
            dataSet: {}
        };
        self._db[SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Users] = {
            keyPath: 'id',
            dataSet: {}
        };
        self._db[SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Delegations] = {
            keyPath: 'id',
            dataSet: {}
        };
    }
    async open() {
        console.log('SecuritySignalProtocolStoreInMemoryDB - open');
        this.openSync();
    }

    async close() {
        console.log('SecuritySignalProtocolStoreInMemoryDB - close');
        const self = this;
        self._db = null;
    }

    #readOnlyStore(storeName) {
        const store = new StoreObject(this._db, storeName);
        return store;
    }
    #readwriteStore(storeName) {
        const store = new StoreObject(this._db, storeName);
        return store;
    }

    #encodePreKey(preKey) {
        const encodedKp = {
            pubKey: ethers.encodeBase64(new Uint8Array(preKey.pubKey)),
            privKey: ethers.encodeBase64(new Uint8Array(preKey.privKey))
        }
        const encodedKpString = JSON.stringify(encodedKp);
        return encodedKpString;
    }

    #decodePreKey(encodedPreKey) {
        if (typeof encodedPreKey === 'undefined') {
            return encodedPreKey;
        }
        encodedPreKey = JSON.parse(encodedPreKey);
        const decodedKp = {
            pubKey: ethers.decodeBase64(encodedPreKey.pubKey).buffer,
            privKey: ethers.decodeBase64(encodedPreKey.privKey).buffer
        }
        return decodedKp;
    }

    #encodeSignedPreKey(signedPreKey) {
        return this.#encodePreKey(signedPreKey);
        // let encodedSignedPreKey = {
        //     keyId: signedPreKey.keyId,
        //     keyPair: this.#encodePreKey(signedPreKey.keyPair),
        //     signature: ethers.encodeBase64(new Uint8Array(signedPreKey.signature))
        // }
        // const encodedSignedPreKeyString = JSON.stringify(encodedSignedPreKey);
        // return encodedSignedPreKeyString;
    }
    #decodeSignedPreKey(encodedSignedPreKey) {
        return this.#decodePreKey(encodedSignedPreKey);
        // if (typeof encodedSignedPreKey === 'undefined') {
        //     return encodedSignedPreKey;
        // }
        // encodedSignedPreKey = JSON.parse(encodedSignedPreKey);
        // let signedPreKey = {
        //     keyId: encodedSignedPreKey.keyId,
        //     keyPair: this.#decodePreKey(encodedSignedPreKey.keyPair),
        //     signature: ethers.decodeBase64(encodedSignedPreKey.signature).buffer
        // }
        // return signedPreKey;
    }
    #encodeIdentityKey(identityKey) {
        const encodedIdentityKey = ethers.encodeBase64(new Uint8Array(identityKey));
        return encodedIdentityKey;
    }
    #decodeIdentityKey(encodedIdentityKey) {
        if (typeof encodedIdentityKey === 'undefined') {
            return encodedIdentityKey;
        }
        const identityKey = ethers.decodeBase64(encodedIdentityKey).buffer;
        return identityKey;
        // encodedIdentityKey = JSON.parse(encodedIdentityKey);
    }

    #encodeIdentifyKeyPair(identityKeyPair) {
        // {pubKey: ArrayBuffer(33), privKey: ArrayBuffer(32)}
        const encodedKp = {
            pubKey: ethers.encodeBase64(new Uint8Array(identityKeyPair.pubKey)),
            privKey: ethers.encodeBase64(new Uint8Array(identityKeyPair.privKey))
        }
        const encodedKpString = JSON.stringify(encodedKp);
        return encodedKpString;
    }

    #decodeIdentifyKeyPair(encodedIdentityKeyPair) {
        if (typeof encodedIdentityKeyPair === 'undefined') {
            return encodedIdentityKeyPair;
        }
        encodedIdentityKeyPair = JSON.parse(encodedIdentityKeyPair);

        const decodedKp = {
            pubKey: ethers.decodeBase64(encodedIdentityKeyPair.pubKey).buffer,
            privKey: ethers.decodeBase64(encodedIdentityKeyPair.privKey).buffer
        }
        return decodedKp;
    }

    async encryptMailPart(key, part) {
        const self = this;
        let plaintext = null;
        if (typeof part === 'undefined') {
            throw ClientError.invalidParameterError(Strings.error.client.enc_data_undefined);
        } else if (part === null) {
            throw ClientError.invalidParameterError(Strings.error.client.enc_data_null);
        } else if (typeof part === 'string') {
            const encoder = new TextEncoder();
            plaintext = encoder.encode(part);
        } else if (part.constructor === ArrayBuffer) {
            plaintext = new Uint8Array(part);
        } else if (part.constructor === Uint8Array) {
            plaintext = part;
        } else if (part.constructor === Array) {
            plaintext = Uint8Array.from(part);
        } else {
            throw ClientError.invalidDataTypeError(Strings.error.client.unsupported_data_type_1 + part + Strings.error.client.unsupported_data_type_2);
        }
        
        if (plaintext == null) {
            throw ClientError.invalidParameterError(Strings.error.client.plaintext_null);
        }

        const res = await self.encrypt(key, plaintext);
        return res;

    }

    async decryptMailPart(key, partCiphertext, asString=false) {
        const self = this;
        const plaintext = await self.decrypt(key, partCiphertext);
        if (asString) {
            const decoder = new TextDecoder();
            const plaintextString = decoder.decode(plaintext);
            return plaintextString;
        }
        return plaintext;
    }

    async #encrypt(plaintext) {
        // window.crypto.subtle.exportKey('jwk|raw', aKey).then(k => console.log(k))
        // window.crypto.subtle.importKey('jwk|raw', aKey, 'AES-CBC', true, ['encrypt', 'decrypt']).then(k => aKey)
        // window.crypto.subtle.generateKey({ name: "AES-CBC", length: 256}, true, ["encrypt", "decrypt", "wrapKey", "unwrapKey"])
        // const iv = window.crypto.getRandomValues(new Uint8Array(16));
        if (typeof plaintext !== 'string') {
            throw ClientError.invalidParameterError('plaintext must be string');
        }
        
        const encoder = new TextEncoder();
        plaintext = encoder.encode(plaintext);
    
        const key = this._encryptionKey;
        const iv = window.crypto.getRandomValues(new Uint8Array(12));
        // {name: 'AES-GCM', iv: iv, tagLength: 128, additionalData: ArrayBuffer,}
        const ciphertext = await window.crypto.subtle.encrypt(
            {
                name: 'AES-GCM', 
                iv: iv, 
                tagLength: 128
            }, 
            key, 
            plaintext
        );
        
        return {
            iv,
            ciphertext: new Uint8Array(ciphertext)
        };
    }

    async #decrypt({iv, ciphertext}) {
        if (ciphertext.constructor === Uint8Array) {
            ciphertext = ciphertext.buffer;
        }
        
        const key = this._encryptionKey;
        const plaintext = await window.crypto.subtle.decrypt(
            {
                name: 'AES-GCM', 
                iv: iv, 
                tagLength: 128
            }, 
            key, 
            ciphertext
        );
        const decoder = new TextDecoder();
        const plaintextString = decoder.decode(plaintext);
        return plaintextString;
    }


    get namespace() {
        if (!this._account || this._account.length === '') {
            return '';
        }
        return this._account + '.';
    }

    async get(storeName, key, defaultValue) {
        const self = this;
        if (key === null || key === undefined) {
            throw ClientError.invalidParameterError('Tried to get value for undefined/null key');
        }
            
        key = self.namespace + key;
        
        const objectStore = self.#readOnlyStore(storeName);
        const value = await objectStore.get(key) || defaultValue;
        return value;
    }

    async getAll(storeName) {
        const self = this;
        const objectStore = self.#readOnlyStore(storeName);
        let results = await objectStore.getAll();
        if (self.namespace && self.namespace.length > 0) {
            results = results.filter(o => {
                return o.id.startsWith(self.namespace)
            });
        }
        return results;
    }

    async remove(storeName, key) {
        const self = this;

        if (key === null || key === undefined) {
            throw ClientError.invalidParameterError('Tried to get value for undefined/null key')
        }
            
        key = self.namespace + key;

        const objectStore = self.#readwriteStore(storeName);
        await objectStore.delete(key);
    }

    async put(storeName, key, value) {
        const self = this;
        if (key === null || key === undefined) {
            throw ClientError.invalidParameterError('Tried to get value for undefined/null key')
        }            
        key = self.namespace + key;
        
        const objectStore = self.#readwriteStore(storeName);
        await objectStore.put({id: key, value: value});
    }
    
    async get_without_namespace(storeName, key, defaultValue) {
        const self = this;
        if (key === null || key === undefined) {
            throw ClientError.invalidParameterError('Tried to get value for undefined/null key');
        }
        
        const objectStore = self.#readOnlyStore(storeName);
        const value = await objectStore.get(key) || defaultValue;
        return value;
    }

    async getAll_without_namespace(storeName) {
        const self = this;
        const objectStore = self.#readOnlyStore(storeName);
        const results = await objectStore.getAll();
        return results;
    }
    
    async put_without_namespace(storeName, key, value) {
        const self = this;
        if (key === null || key === undefined) {
            throw ClientError.invalidParameterError('Tried to get value for undefined/null key')
        } 
    
        const objectStore = self.#readwriteStore(storeName);
        await objectStore.put({id: key, value: value});
    }
    async remove_without_namespace(storeName, key) {
        const self = this;

        const objectStore = self.#readwriteStore(storeName);
        await objectStore.delete(key);
    }

    async reset() {
        await this.close();
        this._db = null;
    }


    /*
    getIdentityKeyPair: () => Promise<KeyPairType | undefined>;
    getLocalRegistrationId: () => Promise<number | undefined>;
    isTrustedIdentity: (identifier: string, identityKey: ArrayBuffer, direction: Direction) => Promise<boolean>;
    saveIdentity: (encodedAddress: string, publicKey: ArrayBuffer, nonblockingApproval?: boolean) => Promise<boolean>;
    loadPreKey: (encodedAddress: string | number) => Promise<KeyPairType | undefined>;
    storePreKey: (keyId: number | string, keyPair: KeyPairType) => Promise<void>;
    removePreKey: (keyId: number | string) => Promise<void>;
    storeSession: (encodedAddress: string, record: SessionRecordType) => Promise<void>;
    loadSession: (encodedAddress: string) => Promise<SessionRecordType | undefined>;
    loadSignedPreKey: (keyId: number | string) => Promise<KeyPairType | undefined>;
    storeSignedPreKey: (keyId: number | string, keyPair: KeyPairType) => Promise<void>;
    removeSignedPreKey: (keyId: number | string) => Promise<void>;
    */
    async getEncryptionKeyBundle(uid) {
        const key = await this.get(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.EncryptionKeys, uid, null);
        if (!key) {
            return null;
        }
        // return key;
        
        const decryptedKey = await this.#decrypt(key);
        const decodedKey = JSON.parse(decryptedKey);
        return decodedKey;
    }
    async saveEncryptionKeyBundle(uid, key) {
        const encodedKey = JSON.stringify(key);
        const encryptedKey = await this.#encrypt(encodedKey);
        await this.put(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.EncryptionKeys, uid, encryptedKey);
    }
    async hasEncryptionKeyBundle(uid) {
        const key = await this.getEncryptionKeyBundle(uid);
        return Boolean(key);
    }

    async saveDeviceId(deviceId) {
        await this.put(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc, `deviceId`, deviceId);
    }
    async getDeviceId() {
        let deviceId = await this.get(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc, `deviceId`, 1);
        return deviceId;
    }
    async getMyAddress() {
        const deviceId = await this.getDeviceId();
        const address = { account: this._account, deviceId: deviceId };
        return address;
    }
    async makeNextMessageId() {
        let baseMessageId = await this.getNextMessageId();
        const nextMessageId = baseMessageId + 1;
        await this.put(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc, `nextMessageId`, nextMessageId);
        return baseMessageId;
    }
    async getNextMessageId() {
        let baseMessageId = await this.get(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc, `nextMessageId`, 1);
        return baseMessageId;
    }

    async makeKeyId() {
        let baseKeyId = await this.getNextKeyId();
        const nextKeyId = baseKeyId + 1;
        await this.put(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc, `nextKeyId`, nextKeyId);
        return baseKeyId;
    }

    async getNextKeyId() {
        let baseKeyId = await this.get(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc, `nextKeyId`, 1);
        return baseKeyId;
    }


    async saveIdentityKeyPair(identityKeyPair) {
        const encodedIdentityKeyPair = this.#encodeIdentifyKeyPair(identityKeyPair)
        const encryptedIdentityKeyPair = await this.#encrypt(encodedIdentityKeyPair);
        await this.put(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc, `identityKey-me`, encryptedIdentityKeyPair);
    }

    async getIdentityKeyPair() {
        const identityKeyPair = await this.get(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc, `identityKey-me`, undefined);
        // return identityKeyPair;
        if (!identityKeyPair) {
            return;
        }
        const decryptedIdentityKeyPair = await this.#decrypt(identityKeyPair);
        const decodedIdentityKeyPair = this.#decodeIdentifyKeyPair(decryptedIdentityKeyPair);
        return decodedIdentityKeyPair;
    }

    async saveLocalRegistrationId(registrationId) {
        await this.put(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc, `registrationId`, registrationId);
    }

    async getLocalRegistrationId() {
        const registrationId = await this.get(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc, `registrationId`, undefined);
        if (typeof registrationId === 'undefined') {
            return registrationId
        } else if (typeof registrationId === 'number') {
            return registrationId;
        }
        throw ClientError.invalidDataTypeError('Stored Registration ID is not a number')
    }
    
    async identityKeyStatus(
        name,
        identityKey
    ) {
        if (name === null || name === undefined) {
            throw ClientError.invalidParameterError('tried to check identity key for undefined/null key')
        }
        const trusted = await this.get(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Identities, name, undefined)
        // TODO: Is this right? If the ID is NOT in our store we trust it?
        if (trusted === undefined) {
            return IdentityKeyStatus.NotExist;
        }
        // const decodedTrusted = trusted;
        const decryptedTrusted = await this.#decrypt(trusted);
        const decodedTrusted = this.#decodeIdentityKey(decryptedTrusted);
        
        const isIdentical = arrayBufferToString(identityKey) === arrayBufferToString(decodedTrusted);
        if (isIdentical) {
            return IdentityKeyStatus.Unchanged;
        }
        return IdentityKeyStatus.Changed;
    }
    
    async isTrustedIdentity(
        name,
        identityKey,
        _direction
    ) {
        if (name === null || name === undefined) {
            throw ClientError.invalidParameterError('tried to check identity key for undefined/null key')
        }
        const trusted = await this.get(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Identities, name, undefined)
        // TODO: Is this right? If the ID is NOT in our store we trust it?
        if (trusted === undefined) {
            return Promise.resolve(true)
        }
        // const decodedTrusted = trusted;
        const decryptedTrusted = await this.#decrypt(trusted);
        const decodedTrusted = this.#decodeIdentityKey(decryptedTrusted);

        // console.log('idkey: ', base64.encode(new Uint8Array(identityKey)), ', trusted: ', base64.encode(new Uint8Array(decodedTrusted)))
        // const trustedIdentityKey = base64.toByteArray(decodedTrusted) 
        return Promise.resolve(arrayBufferToString(identityKey) === arrayBufferToString(decodedTrusted))
    }

    async loadPreKey(keyId) {
        let res = await this.get(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.PreKeys, keyId, undefined)
        
        if (typeof res === 'undefined') {
            return res
        }
        // else if (isKeyPairType(res)) {
        //  res = { pubKey: res.pubKey, privKey: res.privKey }
        //  return res  
        // }
        // throw ClientError.invalidDataTypeError(`stored key has wrong type`)

        res = await this.#decrypt(res);
        res = this.#decodePreKey(res);
        if (isKeyPairType(res)) {
            res = { pubKey: res.pubKey, privKey: res.privKey }
            return res
        }
        throw ClientError.invalidDataTypeError(`stored key has wrong type`)
    }

    async loadSession(identifier) {
        let rec = await this.get(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Sessions, identifier, undefined)
        if (typeof rec === 'undefined' || rec === null) {
            return rec
        } 
        // else if (typeof rec === 'string') {
        //     return rec
        // }
        // throw ClientError.invalidDataTypeError(`session record is not an ArrayBuffer`)
        rec = await this.#decrypt(rec);
        if (typeof rec === 'string') {
            return rec
        }
        throw ClientError.invalidDataTypeError(`session record is not a string`)
    }

    async loadSignedPreKey(keyId) {
        let res = await this.get(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.SignedPreKeys, keyId, undefined)
        if (typeof res === 'undefined') {
            return res
        } 
        // else if (isKeyPairType(res)) {// isSignedPreKeyType
        //     res = { pubKey: res.pubKey, privKey: res.privKey }
        //     return res;
        // }
        // throw ClientError.invalidDataTypeError(`stored key has wrong type`)
        res = await this.#decrypt(res);
        res = this.#decodeSignedPreKey(res);
        if (isKeyPairType(res)) {// isSignedPreKeyType
            res = { pubKey: res.pubKey, privKey: res.privKey }
            return res;
        }
        throw ClientError.invalidDataTypeError(`stored key has wrong type`);
    }
    
    async removePreKey(keyId) {
        await this.remove(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.PreKeys, keyId)
    }

    async saveIdentity(identifier, identityKey) {

        if (identifier === null || identifier === undefined)
            throw ClientError.invalidParameterError('Tried to put identity key for undefined/null key')

        const address = SignalProtocolAddress.fromString(identifier)

        let existing = await this.get(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Identities, address.getName(), undefined)
        if (existing) {
            existing = await this.#decrypt(existing);
            existing = this.#decodeIdentityKey(existing)
        }

        const encodedIdentityKey = this.#encodeIdentityKey(identityKey);
        const encryptedIdentityKey = await this.#encrypt(encodedIdentityKey);

        await this.put(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Identities, address.getName(), encryptedIdentityKey)

        if (existing && !isArrayBuffer(existing)) {
            throw ClientError.invalidDataTypeError('Identity Key is incorrect type')
        }

        if (existing && arrayBufferToString(encryptedIdentityKey) !== arrayBufferToString(existing)) {
            return true
        } else {
            return false
        }
    }
    async removeIdentity(name) {
        await this.remove(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Identities, name);
    }
    
    async storeSession(identifier, record) {
        const encryptedRecord = await this.#encrypt(record);
        return await this.put(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Sessions, identifier, encryptedRecord)
    }

    async loadIdentityKey(identifier) {

        if (identifier === null || identifier === undefined) {
            throw ClientError.invalidDataTypeError('Tried to get identity key for undefined/null key')
        }

        let key = await this.get(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Identities, identifier, undefined)

        if (typeof key === 'undefined') {
            return key
        }
        //  else if (isArrayBuffer(key)) {
        //     return key
        // }
        key = await this.#decrypt(key);

        const identityKey = this.#decodeIdentityKey(key);
        if (isArrayBuffer(identityKey)) {
            return identityKey;
        }
        throw ClientError.invalidDataTypeError(`Identity key has wrong type`)
    }

    async storePreKey(keyId, keyPair) {
        const encodedKeyPair = this.#encodePreKey(keyPair);
        const encryptedKeyPair = await this.#encrypt(encodedKeyPair);
        return await this.put(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.PreKeys, keyId, encryptedKeyPair);
    }

    // TODO: Why is this keyId a number where others are strings?
    async storeSignedPreKey(keyId, keyPair) {
        const encodedSignedPreKey = this.#encodeSignedPreKey(keyPair);
        const encryptedSignedPreKey = await this.#encrypt(encodedSignedPreKey);

        return await this.put(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.SignedPreKeys, keyId, encryptedSignedPreKey)
    }

    async removeSignedPreKey(keyId) {
        return await this.remove(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.SignedPreKeys, keyId)
    }

    async removeSession(identifier) {
        return await this.remove(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Sessions, identifier)
    }

    async removeAllSessions(identifier) {
        const sessions = await this.getAll(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Sessions, identifier);
        for (let i=0; i<sessions.length; i++) {
            if (sessions[i].id.startsWith(identifier + '.')) {
                await this.remove(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Sessions, sessions[i].id);  
            } 
        }
    }

    async saveContact(contact) {
        return await this.put(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Contacts, contact.uuid, contact);
    }

    async deleteContact(uuid) {
        return await this.remove(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Contacts, uuid);
    }

    async getAllContacts() {
        const contacts = await this.getAll(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Contacts);
        return contacts;
    }

    async getUser(addr) {
        let user = await this.get(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Users, addr, undefined)
        return user;
    }
    
    async saveUser(user) {
        // const encodedContact = JSON.stringify(contact);
        return await this.put(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Users, user.addr.toLowerCase(), user);
    }

    async deleteUser(addr) {
        return await this.remove(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Users, addr);
    }

    async getAllUsers() {
        const users = await this.getAll(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Users);
        return users;
    }


    async getEnterpriseProfile() {
        const profileStr = await this.get(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc, `enterprise-profile`, undefined);
        if (!profileStr || profileStr.length === 0) {
            return null;
        }
        try {
            const profile = JSON.parse(profileStr);
            return profile;
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    async saveEnterpriseProfile(profile) {
        if (!profile || profile.length === 0) {
            return;
        }

        try {
            const profileStr = JSON.stringify(profile);
            if(profileStr && profileStr.length > 0) {
                await this.put(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc, `enterprise-profile`, profileStr);
            }
        } catch (e) {
            console.error(e);
        }
    }

    async getNotifyTemplate() {
        const template = await this.get(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc, `notify-template`, undefined) || '';
        return template;
    }
    async saveNotifyTemplate(template) {
        if (!template || template.length === 0) {
            return;
        }
        await this.put(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc, `notify-template`, template);
    }
    
    async getFolders(page, size) {
        await this.open();
        const folders = await this.get(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc, `folders`, undefined);
        if (!folders || folders.length === 0) {
            return null;
        }
        return JSON.parse(folders);
    }

    async saveFolders(folders) {
        if (typeof folders === 'object') {
            folders = JSON.stringify(folders);
        }
        return await this.put(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc, `folders`, folders);
    }


    async hasDraft() {
        return false
    }
    async getDraft() {
        return null;
    }
    async saveDraft(draft) {

    }
    async removeDraft() {
        
    }
    async getContractThread() {
        
    }
    async saveContractThread(contractThread) {
        
    }
    async removeContractThread(id) {
    }


    async getContractTemplates() {
    }
    
    async saveContractTemplate(template) {
    }

    async removeContractTemplate(id) {
    }
    // {id: 'alice@gmail.com', key: 'base64', delegation: 'base64'}
    async saveDelegation(delegation) {
        return await this.put_without_namespace(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Delegations, delegation.id, delegation);
    }

    async loadDelegation(email) {
        const delegation = await this.get_without_namespace(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Delegations, email, undefined);
        return delegation;
    }

    async deleteDelegation(email) {
        await this.remove_without_namespace(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Delegations, email)
    }

    async loadAllDelegations() {
        const res = await this.getAll(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Delegations);
        return res;
    }

    async getAddressTokenPassword() {
        await this.open();
        const password = await this.get(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc, `address-token-pwd`, undefined);
        if (!password || password.length === 0) {
            return null;
        }
        return password;
    }
    async saveAddressTokenPassword(password) {
        if (!password || password.length === 0) {
            throw new Error('Invalid password');
        }
        return await this.put(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc, `address-token-pwd`, password);
    }

    async exportAsJson(asString=true) {
        const self = this;
        await self.open();
        const promise = new Promise((resolve, reject) => {
            DBUtils.exportInMemoryDBToJsonString(self.objectStoreNames, self._db, (err, json) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(json);
                }
            }, asString);
        });
        return promise;
    }

    async importFromJson(json) {
        const self = this;
        await self.open();
        const promise = new Promise((resolve, reject) => {
            DBUtils.importInMemoryDBFromJsonString(self.objectStoreNames, self._db, json, (err) => {
                if (err) {
                    reject(err);
                } else {
                    resolve();
                }
            })
        });
        return promise;
    }

    async removeFolderCaches() {
        const self = this;
        const results = (await self.getAll(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc)).filter(r => {
            return r.id.lastIndexOf('.folder') !== -1;
        }).map(r => {
            return r.id;
        });

        for(const result of results) {
            await self.remove_without_namespace(SecuritySignalProtocolStoreInMemoryDB.#StoreNames.Misc, result);
        }
    }
    async clearDatabase() {
        this._db = null;
        await this.open();
    }
    async clearDatabaseFor(account) {
        this._db = null;
        await this.open();
    }
    async isInitializedFor(account) {
        return false;
    }


    async getAddresses(asArray=false) {
    }
    
    async saveAddress(address, path) {
    }

    async removeAddress(address) {
        
    }
}

export default SecuritySignalProtocolStoreInMemoryDB;