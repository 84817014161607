import BaseRPCHandler from "./BaseRPCHandler";

export default class EthRequestAccountsHandler extends BaseRPCHandler {
    constructor() {
        super('eth_requestAccounts');
    }

    async handle(provider, request) {
        const accounts = await provider.wallet.connect(request);
        return accounts;
    }
}