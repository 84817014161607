import { toast } from "react-toastify";


class BaseError extends Error {
    constructor(code, message) {
        super(message);
        this.name = "BaseError";
        this.code = code;
    }
}

export class ClientError extends BaseError {
    static get CODE_INVALID_PARAMETER() {
        return -1;
    }
    static get CODE_INVALID_DATATYPE() {
        return -2;
    }
    static get CODE_DATABASE() {
        return -3;
    }
    static get CODE_NETWORK() {
        return -4;
    }

    static get CODE_IDENTITY() {
        return -5;
    }
    static get CODE_TX() {
        return -6;
    }
    static get CODE_CRYPTON_EXISTED() {
        return -7;
    }
    static get CODE_CRYPTON_NOT_EXISTED() {
        return -8;
    }
    static get CODE_TIMEOUT() {
        return -9;
    }
    static invalidParameterError(message) {
        return new ClientError(ClientError.CODE_INVALID_PARAMETER, message);
    }
    static invalidDataTypeError(message) {
        return new ClientError(ClientError.CODE_INVALID_DATATYPE, message);
    }
    static databaseError(message) {
        return new ClientError(ClientError.CODE_DATABASE, message);
    }
    static networkError(message) {
        return new ClientError(ClientError.CODE_NETWORK, message);
    }
    static identityKeyError(message) {
        return new ClientError(ClientError.CODE_IDENTITY, message);
    }
    static txError(message) {
        return new ClientError(ClientError.CODE_TX, message);
    }
    static cryptonExistedError(message) {
        return new ClientError(ClientError.CODE_CRYPTON_EXISTED, message);
    }
    static cryptonNotExstedError(message) {
        return new ClientError(ClientError.CODE_CRYPTON_NOT_EXISTED, message);

    }
    static timeoutError(message) {
        return new ClientError(ClientError.CODE_TIMEOUT, message);
    }

    constructor(code, message) {
        super(code, message);
        this.name = "ClientError";
    }
}

export class ServerError extends BaseError {
    static get CODE_UNKNOWN() {
        return -10001;
    }
    static get CODE_SECURITY() {
        return -10002;
    }
    static get CODE_NOT_FOUND() {
        return -10003
    }
    static get CODE_EMPTY_RESPONSE() {
        return -10004;
    }
    static get CODE_HTTP_ERROR() {
        return -10004;
    }
    static get CODE_NOTIFY_ERROR() {
        return -10005;
    }
    static notFoundError(message) {
        return new ServerError(ServerError.CODE_NOT_FOUND, message);
    }
    static emptyResponseError(message) {
        return new ServerError(ServerError.CODE_EMPTY_RESPONSE, message);
    }
    static securityError(message) {
        return new ServerError(ServerError.CODE_SECURITY, message);
    }
    static httpError(resp) {
        return new ServerError(ServerError.CODE_HTTP_ERROR, resp.statusText ||  'Bad http request');
    }
    static notifyError(message) {
        return new ServerError(ServerError.CODE_NOTIFY_ERROR, message);
    }
    static from(result) {
        if (!result) {
            result = {code: ServerError.CODE_UNKNOWN, msg: "Unknown error" }
        }
        return new ServerError(result.code, result.msg || result.message);
    }
    constructor(code, message) {
      super(code, message);
      this.name = "ServerError";
    }

}

export class SmartContractError extends BaseError {
    static get CODE_UNKNOWN() {
        return -20001;
    }
    static get CODE_EMPTY_RESPONSE() {
        return -20002;
    }
    static emptyResponseError(message) {
        return new SmartContractError(SmartContractError.CODE_EMPTY_RESPONSE, message);
    }
    constructor(code, message) {
      super(code, message);
      this.name = "SmartContractError";
    }
}


export function showError(e, position = 'top-center') {
    // const code = (e && e.code) ? e.code : '';
    console.error(e);
    const msg = (e) ? (e.message || e.msg || e.toString()) : 'Unknown error';
    toast.error(msg || 'unknown error', {
        position: position,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false,
    });
}

export function showInfo(message, position = 'top-center') {
    
    toast.info(message, {
        position: position,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false,
    });
}

export function showSuccess(message, position = 'top-center', autoClose=5000) {
    
    toast.success(message, {
        position: position,
        autoClose: autoClose,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false,
    });
}

export function showWarning(message, position = 'top-center', autoClose=5000) {
    toast.warn(message, {
        position: position,
        autoClose: autoClose,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        pauseOnFocusLoss: false,
    })
}