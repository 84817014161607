import { Box, Link } from "@chakra-ui/react";
import LocalizedStrings from "react-localization";
const MAINNET_NAME = "Mainnet";
const TESTNET_NAME = "Fuji";
const DEFAULT_NET_NAME = TESTNET_NAME;
const BLOCKCHAIN_NAME_CAMEL = "Avalanche";

const EnterpriseStrings = new LocalizedStrings({
  en: {
    error: {
      rpc: {
        "-32002":
          "Your request is queued behind a pending one for your wallet, which may be hidden beneath other windows. Recover your wallet app and clear the pending entries first.",
      },
      client: {
        /**/ invalid_signature: "Invalid signature",
      },
    },
    common: {
      message: {
        /**/ supply_prekeys: "Supply prekeys...",
        /**/ generate_keys:
          "Generating Identity Key, registration id, and prekeys...",
        /**/ save_prekey: "Storing PreKey bundle into cloud...",
        /**/ save_idkey: "Saving Identity Key into smart contract...",
        wait_tx_receipt: "Wait for transaction receipt...",
        /**/ save_signal: "Saving signal protocol store...",
        init_web3_storage: "Initializing Web3 Storage...",
        /**/ completed: "Completed!",
      },
      notify_tpl: {
        subject: " invites you to enable secure email",
        p1: `<div style="border: 1px solid #00aaff; width: 100%; padding: 10px; box-sizing: border-box; font-size: 12px;">
                <h2 style="text-align: center;">
                Please use PlexiMail to contact us.
                </h2>
                <p style="word-break: break-all;">
                    Dear Partner<br />
                    I suggest creating your own PlexiMail account, if you haven't already, to ensure the utmost confidentiality of our future correspondence. <br /><br />
                    For your convenience, I’ve put together a streamlined PlexiMail configuration steps for you. <br /><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;1. Answer exactly the prompt for PIN: 
 <strong style="color:red">`,
        p2: `
                </strong><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;2. You will then be asked to confirm your email address in order to acquire your own Web3Storage space, which defaults to 5GB initially. 
                </p>
                <p>
                This process will establish your private PlexiMail address, also allowing you to customize the initial PIN at your convenience. As I do not have access to your newly created address, kindly reply to me through PlexiMail so I can securely collect your contact details. 
                </p>
                <p style="text-align: center;">
                    <button style="background: none; border: none; background-color: #00aaff; color: #fff; padding: 10px;" onclick="window.location.href='`,
        p2_2: `?cfg=`,
        p3: `'">Click to Activate Your PlexiMail</button>
                </p>
                <p>
                Feel free to disregard this offer and create your own PlexiMail account by visiting https://ai-fi.cc directly. Please note that that process may be a bit convoluted with a learning curve to overcome.
                </p>
                    <i style="word-break: break-all;">Note: If you are having problem with the blue button above, copy the URL below and run on any browser to get started.</i><br />
                    <a href="`,
        p4: `?cfg=`,
        p5: `" target="_blank" style="word-break: break-all;">`,
        p6: `?cfg=`,
        p7: `</a>
                </p>
                </div>`,
      },
    },
    tabs: {
      profile: "Profile",
      users: "Users",
      address_book: "Address Book",
      customized_notification: "Customized Notification",
    },
    profile: {
      title: "Enterprise - Profile",
      name: "Enterprise Name",
      name_placeholder: "Please enter name here",
      notes: "Notes",
      notes_placeholder: "Please enter notes here (Optional)",
      cancel: "Cancel",
      close: "Close",
      save: "Save",
    },
    users: {
      add_dlg: {
        title: "Create Account",
        name: "Name",
        name_placeholder: "Please enter name here",
        email: "Email",
        email_placeholder: "Please enter email here",
        phone: "Phone Number",
        phone_placeholder: "Please enter phone number here",
        notes:
          "Notes: After the user is created, please notify the user to check the mailbox to receive the notification email.",
        cancel: "Cancel",
        create: "Create",
      },
      title: "Enterprise - Users",
      users: {
        title: "Accounts",
        col_name: "name",
        col_addr: "Address",
        col_email: "Email",
        col_pwd: "Password",
        col_pin: "PIN",
        col_ops: "Operations",
        empty: "No Accounts",
      },
      close: "Close",
      save: "Save",
      discard: "Discard",
    },
    address_book: {
      users_dlg: {
        title: "Users",
        filter: {
          title: "Filter Users",
          placeholder: "Filtered by name or email",
        },
        empty: "No Users",
        cancel: "Cancel",
      },
      add_dlg: {
        title: {
          create_org: "Create Organization",
          create_contact: "Create Contact",
        },
        name: "Name",
        name_placeholder: "Please enter name here",
        email: "Email",
        email_placeholder: "Please enter email here",
        plexi_addr: "PlexiMail Address",
        plexi_addr_placeholder: "Please enter PlexiMail Address here",
        cancel: "Cancel",
        create: "Create",
      },
      title: "Enterprise - Address Book",
      create: "Create Address Book",
      filter: "Filter by keyword",
      context_menu: {
        edit: "Edit",
        add_org: "Add Sub-group",
        add_user: "Add User",
        delete: "Delete",
      },
      create_org: "Create Organization",
      create_contact: "Create Contact",
      notes: (
        <Box fontSize={"sm"}>
          <strong>Edit:</strong> Enter&nbsp;&nbsp;
          <strong>Add:</strong> shift + A&nbsp;&nbsp;
          <strong>Delete:</strong> Delete
        </Box>
      ),
      contacts: {
        col_select: "Select",
        col_name: "Name",
        col_email: "Email",
        col_addr: "Address",
        col_ops: "OPS",
        empty: "No Contacts",
      },
      filter_contact: "Filter contacts by keyword",
      empty_contact: "No Contacts",
      close: "Close",
      discard: "Discard",
      save: "Save",
    },
    resume: {
      invalid_token:
        "Incorrect API Token format. Verify and ensure the complete string is copied.",
      w3ui: {
        verify: {
          title: "Verifying your email address...",
          message_1: "Click the link in the email we just sent to",
          message_2: "to sign in.",
          message_3: "You can resend authentication email in",
          message_4: "seconds.",
          message_5: "RESEND",
          message_6: "if not succeeding.",
        },
      },
      promotion: {
        title: "PlexiMail by Ai-Fi",
        content: {
          paragraph1: " - Secured End-To-End",
          paragraph2: " - No Metadata Leaking",
          paragraph3: " - No Size Limit",
          paragraph4: " - Open-Source and Forever Free **",
        },
      },
      signIn: {
        title_1: "The",
        title_2: "Trace Email",
        description_1: "Distributed, Permissionless, Traceless and Invisible. ",
        description_2: `You can only reach your partners on the same network. The default is "${DEFAULT_NET_NAME}", a test network displayed on the upper right corner. You may change your network to "${MAINNET_NAME}", the primary public ${BLOCKCHAIN_NAME_CAMEL} blockchain network, or any other test network listed.`,
        description_3:
          "Start a new PlexiMail session with a brand new Ai-Fi Wallet, or resume a previous session by recalling its corresponding wallet. Your wallet contains your PlexiMail addresses and their associated private keys. It is self-directed in maintaining your PlexiMail identities, attestable sorely through your Ai-Fi Wallet.   ",
        note: (
          <>
            ** Press the "Enter" below only if you agree with the{" "}
            <Link
              href="https://ai-fi.cc/terms-of-service.html"
              target="_blank"
              color="#00aaff"
            >
              <i>PlexiMail Terms & Privacy Policy</i>
            </Link>
            .
          </>
        ),
        continue: "Resume",
      },
      resume: "Resume",
    },
  },
});

export default EnterpriseStrings;
