
import {
    KeyHelper,
    // SignedPublicPreKeyType,
    SignalProtocolAddress,
    SessionBuilder,
    // PreKeyType,
    SessionCipher,
    Direction,
    // MessageType 
} from '@privacyresearch/libsignal-protocol-typescript';
import { ethers } from 'ethers';
// import { addMessageToSession } from '@app/sessions/functions';
// import { sessionForRemoteUser, sessionListSubject } from '@app/sessions/state';

import { ClientError, ServerError } from '../common/errors';
import Strings from '../config/Strings';
import { IdentityKeyStatus, SignalInitStep, SignalMessageType } from '../utils/Types';
import SecuritySignalProtocolStoreIndexedDB from './SecuritySignalProtocolStoreIndexedDB';
import { SignalDirectory } from './SignalDirectory';
import { mailAddressDomain, mailAddressSubdomain, mailAddressToSignalIdentity } from '../common/constants';
// import SignalProtocolStoreIndexedDB from './SignalProtocolStoreIndexedDB';

// import * as libsignal from '@privacyresearch/libsignal-protocol-typescript';

class SignalService {
    signalProtocolStore = null;
    signalDirectory = null;
    account = null;
    keyStoreUrl = null;
    constructor(account, subAccount, keyStoreUrl, apiToken, encryptionKey) {
        console.log('SignalService constructor');
        this.account = account;
        this.keyStoreUrl = keyStoreUrl;
        // this.signalProtocolStore = new SignalProtocolStoreIndexedDB(account);

        if (window.appConfig.privacyLevelIsNormal) {
            this.signalProtocolStore = SecuritySignalProtocolStoreIndexedDB.instanceFor(account, subAccount, encryptionKey);
            // this.signalProtocolStore = new SecuritySignalProtocolStoreIndexedDB(account, subAccount, encryptionKey);
        } else {
            this.signalProtocolStore = window.inMemoryDB;
            this.signalProtocolStore.init(account, encryptionKey);
        }  
        
        this.signalDirectory = new SignalDirectory(account, keyStoreUrl, apiToken);
    }

    get needUpdateSignedPreKey() {
        return false;
    }

    async getMyAddress() {
        return await this.signalProtocolStore.getMyAddress();
    }

    async signIdentityKey(identityKey) {
        if (!identityKey || identityKey.length === 0) {
            return null;
        }
        if (identityKey.constructor === ArrayBuffer) {
            identityKey = new Uint8Array(identityKey);
        }
        // const keyBuffer = ethers.base64Decode(identityKey);
        const keyHex = ethers.hexlify(identityKey);

        // '--sign-the-message-silently--'
        const pwd = (window.wallet && window.wallet.asDefaultWallet) ? '--sign-the-message-silently--' : '';
        const web3 = window.web3Helper.web3;
        const signatureHex = await web3.eth.personal.sign(
            keyHex,
            this.account,
            pwd
        );
        const recoveredAddr = window.web3Helper.ecRecover({data: keyHex, signature: signatureHex});
        if (recoveredAddr.toLowerCase() !== this.account) {
            throw ClientError.invalidParameterError(Strings.error.client.invalid_signature);
        }
        
        return signatureHex;
    }
    signalIdentity() {
        return mailAddressToSignalIdentity(`${this.account}@${mailAddressDomain()}`);
    }
    async supplyPreKeys(deviceId, progress = null) {

        const identityKeyPair = await this.signalProtocolStore.getIdentityKeyPair();
        if (!identityKeyPair) {
            throw ClientError.invalidParameterError(Strings.error.client.idkey_not_found);
        }
        if (progress) {
            progress(SignalInitStep.GenerateKeys);
        }

        // const deviceId = await this.signalProtocolStore.getDeviceId();
        const registrationId = await this.signalProtocolStore.getLocalRegistrationId();

        const oneTimePreKeys = [];
        for(let i=0; i<100; i++) {
            const baseKeyId = await this.signalProtocolStore.makeKeyId();
            const preKey = await KeyHelper.generatePreKey(baseKeyId);
            await this.signalProtocolStore.storePreKey(preKey.keyId, preKey.keyPair);
            console.log('preKey: ', preKey);

            const publicPreKey = {
                keyId: preKey.keyId,
                publicKey: preKey.keyPair.pubKey,
            }
            oneTimePreKeys.push(publicPreKey);
        }

        const preKeyBundle = {address: this.signalIdentity(), deviceId, registrationId, oneTimePreKeys};

        if (this.needUpdateSignedPreKey) {
            const signedPreKeyId = await this.signalProtocolStore.makeKeyId();
            const signedPreKey = await KeyHelper.generateSignedPreKey(identityKeyPair, signedPreKeyId);
            await this.signalProtocolStore.storeSignedPreKey(signedPreKey.keyId, signedPreKey.keyPair);

            console.log('signedPreKey: ', signedPreKey);

            const publicSignedPreKey = {
                keyId: signedPreKeyId,
                publicKey: signedPreKey.keyPair.pubKey,
                signature: signedPreKey.signature,
            }
            // TODO: multi-devices
            preKeyBundle['signedPreKey'] = publicSignedPreKey;
            
        }
        const keyStoreUrl = this.keyStoreUrl;
        await this.signalDirectory.storeKeyBundle(this.signalIdentity(), false, keyStoreUrl, preKeyBundle, progress)
    }

    async checkAndUploadPreKeys(isNewDevice, deviceId, registrationId, identityKeyPair, progress = null) {
        if (typeof deviceId === 'undefined' || deviceId === null) {
            deviceId = await this.signalProtocolStore.getDeviceId();
            if (typeof deviceId === 'undefined' || deviceId === null) {
                throw ClientError.invalidParameterError(Strings.error.client.devid_lost)
            }
        }
        if (typeof registrationId === 'undefined' || !registrationId) {
            registrationId = await this.signalProtocolStore.getLocalRegistrationId();
            if (typeof registrationId === 'undefined' || !registrationId) {
                throw ClientError.invalidParameterError(Strings.error.client.regid_lost)
            }
        }
        if (typeof identityKeyPair === 'undefined' || !identityKeyPair) {
            identityKeyPair = await this.signalProtocolStore.getIdentityKeyPair();

            if (typeof identityKeyPair === 'undefined' || !identityKeyPair) {
                throw ClientError.invalidParameterError(Strings.error.client.idkey_lost)
            }
        }
        

        const oneTimePreKeys = [];
        for(let i=0; i<100; i++) {
            const baseKeyId = await this.signalProtocolStore.makeKeyId();
            const preKey = await KeyHelper.generatePreKey(baseKeyId);
            await this.signalProtocolStore.storePreKey(preKey.keyId, preKey.keyPair);
            console.log('preKey: ', preKey);

            const publicPreKey = {
                keyId: preKey.keyId,
                publicKey: preKey.keyPair.pubKey,
            }
            oneTimePreKeys.push(publicPreKey);
        }

        const signedPreKeyId = await this.signalProtocolStore.makeKeyId();
        const signedPreKey = await KeyHelper.generateSignedPreKey(identityKeyPair, signedPreKeyId);
        await this.signalProtocolStore.storeSignedPreKey(signedPreKey.keyId, signedPreKey.keyPair);
        console.log('signedPreKey: ', signedPreKey);

        const publicSignedPreKey = {
            keyId: signedPreKeyId,
            publicKey: signedPreKey.keyPair.pubKey,
            signature: signedPreKey.signature,
        }
        const keyStoreUrl = this.keyStoreUrl;


        const keyBundle = {
            address: this.signalIdentity(),
            deviceId: (isNewDevice ? 1 : deviceId),
            registrationId,
            identityKey: identityKeyPair.pubKey,
            signedPreKey: publicSignedPreKey,
            oneTimePreKeys: oneTimePreKeys,
        };

        if (identityKeyPair.pubKey) {
            const signature = await this.signIdentityKey(identityKeyPair.pubKey);
            if (signature) {
                keyBundle.signature = signature;
            }
            // throw new Error('DEBUG: Force Stop');
        }

        // TODO: multi-devices
        const res = await this.signalDirectory.storeKeyBundle(this.signalIdentity(), isNewDevice, keyStoreUrl, keyBundle, progress)
        return res.data;
    }
    async reset() {
        await this.signalProtocolStore.open();
        await this.signalProtocolStore.clearDatabase();
        // await this.signalProtocolStore.reset();
    }

    async resetFor(account) {
        await this.signalProtocolStore.open();
        await this.signalProtocolStore.clearDatabaseFor(account);
    }

    async clearDatabaseIfNeeded() {
        if (window.appConfig.privacyLevelIsNormal) {
            await this.signalProtocolStore.clearDatabase();
        } else {
            window.inMemoryDB.clearDatabase();
        }
    }

    static async clear() {

        if (window.appConfig.privacyLevelIsNormal) {
            await SecuritySignalProtocolStoreIndexedDB.clear();
        } else {
            window.inMemoryDB.clearDatabase();
        }
    }
    static async clearFor(account) {

        if (window.appConfig.privacyLevelIsNormal) {
            await SecuritySignalProtocolStoreIndexedDB.clearFor(account);
        } else {
            window.inMemoryDB.clearDatabaseFor(account);
        }
    }

    async waitTransactionReceiptMined(txHash, interval=5000) {
        const result = await this.signalDirectory.waitTransactionReceiptMined(txHash, interval);
        return result;
    }
    async storeIdentityKeyIntoSmartContract() {
        const deviceId = await this.signalProtocolStore.getDeviceId();
        const identityKeyPair = await this.signalProtocolStore.getIdentityKeyPair();


        const identityKey = ethers.encodeBase64(new Uint8Array(identityKeyPair.pubKey));
        const keyStoreUrl = this.keyStoreUrl;
        const devices = [{id: deviceId}];
        
        const result = await this.signalDirectory.storeIdentityKeyIntoSmartContract(this.signalDirectory.smartContractAddress, identityKey, keyStoreUrl, devices);
        return result;
    }
    async createIdIfNeeded(progress = null) {
        
        await this.signalProtocolStore.open();

        const localRegistrationId = await this.signalProtocolStore.getLocalRegistrationId();
        const existingIdentityKeyPair = await this.signalProtocolStore.getIdentityKeyPair();
        if ((localRegistrationId > 0) && existingIdentityKeyPair) {
            await this.checkPreKeys(progress);
            return null;
        }
        
        if (progress) {
            progress(SignalInitStep.GenerateKeys);
        }

        const deviceId = 1;
        await this.signalProtocolStore.saveDeviceId(deviceId);

        const registrationId = KeyHelper.generateRegistrationId();
        await this.signalProtocolStore.saveLocalRegistrationId(registrationId);
        console.log('registrationId: ', registrationId);

        const identityKeyPair = await KeyHelper.generateIdentityKeyPair();
        await this.signalProtocolStore.saveIdentityKeyPair(identityKeyPair);
        console.log('identityKeyPair: ', identityKeyPair);

        const tx = await this.checkAndUploadPreKeys(true, deviceId, registrationId, identityKeyPair, progress);
        return tx;
    }

    async isInitialized() {
        await this.signalProtocolStore.open();
        const localRegistrationId = await this.signalProtocolStore.getLocalRegistrationId();
        const existingIdentityKeyPair = await this.signalProtocolStore.getIdentityKeyPair();
        if ((localRegistrationId > 0) && existingIdentityKeyPair) {
            return true;
        }
        return false;
    }

    static async isInitializedFor(account) {

        if (window.appConfig.privacyLevelIsNormal) {
            return await SecuritySignalProtocolStoreIndexedDB.isInitializedFor(account);
        } else {
            return window.inMemoryDB.isInitializedFor(account);
        }
    }

    async createSecondaryDeviceIfNeeded() {

        await this.signalProtocolStore.open();
        const localRegistrationId = await this.signalProtocolStore.getLocalRegistrationId();
        const existingIdentityKeyPair = await this.signalProtocolStore.getIdentityKeyPair();
        if ((localRegistrationId > 0) && existingIdentityKeyPair) {
            return;
        }
        const deviceId = await this.signalDirectory.getLatestRegisteredDeviceId(this.account) + 1;
        await this.signalProtocolStore.saveDeviceId(deviceId);

        const registrationId = KeyHelper.generateRegistrationId();
        await this.signalProtocolStore.saveLocalRegistrationId(registrationId);
        console.log('registrationId: ', registrationId);

        const identityKeyPair = await KeyHelper.generateIdentityKeyPair();
        await this.signalProtocolStore.saveIdentityKeyPair(identityKeyPair);
        console.log('identityKeyPair: ', identityKeyPair);

        const tx = await this.checkAndUploadPreKeys(true, deviceId, registrationId, identityKeyPair);
        return tx;
    }

    async checkPreKeys(progress=null) {
        // TODO:
        await this.signalProtocolStore.open();
        if (progress) {
            progress(SignalInitStep.CheckPreKeyCount);
        }
        const verified = await this.verifyIdentityKey(window.appConfig.mailAddressNeedToBeVerified);
        if (!verified) {
            throw ClientError.identityKeyError('Identity key is out of date.')
        }
        if (!window.appConfig.mailAddressNeedToBeVerified) {
            const identityKeyBundle = await this.signalDirectory.getIdentityKeyBundleFromSmartContract(this.signalIdentity());
            if (identityKeyBundle && identityKeyBundle.identityKeyIsVerified) {
                const identityKeyPair = await this.signalProtocolStore.getIdentityKeyPair();
                const localIdentityKey = ethers.encodeBase64(new Uint8Array(identityKeyPair.pubKey));
                if (localIdentityKey === identityKeyBundle.identityKey) {
                    window.appConfig.mailAddressNeedToBeVerified = true;
                }
            }
        }
        // const isOutOfDate = await this.isIdentityKeyOutOfDate();
        // if (isOutOfDate) {
        //     throw ClientError.identityKeyError('Identity key is out of date.');
        // }

        const deviceId = await this.signalProtocolStore.getDeviceId();
        const preKeyCount = await this.signalDirectory.checkPreKeyCount(deviceId, this.keyStoreUrl);
        if (preKeyCount <= 50) {
            await this.supplyPreKeys(deviceId, progress);
        }
    }

    async startSessions (recipients) {

        const identityKeys = await this.signalDirectory.getIdentityKeys(recipients);
        const addressNeedToEstablish = []
        for(let name in identityKeys) {
            const identityKey = identityKeys[name];
            const idKey = identityKey.identityKey;
            const devices = identityKey.devices;
            for(let k in devices) {
                let device = parseInt(k);
                let address = new SignalProtocolAddress(name, device);
                let encodedAddress = await address.encode();
                if(!this.signalProtocolStore.loadSession(encodedAddress)) {
                    addressNeedToEstablish.push({
                        address: encodedAddress,
                        identityKey: idKey
                    })
                }
            }
        }

        // get Brünhild' key bundle
        const recipientsBundles = this.signalDirectory.getPreKeyBundles(addressNeedToEstablish);
        // const recipientsBundlesArray = [];
        for(let name in recipientsBundles) {
            let devices = recipientsBundles[name];
            for (let k in devices) {
                const deviceId = parseInt(k);
                const recipientBundle = devices[k];
                const recipientAddress = new SignalProtocolAddress(name, deviceId).encode();
                const sessionBuilder = new SessionBuilder(this.signalProtocolStore, recipientAddress);
                // recipientsBundlesArray.push(recipientsBundle);
                await sessionBuilder.processPreKey(recipientBundle);
            }
        }
    };

    async encryptEnvelope(address, deviceId, envelope) {
        if (typeof envelope === 'undefined' || envelope === null) {
            throw ClientError.invalidParameterError(Strings.error.client.msg_is_null);
        } else if (typeof envelope === 'string') {
            const enc = new TextEncoder(); // always utf-8
            envelope = enc.encode(envelope).buffer;
        } else if (envelope.constructor === Uint8Array) {
            envelope = envelope.buffer;
        } else if (envelope.constructor === ArrayBuffer) {

        } else {
            throw ClientError.invalidParameterError(Strings.error.client.unsupported_msg_type);
        }

        const signalIdentity = address.replaceAll('.', '_').replaceAll('@', '_at_');
        const remoteAddress = new SignalProtocolAddress(signalIdentity, deviceId);

        // Now we can send an encrypted message
        const sessionCipher = new SessionCipher(this.signalProtocolStore, remoteAddress);
        const ciphertext = await sessionCipher.encrypt(
            envelope
        );
        // const ciphertextInJsonString = JSON.stringify(ciphertext);
        return {remoteAddress, ciphertext};
    }

    async decryptEnvelope(remoteAddress, ciphertext) {

        const sessionCipher = new SessionCipher(this.signalProtocolStore, remoteAddress);
        if (ciphertext.type === SignalMessageType.WhisperMessage) { // ciphertext message
            const plaintextBytes = await sessionCipher.decryptWhisperMessage(ciphertext.body, 'binary');
            return new Uint8Array(plaintextBytes);
        } else if (ciphertext.type === SignalMessageType.PreKeyWhisperMessage) { // prekey message
            const plaintextBytes = await sessionCipher.decryptPreKeyWhisperMessage(ciphertext.body, 'binary');
            return new Uint8Array(plaintextBytes);
        }
    }
    
    
    async getEncryptionKeyBundle(uid) {
        const key = await this.signalProtocolStore.getEncryptionKeyBundle(uid);
        return key;
    }
    async saveEncryptionKeyBundle(uid, key) {
        await this.signalProtocolStore.saveEncryptionKeyBundle(uid, key);
    }
    async hasEncryptionKeyBundle(uid) {
        const hasKey = await this.signalProtocolStore.hasEncryptionKeyBundle(uid);
        return hasKey; 
    }

    // smart contract
    async getExistedDevices(address, identityKeyBundle) {
        const devices = await this.signalDirectory.getExistedDevices(address, identityKeyBundle);
        return devices;
    }

    async getVerifiedInfo(address) {
        const isVerified = await this.signalDirectory.getVerifiedInfo(address);
        return isVerified;
    }
    async getIdentityKeyBundle(address) {
        const identityKeyBundle = await this.signalDirectory.getIdentityKeyBundle(address);
        return identityKeyBundle;
    }

    async getIdentityKey(address, identityKeyBundle=null) {
        if (identityKeyBundle) {
            return identityKeyBundle.identityKey;
        }
        const key = await this.getIdentityKeyBundle(address).identityKey;
        return key;
        
        // const identityKey = await this.signalDirectory.getIdentityKey(address, identityKeyBundle);
        // return identityKey;
    }

    async isIdentityKeyOutOfDate() {
        const identityKeyPair = await this.signalProtocolStore.getIdentityKeyPair();
        const localIdentityKey = ethers.encodeBase64(new Uint8Array(identityKeyPair.pubKey));
        if (!localIdentityKey) {
            return true;
        }

        if (window.appConfig.mailAddressNeedToBeVerified) {
            const verifyBySmartContract = true;
            const res = await this.signalDirectory.verifyIdentityKey(this.signalIdentity(), localIdentityKey, verifyBySmartContract);
            return res;
        }

        
        const identityKeyBundle = await this.signalDirectory.getIdentityKeyBundle(this.signalIdentity());
        if (!identityKeyBundle) {
            return true;
        }

        if (identityKeyBundle.identityKeyIsVerified && !window.appConfig.mailAddressNeedToBeVerified) {
            window.appConfig.mailAddressNeedToBeVerified = true;
        }
        return false;
    }

    async verifyIdentityKey(verifyBySmartContract=true) {
        const identityKeyPair = await this.signalProtocolStore.getIdentityKeyPair();
        const localIdentityKey = ethers.encodeBase64(new Uint8Array(identityKeyPair.pubKey));
        const res = await this.signalDirectory.verifyIdentityKey(this.signalIdentity(), localIdentityKey, verifyBySmartContract);
        return res;
    }

    async checkIdentityKey(address, identityKeyBundle=null) {
        // const signalIdentity = address.replaceAll('.', '_').replaceAll('@', '_at_');
        await this.signalDirectory.checkIdentityKey(address, identityKeyBundle);
    }
    async getPreKeyBundle(address, deviceId, identityKeyBundle=null) {
        const res = await this.signalDirectory.getPreKeyBundle(address, deviceId, identityKeyBundle);
        return res;
    }

    async getTrustedIdentityKey(address) {
        const identityKey = await this.signalProtocolStore.loadIdentityKey(address);
        return identityKey;
    }

    async sessionIsReady(address, deviceId, identityKey) {
        
        const signalIdentity = address.replaceAll('.', '_').replaceAll('@', '_at_');
        const remoteAddress = new SignalProtocolAddress(signalIdentity, deviceId);
        if (identityKey) {
            const identityKeyArray = ethers.decodeBase64(identityKey).buffer;
            const status = await this.signalProtocolStore.identityKeyStatus(address, identityKeyArray, Direction.SENDING);
            if (status === IdentityKeyStatus.Changed || status === IdentityKeyStatus.NotExist) {
                return false;
            }
        }
        
        const session = await this.signalProtocolStore.loadSession(remoteAddress.toString());
        if (!session) {
            return false;
        }
        return true;
    }

    async startSession(address, deviceId, identityKeyBundle=null) {
        const signalIdentity = address.replaceAll('.', '_').replaceAll('@', '_at_');
        const remoteAddress = new SignalProtocolAddress(signalIdentity, deviceId);
        /**
         * Pre Key Bundle Structure:
         * {
         *      identityKey: ArrayBuffer,
         *      signedPreKey: {
         *          keyId: Number,
         *          publicKey: ArrayBuffer,
         *          signature: ArrayBuffer
         *      },
         *      preKey: {
         *          keyId: Number,
         *          publicKey: ArrayBuffer
         *      },
         *      registrationId: Number
         * }
         */
        console.log('get prekey bundle of "' + address + '.' + deviceId + '" from root registry.')
        const resp = await this.getPreKeyBundle(address, deviceId, identityKeyBundle);
        if (resp.code !== 0) {
            throw ServerError.from(resp);
        }
        const preKeyBundle = resp.data;
        const sessionBuilder = new SessionBuilder(this.signalProtocolStore, remoteAddress);
        await sessionBuilder.processPreKey(preKeyBundle);
    }

    async saveContact(contact) {
        return await this.signalProtocolStore.saveContact(contact);
    }

    async deleteContact(uuid) {
        return await this.signalProtocolStore.deleteContact(uuid);
    }

    async getAllContacts() {
        const contacts = await this.signalProtocolStore.getAllContacts();
        return contacts;
    }
    async getUser(addr) {
        return await this.signalProtocolStore.getUser(addr);
    }
    async saveUser(user) {
        return await this.signalProtocolStore.saveUser(user);
    }

    async deleteUser(addr) {
        return await this.signalProtocolStore.deleteUser(addr);
    }

    async getAllUsers() {
        const users = await this.signalProtocolStore.getAllUsers();
        return users;
    }

    async getEnterpriseProfile() {
        return await this.signalProtocolStore.getEnterpriseProfile();
    }

    async saveEnterpriseProfile(profile) {
        return await this.signalProtocolStore.saveEnterpriseProfile(profile);
    }

    async getNotifyTemplate() {
        return await this.signalProtocolStore.getNotifyTemplate();
    }
    async saveNotifyTemplate(template) {
        return await this.signalProtocolStore.saveNotifyTemplate(template);
    }
    
    async makeNextMessageId() {
        const messageId = await this.signalProtocolStore.makeNextMessageId();
        return messageId;
    }
    async hasDraft(type) {
        const has = await this.signalProtocolStore.hasDraft(type);
        return has;
    }
    async removeDraft(type) {
        await this.signalProtocolStore.removeDraft(type);
    }
    async getDraft(type) {
        const draft = await this.signalProtocolStore.getDraft(type);
        return draft;
    }
    async saveDraft(type, draft){
        await this.signalProtocolStore.saveDraft(type, draft);
    }


    // {id: 'alice@gmail.com', key: 'base64', delegation: 'base64'}
    async saveDelegation(delegation) {
        return await this.signalProtocolStore.saveDelegation(delegation);
    }

    async loadDelegation(email) {
        const delegation = await this.signalProtocolStore.loadDelegation(email);
        return delegation;
    }
    async loadAllDelegations() {
        const delegations = await this.signalProtocolStore.loadAllDelegations();
        return delegations;
    }
    
    async deleteDelegation(email) {
        await this.signalProtocolStore.deleteDelegation(email);
    }
}

export default SignalService;