// import { Link, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import LocalizedStrings from 'react-localization';
// import { TutorialModalLink } from '../components/TutorialModalTrigger';
const BLOCKCHAIN_NAME_CAMEL = 'Avalanche';
const COIN_SYMBOL = 'AVAX'; //ETH
const WalletStrings = new LocalizedStrings({
    en: {
        appName: "Ai-Fi Wallet",
        error: {
            not_match: 'Please enter the exact key info from last time you saved the wallet'
        },
        ui: {
            create: {
                title: 'Add New Account',
                name: 'Name',
                name_placeholder: 'Please enter name here',
                name_taken: 'The name is taken',
                cancel: 'Cancel',
                create: 'Create',
                name_too_long: 'The name length exceeds 100',
            },
            import: {
                title: 'Import Select Accounts from an Existing Crypton',
                entropy_salt: 'Entropy Salt',
                entropy_salt_placeholder: 'Please enter entropy salt',
                passphrase: 'Passphrase',
                passphrase_placeholder: 'Please enter passphrase',
                import_wallets: 'Import Accounts',
                name: 'Name',
                wallet: 'Account',
                already_imported: 'Account has already been imported',
                name_taken: 'The name is taken',
                name_available: 'The name is available',
                name_too_long: 'The name length exceeds 100',
                identical: 'Importing from the same wallet is not allowed',
                cancel: 'Cancel',
                next: 'Next',
                import: 'Import'
            },
            override: {
                title: 'Warning',
                message: 'This Crypton already exists. Do you want to overwrite it? Please note that by doing so, you will lose all cryptocurrencies in the wallet as well as all past emails associated with the Crypton.',
                button: {
                    yes: 'Override',
                    no: 'No'
                }
            },
            one_click: {
                title: '1-Click Resume',
                description: 'This is a streamlined workflow to recover your previously established PlexiMail session without resetting any session parameters, such as Privacy Level, PIN, or the Web3Storage configuration. The standard email verification is still required.',
                note: '* If the Privacy Level is inconsistent with the current running platform, it will be modified to suit the new environment (but only for this specific instance).',
                get_started: 'Get Started'
            },
            choose: {
                title: 'The Ai-Fi Wallet',     
                description_1: `Congratulations on creating your secure PlexiMail identity! The trustless nature of the PlexiMail smart contract on the ICP blockchain ensures any hijack attempts against your PlexiMail Identity are detectable. You can also integrate with other smart contracts for varying levels of security and auditability by adjusting your Settings.`,
                description_2: <>Next, we will configure a custom wallet to further strengthen the confidentiality and anonymity of your PlexiMail activities.
                <br /> <br />
                Important: This custom wallet is not for regular cryptocurrency transactions. It's a technological tool designed to limit traceability across the blockchain and protect your identity. <br />
                <br />
                <br /></>,
                
                get_started: 'Get Started'
            },
            config: {
                title: 'Create New or Recover Wallet',
                create_new: 'Create New',
                recover: 'Recover',
                back: 'Back',
                network: {
                    title: 'Select Blockchain',
                    description: 'PlexiMail adopts the self-executing smart contract to register and protect the PlexiMail addresses/identities. Once registered, the authenticity of a PlexiMail identity becomes auditable without a central authority and inalterable without owning the private key.',
                    mainnet: 'Mainnet',
                    mainnet_note: '* Pay AVAX crypto to register your PlexiMail addresses on the Avalanche Mainnet.',
                    testnet: 'Testnet',
                    testnet_note: '* Conduct a test run on the Fuji Testnet without incurring AVAX charges. Your PlexiMail addresses are not fully protected in this case. You may upgrade your run to the Mainnet later.',
                    unverified: 'Trial without Smart Contract',
                    unverified_node: '* Skip the smart contract registration altogether. Your PlexiMail identities will be checkmarked as “unverified”. You may upgrade your trial to run with the smart contract later.'
                },
                create: {
                    title: 'Create Screen Lock PIN Code',
                    pin_code: 'Please enter the new PIN',
                    confirm_pin_code: 'Please confirm the PIN',
                    creating: 'Creating new wallet and session...',
                    note: '* PIN is a 6-digit secret used to unlock the cached session after idle timeout.',
                    pin_not_match: 'The two PIN codes entered do not match.',
                    back: 'Back'
                },
                recovery: {
                    title: 'Wallet Recovery',
                    entropy_salt: 'Entropy Entender',
                    passphrase: 'Passphrase',
                    recover: 'Recover',
                    back: 'Back',
                    safari: 'Currently the backup with W3:UI configuration is not supported on Safari browser.',
                    token_not_exists: 'Token does not exist, please try again later with another password.',
                }
                
            },
            unlock: {
                title: 'Unlock Local Session with PIN',
                unlocking: 'Unlocking...',
                note: '* PIN is a 6-digit secret used to unlock the cached session after idle timeout.',
                never_used: 'This wallet has never been used and can be safely discarded',
                discard: 'Discard this Wallet'
            },
            connect: {
                title: 'Your Wallet, Your Addresses',
                description: `Set the following address as the default address.`,
                connect: 'Set',
                deny: 'Deny',
                
            },
            wallet: {
                copy: 'Copy Address',
                advanced: 'Advanced',
                create: 'Create New Account',
                import: 'Import Accounts',

                buy: `Buy ${COIN_SYMBOL} with Plexi Token(s)`,
                migrate: 'Liquidate Assets'
            },
            sign_tx: {
                title: 'Register Identity Address',
                description_1: `Your PlexiMail address is registered on the ${BLOCKCHAIN_NAME_CAMEL} Blockchain through PlexiMail Smart Contract. It is anonymous but auditable.`,
                description_2: <>
                Benefits of Smart Contract:<br />
                1. Registered address is not updatable without the private key. <br />
                2. All updates are recorded and immutable. <br />
                3. It is trustless. <br /></>,
                description_3: `To enjoy all these benefits, you need to make payments to the ${BLOCKCHAIN_NAME_CAMEL} Blockchain. Currently, only the registration incurs a one-time fee.`,
                address: 'Address: ',
                identity_key: 'Identity Key: ',
                key_server: 'Key Store Server: ',
                estimated_cost: 'Estimated Cost: ',
                max_cost: 'Max Cost: ',
                within_30_seconds: '* Should take no more than 30 seconds: ',
                cancel: 'Cancel',
                publish: 'Register'
            },
            sign: {
                title: 'Verify Account Ownership',
                description: 'This is a standard Public Key Cryptographic challenge from the key server to verify your PlexiMail identity. This challenge text must be correctly signed with the private key in your wallet.',
                cancel: 'Cancel',
                sign: 'Sign & Verify'
            },
            backup: {
                title: 'Crypton Double-Locks Your Session Data',
                backup_required: 'This is to self-manage your wallet, session contexts and messaging history.',
                next: 'Next',
                entropy_salt: 'Entropy Extender',
                entropy_salt_placeholder: 'e.g. PlexiMail maximizes privacy ...',
                entropy_salt_note: '* Extender leverages physical security, eliminating memorization. Write down your code, store securely (vault, desk, wallet, etc.). The underline adopts the same color scheme.',
                recovery_passphrase: 'Recovery Passphrase',
                recovery_passphrase_placeholder: 'e.g. PlexiMail provides traceless privacy ...',
                confirm_recovery_passphrase: 'Confirm Recovery Passphrase',
                backup: 'Generate',
                update: 'Confirm',
                back: 'Back',
                token_exists: 'Configuration database already exists. Please try a different one.',
                passphrase_not_match: 'The two Passphrases entered do not match.',
                passphrase_note: 'A memorized sequence of words or text used to unlock secure access. Example: "ai-fi.cc - PlexiMail keeps it private". Type to see the underline color change based on your input strength. Green indicates acceptable strength.',
                description_1: 'Crypton is a software apparatus that protects your wallet, online contexts and messaging history with two independent locks, each based on a passphrase of high andomness or entropy. Crypton persists your PlexiMail sessions over time and resides anonymously in the PlexiMail Registra hidden in plain sight. It is essential to handle these passphrases with extreme care to avoid any potential loss of session data. You and you alone have access to it.',
                description_2: 'The recovery passphrase should consist of up to 100 bits of randomness and be memorized. It is further reinforced by the second key phrase, called the "Entropy Extender," securely stored in a designated location (e.g., lockbox, pocket wallet, drawer, etc.), relying on physical security for protection. The Entropy Extender should incorporate as much randomness as possible, without requiring memorization. Together, these key phrases provide a level of security comparable to or greater than that of the private keys used to safeguard significant fortunes across all cryptocurrency blockchains.',
                description_3: '',
                description_4: 'A new account with an individualized PlexiMail address is ready. Confirm with the double keys to introduce it into your wallet.',
                description_5: 'An account created with a new PlexiMail address is ready. Confirm to add it to your wallet using the wallet crypton keys.',
                description_6: 'The new account name is ready. Confirm with their wallet crypton keys.',
                succeed: 'Successfully completed',
                close: 'Close',
                new_account: 'The new account is successfully added. Click "Next" to setup PlexiMail with the new account',
                import_account: 'The selected accounts have been imported.',
                name_changed: 'The account name has been changed.'
            },
            change_pin: {
                reset_title: 'Reset Screen Lock PIN on Reentry',
                title: 'Set Screen Lock PIN Code',
                pin_code: 'Please enter the PIN',
                confirm_pin_code: 'Please confirm the PIN',
                changing: 'Configuring the PIN Code...',
                note: '*  PIN is a 6-digit secret to unlock the cached session after idle timeout.',
                back: 'Back'
            },
            migrate: {
                title: 'Liquidate Assets',
                address: 'Please enter the receiving account',
                note: '* Liquidate all current assets into the specified account.',
                migrate: 'Liquidate'
            },
            change_w3s_api_token: {
                title: 'Change Web3Storage',
                description: 'Paste your Web3 Storage token here in order for PlexiMail to locate your private storage.',
                placeholder: 'Enter Web3 Storage API Token here',
                changing: 'Changing...',
                note: 'All your PlexiMail data are stored in your own private storage space acquired from Web3Storage. Although it is in plain sight, it is virtually anonymous and fully encrypted through dynamic keys from your own wallet with strength equivalent to any public blockchain transaction keys.',
                apply: 'Apply'
            },
            selector: {
                title: 'Switch Account',
                
            }
        }
    }
})

export default WalletStrings;
