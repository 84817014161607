import BaseRPCHandler from "./BaseRPCHandler";

export default class WalletSwitchEthereumChainHandler extends BaseRPCHandler {
    constructor() {
        super('wallet_switchEthereumChain');
    }
    
    async handle(provider, request) {

    }
    
}