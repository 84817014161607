export const idlFactory = ({ IDL }) => {
  const IdentityInfo = IDL.Record({
    'email_address' : IDL.Record({
      'verified' : IDL.Bool,
      'address' : IDL.Text,
      'timestamp' : IDL.Int,
    }),
    'phone_number' : IDL.Record({
      'verified' : IDL.Bool,
      'address' : IDL.Text,
      'timestamp' : IDL.Int,
    }),
  });
  const PIIResponse = IDL.Record({
    'pii' : IdentityInfo,
    'signature' : IDL.Text,
    'publicKey' : IDL.Text,
    'timestamp' : IDL.Int,
  });
  const HttpHeader = IDL.Record({ 'value' : IDL.Text, 'name' : IDL.Text });
  const HttpResponsePayload = IDL.Record({
    'status' : IDL.Nat,
    'body' : IDL.Vec(IDL.Nat8),
    'headers' : IDL.Vec(HttpHeader),
  });
  const TransformArgs = IDL.Record({
    'context' : IDL.Vec(IDL.Nat8),
    'response' : HttpResponsePayload,
  });
  const CanisterHttpResponsePayload = IDL.Record({
    'status' : IDL.Nat,
    'body' : IDL.Vec(IDL.Nat8),
    'headers' : IDL.Vec(HttpHeader),
  });
  const IdentityService = IDL.Service({
    'getProofOfIdentity' : IDL.Func([IDL.Text], [IdentityInfo], []),
    'getSignedProofOfIdentity' : IDL.Func(
        [IDL.Text, IDL.Text],
        [PIIResponse],
        [],
      ),
    'public_key' : IDL.Func(
        [],
        [
          IDL.Variant({
            'Ok' : IDL.Record({ 'public_key_hex' : IDL.Text }),
            'Err' : IDL.Text,
          }),
        ],
        [],
      ),
    'resetProofOfIdentity' : IDL.Func([IDL.Text], [], []),
    'sendEmail' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'sendVerificationEmail' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'sendVerificationSMS' : IDL.Func([IDL.Text, IDL.Text], [IDL.Text], []),
    'signText' : IDL.Func(
        [IDL.Text],
        [
          IDL.Variant({
            'Ok' : IDL.Record({ 'signature_hex' : IDL.Text }),
            'Err' : IDL.Text,
          }),
        ],
        [],
      ),
    'testGenerateVerificationCode' : IDL.Func([], [IDL.Text], []),
    'testURLEncode' : IDL.Func([], [IDL.Text], []),
    'transform' : IDL.Func(
        [TransformArgs],
        [CanisterHttpResponsePayload],
        ['query'],
      ),
    'verifyVerificationEmail' : IDL.Func(
        [IDL.Text, IDL.Text, IDL.Text],
        [],
        [],
      ),
    'verifyVerificationSMS' : IDL.Func([IDL.Text, IDL.Text, IDL.Text], [], []),
  });
  return IdentityService;
};
export const init = ({ IDL }) => { return []; };
