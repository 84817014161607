// import AFWalletRPCError from "../AFWalletRPCError";
import BaseRPCHandler from "./BaseRPCHandler";

export default class EthSendTransactionHandler extends BaseRPCHandler {
    constructor() {
        super('eth_sendTransaction');
    }
    
    async handle(provider, request) {
        
        console.log('EthSendTransactionHandler request:', request);
        
        // const confirmed = await provider.wallet.confirm(request);
        // if (!confirmed) {
        //     throw AFWalletRPCError.userRejectedRequest;
        // }
        
        // const {params} = request;
        
        // const res = await provider.wallet.signer.sendTransaction(params[0]);
        // return res.hash;

        const txhash = await provider.wallet.confirm(request);
        return txhash;
    }
}