import {
  Actor,
  HttpAgent,
} from '@dfinity/agent';
import { CANISTER_ID_PLEXIMAIL_BACKEND } from './backend';
import { idlFactory } from './backend';
// d.ts file is broken, importing idlFactory doesn't work from a .ts file
import { idlFactory as identityIdlFactor } from '../declarations/pleximail_identity/pleximail_identity.did.js';

export function createActor(options) {
  const hostOptions = {
    host:
      process.env.DFX_NETWORK === 'ic'
        ? `https://${CANISTER_ID_PLEXIMAIL_BACKEND}.ic0.app`
        : `http://localhost:4943?canisterId=${CANISTER_ID_PLEXIMAIL_BACKEND}`,
  };
  if (!options) {
    options = {
      agentOptions: hostOptions,
    };
  } else if (!options.agentOptions) {
    options.agentOptions = hostOptions;
  } else {
    options.agentOptions.host = hostOptions.host;
  }

  const agent = new HttpAgent({ ...options.agentOptions });
  // Fetch root key for certificate validation during development
  if (process.env.NODE_ENV !== 'production') {
    console.log(`Dev environment - fetching root key...`);

    agent.fetchRootKey().catch((err) => {
      console.warn(
        'Unable to fetch root key. Check to ensure that your local replica is running'
      );
      console.error(err);
    });
  }

  // Creates an actor with using the candid interface and the HttpAgent
  return Actor.createActor(idlFactory, {
    agent,
    canisterId: CANISTER_ID_PLEXIMAIL_BACKEND,
    ...options?.actorOptions,
  });
}



export function createIdentityActor(canisterId, options) {
  const hostOptions = {
    host:
      process.env.DFX_NETWORK === 'ic'
        ? `https://${canisterId}.ic0.app`
        : `http://localhost:4943?canisterId=${canisterId}`,
  };
  if (!options) {
    options = {
      agentOptions: hostOptions,
    };
  } else if (!options.agentOptions) {
    options.agentOptions = hostOptions;
  } else {
    options.agentOptions.host = hostOptions.host;
  }

  const agent = new HttpAgent({ ...options.agentOptions });
  // Fetch root key for certificate validation during development
  if (process.env.NODE_ENV !== 'production') {
    console.log(`Dev environment - fetching root key...`);

    agent.fetchRootKey().catch((err) => {
      console.warn(
        'Unable to fetch root key. Check to ensure that your local replica is running'
      );
      console.error(err);
    });
  }

  // Creates an actor with using the candid interface and the HttpAgent
  return Actor.createActor(identityIdlFactor, {
    agent,
    canisterId: canisterId,
    ...options?.actorOptions,
  });
}