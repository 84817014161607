// import './App.css';
import React, { useState, useEffect } from "react";
import { CheckCircleIcon, CloseIcon } from "@chakra-ui/icons";

import {
  VStack,
  Text,
  // Modal,
  // ModalOverlay,
  // ModalContent,
  // ModalHeader,
  // ModalFooter,
  // ModalBody,
  ModalCloseButton,
  useDisclosure,
  //   Link,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Link,
  MenuItem,
  Stack,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormHelperText,
  Select,
  Radio,
  CheckboxGroup,
  Checkbox,
  Box,
  Heading,
  Flex,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { showError } from "../../common/errors";
import MailAddressUtils from "../../utils/MailAddressUtils";
const title_size = "sm";

function BasicPanel({ creationContext, setCreationContext }) {

  const [isNameInvalid, setNameInvalid] = useState(false);
  const [isEmailInvalid, setEmailInvalid] = useState(false);
  const [isDomainInvalid, setDomainInvalid] = useState(false);
  
  return (
    <Stack fontSize={title_size}>
      <Heading size={title_size}>1. Basic</Heading>
      <FormControl isInvalid={isNameInvalid}>
        <FormLabel>Name</FormLabel>
        <Input
          type="text"
          placeholder={"eg. Ai-Fi.net Inc"}
          onChange={(e) => {
            const name = e.target.value || '';

            if (/^[\w\W_.]+$/.test(name)) {
              setNameInvalid(false);
              setCreationContext({...creationContext, 
                basic: {...creationContext.basic, name: name}, 
                isBasicReady: (creationContext.basic.email && !isEmailInvalid)&& (creationContext.basic.domain && !isDomainInvalid)
              }); 
            } else {
              setNameInvalid(true);
              setCreationContext({...creationContext, 
                basic: {...creationContext.basic, name: null}, 
                isBasicReady: false
              }); 
            }
          }}
        />
        {isNameInvalid ? (
          <FormErrorMessage>Name is required.</FormErrorMessage>
        ) : (
          <FormHelperText>
            A business name can be your company name.
          </FormHelperText>
        )}
      </FormControl>
      <FormControl isInvalid={isDomainInvalid}>
      <FormLabel>Domain Name</FormLabel>
      <Input
          type="text"
          placeholder={"eg. ai-fi.net"}
          onChange={(e) => {
            const domainName = e.target.value.trim().toLowerCase();
            if (
              /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(
                domainName
              )
            ) {
              setCreationContext({...creationContext, 
                basic: {...creationContext.basic, domain: domainName}, 
                domain: { ...creationContext.domain, domain: domainName }, 
                isBasicReady: (creationContext.basic.email && !isEmailInvalid) && (creationContext.basic.name && !isNameInvalid)
              }); 
              setDomainInvalid(false);
            } else {
              setCreationContext({...creationContext, 
                basic: {...creationContext.basic, domain: ''}, 
                domain: { ...creationContext.domain, domain: '' }, 
                isBasicReady: (creationContext.basic.email && !isEmailInvalid)
              }); 
              setDomainInvalid(true);
            }
          }}
        />
        {isDomainInvalid ? (
          <FormErrorMessage>Domain Name is invalid.</FormErrorMessage>
        ) : (
          <FormHelperText>
            All your mailboxes are with this suffix (eg. alice@ai-fi.net).
          </FormHelperText>
        )}
      </FormControl>

      <FormControl isInvalid={isEmailInvalid}>
        <FormLabel>Contact Email</FormLabel>
        <Input
          type="text"
          placeholder={"eg. support@ai-fi.net"}
          onChange={(e) => {
            const email = e.target.value || '';
            if (MailAddressUtils.isValidEmailAddress(email, false)) {
              setEmailInvalid(false);
              setCreationContext({...creationContext,
                basic: {...creationContext.basic, email: email}, 
                isBasicReady: (creationContext.basic.name && !isNameInvalid) && (creationContext.basic.domain && !isDomainInvalid)
              });
            } else {
              setEmailInvalid(true);
              setCreationContext({...creationContext, 
                basic: {...creationContext.basic, email: email}, 
                isBasicReady: false
              });
            }
          }}
        />
        {isEmailInvalid ? (
          <FormErrorMessage>Contact Email is invalid.</FormErrorMessage>
        ) : (
          <FormHelperText>
            This email only used for charging notification.
          </FormHelperText>
        )}
      </FormControl>
      <FormControl>
        <FormLabel>Capacity</FormLabel>
        <Select
          // defaultValue={basic ? basic.capacity : "1"}
          value={(creationContext && creationContext.basic && creationContext.basic.capacity) || "1"}
          onChange={(e) => {
            const capacity = e.target.value;
            setCreationContext({...creationContext, basic: {...creationContext.basic, capacity: capacity}});
          }}
        >
          <option value={"1"}>1-50</option>
          <option value={"2"}>50-100</option>
          <option value={"3"}>100-500</option>
          <option value={"4"}>over 500</option>
        </Select>
      </FormControl>
    </Stack>
  );
}

function SubscriptionPanel({ creationContext, setCreationContext }) {
  return (
    <Stack fontSize={title_size}>
      <Heading size={title_size}>3. Features</Heading>
      <CheckboxGroup colorScheme="green" defaultValue={["1", "2", "3", "4"]}>
        <Stack>
          {/* isDisabled={true} disabled={true} */}
          <Checkbox value="1">Contacts</Checkbox>
          <Checkbox value="2">PlexiSign</Checkbox>
          <Checkbox value="3">Multi-Address</Checkbox>
          <Checkbox value="4">Group Communication</Checkbox>
          <Checkbox value="5">Notes (come soon)</Checkbox>
        </Stack>
      </CheckboxGroup>
    </Stack>
  );
}

function DomainPanel({ creationContext, setCreationContext }) {
  const [isVerifying, setVerifying] = useState(false);

  return (
    <Stack fontSize={title_size}>
      <Heading size={title_size}>2. Domain</Heading>
      <FormControl>
        <FormLabel>Domain Name</FormLabel>
        <Input
          type="text"
          placeholder={"eg. ai-fi.net"}
          disabled={true}
          value={(creationContext && creationContext.domain && creationContext.domain.domain) || ''}
          onChange={(e) => {
            const domainName = e.target.value.trim().toLowerCase();
            if (
              /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(
                domainName
              )
            ) {
              setCreationContext({...creationContext, domain: { ...creationContext.domain, domain: domainName }})
            } else {
              setCreationContext({...creationContext, domain: { domain: null, txtRecord: null, verified: false }})
            }
          }}
        />
      </FormControl>

      {creationContext && creationContext.isProcessing && (
        <HStack mt={5} align="center">
          <Spinner size={"sm"} color="orange" />
          <Text color="orange">
            {creationContext.isDomainReady
              ? "Verifying Domain ..."
              : "Request Verifying Domain ..."}
          </Text>
        </HStack>
      )}
      {isVerifying && (
        <HStack mt={5} align="center">
          <Spinner size={"sm"} color="orange" />
          <Text color="orange">
            Request Verifying Domain ...
          </Text>
        </HStack>
      )
      }
      <Button
        mt={5}
        variant={"link"}
        isLoading={isVerifying}
        onClick={() => {
          setVerifying(true);
          window.plexiMailService
            .beginVerifyingDomain(creationContext.domain.domain)
            .then((res) => {
              if (res.code !== 0) {
                throw new Error("Failed to request verifying domain.");
              }
              setCreationContext({...creationContext, domain: {...creationContext.domain, txtRecord: res.data}, isDomainReady: true});
              setVerifying(false);
            })
            .catch((e) => {
              console.log(e);
              showError(e);
              setVerifying(false);
            });
        }}
      >
        Request Verifying Domain
      </Button>
      {creationContext.isDomainReady && (
        <>
          <Text>
            1. Log in to your DNS console (located on the platform where you
            purchased the custom domain).
          </Text>
          <Text>
            2. Create a DNS record, the record Type is TXT, the Host name is
            “_pleximail-challenge” and value is “{creationContext && creationContext.domain && creationContext.domain.txtRecord}“
          </Text>
        </>
      )}
    </Stack>
  );
}

const oneMonth = ((1000 % 60) % 60) * 24 * 30;
function PaymentPanel({ creationContext, setCreationContext }) {
  const [isLoading, setLoading] = useState(true);
  
  const loadSubscription = async () => {
    setLoading(true);
    const expiredAt = window.BigInt(new Date().getTime() + oneMonth);
    setTimeout(() => {
      setCreationContext({...creationContext, subscription: {
        plan: creationContext.basic.capacity,
        stripe: {
          price: "price_1QINWqHwNQsphCyIczBhnwTl"
        },
        expired_at: expiredAt,
      }, isPaymentReady: true})
      setLoading(false);
    }, 0);
  };

  useEffect(() => {
    loadSubscription()
      .then(() => {})
      .catch((e) => {
        showError(e);
      });
  }, []);

  return (
    <Stack fontSize={title_size}>
      <Heading size={title_size}>4. Payment</Heading>
      {!isLoading && (
        <>
          <Checkbox value="price_1QINWqHwNQsphCyIczBhnwTl" checked={true}>US$ 12.00/mo</Checkbox>
          <Text>Automatic renewal for monthly</Text>
        </>
      )}
      {isLoading && (
        <HStack mt={5} align="center">
          <Spinner size={"sm"} color="orange" />
          <Text color="orange">{"Generating subscription bill ..."}</Text>
        </HStack>
      )}

      {creationContext && creationContext.isProcessing && (
        <HStack mt={5} align="center">
          <Spinner size={"sm"} color="orange" />
          <Text color="orange">
            {"Pending for payment to be completed ..."}
          </Text>
        </HStack>
      )}
    </Stack>
  );
}


function WaitingPaymentPanel({ creationContext, setCreationContext }) {
  const [isChecking, setChecking] = useState(true);
  

  useEffect(() => {
    setChecking(true);
    checkPayment().then(() => {
      setChecking(false);
    }).catch(e => { console.error(e);
      setChecking(false);
    });
  }, []);

  const doCheckPayment = async() => {
    const res = await window.plexiMailService.retrieveCheckoutSession(creationContext.domain.domain);
    if (res.code !== 0) {
      return -1;
    }

    if (res.data.payment_status !== 'paid') {
      return -2;
    }
    return 0;
  };

  const checkPayment = async() => {
    try {
      const res = await doCheckPayment();
      if (res === 0) {
        setCreationContext({...creationContext, isPaid: true});
        return;
      } else {
        const promise = new Promise((resolve, reject) => {
          setTimeout(resolve, 5000);
        }) 
        await promise;
        await checkPayment();
      }
    } catch (e) {
      console.error(e);
      showError(e);
      await checkPayment();
    }
  };


  return (
    <Stack fontSize={title_size}>
      <Heading size={title_size}>4. Wait for payment</Heading>
      {isChecking && (
        <HStack mt={5} align="center">
          <Spinner size={"sm"} color="orange" />
          <Text color="orange">{"Waiting for payment..."}</Text>
        </HStack>
      )}
    </Stack>
  );
}

function ResultPanel({ creationContext, error }) {
  const Success = () => {
    return (
      <Box fontSize={title_size} textAlign="center" py={10} px={6}>
        <CheckCircleIcon boxSize={"50px"} color={"green.500"} />
        <Heading as="h2" size="xl" mt={6} mb={2}>
          Business Entity is successfully created
        </Heading>
        <Text color={"gray.500"}>
          Name: {creationContext && creationContext.basic && creationContext.basic.name} <br />
          Capacity: {creationContext && creationContext.basic && creationContext.basic.capacity} <br />
          Email: {creationContext && creationContext.basic && creationContext.basic.email} <br />
          Domain: {creationContext && creationContext.domain && creationContext.domain.domain} <br />
        </Text>
      </Box>
    );
  };

  const Error = () => {
    return (
      <Box textAlign="center" py={10} px={6}>
        <Box display="inline-block">
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            bg={"red.500"}
            rounded={"50px"}
            w={"55px"}
            h={"55px"}
            textAlign="center"
          >
            <CloseIcon boxSize={"20px"} color={"white"} />
          </Flex>
        </Box>
        <Heading as="h2" size="xl" mt={6} mb={2}>
          Failed to create Business Entity
        </Heading>
        <Text color={"gray.500"}>
          {error}
        </Text>
      </Box>
    );
  };
  return (
    <Stack>
      {(!error || error.code === 0) && <Success />}
      {error && error.code !== 0 && <Error />}
    </Stack>
  );
}

const EnterpriseCreationState = {
  Basic: 1,
  Subscription: 2,
  Domain: 3,
  Payment: 4,
  CheckPayment: 5,
  Result: 6,
};

export default function EnterpriseCreationModal({ disclosure, onComplete }) {
  const [creationState, setCreationState] = useState(
    EnterpriseCreationState.Basic
  );

  const [creationContext, setCreationContext] = useState({
    basic: {name: null, email: null, capacity: "1"},
    domain: {domain: null, txtRecord: null, verified: false},
    subscription: {},
    isProcessing: false,
    isBasicReady: false,
    isDomainReady: false,
    isSubscriptionReady: true,
    isPaymentReady: false,
    isPaid: false,
  });

  //   const [basic, isBasicReady, BasicPanel] = useBasicPanel({
  //     creationContext: creationContext,
  //   });
  //   const [domain, isDomainReady, DomainPanel] = useDomainPanel({
  //     creationContext: creationContext,
  //     requestVerifyingDomain: requestVerifyingDomain,
  //   });
  //   const [subscription, isSubscriptionReady, SubscriptionPanel] =
  //     useSubscriptionPanel({ creationContext: creationContext });
  //   const [payment, isPaymentReady, PaymentPanel] = usePaymentPanel({
  //     creationContext: creationContext,
  //   });

  useEffect(() => {
    // didUpdate
    if (!disclosure.isOpen) {
      return;
    }
  }, [disclosure.isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  //   const areAllInfosReady = () => {
  //     return (
  //       isBasicReady && isDomainReady && isSubscriptionReady && isPaymentReady
  //     );
  //   };
  const isCurrentStageReady = () => {
    switch (creationState) {
      case EnterpriseCreationState.Basic:
        return creationContext.isBasicReady;
      case EnterpriseCreationState.Domain:
        return creationContext.isDomainReady;
      case EnterpriseCreationState.Subscription:
        return creationContext.isSubscriptionReady;
      case EnterpriseCreationState.Payment:
        return creationContext.isPaymentReady;
      case EnterpriseCreationState.CheckPayment:
          return creationContext.isPaid;
      case EnterpriseCreationState.Result:
        return true;
      default:
        return creationContext.isBasicReady;
    }
  };
  const reset = () => {
    setCreationContext({
      basic: {name: null, email: null, capacity: "1"},
      domain: {domain: null, txtRecord: null, verified: false},
      payment: {id: null, url: null},
      subscription: {},
      isProcessing: false,
      isBasicReady: false,
      isDomainReady: false,
      isSubscriptionReady: true,
      isPaymentReady: false,
    });
    setCreationState(EnterpriseCreationState.Basic);
  };

  const saveBusiness = async () => {
    await window.plexiMailService.saveBusinessEntity(creationContext.basic)

  };

  const verifyDomain = async () => {
    await window.plexiMailService.verifyDomain(creationContext.domain.domain);
  };

  const createPaymentSession = async () => {
    const result = await window.plexiMailService.createPaymentSession(creationContext.domain.domain);
    if (result.code !== 0) {
      showError(result);
      return;
    }

    setCreationContext({...creationContext, payment: {id: result.data.id, url: result.data.url}});
    setCreationState(EnterpriseCreationState.CheckPayment);

    // window.open(result.data.url, '_blank');


    let a = window.document.createElement("a");
    a.setAttribute("href", result.data.url);
    window.document.body.append(a);
    a.click();
    a.remove();
    
  };

  const subscribe = async () => {
    await window.plexiMailService.subscribe(
      creationContext.domain.domain
    );
  };

  const next = async () => {
    try {
      setCreationContext({...creationContext, isProcessing: true})
      switch (creationState) {
        case EnterpriseCreationState.Basic:
          break;
        case EnterpriseCreationState.Subscription:
          await saveBusiness();
          break;
        case EnterpriseCreationState.Domain:
          await verifyDomain();
          break;
        case EnterpriseCreationState.Payment:
          await createPaymentSession();
          break;
        case EnterpriseCreationState.CheckPayment:
          await subscribe();
          break;
        case EnterpriseCreationState.Result:
          break;
        default:
          break;
      }
    } catch (e) {
      throw e;
    } finally {
      setCreationContext({...creationContext, isProcessing: false})
    }

    if (creationState === EnterpriseCreationState.Result) {
      reset();
      onComplete(creationContext && creationContext.domain && creationContext.domain);
      disclosure.onClose();
    } else {
      setCreationState(creationState + 1);
    }
  };

  const previous = async () => {
    if (creationState === EnterpriseCreationState.Basic) {
      reset();
      disclosure.onClose();
    } else {
      setCreationState(creationState - 1);
    }
  };
  return (
    <AlertDialog
      size={"xl"}
      isOpen={disclosure.isOpen}
      onClose={() => {
        reset();
        disclosure.onClose();
      }}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay />
      <AlertDialogContent>
        <AlertDialogHeader>
          Business Subscription
          <ModalCloseButton />
        </AlertDialogHeader>

        <AlertDialogBody>
          {creationState === EnterpriseCreationState.Basic && (
            <BasicPanel
              creationContext={creationContext}
              setCreationContext={setCreationContext}
            />
          )}

          {creationState === EnterpriseCreationState.Subscription && (
            <SubscriptionPanel
              creationContext={creationContext}
              setCreationContext={setCreationContext}
            />
          )}
          {creationState === EnterpriseCreationState.Domain && (
            <DomainPanel
              creationContext={creationContext}
              setCreationContext={setCreationContext}
            />
          )}

          {creationState === EnterpriseCreationState.Payment && (
            <PaymentPanel
              creationContext={creationContext}
              setCreationContext={setCreationContext}
            />
          )}
          {creationState === EnterpriseCreationState.CheckPayment && (
            <WaitingPaymentPanel
              creationContext={creationContext}
              setCreationContext={setCreationContext}
            />
          )}
          {creationState === EnterpriseCreationState.Result && (
            <ResultPanel
              creationContext={creationContext}
              setCreationContext={setCreationContext}
              error={null}
            />
          )}
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button
            colorScheme={
              creationState === EnterpriseCreationState.Basic ? "red" : "gray"
            }
            mr={3}
            onClick={() => {
              previous()
                .then(() => {})
                .catch((e) => {
                  showError(e);
                });
            }}
          >
            {creationState === EnterpriseCreationState.Basic
              ? "Cancel"
              : "Back"}
          </Button>
          <Button
            colorScheme="green"
            mr={3}
            isDisabled={!isCurrentStageReady()}
            isLoading={creationContext.isProcessing}
            onClick={() => {
              next()
                .then(() => {})
                .catch((e) => {
                  showError(e);
                });
            }}
          >
            {creationState === EnterpriseCreationState.Basic && "Next"}
            {creationState === EnterpriseCreationState.Subscription &&
              "Confirm"}
            {creationState === EnterpriseCreationState.Domain && "Confirm"}
            {creationState === EnterpriseCreationState.Payment && "Pay"}
            {creationState === EnterpriseCreationState.CheckPayment && "Next"}
            {creationState === EnterpriseCreationState.Result && "Done"}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
