import {
  Text,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Checkbox,
  // IconButton,
} from "@chakra-ui/react";

import React, { useEffect } from "react";
import Strings from "../config/Strings";
import { TutorialModalLink } from "./TutorialModalTrigger";

function UnverifiedAlert({ disclosure, onCompleted }) {
  useEffect(() => {}, []);

  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader color={"orange"} fontSize="lg" fontWeight="bold">
            {Strings.editor.unverified.title}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>
              {Strings.editor.unverified.description_1}&nbsp;
              <TutorialModalLink
                anchor="hide-in-plain-sight"
                title={"Learn more"}
              />{" "}
            </Text>

            <Text mt={5}>{Strings.editor.unverified.description_2}</Text>

            <Text mt={5}>{Strings.editor.unverified.description_3}</Text>

            <Checkbox
              defaultChecked={false}
              mt={5}
              onChange={(e) => {
                window.appConfig.unverifiedAlertIsHiddenOnEditorIsReady =
                  e.target.checked;
              }}
            >
              {Strings.editor.unverified.dont_show_again}
            </Checkbox>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              mr={4}
              colorScheme={"green"}
              onClick={() => {
                disclosure.onClose();
                onCompleted();
              }}
            >
              {Strings.editor.unverified.next}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

export default UnverifiedAlert;
