import {
  Button,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay /*, Select*/,
  VStack,
} from "@chakra-ui/react";
import { createRef, useEffect, useState } from "react";
import { BACKUP_FOLDER } from "../common/constants";
import { showError } from "../common/errors";
import Strings from "../config/Strings";

// const FolderTree = () => {

//     const folders = window.mailService.flatFolders();

//     if (folders && folders.length > 0) {
//         return <>
//         {folders && folders.map(flatedFolder => <option key={flatedFolder.key + '-' + flatedFolder.path} value={flatedFolder.path}>{flatedFolder.name}</option>)
//         }
//         </>
//     } else {
//         return <option value="">No Parent</option>
//     }
// }

const FolderCreationModal = ({ disclosure, setFolders }) => {
  // const { addToast } = useToasts()
  const [isLoading, setIsLoading] = useState(false);
  const nameRef = createRef();
  const parentRef = createRef();
  const S = Strings.main.dialog.create_folder;

  useEffect(() => {
    console.log("FolderCreationModal useEffect with empty");
    if (window.mailService) {
      // const folders = window.mailService.flatFolders();
      // setFlatedCustomizedFolders(folders)
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log("FolderCreationModal useEffect without param");
  }); // eslint-disable-line react-hooks/exhaustive-deps
  function reset() {
    if (nameRef && nameRef.current) {
      nameRef.current.value = "";
    }
  }
  async function saveClicked() {
    const name = nameRef.current.value;
    // const specCharset = ['\\', '/', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '=', ':', ';', ',', '.', ];
    const specCharset = ["\\", "/"];
    for (const c of specCharset) {
      if (name.indexOf(c) !== -1) {
        showError(
          c + Strings.main.dialog.create_folder.message.character_is_not_allowed
        );
        return;
      }
    }

    const path = parentRef.current ? parentRef.current.value : "";
    const id = name.replaceAll(" ", "-");
    const folder = { id, name, path };
    setIsLoading(true);
    try {
      await window.mailService.createFolder(folder);

      const folders = { ...window.mailService.folders };
      delete folders[BACKUP_FOLDER];
      setFolders(folders);
      setIsLoading(false);
      reset();
      disclosure.onClose();
    } catch (e) {
      console.error(e);
      showError(e);
      setIsLoading(false);
      reset();
      disclosure.onClose();
    }
  }

  function closeClicked() {
    disclosure.onClose();
  }
  return (
    <Modal
      size={"xl"}
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {S.title}
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <VStack align={"left"}>
            <FormLabel>{S.content.name}</FormLabel>
            <Input ref={nameRef} defaultValue={""} />
            {/* <FormLabel>{S.content.under} </FormLabel>
                    <Select ref={parentRef} placeholder='Parent Folder'>
                        <FolderTree />
                    </Select> */}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="gray"
            mr={3}
            disabled={isLoading}
            onClick={closeClicked}
          >
            {S.button.cancel}
          </Button>
          <Button
            colorScheme="green"
            isLoading={isLoading}
            onClick={saveClicked}
          >
            {S.button.save}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FolderCreationModal;
