import BaseRPCHandler from "./BaseRPCHandler";

export default class WalletRequestPermissionsHandler extends BaseRPCHandler {
    constructor() {
        super('wallet_requestPermissions');
    }
    
    async handle(provider, request) {

    }
}
