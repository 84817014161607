// import './App.css';
import React, { useState, useEffect } from "react";

import {
  VStack,
  Text,
  Button,
  // TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  // Tfoot,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Input,
  IconButton,
  useDisclosure,
  Box,
  HStack,
  Flex,
  FormLabel,
  FormControl,
  Checkbox,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  MenuDivider,
} from "@chakra-ui/react";

import { showError, showSuccess } from "../../common/errors";

import {
  FiTrash2,
  FiCopy,
  FiPlus,
  FiSend,
  FiSave,
  FiRotateCcw,
} from "react-icons/fi";

import {
  AiOutlineMore,
  AiOutlineDownload /*AiOutlineUpload, AiOutlineFile, AiOutlineFileSync, AiOutlineFileText*/,
} from "react-icons/ai";

import AccountManager from "./AccountManager";
import EnterpriseStrings from "./EnterpriseStrings";

import CopyToClipboard from "react-copy-to-clipboard";
import {
  EnterpriseRemoteModal /*, ExportContactsMenuItem, ImportContactsMenuItem*/,
} from "./EnterpriseRemoteModal";
import EnterpriseRemoteService from "./EnterpriseRemoteService";

import { SyncDirection } from "../../utils/Types";
import { mailAddressSuffix } from "../../common/constants";

// import copy from 'copy-to-clipboard';

const CreateAccountAlert = ({ selectedAccount, disclosure, onCompleted }) => {
  // Encode your PlexiMail Address into a picture or QR code for passing along to your friends and partners.

  // Optionally enter a password for extra protection.
  const [isCreating, setCreating] = useState(false);
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [phone, setPhone] = useState(null);
  const createAccount = async () => {
    try {
      setCreating(true);
      const accountManager = new AccountManager(window.mailService);
      const account = await accountManager.createAccount(
        name,
        email,
        phone,
        true
      );
      const encoded = await accountManager.encodeAccount(account);
      const password = await accountManager.sendNotify({
        selectedAccount,
        account,
        encoded,
      });
      account.password = password;
      await accountManager.saveAccount(account);
      disclosure.onClose();
      onCompleted(account);
    } catch (e) {
      console.error(e);
      showError(e);
    } finally {
      setCreating(false);
    }
  };

  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {EnterpriseStrings.users.add_dlg.title}
          </AlertDialogHeader>

          <AlertDialogBody>
            <FormControl>
              <FormLabel>{EnterpriseStrings.users.add_dlg.name} </FormLabel>
              <Input
                autoComplete="off"
                placeholder={EnterpriseStrings.users.add_dlg.name_placeholder}
                type={"text"}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{EnterpriseStrings.users.add_dlg.email} </FormLabel>
              <Input
                autoComplete="off"
                placeholder={EnterpriseStrings.users.add_dlg.email_placeholder}
                type={"text"}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>{EnterpriseStrings.users.add_dlg.phone} </FormLabel>
              <Input
                autoComplete="off"
                placeholder={EnterpriseStrings.users.add_dlg.phone}
                type={"text"}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
            </FormControl>
            <Text fontSize="sm" color="gray">
              <i>{EnterpriseStrings.users.add_dlg.notes}</i>
            </Text>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              isDisabled={isCreating}
              colorScheme={"gray"}
              mr={4}
              onClick={() => {
                disclosure.onClose();
              }}
              ml={3}
            >
              {EnterpriseStrings.users.add_dlg.cancel}
            </Button>
            <Button
              isDisabled={!email || email.length === 0}
              isLoading={isCreating}
              colorScheme={"green"}
              onClick={() => {
                createAccount().then((e) => {});
              }}
            >
              {EnterpriseStrings.users.add_dlg.create}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

const UserContent = ({
  selectedAccount,
  selectedIndex,
  setSelectedIndex,
  composeDisclosure,
  setSelectedRecipients,
  disclosure,
}) => {
  // const { addToast } = useToasts()
  // const [qrcodeFilter, setQRCodeFilter] = useState('none');
  const [isSyncing, setSyncing] = useState(false);
  const [users, setUsers] = useState([]);
  const [deletingUsers, setDeletingUsers] = useState([]);
  const [changed, setChanged] = useState(false);
  const alertDisclosure = useDisclosure({
    onOpen: () => {},
  });
  const [syncService, setSyncService] = useState(
    new EnterpriseRemoteService(SyncDirection.Import, selectedAccount, 0)
  );
  const [syncDirection, setSyncDirection] = useState(SyncDirection.Import);

  const syncDisclosure = useDisclosure({
    onOpen: () => {},
    onClose: () => {
      if (syncService) {
        syncService.stop();
        setSyncService(
          new EnterpriseRemoteService(SyncDirection.Import, selectedAccount, 0)
        );
      }
    },
  });
  const [selectedUsers, setSelectedUsers] = useState([]);
  useEffect(() => {
    if (!disclosure.isOpen) {
      return;
    }
    if (selectedIndex !== 1) {
      return;
    }
    setChanged(false);
    setSyncing(false);
    window.mailService.getAllUsers().then((users) => {
      setUsers(users);
    });
  }, [disclosure.isOpen, selectedIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // didUpdate
  }); // eslint-disable-line react-hooks/exhaustive-deps

  const deleteUser = async (user) => {
    try {
      setDeletingUsers([...deletingUsers, user]);
      const newUsers = users.filter((u) => {
        return u.addr.toLowerCase() !== user.addr.toLowerCase();
      });
      setUsers(newUsers);

      // await window.mailService.deleteUser(user.addr);
      // const users = window.mailService.getAllUsers();
      // setUsers(users);
    } catch (e) {
      console.error(e);
      showError(e);
    }
  };
  const realDeleteUsers = async () => {
    for (const user of deletingUsers) {
      await window.mailService.deleteUser(user.addr);
    }
    await window.mailService.syncContactsIfNeeded();
    setChanged(false);
  };
  const isMe = (addr) => {
    if (
      addr &&
      selectedAccount &&
      addr.toLowerCase() === selectedAccount.toLowerCase()
    ) {
      return true;
    }
    return false;
  };
  return (
    <>
      <CreateAccountAlert
        selectedAccount={selectedAccount}
        disclosure={alertDisclosure}
        onCompleted={(account) => {
          // showInfo('User has been created. Please notify the user to check the notification email.');
          setUsers([...users, account]);
        }}
      />

      <EnterpriseRemoteModal
        disclosure={syncDisclosure}
        syncService={syncService}
        direction={syncDirection}
        autoClose={true}
      />
      <HStack
        spacing={{ base: "0", md: "6" }}
        py={{ base: 2, md: 2 }}
        justifyContent={"flex-end"}
      >
        {/* <Text fontSize={{base: "small", md: "xl"}} fontWeight={"bold"}>{S.head}</Text> */}

        <Flex>
          {/* <IconButton
                    mr={3}
                    isDisabled={false}
                    colorScheme={"purple"}
                    icon={<AiOutlineFileZip  />}
                    onClick={() => {}}
                    spacing={{ base: '6', md: '6' }} /> */}
          <IconButton
            isDisabled={isSyncing}
            icon={<FiRotateCcw />}
            colorScheme={"red"}
            variant="outline"
            aria-label="Discard"
            title="Discard"
            mr={3}
            onClick={() => {
              window.mailService.getAllUsers().then((users) => {
                setUsers(users);
                setChanged(false);
                setDeletingUsers([]);
              });
            }}
            spacing={{ base: "6", md: "6" }}
          />
          <IconButton
            isDisabled={!changed}
            isLoading={isSyncing}
            aria-label="Save"
            title="Save"
            icon={<FiSave />}
            colorScheme={"green"}
            variant="outline"
            mr={3}
            onClick={() => {
              setSyncing(true);
              realDeleteUsers()
                .then(() => {
                  setSyncing(false);
                  // disclosure.onClose();
                })
                .catch((e) => {
                  setSyncing(false);
                  console.error(e);
                  showError(e);
                });
            }}
            spacing={{ base: "6", md: "6" }}
          />

          <IconButton
            isDisabled={false}
            icon={<FiPlus />}
            colorScheme={"green"}
            variant="outline"
            aria-label="Create User"
            title="Create User"
            mr={3}
            onClick={() => {
              alertDisclosure.onOpen();
            }}
            spacing={{ base: "6", md: "6" }}
          />
          <Menu isLazy>
            <MenuButton
              as={IconButton}
              variant="outline"
              aria-label="More"
              title="More"
              disabled={!selectedUsers || selectedUsers.length === 0}
              icon={<AiOutlineMore />}
              colorScheme={"orange"}
            />
            <MenuList>
              {/* <Text pl={3} fontWeight={"bold"} fontSize={"sm"}>CSV</Text>
                <MenuItem icon={<AiOutlineFileText />}>{"Import Users"}</MenuItem>
                <MenuDivider /> */}
              <Text pl={3} fontWeight={"bold"} fontSize={"sm"}>
                Mobile
              </Text>
              {/* <MenuItem icon={<AiOutlineUpload />} onClick={() => {
                  console.log('selectedUsers:', selectedUsers)
                  syncService.direction = SyncDirection.Import;
                  setSyncDirection(SyncDirection.Import)
                  syncDisclosure.onOpen();
                }}>{"Import Users"}</MenuItem> */}
              <MenuItem
                icon={<AiOutlineDownload />}
                onClick={() => {
                  console.log("selectedUsers:", selectedUsers);
                  syncService.direction = SyncDirection.Export;
                  syncService.selectedUsers = selectedUsers;
                  setSyncDirection(SyncDirection.Export);
                  syncDisclosure.onOpen();
                }}
              >
                {"Export Users"}
              </MenuItem>
              {/* <ExportContactsMenuItem selectedUsers={selectedUsers} onCompleted={(e) => {

                }} />
                <ImportContactsMenuItem onCompleted={(e) => {

                }} /> */}
              <MenuDivider />
              <Text pl={3} fontWeight={"bold"} fontSize={"sm"}>
                Mail
              </Text>
              <MenuItem
                icon={<FiSend />}
                onClick={() => {
                  const addresses = selectedUsers.map((user) => {
                    const addr = window.mailService.parseAddress(
                      isMe(user.addr) ? "" : user.name,
                      `${user.addr}${mailAddressSuffix()}`
                    );
                    return addr;
                  });

                  setSelectedRecipients(addresses);
                  composeDisclosure.onOpen();
                }}
              >
                {"Send Mail"}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
      <Box w={"100%"} justifyContent={"space-between"}></Box>

      {/* <TableContainer w={'100%'}> */}
      <Table mt={4} variant="simple" w={"100%"}>
        <TableCaption>
          <Text>{EnterpriseStrings.users.users.title}</Text>
        </TableCaption>
        <Thead>
          <Tr>
            <Th>{EnterpriseStrings.users.users.col_select}</Th>
            <Th>{EnterpriseStrings.users.users.col_name}</Th>
            <Th>{EnterpriseStrings.users.users.col_addr}</Th>
            <Th>{EnterpriseStrings.users.users.col_email}</Th>
            <Th>{EnterpriseStrings.users.users.col_pwd}</Th>
            <Th>{EnterpriseStrings.users.users.col_pin}</Th>
            <Th>{EnterpriseStrings.users.users.col_ops}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {/* textDecoration={item.status === 0 ? 'none': 'line-through red'} */}
          {users &&
            users.length > 0 &&
            users.map((user) => {
              return (
                <Tr key={user.addr}>
                  <Td>
                    <Checkbox
                      disabled={isMe(user.addr)}
                      defaultChecked={false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          const newSelectedUsers = [...selectedUsers, user];
                          setSelectedUsers(newSelectedUsers);
                        } else {
                          const newSelectedUsers = selectedUsers.filter((u) => {
                            return u.addr !== user.addr;
                          });
                          setSelectedUsers([...newSelectedUsers]);
                        }
                      }}
                    />
                  </Td>
                  <Td>{user.name}</Td>
                  <Td>{user.addr}</Td>
                  <Td>{user.email || "-"}</Td>
                  <Td>{user.password || "-"}</Td>
                  <Td>{user.pin || "-"}</Td>
                  <Td>
                    <IconButton
                      background={"none"}
                      color="red"
                      isDisabled={isMe(user.addr)}
                      icon={<FiTrash2 />}
                      onClick={() => {
                        deleteUser(user).then(() => {
                          setChanged(true);
                        });
                      }}
                    />
                    <CopyToClipboard
                      text={
                        isMe(user.addr) || !user.name || user.name.length === 0
                          ? `${user.addr}${mailAddressSuffix()}`
                          : `'${user.name}' <${
                              user.addr
                            }${mailAddressSuffix()}>`
                      }
                      onCopy={() => {
                        showSuccess("Copied");
                      }}
                    >
                      <IconButton
                        background={"none"}
                        color="gray"
                        icon={<FiCopy />}
                        // onClick={() => {
                        //   const addr = (isMe(user.addr) || !user.name || user.name.length === 0) ? `${user.addr}${mailAddressSuffix()}` : `'${user.name}' <${user.addr}${mailAddressSuffix()}>`;

                        //   copy(addr, {onCopy: () =>{
                        //     showSuccess('Copied')
                        //   }});
                        // }}
                      />
                    </CopyToClipboard>

                    <IconButton
                      background={"none"}
                      color="green"
                      icon={<FiSend />}
                      onClick={() => {
                        const addr = window.mailService.parseAddress(
                          isMe(user.addr) ? "" : user.name,
                          `${user.addr}${mailAddressSuffix()}`
                        );
                        setSelectedRecipients([addr]);
                        composeDisclosure.onOpen();
                      }}
                    />
                  </Td>
                </Tr>
              );
            })}

          {(!users || users.length === 0) && (
            <Tr>
              <Td colSpan={7} align="center" w={"100%"} maxW={"100%"}>
                <VStack width={"100%"}>
                  <Text
                    color={"rgb(74, 85, 104)"}
                    width={"100%"}
                    textAlign="center"
                    algin={"center"}
                    fontWeight={"bold"}
                    fontSize={"md"}
                  >
                    {EnterpriseStrings.users.users.empty}
                  </Text>
                  {/* <Text
                      color="gray"
                      fontSize="sm"
                      width={"100%"}
                      algin={"center"}
                      textAlign="center"
                    >
                      {"Empty"}
                    </Text> */}
                </VStack>
              </Td>
            </Tr>
          )}
        </Tbody>

        {/* <Tfoot>
                <Tr>
                  <Th colSpan={5}>
                    <Flex justify={'flex-end'} mt={4} fontWeight={'bold'}>
                      <Text align="right" textAlign={'right'}>
                          asdasdasdas
                      </Text>
                    </Flex>
                  </Th>
                </Tr>
              </Tfoot> */}
      </Table>
      {/* </TableContainer> */}
    </>
  );
};

export default UserContent;
