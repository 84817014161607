import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Button,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  // ModalHeader,
  ModalFooter,
  ModalBody,
  Image,
  ModalCloseButton,
  IconButton,
  Link,
} from "@chakra-ui/react";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import Slider from "react-slick";

import { useState } from "react";
import { FiHelpCircle } from "react-icons/fi";
// import {ReactComponent as MetamaskLogo} from './metamask.svg';
// import {ReactComponent as Web3Icon} from './web3.svg';
import ConfirmationButton from "./ConfirmationButton";
import Strings from "../config/Strings";
import { HELP_DOC_URL } from "../common/constants";
import { isMobile } from "../common/constants";

export default function TutorialModal({ disclosure }) {
  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes

  const [slider, setSlider] = useState(null);
  const top = useBreakpointValue({ base: "90%", md: "50%" });
  const side = useBreakpointValue({ base: "30%", md: "40px" });
  const [configButton, setConfigButton] = useState(
    <IconButton
      icon={<FiHelpCircle />}
      mr={3}
      color="gray.600"
      onClick={() => {
        // ghost
        window.open(HELP_DOC_URL, "_blank");
      }}
    />
  );
  const [slideType, setSlideType] = useState(0); // 0, 1, 2

  const settings = {
    dots: true,
    arrows: false,
    fade: true,
    infinite: false,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (currentSlide, nextSlide) => {
      if (nextSlide === 0) {
        setSlideType(0);
      } else if (nextSlide === cards.length - 1) {
        setSlideType(2);
      } else {
        setSlideType(1);
      }
      if (nextSlide === 0) {
        setConfigButton(
          <IconButton
            icon={<FiHelpCircle />}
            mr={3}
            color="gray.600"
            onClick={() => {
              // ghost
              window.open(HELP_DOC_URL, "_blank");
            }}
          />
        );
      } else if (nextSlide === cards.length - 3) {
        const S = Strings.home.dialog.tutorial.slide12.button.get;

        setConfigButton(
          <>
            <IconButton
              icon={<FiHelpCircle />}
              mr={3}
              color="gray.600"
              onClick={() => {
                // ghost
                window.open(HELP_DOC_URL, "_blank");
              }}
            />
            <ConfirmationButton
              buttonTitle={S.title}
              title={S.confirm.title}
              message={S.confirm.content}
              onClick={() => {
                // ghost
                window.open("https://web3.storage/account", "_blank");
                //window.location.href = ('https://metamask.io/');
              }}
            />
          </>
        );
        // <Button colorScheme='green' onClick={() => {// ghost
        //  window.open('https://web3.storage/account', '_blank')
        //  //window.location.href = ('https://metamask.io/');
        // }}>Get Web3.Storage</Button>
      } else if (nextSlide === cards.length - 2) {
        const S = Strings.home.dialog.tutorial.slide13.button.install;

        setConfigButton(
          <>
            <IconButton
              icon={<FiHelpCircle />}
              mr={3}
              color="gray.600"
              onClick={() => {
                // ghost
                window.open(HELP_DOC_URL, "_blank");
              }}
            />
            <ConfirmationButton
              buttonTitle={S.title}
              title={S.confirm.title}
              message={S.confirm.content}
              onClick={() => {
                // ghost
                window.open("https://metamask.io/", "_blank");
                //window.location.href = ('https://metamask.io/');
              }}
            />
          </>
        );
      } else if (nextSlide === cards.length - 1) {
        const S = Strings.home.dialog.tutorial.slide14.button.config;

        setConfigButton(
          <>
            <IconButton
              icon={<FiHelpCircle />}
              mr={3}
              color="gray.600"
              onClick={() => {
                // ghost
                window.open(HELP_DOC_URL, "_blank");
              }}
            />
            <Button
              colorScheme="green"
              onClick={() => {
                // ghost

                window.appConfig.isNewUser = false;
                // window.location.reload();
                setTimeout(function () {
                  window.location.reload(true);
                });
              }}
            >
              {S.title}
            </Button>
          </>
        );
      } else {
        setConfigButton(
          <IconButton
            icon={<FiHelpCircle />}
            color="gray.600"
            onClick={() => {
              // ghost
              window.open(HELP_DOC_URL, "_blank");
            }}
          />
        );
      }
    },
    afterChange: (currentSlide) => {
      // if (currentSlide === 2) {
      //   setConfigButton(<Button colorScheme='green' onClick={() => {// ghost
      //     window.open('https://web3.storage/account', '_blank')
      //     //window.location.href = ('https://metamask.io/');
      //   }}>Get Web3.Storage</Button>)
      // } else if (currentSlide === 3) {
      //   setConfigButton(<Button colorScheme='green' onClick={() => {// ghost
      //     window.open('https://metamask.io/', '_blank')
      //   }}>Install MetaMask</Button>)
      // } else if (currentSlide === (cards.length - 1)) {
      //   setConfigButton(<Button colorScheme='green' onClick={() => {// ghost
      //     if (window.ethereum) {
      //       window.location.reload();
      //     } else {
      //       if (slider) {
      //         slider.slickGoTo(2)
      //       }
      //     }
      //   }}>Go Prepare the Run Environment</Button>)
      // } else {
      //   setConfigButton(null)
      // }
    },
  };
  const T = Strings.home.dialog.tutorial;
  // This list contains all the data for carousels
  // This can be static or loaded from a server
  const cards = [
    {
      content: (
        <>
          <Heading fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }}>
            {T.slide1.title}
            <br />
            {T.slide1.subtitle}
          </Heading>
          <Text fontSize={{ base: "sm", lg: "sm" }}>
            {T.slide1.content.paragraph1_1}
            <Link
              color="#00aaff"
              href="https://counterseal.net"
              target={"_blank"}
            >
              https://counterseal.net
            </Link>{" "}
            {T.slide1.content.paragraph1_2}{" "}
            <Link color="#00aaff" href="https://ai-fi.net" target={"_blank"}>
              https://ai-fi.net
            </Link>{" "}
            {T.slide1.content.paragraph1_3} <br />
            <br />
            {T.slide1.content.paragraph2}
          </Text>
        </>
      ),
      image: "/intro/bg1.jpeg",
    },
    {
      content: (
        <>
          <Heading fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }}>
            {T.slide2.title}
          </Heading>
          <Box align="center">
            <Image src="/intro/youaretheproduct.png" width={"60%"} />
          </Box>
          <Text fontSize={{ base: "sm", lg: "sm" }}>
            {T.slide2.content.paragraph1}
          </Text>
        </>
      ),
      image: "/intro/bg1.jpeg",
    },
    {
      content: (
        <>
          <Heading fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }}>
            {T.slide3.title}
          </Heading>

          <Box align="center">
            <Image src="/intro/surveillancecapitalism.png" width={"60%"} />
          </Box>
          <Text
            fontSize={{ base: "sm", lg: "sm" }}
            // color="GrayText"
          >
            {T.slide3.content.paragraph1}
          </Text>

          {/* <Button colorScheme='orange' mr={4} onClick={() => {
            // ghost
            window.open('https://metamask.io/', '_blank')
          }}>Download &amp; Install MetaMask</Button>  
          <Button colorScheme='green' onClick={() => {
            // ghost
            // window.open('https://web3.storage/account', '_blank')
          }}>Get Storage from Web3.Storage</Button> */}
        </>
      ),
      image: "/intro/bg1.jpeg",
    },
    {
      content: (
        <>
          <Heading fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }}>
            {T.slide4.title}
          </Heading>

          <Box align="center">
            <Image src="/intro/nomorefreefeeds.png" width={"60%"} />
          </Box>
          <Text fontSize={{ base: "sm", lg: "sm" }}>
            {T.slide4.content.paragraph1}
          </Text>
        </>
      ),
      image: "/intro/bg1.jpeg",
    },
    {
      content: (
        <>
          <Heading fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }}>
            {T.slide5.title}
          </Heading>

          {!isMobile && T.slide5.content.table}

          <Text
            dangerouslySetInnerHTML={{ __html: T.slide5.content.paragraph1 }}
            fontSize={{ base: "sm", lg: "sm" }}
          ></Text>
        </>
      ),
      image: "/intro/bg1.jpeg",
    },
    {
      content: (
        <>
          <Heading fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }}>
            {T.slide6.title}
          </Heading>
          <Box align="center">
            <Image src="/intro/epostcard.png" width={"80%"} />
          </Box>
          <Text fontSize={{ base: "sm", lg: "sm" }}>
            {T.slide6.content.paragraph1}
          </Text>
        </>
      ),
      image: "/intro/bg1.jpeg",
    },
    {
      content: (
        <>
          <Heading fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }}>
            {T.slide7.title}
          </Heading>
          <Box align="center">
            <Image src="/intro/burneraddresses-1.png" width={"80%"} />
          </Box>

          <Text
            dangerouslySetInnerHTML={{
              __html: T.slide7.content.paragraph1 + "<br />",
            }}
            fontSize={{ base: "sm", lg: "sm" }}
          ></Text>
          <Text fontSize={{ base: "sm", lg: "sm" }}>
            <i>{T.slide7.content.paragraph2}</i>
          </Text>
        </>
      ),
      image: "/intro/bg1.jpeg",
    },
    {
      content: (
        <>
          <Heading fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }}>
            {T.slide8.title}
          </Heading>

          <Box align="center">
            <Image src="/intro/architecture-1.png" width={"100%"} />
          </Box>

          <Text
            dangerouslySetInnerHTML={{ __html: T.slide8.content.paragraph1 }}
            fontSize={{ base: "sm", lg: "sm" }}
          >
            {/* {T.slide8.content.paragraph1} */}
          </Text>
        </>
      ),
      image: "/intro/bg1.jpeg",
    },
    {
      content: (
        <>
          <Heading fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }}>
            {T.slide9.title}
          </Heading>
          <Text fontSize={{ base: "sm", lg: "sm" }}>
            {T.slide9.content.paragraph1}
            <br />
            {T.slide9.content.paragraph2}
            <br />
            {T.slide9.content.paragraph3}
            <br />
            {T.slide9.content.paragraph4}
            <br />
            {T.slide9.content.paragraph5}
            <br />
            {T.slide9.content.paragraph6}
          </Text>
        </>
      ),
      image: "/intro/bg1.jpeg",
    },
    {
      content: (
        <>
          <Heading fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }}>
            {T.slide10.title}
          </Heading>

          <Box align="center">
            <Image src="/intro/evm-1.png" width={"100%"} />
          </Box>
          <Text fontSize={{ base: "sm", lg: "sm" }}>
            {T.slide10.content.paragraph1}
          </Text>
        </>
      ),
      image: "/intro/bg1.jpeg",
    },
    {
      content: (
        <>
          <Heading fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }}>
            {T.slide11.title}
          </Heading>
          <Text fontSize={{ base: "sm", lg: "sm" }}>
            {T.slide11.content.paragraph1}
          </Text>

          {/* <Button colorScheme='orange' onClick={() => {// ghost
            if (window.ethereum) {
              window.location.reload();
            } else {
              if (slider) {
                slider.slickGoTo(2)
              }
            }
          }}>Go Prepare the Run Environment</Button>   */}
        </>
      ),
      image: "/intro/bg1.jpeg",
    },
    {
      content: (
        <>
          <Box alignContent={"center"} align="center">
            <Image src="/intro/web3.storage.png" width={300} />
            {/* <Web3Icon width={100} /> */}
          </Box>
          <Text fontSize={{ base: "sm", lg: "sm" }}>
            {T.slide12.content.paragraph1} <br />
            <br />
            <span
              dangerouslySetInnerHTML={{ __html: T.slide12.content.paragraph2 }}
            >
              {/* {T.slide12.content.paragraph2} */}
            </span>
          </Text>
          {/* <Button colorScheme='green' onClick={() => {// ghost
            window.open('https://web3.storage/account', '_blank')
          }}>Get Web3.Storage</Button> */}
        </>
      ),
      image: "/intro/bg1.jpeg",
    },
    {
      content: (
        <>
          <Box alignContent={"center"} align="center">
            {/* <MetamaskLogo width={200} /> */}
            <Image src="/intro/wallet-icon.png" width={300} />
          </Box>
          <Text fontSize={{ base: "sm", lg: "sm" }}>
            {T.slide13.content.paragraph1_1}{" "}
            <Link color={"#00aaff"} href={HELP_DOC_URL} target={"_blank"}>
              Help file
            </Link>{" "}
            {T.slide13.content.paragraph1_2}
          </Text>
          {/* <Button colorScheme='orange' onClick={() => {// ghost
            window.open('https://metamask.io/', '_blank')
          }}>Install MetaMask</Button>   */}
        </>
      ),
      image: "/intro/bg1.jpeg",
      // image: '/intro/bg2.jpeg',
    },
    {
      content: (
        <>
          <Heading fontSize={{ base: "3xl", md: "4xl", lg: "4xl" }}>
            {T.slide14.title}
          </Heading>
          <Text fontSize={{ base: "sm", lg: "sm" }}>
            {T.slide14.content.paragraph1}
          </Text>

          {/* <Button colorScheme='orange' onClick={() => {// ghost
            if (window.ethereum) {
              window.location.reload();
            } else {
              if (slider) {
                slider.slickGoTo(2)
              }
            }
          }}>Go Prepare the Run Environment</Button>   */}
        </>
      ),
      image: "/intro/bg1.jpeg",
    },
  ];

  return (
    <Modal
      size={"full"}
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        {/* <ModalHeader></ModalHeader> */}
        {/* <ModalHeader>MetaMask Wallet not Found</ModalHeader> */}
        <ModalCloseButton
          zIndex={10000}
          onClick={() => {
            setSlideType(0);
          }}
        />

        <ModalBody>
          <Box
            position={"relative"}
            height={"600px"}
            width={"full"}
            overflow={"hidden"}
          >
            {/* CSS files for react-slick */}
            <link
              rel="stylesheet"
              type="text/css"
              charSet="UTF-8"
              href="/slick.min.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="/slick-theme.min.css"
            />
            {/* https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0 */}
            {/* Left Icon */}
            <IconButton
              disabled={slideType === 0}
              aria-label="left-arrow"
              variant="ghost"
              position="absolute"
              left={side}
              top={top}
              transform={"translate(0%, -50%)"}
              zIndex={2}
              onClick={() => {
                slider?.slickPrev();
              }}
            >
              <BiLeftArrowAlt size="40px" />
            </IconButton>
            {/* Right Icon */}
            <IconButton
              disabled={slideType === 2}
              aria-label="right-arrow"
              variant="ghost"
              position="absolute"
              right={side}
              top={top}
              transform={"translate(0%, -50%)"}
              zIndex={2}
              onClick={() => {
                slider?.slickNext();
              }}
            >
              <BiRightArrowAlt size="40px" />
            </IconButton>
            {/* Slider */}
            <Slider
              {...settings}
              ref={(s) => {
                setSlider(s);
              }}
            >
              {cards.map((card, index) => (
                <Box
                  key={index}
                  height={"6xl"}
                  position="relative"
                  backgroundPosition="center"
                  backgroundRepeat="no-repeat"
                  backgroundSize="cover"
                  // overflowY={"auto"}
                  // backgroundImage={`url(/intro/bg1.jpeg)`}
                  // backgroundImage={`url(${card.image})`}
                >
                  {/* This is the block you need to change, to customize the caption */}
                  <Container
                    size="container.lg"
                    height="600px"
                    position="relative"
                  >
                    <Stack
                      spacing={6}
                      w={"full"}
                      // maxW={'lg'}
                      position="absolute"
                      top="50%"
                      transform="translate(0, -50%)"
                    >
                      {/* top="50%" transform="translate(0, -50%)" */}
                      {card.content}
                    </Stack>
                  </Container>
                </Box>
              ))}
            </Slider>
          </Box>
        </ModalBody>

        <ModalFooter>{configButton}</ModalFooter>
      </ModalContent>
    </Modal>
  );
}
