import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AppConfig from './config/AppConfig';
import TorUtils from './utils/TorUtils';
import SecuritySignalProtocolStoreInMemoryDB from "./services/SecuritySignalProtocolStoreInMemoryDB";
import ICPAuthProvider from './icp/authContext';

const doRender = () => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  // root.render(
  //   <React.StrictMode>
  //     <App />
  //   </React.StrictMode>
  // );
  root.render(
    <ICPAuthProvider>
      <App />
    </ICPAuthProvider>
  );
};

window.isBrave = false;

if (!window.inMemoryDB) {
  window.inMemoryDB = new SecuritySignalProtocolStoreInMemoryDB();
}
// if (!window.IndexedDBDriver) {
//   window.IndexedDBDriver = IndexedDBDriver;
// }
if (!window.appConfig) {
  window.appConfig = new AppConfig();
}
try {
  if (window.topbar) {
    window.topbar.hide();
  }
}catch(e) {}

if (window.navigator.brave) {
  window.navigator.brave.isBrave().then(isBrave => {
    window.isBrave = isBrave;
    return TorUtils.detect();
  }).then((result) => {
    window.torInfo = result;
    doRender();
  }).catch((e) => {
    console.error(e);
    // showError(e);
  })
} else {
  TorUtils.detect().then((result) => {
    window.torInfo = result;
    doRender();
  }).catch((e) => {
    console.error(e);
  })
}