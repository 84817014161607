// import './App.css';
import React, { useState, useEffect, createRef } from "react";

import {
  VStack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  // AlertDialog,
  // AlertDialogOverlay,
  // AlertDialogContent,
  // AlertDialogHeader,
  // AlertDialogBody,
  // AlertDialogFooter,
  // Checkbox,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  // Progress,
  // useDisclosure,
  Spinner,
  Box,
  Link,
  HStack,
  SimpleGrid,
  Flex,
  Heading,
  Center,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  useColorModeValue,
  MenuItem,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  FormControl,
  FormLabel,
  AlertDialogFooter,
  useDisclosure,
} from "@chakra-ui/react";

import { CopyToClipboard } from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import { QRCodeCanvas } from "qrcode.react";
import Strings from "../config/Strings";
// import { exportComponentAsJPEG } from 'react-component-export-image';
import { ClientError, showError } from "../common/errors";
import { FaArrowLeft, FaEye, FaEyeSlash, FaPlus, FaUndo } from "react-icons/fa";
import { mailAddressSuffix } from "../common/constants";
import { CloseIcon } from "@chakra-ui/icons";
import { FiCopy, FiEdit3, FiFolderPlus, FiLock, FiTrash, FiUnlock } from "react-icons/fi";
import MailAddressUtils from "../utils/MailAddressUtils";
// import { MdOutlinePrivacyTip, MdOutlineAlternateEmail } from "react-icons/md";
// import zxcvbn from 'zxcvbn';

// import { useToasts } from 'react-toast-notifications'
/*
const SaveAlert = ({disclosure, onCompleted}) => {
  // Encode your PlexiMail Address into a picture or QR code for passing along to your friends and partners. 

// Optionally enter a password for extra protection.

const [withPassword, setWithPassword] = useState(false);

const [verified, setVerified] = useState(false);
const [passwordStrength, setPasswordStrength] = useState(null);
const [showPassword, setShowPassword] = useState(false);
const [password, setPassword] = useState(null); 

const S = Strings.profile.save;

const colors = ['gray', 'red', 'pink', 'orange', 'green'];

const togglePassword = () => {
    setShowPassword(!showPassword);
}
const verifyPassword = (e) => {
  const password = e.target.value || '';
  if (password === '') {
      setPasswordStrength(null);
      setPassword(null);
      setVerified(false);
      return;
  }
  // if (setupWizardState === SetupWizardState.EnterPassword) {
  //     setPassword(password);
  //     return;
  // }
  const r = zxcvbn(password);
  setPasswordStrength(r);
  setPassword(password);

  if (r.score >= 1) {
    setVerified(true);
  } else {
    setVerified(false);
  }
}
return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
          {S.title}
          </AlertDialogHeader>

          <AlertDialogBody>
          <Text>{S.message}</Text>
          <Checkbox onChange={(e) => {
            setWithPassword(e.target.checked);
          }}>{S.checkbox}</Checkbox>
          {withPassword && 
          <>
            <InputGroup mt={2}>
              <Input 
                    autoComplete="off" placeholder={S.input} type={showPassword ? 'text' : 'password'} onChange={verifyPassword} />
              <InputRightElement>
              {showPassword &&
              <IconButton icon={<FaEye />} onClick={togglePassword} />
              }
              {!showPassword &&
              <IconButton icon={<FaEyeSlash />} onClick={togglePassword} />
              }
              </InputRightElement>
            </InputGroup>  
            {(passwordStrength) &&
            <>
                <Progress mt={2} value={(passwordStrength.score + 1) * 20} colorScheme={colors[passwordStrength.score]} size='xs' />
                <Text fontSize={"12px"} align={"right"} color={colors[passwordStrength.score]}>{passwordStrength.feedback.warning}</Text>
            </>
            }
          </>
          }
          </AlertDialogBody>

          <AlertDialogFooter>

            <Button colorScheme={"gray"} mr={4} onClick={() => {
              disclosure.onClose();
              // onCompleted(null);
            }} ml={3}>
            {S.button.cancel}
            </Button>
            <Button disabled={withPassword && !verified} colorScheme={"green"} onClick={() => {
              
              disclosure.onClose();
              
              onCompleted(password);

              setWithPassword(false);
              setVerified(false);
              setPasswordStrength(null);
              setShowPassword(false);
              setPassword(null);

            }} ml={3}>
            {S.button.next}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>)
}
*/


function RemoveAddressAlert({ disclosure, address, onCompleted }) {
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    console.log('---- address: ', address)
  }, [disclosure.isOpen]);

  const removeAddress = async () => {
    try {
      setLoading(true);
      await window.mailService.removeAddress(address);
      onCompleted(address);
      disclosure.onClose();
    } catch (e) {
      console.error(e);
      showError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AlertDialog
      // size={""}
      isOpen={disclosure.isOpen}
      onClose={() => {
        disclosure.onClose()
      }}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Address
          </AlertDialogHeader>

          <AlertDialogBody>
            
            <Text>
            Are you sure you want to delete the following address:
            </Text>
            
            <Text color={"red"}>
            {address}
            </Text>
            


            {isLoading &&
            <HStack mt={3}>
              <Spinner />
              <Text
                as={"span"}
                color={"gray.500"}
                fontSize={{ base: "sm", sm: "md" }}
              >
                {"Deleting Addresses ..."}
              </Text>
            </HStack>
            }
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              mr={4}
              colorScheme={"green"}
              isDisabled={isLoading}
              onClick={() => {
                onCompleted(null);
                disclosure.onClose();
              }}
            >
              Cancel
            </Button>

            <Button
              mr={4}
              isLoading={isLoading}
              colorScheme={"red"}
              onClick={() => {
                removeAddress();
              }}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
function AddAddressAlert({ disclosure, lastIndex, model, onCompleted }) {
  const [label, setLabel] = useState(null);
  const [name, setName] = useState(null);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    console.log('---- lastIndex: ', lastIndex, ", model: ", model)
  }, [disclosure.isOpen]);

  const generateAddress = async () => {
    if (model === 1) {
      return {lastIndex: -1, address: name};
    }

    const result = await window.wallet.generateAddress(lastIndex);
    return result;
  };

  const saveAddress = async (result) => {
    if (!result.address || result.address.length === 0) {
      throw ClientError.invalidParameterError("Address could not be empty");
    }

    await window.mailService.saveAddress(result.lastIndex, result.address, label);

    onCompleted(result.address);
    setName(null);
    setLabel(null);
    disclosure.onClose();
  };

  const addAddress = () => {
    setLoading(true);
    generateAddress().then(result => {
      return saveAddress(result);
    }).then(() => {
      setLoading(false);
    }).catch(e => {
      console.log(e);
      showError(e);
      setLoading(false);
    })
  };

  return (
    <AlertDialog
      // size={""}
      isOpen={disclosure.isOpen}
      onClose={() => {
        setName(null);
        setLabel(null);
        disclosure.onClose()
      }}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {model === 0 && "Add Incognito Address"}
            {model === 1 && "Add Normal Address"}
            
          </AlertDialogHeader>

          <AlertDialogBody>

          <FormControl mt={4}>
            <FormLabel>Label</FormLabel>
            <Input
              placeholder={"eg. Alice Von" }
              value={label || ""}
              onChange={(e) => {
                setLabel(e.target.value ? e.target.value : null);
              }}
            />
          </FormControl>
            {/* <Text>{ContractStrings.dialog.fill_name.description}</Text> */}
            {model === 0 && 
            
            <Text>
              An incognito PlexiMail Address will be generated after "Add" button clicked.
            </Text>
            }

            {model === 1 && 
            <>
              <FormControl mt={4}>
                <FormLabel>Human readable address</FormLabel>
                <Input
                  placeholder={"eg. alice.von" }
                  value={name || ""}
                  onChange={(e) => {
                    setName(e.target.value ? e.target.value : null);
                  }}
                  onKeyDown={(e) => {

                    if (e.code === 'Enter') {
                      addAddress();
                    }
                  }}
                />
              </FormControl>
              </>
            }


{isLoading &&
            <HStack mt={3}>
              <Spinner />
              <Text
                as={"span"}
                color={"gray.500"}
                fontSize={{ base: "sm", sm: "md" }}
              >
                {"Saving Addresses ..."}
              </Text>
            </HStack>
            }
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              mr={4}
              colorScheme={"red"}
              isDisabled={isLoading}
              onClick={() => {
                onCompleted(null);
                setName(null);
                disclosure.onClose();
              }}
            >
              Cancel
            </Button>

            <Button
              mr={4}
              isDisabled={model === 1 && ((!name || name.length === 0) || (!label || label.length === 0))}
              isLoading={isLoading}
              colorScheme={"green"}
              onClick={() => {
                addAddress();
              }}
            >
              Add
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}



const AddressesList = ({selectedAccount, onSelected, onClose}) => {
  const [isLoading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [lastIndex, setLastIndex] = useState(0);
  const [addAddressModel, setAddAddressModel] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState(null);

  const bg = useColorModeValue("white", "gray.900");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const addAddressDisclosure = useDisclosure();
  const removeAddressDisclosure = useDisclosure();
  const [hasHumanReadableAddress, setHasHumanReableAddress] = useState(false);
  
  useEffect(() => {
    setLoading(true);
    window.mailService.getAddresses(true).then(result => {
      setLoading(false);
      setAddresses([...result.addresses]);
      const humanReadableAddresses = result.addresses.filter((addr) => {
        return !MailAddressUtils.isValidEthereumAddress(addr.address, true);
      });
      setHasHumanReableAddress(humanReadableAddresses && humanReadableAddresses.length > 0);
      setLastIndex(result.lastIndex);

    }).catch(e => {
      setLoading(false);
      setAddresses([]);
      setErrorMessage(e.message);
    });

  }, [])

  const reload = () => {
    setLoading(true);
    window.mailService.getAddresses(true).then(result => {
      setLoading(false);
      setAddresses([...result.addresses]);
      const humanReadableAddresses = result.addresses.filter((addr) => {
        return !MailAddressUtils.isValidEthereumAddress(addr.address, true);
      });
      setHasHumanReableAddress(humanReadableAddresses && humanReadableAddresses.length > 0);
      setLastIndex(result.lastIndex);
    }).catch(e => {
      setLoading(false);
      setAddresses([]);
      setErrorMessage(e.message);
    });
  };
  if (isLoading) {

    return (<HStack mt={3}>
    <Spinner />
    <Text
      as={"span"}
      color={"gray.500"}
      fontSize={{ base: "sm", sm: "md" }}
    >
      {"Loading Addresses ..."}
    </Text>
  </HStack>)
  }
  if (errorMessage) {
    return (

    <Box textAlign="center" py={10} px={6}>
    <Box display="inline-block">
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        bg={'red.500'}
        rounded={'50px'}
        w={'55px'}
        h={'55px'}
        textAlign="center">
        <CloseIcon boxSize={'20px'} color={'white'} />
      </Flex>
    </Box>
    <Heading as="h2" size="xl" mt={6} mb={2}>
      Failed to load addresses
    </Heading>
    <Text color={'gray.500'}>
      {errorMessage}
    </Text>
    <Flex justifyContent={"space-around"}>
      <Button colorScheme="red" onClick={reload}>Refresh</Button> <Button colorScheme="red" onClick={onClose}>Close</Button>
    </Flex>
  </Box>
    )
  }

  return <>
  <AddAddressAlert lastIndex={lastIndex} model={addAddressModel} disclosure={addAddressDisclosure} onCompleted={() => {
    reload();
  }} />
  <RemoveAddressAlert address={selectedAddress} disclosure={removeAddressDisclosure} onCompleted={() => {
    reload();
  }} />
  <Flex justifyContent={"space-between"} height={"60px"} borderBottom={"1px lightgray solid"}  mt={4}>
    <Center><Text fontSize={"22px"} fontWeight={"bold"}>My Addresses</Text></Center>
  <Menu>
              <MenuButton
                py={2}
                aria-label={"New Address"}
                title={"New Address"}
                label={"New Address"}
                transition="all 0.3s"
                _focus={{ boxShadow: "none" }}
              >
                {/* <ReactIcons.FaPlus /> */}

                <IconButton
                  as={"span"}
                  position="unset"
                  colorScheme="green"
                  icon={<FaPlus />}
                  spacing={{ base: "6", md: "6" }}
                />
              </MenuButton>
              <MenuList bg={bg} borderColor={borderColor}>
                <MenuItem
                  icon={<FiLock />}
                  isDisabled={addresses && addresses.length >= 10}
                  onClick={() => {
                    setAddAddressModel(0);
                    addAddressDisclosure.onOpen();
                    
                  }}
                >
                  {"New Incognito Address"}
                </MenuItem>
                <MenuItem
                  icon={<FiUnlock />}
                  isDisabled={hasHumanReadableAddress}
                  onClick={() => {
                    setAddAddressModel(1);
                    addAddressDisclosure.onOpen();

                  }}
                >
                  {"New Normal Address"}
                </MenuItem>
                {/* <MenuDivider /> */}
              </MenuList>
            </Menu>
  </Flex>
  <SimpleGrid columns={1} spacing={2}>
    {addresses && addresses.map((address, idx) => {
      return (
        <Flex key={address.address + "-" + idx} cursor={"pointer"} onClick={() => {
          onSelected(idx, address.address);
        }} borderBottom={"1px lightgray solid"} paddingY={2}>

          <Center paddingX={2}>
            <Avatar size={"sm"} position={"unset"} name={address.label || address.address} textColor={"white"} />
          </Center>
          <Center flex={1}>
            <Box paddingX={2} flex={1}>
              {address.label &&
              <Text fontSize={"sm"} fontWeight={"bold"} wordBreak={"break-all"} >
                {address.label}
              </Text>
              }
              <Text fontSize={"sm"} wordBreak={"break-all"} textDecoration={"underline"} >
              
              {address.address}{mailAddressSuffix()}
              </Text>
            </Box>
          </Center>
          {address.address !== window.appConfig.recentActiveAccount && 
          <Center paddingX={2}>
            <HStack spacing={2}>
            {/* <Button colorScheme="gray" leftIcon={<FiCopy />}>Copy</Button>
            <Button colorScheme="red" leftIcon={<FiTrash />}>Delete</Button> */}
            {/* <IconButton  mr={4} onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }} icon={<FiCopy />} colorScheme="gray" title="Copy" aria-label="Copy" /> */}
            <IconButton size={"sm"} icon={<FiTrash />} onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setSelectedAddress(address.address);
              removeAddressDisclosure.onOpen();
            }} colorScheme="red" title="Delete" aria-label="Delete" />
            </HStack>
          </Center>
          }
        </Flex>
      )
    })}
    
  </SimpleGrid>
  </>;
};

const ProfileModal = ({ selectedAccount, composeDisclosure }) => {
  // const { addToast } = useToasts()

  const [state, setState] = useState(0);
  const [saving, setSaving] = useState(false);

  const profileContentRef = createRef();
  // const [qrcode, setQRCode] = useState(null);
  // const [password, setPassword] = useState(null);
  const [encryptedAddress, setEncryptedAddress] = useState(null);
  const [size, setSize] = useState(320); //128
  const [showPassword, setShowPassword] = useState(false);
  const [uiState, setUIState] = useState(0);
  var [currentAddress, setCurrentAddress] = useState(null);
  // const [qrcodeFilter, setQRCodeFilter] = useState('none');
  // const disclosure = useDisclosure({
  //   onOpen: ()=>{
  //   }
  // });

  function togglePassword() {
    // if (showPassword) {
    //   setQRCodeFilter('none');
    // } else {
    //   setQRCodeFilter('blur(15px)');
    // }
    setShowPassword(!showPassword);
  }

  useEffect(() => {
    setSize(320);
    console.log("---- currentAddress changed:", currentAddress)
    if (currentAddress) {
      window.mailService
        .getAddressTokenPassword()
        .then((password) => {
          if (!password) {
            return window.mailService.refreshAddressTokenPassword();
          }
          return password;
        })
        .then((password) => {
          return encryptAddress(
            password,
            currentAddress + mailAddressSuffix()
          ).then((encryptedAddress) => {
            return { password, qrcode: encryptedAddress };
          });
        })
        .then((result) => {
          setEncryptedAddress(result);
          // setPassword(result.password);
          // setQRCode(result.encryptedAddress);
        })
        .catch((e) => {
          showError(e);
        });
    }
  }, [currentAddress]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    // didUpdate
    console.log("ProfileModal useEffect without param " + state);
  }); // eslint-disable-line react-hooks/exhaustive-deps
  const refreshPassword = () => {
    window.mailService
      .refreshAddressTokenPassword()
      .then((password) => {
        return encryptAddress(
          password,
          currentAddress + mailAddressSuffix()
        ).then((encryptedAddress) => {
          return { password, qrcode: encryptedAddress };
        });
      })
      .then((result) => {
        setEncryptedAddress(result);
        // setPassword(result.password);
        // setQRCode(result.encryptedAddress);
      })
      .catch((e) => {
        showError(e);
      });
  };
  // useEffect(() => {// didMount
  //     console.log('ProfileModal useEffect with [] ' + state)
  // }, [])
  const encryptAddress = async (password, address) => {
    const result =
      "enc:" +
      (await window.mailService.cryptoService.encryptMnemonic(
        password,
        address
      ));
    return result;
  };
  const S = Strings.profile;

  const doSave = () => {
    try {
      setSaving(true);

      const canvas = document.getElementById("profile-qrcode");
      var dataURL = canvas.toDataURL("image/png");
      let a = window.document.createElement("a");
      a.setAttribute("href", dataURL);
      a.setAttribute("download", "Profile-" + currentAddress + ".png");
      window.document.body.append(a);
      a.click();
      window.window.URL.revokeObjectURL(dataURL);
      a.remove();
      setSaving(false);

      // setSize(128);
      // setQRCode(selectedAccount + mailAddressSuffix())
    } catch (e) {
      console.error(e);
      setSaving(false);
      // setSize(128);
      // setQRCode(selectedAccount + mailAddressSuffix())
    }
  };
  /*
    const dataUriToUint8Array = (dataUri) => {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      let components = dataUri.split(',');
      let byteString = atob(components[1]);
    
      // separate out the mime component
      // let mimeString = components[0].split(':')[1].split(';')[0]
    
      // write the bytes of the string to an ArrayBuffer
      let ab = new ArrayBuffer(byteString.length);
    
      // create a view into the buffer
      let ia = new Uint8Array(ab);
      return ia;
      // // set the bytes of the buffer to the correct values
      // for (var i = 0; i < byteString.length; i++) {
      //     ia[i] = byteString.charCodeAt(i);
      // }
    
      // // write the ArrayBuffer to a blob, and you're done
      // var blob = new Blob([ab], {type: mimeString});
      // return blob;
    }
    */
  const saveAsFile = () => {
    // disclosure.onOpen();
    doSave();
    // exportComponentAsJPEG(profileContentRef, {fileName:'Profile-' + selectedAccount + '.jpg'}).then(() => {}).catch(e => {
    //   showError(e);
    // })
  };
  /*
    const doSave1 = () => {
      exportComponentAsJPEG(profileContentRef, {fileName:'Profile-' + selectedAccount + '.jpg'}).then(() => {


        // setSize(128);
        // setQRCode(selectedAccount + mailAddressSuffix())
      }).catch(e => {

        // setSize(128);
        // setQRCode(selectedAccount + mailAddressSuffix())
        showError(e);
      })
    }

    const encryptPlexiMailAddress = async(password) => {
      if (!password) {
        return;
      }
      const result = 'enc:' + await window.mailService.cryptoService.encryptMnemonic(password, encryptAddress.qrcode);

      // setSize(320);
      // setQRCode(result);
    }
    const saveAddressToken = () => {
      doSave();
    }
    const saveWithPassword = (password=null) => {
      encryptPlexiMailAddress(password).then(() => {

        setTimeout(() => {
          doSave();
        }, 500);

      }).catch(e => {
        // setSize(128);
        // setQRCode(selectedAccount + mailAddressSuffix())
        showError(e);
      });

      // exportComponentAsJPEG(profileContentRef, {fileName:'Profile-' + selectedAccount + '.jpg'}).then(() => {}).catch(e => {
      //   showError(e);
      // })
    }
    */

  return (
    <Modal
      size={"xl"}
      isOpen={composeDisclosure.isOpen}
      onClose={composeDisclosure.onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {uiState === 0 && ""}
          {uiState === 1 && S.title}
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          {uiState === 0 &&

            <AddressesList selectedAccount={selectedAccount} onSelected={(index, address) => {
              setUIState(1);
              currentAddress = address;
              setCurrentAddress(address);
            }} onClose={() => {
              composeDisclosure.onClose();
            }} />
          }
          {uiState === 1 &&
          <>
          <Box>
            <Button leftIcon={<FaArrowLeft />} onClick={() => {setUIState(0)}}>Back</Button>
          </Box>
          {!encryptedAddress && (
            <VStack id="profile-body" ref={profileContentRef}>
              <Spinner />
              <Text>Generating Address Token ...</Text>
            </VStack>
          )}

          {encryptedAddress && (
            <VStack id="profile-body" ref={profileContentRef}>
              <Toaster position="bottom-center" />

              {/* <QRCodeCanvas value={selectedAccount + mailAddressSuffix()} /> */}
              {/* <QRCode value={selectedAccount + mailAddressSuffix()} logoImage={"../public/logo192.png"} /> */}
              <Box filter={showPassword ? "blur(15px)" : "none"}>
                <CopyToClipboard
                  text={currentAddress + mailAddressSuffix()}
                  onCopy={() => {
                    // addToast('Copied!', {
                    //     appearance: 'info',
                    //     autoDismiss: true,
                    // })
                    setState(state + 1);
                    toast(S.message.copied);
                  }}
                >
                  <QRCodeCanvas
                    id="profile-qrcode"
                    value={encryptedAddress.qrcode}
                    includeMargin={true}
                    bgColor={"white"}
                    fgColor={"black"}
                    size={size}
                    // imageSettings={{
                    //   src: 'avatar.svg',
                    //   // src:'favicon-32x32.png',
                    // excavate:true,
                    // width: 40,
                    // height: 40
                    // }}
                  />
                </CopyToClipboard>
              </Box>

              <Link
                onClick={() => {
                  refreshPassword();
                }}
              >
                <HStack>
                  <FaUndo />
                  <Text>{S.button.refresh}</Text>
                </HStack>
              </Link>
              {/* <CopyToClipboard text={selectedAccount + mailAddressSuffix()} onCopy={() => {
                            // addToast('Copied!', {
                            //     appearance: 'info',
                            //     autoDismiss: true,
                            // })
                            setState(state + 1)
                            toast(S.message.copied);
                        }}>
                            <Text wordBreak='break-all' cursor={'pointer'}>{selectedAccount + mailAddressSuffix()}</Text>
                        </CopyToClipboard> */}

              <InputGroup>
                <CopyToClipboard
                  text={encryptedAddress.password}
                  onCopy={() => {
                    // addToast('Copied!', {
                    //     appearance: 'info',
                    //     autoDismiss: true,
                    // })
                    setState(state + 1);
                    toast(S.message.copied);
                  }}
                >
                  <Input
                    readOnly={true}
                    value={encryptedAddress.password}
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                  />
                </CopyToClipboard>
                <InputRightElement>
                  {showPassword && (
                    <IconButton icon={<FaEye />} onClick={togglePassword} />
                  )}
                  {!showPassword && (
                    <IconButton
                      icon={<FaEyeSlash />}
                      onClick={togglePassword}
                    />
                  )}
                </InputRightElement>
              </InputGroup>

              {/* <Text hidden={!showPassword} color={"red"} fontSize={12}>{S.content.note}</Text>  */}
              <Text mt={5} color={"gray"} fontSize={12}>
                {S.content.copy}
              </Text>
            </VStack>
          )}

          </>
          }
          
          {/* <SaveAlert disclosure={disclosure} onCompleted={(password) => {
                      saveWithPassword(password);
                    }} /> */}
        </ModalBody>

        <ModalFooter>
          {/* <Button colorScheme='red' mr={3} onClick={composeDisclosure.onClose}>Close</Button> */}
            {uiState === 1 &&
            <>
          <CopyToClipboard
            text={currentAddress + mailAddressSuffix()}
            onCopy={() => {
              // addToast('Copied!', {
              //     appearance: 'info',
              //     autoDismiss: true,
              // })
              setState(state + 1);
              toast(S.message.copied);
            }}
          >
            <Button colorScheme="gray" mr={3} disabled={saving}>
              {S.button.copy}
            </Button>
          </CopyToClipboard>
          <Button colorScheme="green" isLoading={saving} onClick={saveAsFile}>
            {S.button.save}
          </Button></>
          }
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProfileModal;
