// https://mail.ai-fi.cc?config=xxx
/**
 * {
 *  "addr": "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
 *  "phrase": "scatter grief usage switch purse echo anxiety carpet audit husband doll unable special innocent bomb glimpse valley hard random kit mansion rather salad simple",
 *  "email": "alice@gmail.com",
 *  "pin": "689873",
 *  "mode": 2,
 *  "chain": '0xa86a',
 *  "privacy": 0,
 * }
 *
 **/

import { ethers } from "ethers";
import MailAddressUtils from "../../utils/MailAddressUtils";

import * as Signer from "@ucanto/principal/ed25519";

import {
  MailEncryptionType, StorageProvider,
  // MailboxType,
} from "../../utils/Types";
import EnterpriseStrings from "./EnterpriseStrings";
import { mailAddressDomain, mailAddressSuffix, mailAddressToSignalIdentity } from "../../common/constants";

class AccountManager {
  mailService = null;
  constructor(mailService) {
    this.mailService = mailService;
  }
  async shareSpace(email) {
    // {id: 'alice@gmail.com', key: 'base64', delegation: 'base64'}

    // async saveDelegation(delegation);
    // async loadDelegation(email) ;
    // async deleteDelegation(email);

    if (window.appConfig.storageProvider === StorageProvider.LightHouse) {
      return {
        agent: null,
        space: null,
        // email: email,
        sp: window.appConfig.storageProvider
      };

      // if (window.appConfig.storageProvider === StorageProvider.LightHouse) {
        
      //   return {
      //     store: window.appConfig.lightHouseApiKey,
      //     backup: window.appConfig.backupLightHouseApiKey
      //   };
      // } 
    } else {

      const delegation = await window.mailService.loadDelegation(email);
      if (delegation) {
        return { agent: delegation.agent, space: delegation.space };
      }

      const principal = await Signer.generate();
      const agent = Signer.format(principal);
      // const space = await this.assignSpace(email, principal.did());
      const space = await window.mailService.web3StorageClient.createDelegation(
        principal.did(),
        ["upload/add", "store/add"],
        "base64"
      );

      await window.mailService.saveDelegation({ id: email, agent, space });
      return { agent, space, sp: window.appConfig.storageProvider };
    }
  }

  async assignSpace(addr, did) {
    // createSpace
    // create delegate
    // archive delegate
    // const res = await window.mailService.web3StorageClient.createAndShareSpace(addr, did)
    if (window.appConfig.storageProvider === StorageProvider.LightHouse) {
      return {
        store: window.appConfig.lightHouseApiKey,
        backup: window.appConfig.backupLightHouseApiKey
      };
    } else if (window.appConfig.storageProvider === StorageProvider.Web3Storage) {

      const res = await window.mailService.web3StorageClient.createDelegation(
        did,
        ["upload/add", "upload/remove", "store/add", "store/remove"],
        "base64"
      );
      return res;
    }
    return null;
  }

  /**
   *
   * @param {*} email
   */
  async createAccount(name = "", email = "", phone = "", withStore = false) {

    if (!email) {
      throw new Error("invalid account parameters");
    }

    if (typeof email !== "string") {
      throw new Error("invalid account parameters");
    }
    let enterpriseProfile = await window.mailService.getEnterpriseProfile();
    if (!enterpriseProfile) {
      if (process.env.REACT_APP_VERSION === "PRO") {
        throw new Error("Please set the enterprise profile first");
      } else {
        enterpriseProfile = { name: null, note: "" };
      }
    }

    const pin = await this.generatePin();
    const wallet = await this.createWallet();
    const principal = withStore ? await Signer.generate() : null;
    const agent = withStore ? Signer.format(principal) : null;
    const store = withStore
      ? await this.assignSpace(wallet.address, principal.did())
      : null;



    const [res, canisterId] = await this.addUser(`${wallet.address.toLowerCase()}@${mailAddressDomain()}`);
    if (!res) {
      throw new Error('Failed to add user to canister');
    }

    
    const config = {
      addr: wallet.address,
      phrase: wallet.mnemonic.phrase,
      name: name,
      email: email,
      phone: phone,
      on: enterpriseProfile.name,
      pin: pin,
      mode: 1,
      chain: "0xa86a",
      privacy: 0,
      // ab: window.appConfig.enterpriseContactsIpnsName,
      agent,
      store,
      sp: window.appConfig.storageProvider,
      canid: canisterId,
      st: window.appConfig.subscriptionType,
      domain: window.appConfig.domainName
    };

    return config;
  }

  async addUser(address) {
    // const signalIdentity = mailAddressToSignalIdentity(address);
    const res = await window.plexiMailService.addUser(address);
    return res;
  } 

  async generatePin() {
    const chars = "0123456789";
    let password = "";
    for (let i = 0; i < 6; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return password;
  }

  async createWallet() {
    const wallet = ethers.Wallet.createRandom();
    return wallet;

    // const hdwallet = ethers.HDNodeWallet.createRandom();
    // const wallet = hdwallet.deriveChild(1);
    // const phrase = wallet.mnemonic.phrase;
    // return phrase;
  }
  async encodeAccount(cfg) {
    const cfgStr = JSON.stringify(cfg);
    const cfgArr = new TextEncoder().encode(cfgStr);
    const b64Str = ethers.encodeBase64(cfgArr);
    return b64Str;
  }

  async decodeAccount(cfg) {
    const cfgArr = ethers.decodeBase64(cfg);
    const cfgStr = new TextDecoder().decode(cfgArr);
    const cfgObj = JSON.parse(cfgStr);
    return cfgObj;
  }

  async saveAccount(account) {
    // const isNew = !account.uuid;
    // if (isNew) {
    //   account.uuid = await this.cryptoService.generateUUID();
    // }
    // return await window.mailService.signalService.saveUser(account);

    return await window.mailService.saveUser(account);
  }

  async getEnterpriseProfile() {
    return await window.mailService.getEnterpriseProfile();
  }

  async saveEnterpriseProfile(profile) {
    return await window.mailService.saveEnterpriseProfile(profile);
  }

  async createBody({ selectedAccount, account, encoded }) {
    const index = window.location.href.indexOf("?");

    let baseurl =
      index === -1
        ? window.location.href
        : window.location.href.substring(0, index);
    if (baseurl.endsWith("#")) {
      baseurl = baseurl.substring(0, baseurl.length - 1);
    }
    /*
    const body = `<div style="border: 1px solid #00aaff; width: 100%; padding: 10px; box-sizing: border-box; font-size: 12px;">
        <h2 style="text-align: center;">
            请使用PlexiMail与我们联系
        </h2>
        <p style="word-break: break-all;">
            亲爱的用户<br />
            为了保护你的隐私本公司为你创建了了一个安全邮箱账户, 点击下方链接可以激活该账号。<br />
            你账户的PIN Code是: ${account.pin}
        </p>
        <p style="text-align: center;">
            <button style="background: none; border: none; background-color: #00aaff; color: #fff; padding: 10px;" onclick="window.location.href='${baseurl}'">Click to Activate Your PlexiMail</button>
        </p>
            <i style="word-break: break-all;">Note: 如果你不能点击上方链接，请复制下面的地址:</i><br />
            <a href="${baseurl}?cfg=${encoded}" target="_blank" style="word-break: break-all;">${baseurl}?cfg=${encoded}</a>
        </p>
    </div>`;
    */
    let template = (await window.mailService.getNotifyTemplate()) || "";

    const body = `${EnterpriseStrings.common.notify_tpl.p1} ${account.pin} ${EnterpriseStrings.common.notify_tpl.p2}${baseurl}${EnterpriseStrings.common.notify_tpl.p2_2}${encoded}${EnterpriseStrings.common.notify_tpl.p3}${baseurl}${EnterpriseStrings.common.notify_tpl.p4}${encoded}${EnterpriseStrings.common.notify_tpl.p5}${baseurl}${EnterpriseStrings.common.notify_tpl.p6}${encoded}${EnterpriseStrings.common.notify_tpl.p7} ${template}`;
    return body;
  }

  async sendNotify({ selectedAccount, account, encoded }) {

    const index = window.location.href.indexOf("?");
    let baseurl =
      index === -1
        ? window.location.href
        : window.location.href.substring(0, index);
    if (baseurl.endsWith("#")) {
      baseurl = baseurl.substring(0, baseurl.length - 1);
    }

    console.log('Enterprise invite url: ', baseurl + '?cfg=' + encoded);
    return;
    const from = selectedAccount + mailAddressSuffix();
    //const rfc822String = toRef.current.value;
    // const to = rfc822StringToAddresses(rfc822String);
    const to = [MailAddressUtils.parseOneAddress(account.email)];
    // const to = MailAddressUtils.parseAddressArray(toValues);
    const subject = `${account.on} ${EnterpriseStrings.common.notify_tpl.subject}`;
    // const subject = document.getElementById('txtSubject').value;
    const body = await this.createBody({ selectedAccount, account, encoded });
    // console.log('Notify Mail:', body);
    let messageType = MailEncryptionType.password;

    const password = await window.mailService.generatePassword(16);
    const encryptionKey = await window.mailService.createEncryptionKey(
      0,
      password
    );

    const uuid = await window.mailService.generateUUID();
    const message = {
      uid: uuid,
      pid: 0,
      type: messageType,
      from,
      to,
      subject,
      body,
      attachments: [],
    };

    const keyBundle = {
      pwd: password,
      main: encryptionKey,
      parties: [],
    };
    message.salt = encryptionKey.salt;
    await window.mailService.sendMessage(keyBundle, message);
    return password;
  }
}

export default AccountManager;
