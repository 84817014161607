// import './App.css';

import React, { useEffect, useState } from "react";
import {
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  // TableContainer,
  Table,
  //   TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  Button,
  Flex,
  Checkbox,
} from "@chakra-ui/react";

// import appConfig from '../config/AppConfig';
import { showError } from "../common/errors";

export default function SharedStorageManagementModal({ disclosure }) {
  const [delegations, setDelegations] = useState([]);
  const [selectedDelegations, setSelectedDelegations] = useState({});

  useEffect(() => {
    if (disclosure.isOpen) {
      window.mailService
        .loadAllDelegations()
        .then((delegations) => {
          setSelectedDelegations({});
          setDelegations(delegations);
        })
        .catch((e) => {
          console.error(e);
          showError(e);
        });
    }
  }, [disclosure.isOpen]);

  const saveClicked = () => {
    window.mailService
      .deleteDelegations(selectedDelegations)
      .then(() => {
        return window.mailService.loadAllDelegations();
      })
      .then((delegations) => {
        setDelegations(delegations);
        setSelectedDelegations({});
      })
      .catch((e) => {
        console.error(e);
        showError(e);
      });
  };

  return (
    <Modal
      size={"xl"}
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {"Shared Storage Management"}
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Table mt={4} variant="simple" w={"100%"}>
            {/* <TableCaption>
                    <Text>
                    </Text>
                </TableCaption> */}
            <Thead>
              <Tr>
                <Th>
                  <Checkbox
                    isDisabled={!delegations || delegations.length === 0}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const selected = {};
                        for (const delegation of delegations) {
                          selected[delegation.id] = delegation;
                        }
                        setSelectedDelegations(selected);
                      } else {
                        setSelectedDelegations({});
                      }
                    }}
                  />
                </Th>
                <Th>Email</Th>
              </Tr>
            </Thead>
            <Tbody>
              {delegations &&
                delegations.length > 0 &&
                delegations.map((delegation, index) => {
                  const selected = selectedDelegations[delegation.id];
                  return (
                    <Tr key={delegation.id}>
                      <Td>
                        <Checkbox
                          defaultChecked={false}
                          isChecked={selected}
                          colorScheme={"green"}
                          onChange={(e) => {
                            if (e.target.checked) {
                              selectedDelegations[delegation.id] = delegation;
                              setSelectedDelegations({
                                ...selectedDelegations,
                              });
                            } else {
                              delete selectedDelegations[delegation.id];
                              setSelectedDelegations({
                                ...selectedDelegations,
                              });
                            }
                          }}
                        />
                      </Td>
                      <Td>{delegation.id}</Td>
                    </Tr>
                  );
                })}

              {(!delegations || delegations.length === 0) && (
                <Tr>
                  <Td colSpan={2} align="center" w={"100%"} maxW={"100%"}>
                    <Text
                      color={"rgb(74, 85, 104)"}
                      width={"100%"}
                      textAlign="center"
                      algin={"center"}
                      fontWeight={"bold"}
                      fontSize={"md"}
                    >
                      No shared storage
                    </Text>
                  </Td>
                </Tr>
              )}
            </Tbody>

            <Tfoot>
              <Tr>
                <Th colSpan={5}>
                  <Flex justify={"flex-end"} mt={4} fontWeight={"bold"}>
                    {Object.keys(selectedDelegations).length === 1 && (
                      <Text align="right" textAlign={"right"}>
                        {Object.keys(selectedDelegations).length} item has been
                        selected.
                      </Text>
                    )}

                    {Object.keys(selectedDelegations).length > 1 && (
                      <Text align="right" textAlign={"right"}>
                        {Object.keys(selectedDelegations).length} items has been
                        selected.
                      </Text>
                    )}
                  </Flex>
                </Th>
              </Tr>
            </Tfoot>
          </Table>
          {/* </TableContainer> */}
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={Object.keys(selectedDelegations).length <= 0}
            colorScheme="red"
            onClick={() => {
              saveClicked();
            }}
          >
            {"Delete"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
