import { Text } from "@chakra-ui/react";

import HomeScreen from "./HomeScreen";
import ResumeScreen from "./enterprise/ResumeScreen";
import { SetupWizardState } from "../utils/Types";
import { useEffect, useState } from "react";
import { ethers } from "ethers";

export default function SetupContainer({
  setSetupWizardState,
  setAccounts,
  setSelectedAccount,
  faucetDisclosure,
}) {
  const cfg = new URL(window.location.href).searchParams.get("cfg");
  console.log("window.wallet:", window.wallet);
  const [isLoading, setLoading] = useState(true);
  const [needResume, setNeedResume] = useState(false);
  useEffect(() => {
    if (window.wallet && cfg && cfg.length > 0) {
      setLoading(true);
      window.wallet
        .getWalletList()
        .then((wallets) => {
          setLoading(false);
          if (!wallets || wallets.length === 0) {
            setNeedResume(true);
          } else {
            const cfgBuffer = ethers.decodeBase64(cfg);
            if (cfgBuffer) {
              const cfgString = new TextDecoder().decode(cfgBuffer);
              if (cfgString) {
                const cryptonToken = JSON.parse(cfgString);
                if (cryptonToken) {
                  const address = cryptonToken.addr.toLowerCase();
                  const existed = wallets.includes((w) => {
                    return w.address.toLowerCase() === address;
                  });
                  if (!existed) {
                    const v = localStorage[`${address}.app.setup-wizard-state`];
                    if (v) {
                      if (v.length > 0) {
                        const state = parseInt(v);
                        if (state === SetupWizardState.ConnectWallet) {
                          setNeedResume(true);
                          return;
                        }
                      }
                    } else {
                      setNeedResume(true);
                      return;
                    }
                  }
                }
              }
            }
            setNeedResume(false);
          }
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setNeedResume(false);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  if (needResume) {
    return (
      <ResumeScreen
        faucetDisclosure={faucetDisclosure}
        setSetupWizardState={setSetupWizardState}
        setAccounts={setAccounts}
        setSelectedAccount={setSelectedAccount}
      />
    );
  }
  return (
    <HomeScreen
      faucetDisclosure={faucetDisclosure}
      setSetupWizardState={setSetupWizardState}
      setAccounts={setAccounts}
      setSelectedAccount={setSelectedAccount}
    />
  );

  // if (window.appConfig.setupWizardState === SetupWizardState.ConnectWallet && cfg && cfg.length > 0) {
  //     return <ResumeScreen faucetDisclosure={faucetDisclosure} setSetupWizardState={setSetupWizardState} setAccounts={setAccounts} setSelectedAccount={setSelectedAccount} />
  // }
  // return <HomeScreen faucetDisclosure={faucetDisclosure} setSetupWizardState={setSetupWizardState} setAccounts={setAccounts} setSelectedAccount={setSelectedAccount} />
}
