

// import { base64 } from 'ethers/lib/utils';
import axios from "axios";
import { ethers } from "ethers";
import { keys } from 'libp2p-crypto';

import { ServerError, ClientError } from "../../common/errors";
import Strings from '../../config/Strings';
import WalletStrings from '../WalletStrings';

import { identity } from 'multiformats/hashes/identity';
import * as Digest from 'multiformats/hashes/digest';
import { base36 } from 'multiformats/bases/base36';
import { CID } from 'multiformats/cid';


import {USE_DAG_FILE} from '../../common/constants';
import { StorageProvider } from "../../utils/Types";

const ed = require('@noble/ed25519')


const libp2pKeyCode = 0x72;
const CRYPTON_PREFIX = 'afwallet.';

const BACKEND_SERVER = new URL(process.env.REACT_APP_BACKEND_SERVER);
const PUSH_SERVER_PROTOCOL = BACKEND_SERVER.protocol;
const PUSH_SERVER_HOST = BACKEND_SERVER.hostname;
const PUSH_SERVER_PORT = '';
const PUSH_SERVER_ROOT_PATH = '';
const PUSH_SERVER_ADDRESS = PUSH_SERVER_PROTOCOL + '//' + PUSH_SERVER_HOST + PUSH_SERVER_PORT + PUSH_SERVER_ROOT_PATH;

export default class BackupService {

    // constructor() {
    // }
    async #axios_get(url, name, progress) {
        // this.cancel();
        this.getAbortController = new AbortController();

        // this.getAbortController = new AbortController();
        try {
            const res = await axios.get(
                url,
                {
                    signal: this.getAbortController.signal,
                    // responseType: "arraybuffer",
                    responseType: 'blob',
                    // maxContentLength: 10000000,
                    onDownloadProgress: (event) => {
                        if (progress) {
                            progress(event);
                        }
                    }
                });
            if (res.status === 404) {
                throw ServerError.notFoundError(Strings.error.client.file_not_found + url);
            }
            if (res.status !== 200) {
                throw ServerError.httpError(res);
            }
            this.getAbortController = null;
            const blob = res.data;
            const file = new File([blob], name);
            return file;
        } catch (e) {
            this.getAbortController = null;
            throw e;
        }
    }

    // https://bafybeifkpqlpwh6ko2scnpsjv4eqbzawerpnmkfkcyz64wu2jeet26p4ve.ipfs.cf-ipfs.com/folders.json
    // https://ipfs.io/ipfs/bafybeifkpqlpwh6ko2scnpsjv4eqbzawerpnmkfkcyz64wu2jeet26p4ve/folders.json
    // https://cloudflare-ipfs.com/ipfs/bafybeifkpqlpwh6ko2scnpsjv4eqbzawerpnmkfkcyz64wu2jeet26p4ve/folders.json
    async #dweb_get(cid, name, mode, progress) {
        let url = 'https://dweb.link/ipfs/' + cid;
        if (USE_DAG_FILE && name) {
            url += ('/' + name);
        }
        const file = await this.#axios_get(url, name, progress);
        return file;
    }

    async #w3s_get(cid, name, mode, progress) {
        let url = null;
        const isV0 = cid.startsWith('Qm');
        if (mode === StorageProvider.LightHouse || isV0) {
            url = `https://gateway.lighthouse.storage/ipfs/${cid}`
        } else {
            url = (USE_DAG_FILE && name) ? `https://${cid}.ipfs.w3s.link/${name}` : `https://${cid}.ipfs.w3s.link`;
        }
        
        // const url = 'https://' + cid + '.ipfs.w3s.link/' + name;
        // let url = 'https://w3s.link/ipfs/' + cid;
        // if (USE_DAG_FILE && name) {
        //     url += ('/' + name);
        // }
        const file = await this.#axios_get(url, name, progress);
        return file;
    }

    async #cf_ipfs_get(cid, name, mode, progress) {
        // const url = 'https://' + cid + '.pfs.cf-ipfs.com/' + name;
        let url = 'https://cf-ipfs.com/ipfs/' + cid;
        if (USE_DAG_FILE && name) {
            url += ('/' + name);
        }
        const file = await this.#axios_get(url, name, progress);
        return file;
    }

    async #ipfs_get(cid, name, mode, progress) {
        let url = 'https://ipfs.io/ipfs/' + cid;
        if (USE_DAG_FILE && name) {
            url += ('/' + name);
        }
        const file = await this.#axios_get(url, name, progress);
        return file;
    }

    async #cloudflare_ipfs_get(cid, name, mode, progress) {
        let url = 'https://cloudflare-ipfs.com/ipfs/' + cid;
        if (USE_DAG_FILE && name) {
            url += ('/' + name);
        }
        const file = await this.#axios_get(url, name, progress);
        return file;
    }

    async #pinata_get(cid, name, mode, progress) {
        let url = 'https://gateway.pinata.cloud/ipfs/' + cid;
        if (USE_DAG_FILE && name) {
            url += ('/' + name);
        }
        const file = await this.#axios_get(url, name, progress);
        return file;
    }

    async #ipfs_gateway_get(cid, name, mode, progress) {
        // https://ipfs-gateway.cloud/ipfs/bafybeifx7yeb55armcsxwwitkymga5xf53dxiarykms3ygqic223w5sk3m#x-ipfs-companion-no-redirect
        let url = 'https://ipfs-gateway.cloud/ipfs/' + cid;
        if (USE_DAG_FILE && name) {
            url += ('/' + name);
        }
        const file = await this.#axios_get(url, name, progress);
        return file;
    }

    async #ipfs_eternum_io_get(cid, name, mode, progress) {
        // https://ipfs.eternum.io/ipfs/bafybeifx7yeb55armcsxwwitkymga5xf53dxiarykms3ygqic223w5sk3m#x-ipfs-companion-no-redirect
        let url = 'https://ipfs.eternum.io/ipfs/' + cid;
        if (USE_DAG_FILE && name) {
            url += ('/' + name);
        }
        const file = await this.#axios_get(url, name, progress);
        return file;
    }
    
    #getFunctions = [
        this.#w3s_get.bind(this),
        this.#dweb_get.bind(this),
        this.#ipfs_get.bind(this),
        this.#pinata_get.bind(this),
        this.#ipfs_gateway_get.bind(this),
        this.#ipfs_eternum_io_get.bind(this),
        this.#cloudflare_ipfs_get.bind(this),
        this.#cf_ipfs_get.bind(this)
    ];

    async #do_get(cid, name = 'wallet.crypton.json', mode, progress, fnIndex = 0) {

        // web_storage_get
        // cf_ipfs_get
        // cloudflare_ipfs_get
        // w3s_get
        // ipfs_get
        // dweb_get
        const fnLen = this.#getFunctions.length;
        const getFn = this.#getFunctions[fnIndex++];
        try {
            // const file = await getFn.call(this, cid, name, progress);
            const file = await getFn(cid, name, mode, progress);
            return file;
        } catch (e) {
            console.error(e);
            const cancelled = (e.code === "ERR_CANCELED") ||  (e.code === DOMException.ABORT_ERR);
            if (cancelled) {
                throw e;
            }
            if (fnIndex >= fnLen) {
                throw e;
            }
            if (e.name === 'ServerError' && e.code === ServerError.CODE_NOT_FOUND) {
                throw e;
            }
            const file = await this.#do_get(cid, name, mode, progress, fnIndex);
            return file;
        }
    }

    async #get(cid, name, mode, progress=null) {
        const file = await this.#do_get(cid, name, mode, progress, 0);
        return file;
    }

    async #generateCryptonName(salt, password) {
        const kp = await this.#getEd25519KeyPair(CRYPTON_PREFIX + salt, password);
        const digest = Digest.create(identity.code, kp.public.bytes)
        return CID.createV1(libp2pKeyCode, digest).toString(base36)
    }
    async #getWalletCID(name) {
        // TODO:
    }

    async checkIfNewCreated(accountAddress) {
        
        try {
            // TODO:

        } catch (err) {
            throw err;
        }
        return true;
    }

    async #getKeyMaterial(password) {
        const keyMaterial = window.crypto.subtle.importKey(
            "raw", 
            password, 
            {name: "PBKDF2"}, 
            false, 
            ["deriveBits", "deriveKey"]
        );
        return keyMaterial;
    }

    async #getBits(salt, keyMaterial) {
        const derivedBits = await window.crypto.subtle.deriveBits(
            {
                name: "PBKDF2",
                salt: salt,
                iterations: 100000,
                hash: "SHA-256",
            },
            keyMaterial,
            256
        );
        return derivedBits;
    }

    async #getSeed(salt, password) {
        const encoder = new TextEncoder();
        salt = encoder.encode(salt);
        password = encoder.encode(password);

        const keyMaterial = await this.#getKeyMaterial(password);
        const seed = await this.#getBits(salt, keyMaterial);

        return seed;
    }
    async #getEd25519KeyPair(salt, password) {
        
        let seed = await this.#getSeed(salt, password);
        if (seed instanceof ArrayBuffer) {
            seed = new Uint8Array(seed);
        }
        const key = await keys.generateKeyPairFromSeed('Ed25519', seed, 1024);
        return key;
    }

    async #getCrypton(salt, password) {
        const kp = await this.#getEd25519KeyPair(CRYPTON_PREFIX + salt, password);

        const digest = Digest.create(identity.code, kp.public.bytes)
        const cid = CID.createV1(libp2pKeyCode, digest).toString(base36);
        // const privKey1 = kp.marshal().slice(0, 32);
        // const sharedSecret1 = await ed.getSharedSecret(privKey1, ephemeralKeyPair.public.marshal());

        return {
            keyPair: kp,
            name: cid
        }
    }

    async #createCrypton(salt, password) {
        const {keyPair, name} = await this.#getCrypton(salt, password);
        const ephemeralKeyPair = await keys.generateKeyPair('Ed25519');

        const privKey = ephemeralKeyPair.marshal().slice(0, 32);
        const sharedSecret = await ed.getSharedSecret(privKey, keyPair.public.marshal());

        const encryptionKey = await window.crypto.subtle.importKey('raw', sharedSecret, 'AES-GCM', true, ['encrypt', 'decrypt']);
        

        // const privKey1 = kp.marshal().slice(0, 32);
        // const sharedSecret1 = await ed.getSharedSecret(privKey1, ephemeralKeyPair.public.marshal());

        return {
            name: name,
            keyPair: keyPair,
            ephemeralPublicKey: ephemeralKeyPair.public._key,
            encryptionKey: encryptionKey,
        }
    }

    async #encrypt(key, plaintext) {
        if (typeof plaintext === 'undefined') {
            throw ClientError.invalidParameterError(Strings.error.client.enc_data_undefined);
        } else if (plaintext === null) {
            throw ClientError.invalidParameterError(Strings.error.client.enc_data_null);
        } else if (typeof plaintext === 'string') {
            const encoder = new TextEncoder();
            plaintext = encoder.encode(plaintext);
        } else if (plaintext.constructor === ArrayBuffer) {
            plaintext = new Uint8Array(plaintext);
        } else if (plaintext.constructor === Uint8Array) {
        } else if (plaintext.constructor === Array) {
            plaintext = Uint8Array.from(plaintext);
        } else {
            throw ClientError.invalidDataTypeError(Strings.error.client.unsupported_data_type_1 + plaintext + Strings.error.client.unsupported_data_type_2);
        }

        const iv = window.crypto.getRandomValues(new Uint8Array(12));
        const ciphertext = await window.crypto.subtle.encrypt(
            {
                name: "AES-GCM", 
                iv: iv, 
                tagLength: 128
            }, 
            key, 
            plaintext
        );
        return {iv, ciphertext: new Uint8Array(ciphertext)};
    }
    
    async #decrypt(key, {iv, ciphertext}) {
        const plaintext = await window.crypto.subtle.decrypt(
            {
                name: "AES-GCM", 
                iv: iv, 
                tagLength: 128
            }, 
            key, 
            ciphertext
        );
        const decoder = new TextDecoder();
        const plaintextString = decoder.decode(plaintext);
        return plaintextString;
        // return plaintext
    }

    async #retrieve(name) {
        try {
            // const cid = await this.#resolveTokenCID(name);
            const result = await this.#ICPResolveTokenCID(name);
            const file = await this.#get(result.cid, 'wallet.crypton.json', result.sp);
            const tokenString = await file.text();
            const token = JSON.parse(tokenString);
            return token;
        } catch (e) {
            console.error(e);
            if (e.message.toLowerCase().includes('not found')) {
                return null;
            }
            throw e;
        }
    }
    
    async #resolveTokenCID(name) {
        let resp = await fetch(`${PUSH_SERVER_ADDRESS}/api/crypton/${name}`, {
            method: "GET", 
            mode: "cors"
        })

        if (resp.status !== 200) {
            console.log('http error, code:', resp.status, ', msg:', resp.statusText);
            throw ServerError.httpError(resp);
        }
        const res = await resp.json();
        if (res.code !== 0) {
            throw ServerError.from(res);
        }
        const cid = res.data;
        if (!cid || cid.length === 0) {
            throw ClientError.invalidDataTypeError('Not found');
        }
        return cid;
    }
    

    async #updateTokenCID(keyPair, name, cid) {

        const data = new TextEncoder().encode(cid);
        const signature = await keyPair.sign(data);
        const pubKeyInBase64 = ethers.encodeBase64(keyPair.public.bytes)
        const signatureInBase64 = ethers.encodeBase64(signature);

        let resp = await fetch(`${PUSH_SERVER_ADDRESS}/api/crypton`, {
            method: "POST", 
            mode: "cors",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({name: name, publicKey: pubKeyInBase64, crypton:cid, signature: signatureInBase64}) 
        })

        if (resp.status !== 200) {
            console.log('http error, code:', resp.status, ', msg:', resp.statusText);
            throw ServerError.httpError(resp);
        }
        const res = await resp.json();
        if (res.code !== 0) {
            throw ServerError.from(res);
        }
    }
    async #ICPResolveTokenCID(name, asJSON=true) {
        try {
            const crypton = await window.plexiMailService.getCrypton(name);

            if (!crypton || crypton.length === 0) {
                throw ClientError.invalidDataTypeError('Not found');
            }
            if (Array.isArray(crypton) && crypton.length > 0) {
                let c = crypton[0];
                if (asJSON) {
                    const arr = c.split(':');
                    if (!arr || arr.length !== 2) {
                        throw ClientError.invalidDataTypeError('Incompatible crypton');
                    }
                    const result = {sp: parseInt(arr[0]), cid: arr[1]};
                    return result;
                } else {
                    return c;
                }
            }

            if (asJSON) {
                const arr = crypton.split(':');
                if (!arr || arr.length !== 2) {
                    throw ClientError.invalidDataTypeError('Incompatible crypton');
                }

                const result = {sp: parseInt(arr[0]), cid: arr[1]};
                return result;
            } else {
                return crypton;
            }
        } catch (e) {
            console.error(e);
            throw ServerError.notFoundError('Crypton not found')
        }
    }
    async #ICPUpdateTokenCID(keyPair, name, cid) {

        const data = new TextEncoder().encode(cid);
        const signature = await keyPair.sign(data);
        const pubKeyInBase64 = ethers.encodeBase64(keyPair.public.bytes)
        const signatureInBase64 = ethers.encodeBase64(signature);
        const crypton = `${window.appConfig.storageProvider}:${cid}`;
        const form = {name: name, publicKey: pubKeyInBase64, crypton:crypton, signature: signatureInBase64};
        try {
            await window.plexiMailService.saveCrypton(form)
        } catch (e) {
            console.error(e);
            throw ServerError.securityError('Failed to save crypton')
        }
    }
    async #save(local, token) {
        const tokenString = JSON.stringify(token.data);
        if (local) {
            let blob=new Blob([tokenString]); 
            let url = URL.createObjectURL(blob);
            let a = window.document.createElement("a");
            a.setAttribute("href", url);
            a.setAttribute("download", "wallet.crypton.json");
            window.document.body.append(a);
            a.click();
            window.window.URL.revokeObjectURL(url);
            a.remove();
        } else {

            // const web3StorageClient = new W3UpWeb3Storage()
            

            const files = [new File([tokenString], 'wallet.crypton.json')];
            const cid = await window.mailService.web3StorageClient.put(files, {name: 'wallet.crypton.json'});

            // await this.#updateTokenCID(token.keyPair, token.name, cid);
            await this.#ICPUpdateTokenCID(token.keyPair, token.name, cid);
        }
    }
    async #removeFile(cid) {
        await window.mailService.removeFile(cid);
    }
    
    async changeIPNS(salt, password, content) {
        const {keyPair, name} = await this.#getCrypton(salt, password);
        // await this.#updateTokenCID(keyPair, name, content);
        await this.#ICPUpdateTokenCID(keyPair, name, content);
    }

    async recover(salt, password, token=null) {
        salt = window.plexiMailService.getPrincipal() + "#" + salt;

        const {keyPair, name} = await this.#getCrypton(salt, password);
        
        if (!token) {
            token = await this.#retrieve(name);
        }
        if (!token) {
            return null;
        }
        
        const ephemeralPublicKey = ethers.decodeBase64(token.ephemeralPublicKey);
        const payload = {
            iv: ethers.decodeBase64(token.payload.iv),
            ciphertext: ethers.decodeBase64(token.payload.ciphertext)
        }

        const privKey = keyPair.marshal().slice(0, 32);
        const sharedSecret = await ed.getSharedSecret(privKey, ephemeralPublicKey);

        const encryptionKey = await window.crypto.subtle.importKey('raw', sharedSecret, 'AES-GCM', true, ['encrypt', 'decrypt']);
        
        const jsonString = await this.#decrypt(encryptionKey, payload);
        try {
            const result = JSON.parse(jsonString);
            return result;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async check(salt, password) {
        const token = await this.recover(salt, password);
        return token != null;
    }
    
    //    backup(salt, password, walletID, wallet, apiToken, pinCode, privacyLevel, force, local);
    async backup(salt, password, walletID, agent, wallet, pinCode, privacyLevel, force=false, local=false) {
        let newSalt = window.plexiMailService.getPrincipal() + "#" + salt;
        if (!force) {
            const existed = await this.check(salt, password);
            if (existed) {
                throw ClientError.cryptonExistedError(WalletStrings.ui.override.message);
            }
        }
        const chainId = window.appConfig ? window.appConfig.chainId : window.chainId;
        const {
            name,
            keyPair,
            ephemeralPublicKey,
            encryptionKey
        } = await this.#createCrypton(newSalt, password);
        const payload = JSON.stringify({walletID, agent, wallet, chainId, pinCode, privacyLevel});
        const result = await this.#encrypt(encryptionKey, payload);

        const token = {
            name: name,
            keyPair,
            data: {
                version: 1,
                encoding: 'base64',
                ephemeralPublicKey: ethers.encodeBase64(ephemeralPublicKey),
                payload: {
                    iv: ethers.encodeBase64(result.iv),
                    ciphertext: ethers.encodeBase64(result.ciphertext)
                }
            }
        }

        let old = null;
        try {
            // old = await this.#resolveTokenCID(name);
            old = await this.#ICPResolveTokenCID(name, false);
            
        } catch (e) {
            console.error(e);
        }

        await this.#save(local, token);

        if (old && old.length > 0) {
            try {
                await this.#removeFile(old);
            } catch (e) {
                console.error(e);
            }
        }
    }

    async delete(salt, password) {
        salt = window.plexiMailService.getPrincipal() + "#" + salt;

        const {name} = await this.#getCrypton(salt, password);

        let resp = await fetch(`${PUSH_SERVER_ADDRESS}/api/crypton/${name}`, {
            method: "DELETE", 
            mode: "cors"
        })

        if (resp.status !== 200) {
            console.log('http error, code:', resp.status, ', msg:', resp.statusText);
            throw ServerError.httpError(resp);
        }
        const res = await resp.json();
        if (res.code !== 0) {
            throw ServerError.from(res);
        }
        
    }

}