/*
{
    chakra-ui-color-mode: "light"
    mail.folders-sign-key: "CAESQDq/dZ1UWDJmbWyW/WY3VHlskleHmLHA+3YeI5bdc2qNa2cPIyqbMsZhEIRjL36HiWWxo943FaNcQZZAZaXOPjw="
    mail.prekeys-sign-key: "CAESQE+Np11BRtZPXBH/DGw1oRV7KO9RWJSAQ3rGWo514RBiw9gR+rgQG7JEBfFkQuN66MncTe5eRk+etHflENB6WlE="
    mail.signal-context-sign-key: "CAESQEwmM2hToqbIZqXqVw6YawjXCliAtLW2cLTsXNGywbP3xZqnqM/uuQ6Y8UHeN6DVnvxkWXPdDpfN+5XIPhK0hfU="
    web3.storage-token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDVBMTMzNTJBNzcwYkE4M2M2YUU4ZTQwOWIyMTU4NGY1YjJhZmI3OGMiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2NTUyNjUzOTcwNDAsIm5hbWUiOiJFeGFtcGxlIn0.o89ztC7cQ3lyvqgI77phbL7Vz3_pZK0IbKIA-clZ-X4"
}
*/
import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Button,
  SimpleGrid,
  // Link,
} from "@chakra-ui/react";

import { SetupWizardState } from "../utils/Types";
import Strings from "../config/Strings";
import { TutorialModalLink } from "./TutorialModalTrigger";
import { showError } from "../common/errors";
import { ConfirmationCloseButton } from "./ConfirmationButton";
import { useState } from "react";

function SetupMethodSelector({ setSetupWizardState }) {
  const [isCreating, setCreating] = useState(false);
  function createAccount() {
    // setSetupWizardState(SetupWizardState.SelectBackupMethod)
    if (window.wallet && window.wallet.asDefaultWallet) {
      setCreating(true);
      window.mailService
        .initCryptServiceIfNeeded()
        .then(() => {
          return window.wallet.dropBackup();
        }).then(() => {
          return window.mailService.deleteMailList()
        })
        .then(() => {
          setCreating(false);
          window.appConfig.tempMnemonic = window.appConfig.mnemonic;
          setSetupWizardState(SetupWizardState.ConfigWeb3Storage);
        })
        .catch((e) => {
          setCreating(false);
          console.error(e);
        });
      // setSetupWizardState(SetupWizardState.ConfigWeb3Storage);
    } else {
      window.appConfig.web3StorageApiToken = "";
      setSetupWizardState(SetupWizardState.SelectBackupMethod);
    }
  }

  function restoreAccount() {
    // setSetupWizardState(SetupWizardState.SelectRecoveryMethod);

    if (window.wallet && window.wallet.asDefaultWallet) {
      window.mailService.initCryptServiceIfNeeded().then(() => {
        window.appConfig.tempMnemonic = window.appConfig.mnemonic;
        setSetupWizardState(SetupWizardState.RestoreWeb3Storage);
      });
      // setSetupWizardState(SetupWizardState.RestoreWeb3Storage);
    } else {
      setSetupWizardState(SetupWizardState.SelectRecoveryMethod);
    }
  }

  const reset = () => {
    window.mailService
      .reset(true)
      .then(() => {
        return window.wallet.reset(true);
      })
      .then(() => {
        setSetupWizardState(SetupWizardState.ConnectWallet);
        window.appConfig.setupWizardState = SetupWizardState.ConnectWallet;
        window.forceQuit = true;
        setTimeout(function () {
          window.location.reload(true);
        });
      })
      .catch((e) => {
        console.error(e);
        showError(e);
      });
  };

  function backClicked() {
    if (window.web3Helper && window.web3Helper.magic) {
      reset();
      // window.web3Helper.magic.wallet.disconnect().then(() => {
      // // window.web3Helper.magic.connect.disconnect().then(() => {
      //     reset();
      // }).catch(e => {
      //     console.error(e);
      //     // showError(e);
      // });
    } else {
      reset();
    }
  }

  return (
    <>
      <Stack spacing={4}>
        <Heading
          color={"gray.800"}
          lineHeight={1.1}
          fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
        >
          {/* <Text
                    as={'span'}
                    bgGradient="linear(to-r, red.400,pink.400)"
                    bgClip="text">
                        {Strings.setup_method.step} &nbsp;
                </Text>  */}
          {Strings.setup_method.title}
        </Heading>
        <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
          {Strings.setup_method.content.paragraph1}&nbsp;
          <TutorialModalLink title={"Learn more"} anchor={""} />
        </Text>
        <Text color={"orange.500"} fontSize={{ base: "sm", sm: "md" }}>
          {Strings.setup_method.content.paragraph2}
        </Text>
      </Stack>
      <Box as={"form"} mt={4}>
        <Stack spacing={4}>
          {/* <FormLabel htmlFor='country'>如果你是一个新用户请选择配置web3 storage. 否则请选择导入web3 配置。</FormLabel> */}

          <Button
            fontFamily={"heading"}
            mt={0}
            isDisabled={isCreating}
            w={"full"}
            bgGradient="linear(to-r, red.400,pink.400)"
            color={"white"}
            _hover={{
              bgGradient: "linear(to-r, red.400,pink.400)",
              boxShadow: "xl",
            }}
            onClick={restoreAccount}
          >
            {Strings.setup_method.button.restore.title}
          </Button>

          <Button
            fontFamily={"heading"}
            isLoading={isCreating}
            mt={8}
            w={"full"}
            color={"white"}
            bgGradient="linear(to-r, gray.600,gray.400)"
            _hover={{
              bgGradient: "linear(to-r, gray.400,gray.600)",
              boxShadow: "xl",
            }}
            onClick={createAccount}
          >
            {Strings.setup_method.button.create.title}
          </Button>

          <ConfirmationCloseButton
            fontFamily={"heading"}
            mt={8}
            w={"full"}
            bgColor={"gray.200"}
            // bgGradient="linear(to-r, red.400,pink.400)"
            color={"black"}
            // _hover={{
            //     bgGradient: 'linear(to-r, red.400,pink.400)',
            //     boxShadow: 'xl',
            // }}
            onClick={backClicked}
          >
            {Strings.setup_method.button.back.title}
          </ConfirmationCloseButton>

          {/* <Button
                    fontFamily={'heading'}
                    mt={8}
                    w={'full'}
                    bgColor={"gray.200"}
                    // bgGradient="linear(to-r, red.400,pink.400)"
                    color={'black'}
                    // _hover={{
                    //     bgGradient: 'linear(to-r, red.400,pink.400)',
                    //     boxShadow: 'xl',
                    // }}
                    onClick={backClicked}>
                    {Strings.setup_method.button.back.title}
                    </Button> */}
        </Stack>
      </Box>
    </>
  );
}

export default function SetupMethodSelectorScreen({ setSetupWizardState }) {
  return (
    <Box position={"relative"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
        >
          <SetupMethodSelector setSetupWizardState={setSetupWizardState} />
        </Stack>
      </Container>
    </Box>
  );
}
