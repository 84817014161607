/*
{
    chakra-ui-color-mode: "light"
    mail.folders-sign-key: "CAESQDq/dZ1UWDJmbWyW/WY3VHlskleHmLHA+3YeI5bdc2qNa2cPIyqbMsZhEIRjL36HiWWxo943FaNcQZZAZaXOPjw="
    mail.prekeys-sign-key: "CAESQE+Np11BRtZPXBH/DGw1oRV7KO9RWJSAQ3rGWo514RBiw9gR+rgQG7JEBfFkQuN66MncTe5eRk+etHflENB6WlE="
    mail.signal-context-sign-key: "CAESQEwmM2hToqbIZqXqVw6YawjXCliAtLW2cLTsXNGywbP3xZqnqM/uuQ6Y8UHeN6DVnvxkWXPdDpfN+5XIPhK0hfU="
    web3.storage-token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDVBMTMzNTJBNzcwYkE4M2M2YUU4ZTQwOWIyMTU4NGY1YjJhZmI3OGMiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2NTUyNjUzOTcwNDAsIm5hbWUiOiJFeGFtcGxlIn0.o89ztC7cQ3lyvqgI77phbL7Vz3_pZK0IbKIA-clZ-X4"
}
*/
import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Button,
  SimpleGrid,
  // Link,
} from "@chakra-ui/react";

import { MnemonicBackupMethod, SetupWizardState } from "../utils/Types";
import Strings from "../config/Strings";
import { TutorialModalLink } from "./TutorialModalTrigger";

function BackupMethodSelector({ setSetupWizardState }) {
  function saveIntoWeb3Storage() {
    window.appConfig.mnemonicBackupMethod = MnemonicBackupMethod.Web3Storage;
    // setSetupWizardState(SetupWizardState.ConfigPassword)
    if (window.wallet && window.wallet.asDefaultWallet) {
      // const existed = await window.mailService.checkCryptonToken(selectedAccount, password);
      window.mailService.initCryptServiceIfNeeded().then(() => {
        window.appConfig.tempMnemonic = window.appConfig.mnemonic;
        setSetupWizardState(SetupWizardState.ConfigWeb3Storage);
      });
      // setSetupWizardState(SetupWizardState.ConfigWeb3Storage);
    } else {
      setSetupWizardState(SetupWizardState.ConfigPassword);
    }
  }

  function saveAsLocalFile() {
    window.appConfig.mnemonicBackupMethod = MnemonicBackupMethod.LocalFile;
    // setSetupWizardState(SetupWizardState.ConfigPassword)
    if (window.wallet && window.wallet.asDefaultWallet) {
      window.mailService.initCryptServiceIfNeeded().then(() => {
        window.appConfig.tempMnemonic = window.appConfig.mnemonic;
        setSetupWizardState(SetupWizardState.ConfigWeb3Storage);
      });
    } else {
      setSetupWizardState(SetupWizardState.ConfigPassword);
    }
  }

  function backClicked() {
    window.appConfig.mnemonicBackupMethod = MnemonicBackupMethod.Web3Storage;
    setSetupWizardState(SetupWizardState.SelectSetupMethod);
  }

  return (
    <>
      <Stack spacing={4}>
        <Heading
          color={"gray.800"}
          lineHeight={1.1}
          fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
        >
          {/* <Text
                    as={'span'}
                    bgGradient="linear(to-r, red.400,pink.400)"
                    bgClip="text">
                        {Strings.backup_method.step} &nbsp;
                </Text>  */}
          {Strings.backup_method.title}
        </Heading>
        <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
          {Strings.backup_method.content.paragraph1}
          <br />
        </Text>
        <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
          {Strings.backup_method.content.paragraph2}
          <br />
        </Text>
        <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
          {Strings.backup_method.content.paragraph3}&nbsp;
          <Text as="span" fontWeight={"bold"} color="black">
            {Strings.backup_method.content.paragraph4}
          </Text>
          &nbsp;
          <TutorialModalLink title={"Learn more"} />
        </Text>
      </Stack>
      <Box as={"form"} mt={4}>
        <Stack spacing={4}>
          {/* <FormLabel htmlFor='country'>如果你是一个新用户请选择配置web3 storage. 否则请选择导入web3 配置。</FormLabel> */}

          <Button
            fontFamily={"heading"}
            w={"full"}
            bgGradient="linear(to-r, red.400,pink.400)"
            color={"white"}
            _hover={{
              bgGradient: "linear(to-r, red.400,pink.400)",
              boxShadow: "xl",
            }}
            onClick={saveIntoWeb3Storage}
          >
            {Strings.backup_method.button.web3.title}
          </Button>

          <Button
            fontFamily={"heading"}
            mt={8}
            w={"full"}
            bgGradient="linear(to-r, red.400,pink.400)"
            color={"white"}
            _hover={{
              bgGradient: "linear(to-r, red.400,pink.400)",
              boxShadow: "xl",
            }}
            onClick={saveAsLocalFile}
          >
            {Strings.backup_method.button.local.title}
          </Button>

          <Button
            fontFamily={"heading"}
            mt={8}
            w={"full"}
            bgColor={"gray.200"}
            // bgGradient="linear(to-r, red.400,pink.400)"
            color={"black"}
            // _hover={{
            //     bgGradient: 'linear(to-r, red.400,pink.400)',
            //     boxShadow: 'xl',
            // }}
            onClick={backClicked}
          >
            {Strings.backup_method.button.back.title}
          </Button>
        </Stack>
      </Box>
    </>
  );
}

export default function BackupMethodSelectorScreen({ setSetupWizardState }) {
  return (
    <Box position={"relative"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
        >
          <BackupMethodSelector setSetupWizardState={setSetupWizardState} />
        </Stack>
      </Container>
    </Box>
  );
}
