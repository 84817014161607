// https://docs.cloud.coinbase.com/sign-in-with-coinbase/docs/api-prices
// https://www.coingecko.com/en/api/documentation
const getCurrentPriceFromCoinbase = async(from='AVAX', to='USD') => {
    // {"data":{"base":"AVAX","currency":"USD","amount":"14.09"}}
    let url = 'https://api.coinbase.com/v2/prices/AVAX-USD/buy';
    const resp = await fetch(url, {
        mode: "cors",
        headers: {
            // 'Authorization': 'Bearer ' + localStorage.getItem('admin.jwt'),
            'Content-Type': 'application/json'
        }
      });
      const json = await resp.json();
      return json?.data?.amount || 0;
}

const getCurrentPriceFromCoingecko = async(from='AVAX', to='USD') => {
    // {"avalanche-2": { "usd": 14.003424905951661}}
    let url = 'https://api.coingecko.com/api/v3/simple/price?ids=avalanche-2&vs_currencies=usd&precision=full';
    const resp = await fetch(url, {
      mode: "cors",
      headers: {
          // 'Authorization': 'Bearer ' + localStorage.getItem('admin.jwt'),
          'Content-Type': 'application/json'
      }
    });
    const json = await resp.json();
    return json['avalanche-2']?.usd;
}


export async function getCurrentPrice(from='AVAX', to='USD', exchange='coingecko') {
    switch (exchange) {
        case 'coingecko':
            return await getCurrentPriceFromCoingecko(from, to);
        case 'coinbase':
            return await getCurrentPriceFromCoinbase(from, to);
        default:
            throw new Error('Unsupported exchange');
    }
}


