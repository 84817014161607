// import { SignedPublicPreKeyType, DeviceType, PreKeyType } from '@privacyresearch/libsignal-protocol-typescript'

//import { base64 } from 'ethers/lib/utils';
import * as base64 from 'base64-js';
/*
export interface PublicDirectoryEntry {
    identityKey: ArrayBuffer
    signedPreKey: SignedPublicPreKeyType
    oneTimePreKey?: ArrayBuffer
}

interface FullDirectoryEntry {
    registrationId: number
    identityKey: ArrayBuffer
    signedPreKey: SignedPublicPreKeyType
    oneTimePreKeys: PreKeyType[]
}

export interface PublicPreKey {
    keyId: number
    publicKey: string
}

export interface SignedPublicKey {
    keyId: number
    publicKey: string
    signature: string
}

export interface PublicPreKeyBundle {
    identityKey: string
    signedPreKey: SignedPublicKey
    preKey?: PublicPreKey
    registrationId: number
}

interface SerializedFullDirectoryEntry {
    registrationId: number
    identityKey: string
    signedPreKey: SignedPublicKey
    oneTimePreKeys: PublicPreKey[]
}
*/
import {ethers/*, BigNumber*/} from 'ethers';
import { ClientError, ServerError, SmartContractError } from '../common/errors';
import Strings from '../config/Strings';
// import appConfig from '../config/AppConfig';
import plexMailRootRegistry from '../PlexiMailRootRegistryABI.json';
import { EthereumChains, SignalInitStep, SmartContractAddresses } from '../utils/Types';
// import Web3 from 'web3';
import MailAddressUtils from '../utils/MailAddressUtils';
// import Web3 from 'web3';
// import Web3Helper from '../common/Web3Helper';
// rinkeby
//const plexMailRootRegistryAddress = '0x58669B882333B7856a67cA56Dcd37783fF2C7a4F';

// sepolia
// const plexMailRootRegistryAddress = '0xF720853507EA9Ed14A96571aa9189d3bF6cDd32F';
// goerli
// const plexMailRootRegistryAddress = '0xEFE0B5b1C6c8E8623897Cf3ED87ee967C58a018F';


export class SignalDirectory {
    _url = null;
    _apiKey = null;
    _web3Provider = null;
    _identityKeyIsStored = false;
    _account = null;
    constructor(account, url, apiKey) {
        this._account = account;
        this._url = url;
        this._apiKey = apiKey;
        // if (window.web3Helper.ethereum) {
        //     if (window.chainId === EthereumChains.Sepolia) {
        //         this._web3Provider = new ethers.BrowserProvider(window.ethereum);
        //     } else {
        //         this._web3Provider = new ethers.BrowserProvider(window.web3Helper.ethereum);
        //     }
        // }
    }
    get web3Provider() {
        return window.web3Helper.browserProvider;
    }

    get smartContractAddress() {
        return SmartContractAddresses[window.appConfig.chainId]
    }

    async createContract(address) {
        if (window.web3Helper.ethereum === null) {
            throw ClientError.invalidParameterError(Strings.error.client.metamask_not_installed);
        }

        const signer = await this.web3Provider.getSigner(this._account);
        const contract = new ethers.Contract(address, plexMailRootRegistry.abi, signer);
        return contract;
    }
    
    async getVerifiedInfo(address) {
        // validator.isAddress(address)
        if (!MailAddressUtils.isValidEthereumAddress(address)) {
            return {verified: false, verifiedBy: null};
        }
        let keyBundle = await this.getIdentityKeyBundleFromSmartContract(address);
        if (!keyBundle || !keyBundle.identityKey || keyBundle.identityKey.length === 0) {
            keyBundle = await this.getIdentityKeyBundleFromSmartContractInOtherChain(address);
            if (!keyBundle || !keyBundle.identityKey || keyBundle.identityKey.length === 0) {
                return {verified: false, verifiedBy: null};
            }
        }

        return {verified: true, verifiedBy: keyBundle.verifiedBy};
    }

    async getIdentityKeyBundle(address, keyStoreUrl=null) {
        let keyBundle = await this.getIdentityKeyBundleFromSmartContract(address);
        if (!keyBundle || !keyBundle.identityKey || keyBundle.identityKey.length === 0) {
            // keyBundle = await this.getIdentityKeyBundleFromRootRegistry(address, keyStoreUrl);
            keyBundle = await this.getIdentityKeyBundleFromSmartContractInOtherChain(address);
            if (!keyBundle || !keyBundle.identityKey || keyBundle.identityKey.length === 0) {
                keyBundle = await this.getIdentityKeyBundleFromRootRegistry(address, keyStoreUrl);
            }
        }
        return keyBundle;
    }

    async getIdentityKeyBundleFromSmartContractInOtherChain(address) {
        return null;
        // if (!window.wallet || !window.wallet.asDefaultWallet) {
        //     return null;
        // }

        // let contract = null;
        // let verifiedBy = null;
        // if (window.chainId === EthereumChains.Fuji) {
        //     const contractAddress = SmartContractAddresses[EthereumChains.Avalanche];
        //     contract = await window.wallet.createContract(EthereumChains.Avalanche, this._account, contractAddress, plexMailRootRegistry.abi);
        //     verifiedBy = 'Avalanche';
        // } else {
        //     const contractAddress = SmartContractAddresses[EthereumChains.Fuji];
        //     contract = window.wallet.createContract(EthereumChains.Avalanche, this._account, contractAddress, plexMailRootRegistry.abi);
        //     verifiedBy = 'Fuji';
        // }

        // if (!contract) {
        //     return null;
        // }

        // const keyBundle = await contract.queryKeyBundle(address);
        // if (!keyBundle) {
        //     return null;
        // }
        // const result = {
        //     identityKey: keyBundle.identityKey,
        //     keyStoreUrl: keyBundle.keyStoreUrl,
        //     devices: keyBundle.devices,
        //     identityKeyIsVerified: true,
        //     verifiedBy: verifiedBy
        // }
        // return result;
    }
    async getIdentityKeyBundleFromSmartContract1(address) {
        const contract = await this.createContract(this.smartContractAddress);
        const keyBundle = await contract.queryKeyBundle(address);
        if (!keyBundle) {
            return null;
        }
        if (!keyBundle.identityKey || keyBundle.identityKey.length === 0) {
            return null;
        }
        const verified = (keyBundle.identityKey && keyBundle.identityKey.length > 0);
        const verifiedBy = (window.chainId === EthereumChains.Fuji) ? 'Fuji' : 'Avalanche'
        const result = {
            identityKey: keyBundle.identityKey,
            keyStoreUrl: keyBundle.keyStoreUrl,
            devices: keyBundle.devices,
            identityKeyIsVerified: verified,
            verifiedBy: verified ? verifiedBy : null
        } 
        return result;
    }

    async getIdentityKeyBundleFromSmartContract(address) {
        try {
            const keyBundle = await window.plexiMailService.queryAccount(address);
            
            
            const verified = (keyBundle.identityKey && keyBundle.identityKey.length > 0);
            const verifiedBy = 'ICP'
            const result = {
                identityKey: keyBundle.identityKey,
                keyStoreUrl: keyBundle.keyStoreUrl,
                devices: keyBundle.devices,
                identityKeyIsVerified: verified,
                verifiedBy: verified ? verifiedBy : null
            }
            return result;
        } catch (e) {
            console.error(e);
            throw ServerError.notFoundError("KeyBundle not found");
        }
    }

    async getIdentityKeyBundleFromRootRegistry(address, keyStoreUrl=null) {
        try {
            const keyBundle = await window.plexiMailService.queryAccount(address);
            keyBundle.identityKeyIsVerified = false;
            return keyBundle;
        } catch (e) {
            console.error(e);
            throw ServerError.notFoundError("KeyBundle not found");
        }
    }

    async getIdentityKey(address, identityKeyBundle) {
        if (identityKeyBundle) {
            return identityKeyBundle.identityKey;
        }
        identityKeyBundle = await this.getIdentityKeyBundle(address);
        if (!identityKeyBundle) {
            return null;
        }
        return identityKeyBundle.identityKey;
    }
    
    async getLatestRegisteredDeviceId(address, identityKeyBundle=null) {
        const devices = await this.getExistedDevices(address, identityKeyBundle);
        if (devices && devices.length > 0) {
            return devices[0].id;
        }
        return 0;
    }

    async getExistedDevices(address, identityKeyBundle=null) {
        if (!identityKeyBundle) {
            identityKeyBundle = await this.getIdentityKeyBundle(address)
        }
        if (!identityKeyBundle.devices || identityKeyBundle.devices.length === 0) {
            return null;
        }
        const devices = identityKeyBundle.devices; //JSON.parse(identityKeyBundle.devices);
        if (!devices || devices.length === 0) {
            return null;
        }
        // [{id, registrationId, signedPreKeyId, signedPrePublicKey, signature, keyStoreUrl}]
        devices.sort((a, b) => {return b.id - a.id})
        return devices;
    }

    async getParsedIdentityKeyBundle(address, identityKeyBundle=null) {
        if (!identityKeyBundle) {
            identityKeyBundle = await this.getIdentityKeyBundleFromSmartContract(address)
        }
        if (!identityKeyBundle || !identityKeyBundle.devices || identityKeyBundle.devices.length === 0) {
            return null;
        }
        const devices = identityKeyBundle.devices;// JSON.parse(identityKeyBundle.devices);
        if (!devices || devices.length === 0) {
            return null;
        }
        // [{id, registrationId, signedPreKeyId, signedPrePublicKey, signature, keyStoreUrl}]
        devices.sort((a, b) => {return b.id - a.id});

        const parsedIdentityKeyBundle = {
            ...identityKeyBundle
        };
        parsedIdentityKeyBundle.devices = devices;
        return parsedIdentityKeyBundle;
    }

    async storeIdentityKeyIntoSmartContract(smartContractAddress, identityKey, keyStoreUrl, devices, retry=5) {
        if (retry === 0) {
            throw ClientError.invalidParameterError('Failed to login to your wallet');;
        }
        try {
            const contract = await this.createContract(smartContractAddress);
            const response = await contract.storeKeyBundle(identityKey, keyStoreUrl, JSON.stringify(devices));
            console.log('new device: ', response);
            return response;
        } catch (e) {
            if (e && e.code === -32603) {
                // let accounts = null;
                // if (window.chainId === EthereumChains.Sepolia) {
                //     accounts = await window.magic.wallet.connectToMetaMask();
                // } else {
                //     accounts = await window.magic.wallet.connectWithUI();
                // }
                const accounts = await window.web3Helper.web3.eth.requestAccounts();
                if (!accounts || accounts.length === 0) {
                    throw ClientError.invalidParameterError('Account not found');
                }
                return await this.storeIdentityKeyIntoSmartContract(smartContractAddress, identityKey, keyStoreUrl, devices, retry--);
            } else {
                throw e;
            }
        }
    }

    bufferToHex(buffer) {
        return [...new Uint8Array (buffer)].map (b => b.toString (16).padStart (2, "0")).join ("");
    }

    // async storeKeyBundle(address: string, bundle: FullDirectoryEntry): Promise<void>
    async storeKeyBundle(address, isNewDevice, keyStoreUrl, bundle, progress) {
        
        let tx = null;
        if (progress) {
            progress(SignalInitStep.SavePreKeyBundle);
        }
        
        const encodedBundle = serializeKeyRegistrationBundle(bundle);


        try {
            const success = await window.plexiMailService.registerDevice(encodedBundle);
            if (!success) {
                throw ServerError.from('Failed to register');
            }
        } catch (e) {
            console.error(e);
            throw ServerError.notFoundError("User not found");
        }


        if (bundle.identityKey) {
            if (window.appConfig.mailAddressNeedToBeVerified && isNewDevice && !this._identityKeyIsStored) {
                if (progress) {
                    progress(SignalInitStep.SaveIdentityKey);
                }
                // TODO: multi-devices
                const identityKeyBundle = await this.getParsedIdentityKeyBundle(address);
                //devices.push({id: bundle.deviceId});
                const devices = identityKeyBundle ? (identityKeyBundle.devices || []) : [];
                if (devices.length === 0) {
                    devices.push({id: bundle.deviceId});
                }

                const identityKey = base64.fromByteArray(new Uint8Array(bundle.identityKey));
                if (!identityKeyBundle || (identityKeyBundle.identityKey !== identityKey)) { // TODO: check deviceId
                    
                    // const signer = this.web3Provider.getSigner();
                    // const contract = new ethers.Contract(this.smartContractAddress, plexMailRootRegistry.abi, signer);
                    
                    // WITHOUT RETRY
                    // const contract = await this.createContract(this.smartContractAddress);
                    // const response = await contract.storeKeyBundle(identityKey, keyStoreUrl, JSON.stringify(devices));
                    // console.log('new device: ', response);

                    // WITH RETRY
                    const response = await this.storeIdentityKeyIntoSmartContract(this.smartContractAddress, identityKey, keyStoreUrl, devices);
                    console.log('new device: ', response);
                    tx = response.hash;

                    // if (progress) {
                    //     progress(SignalInitStep.WaitTransactionReceipt);
                    // }
                    // await this.waitTransactionReceiptMined(response.hash);
                    // if (progress) {
                    //     progress(SignalInitStep.Completed);
                    // }

                }

                this._identityKeyIsStored = true;
                //return;
            }
            if (!window.appConfig.mailAddressNeedToBeVerified) {
                this._identityKeyIsStored = true;
                tx = 'no-smart-contract-protection';
            }
        }
        return {code: 0, msg: 'success', data: tx};
    }

    async waitTransactionReceiptMined(txHash, interval=5000) {

        const self = this;
        const web3 = window.web3Helper.web3;

        const transactionReceiptAsync = function(resolve, reject) {
            web3.eth.getTransactionReceipt(txHash).then(receipt => {
                if (receipt == null) {
                    setTimeout(() => {
                        transactionReceiptAsync(resolve, reject)
                    }, interval ? interval : 500);

                } else {
                    resolve(receipt);
                }
            }).catch((e) => {
                reject(e)
            });
        };
    
        if (Array.isArray(txHash)) {
            return Promise.all(txHash.map(
                oneTxHash => self.getTransactionReceiptMined(oneTxHash, interval)));
        } else if (typeof txHash === "string") {
            return new Promise(transactionReceiptAsync);
        } else {
            throw new Error("Invalid Type: " + txHash);
        }
    }
    
    async checkPreKeyCount(deviceId, keyStoreUrl) {
        try {
            const result = await window.plexiMailService.queryPrekeyCount(deviceId);
            return result.count;
        } catch (e) {
            console.error(e);
            throw ServerError.notFoundError("User not found");
        }
    }

    async verifyIdentityKey(address, identityKey, verifyBySmartContract, identityKeyBundle = null) {
        if (!identityKeyBundle) {
            // identityKeyBundle = await this.getIdentityKeyBundle(address);
            if (verifyBySmartContract) {
                identityKeyBundle = await this.getIdentityKeyBundleFromSmartContract(address);
            } else {
                identityKeyBundle = await this.getIdentityKeyBundleFromRootRegistry(address);
            }
        }
        if (!identityKeyBundle) {
            return false;
        }
        if (!identityKeyBundle || !identityKeyBundle.identityKey || identityKeyBundle.identityKey === '') {
            throw SmartContractError.emptyResponseError('Account not found in blockchain');
        }
        return (identityKeyBundle.identityKey === identityKey);
    }
    async checkIdentityKey(address, identityKeyBundle = null) {
        if (!identityKeyBundle) {
            identityKeyBundle = await this.getIdentityKeyBundle(address);
        }
        if (!identityKeyBundle || !identityKeyBundle.identityKey || identityKeyBundle.identityKey === '') {
            throw SmartContractError.emptyResponseError('Account not found in blockchain');
        }
        // {identityKey: account.identityKey, signature: account.signature, ...device});
        
        // public type MonitorAccountResult = {
        //     identityKey: Text;
        //     signature: Text;
        //     keyStoreUrl: Text;
            
        //     deviceId: Nat;
        //     registrationId: Nat;
        //     signedPreKey: SignedPreKeyEntry;
        // };

        // export interface SignedPreKeyEntry {
        //     'signature' : string,
        //     'publicKey' : string,
        //     'keyId' : bigint,
        //   }

        try {
            // const devices = window.plexiMailService.queryAccount(address);
            const devices = await window.plexiMailService.monitorAccount(address);
            if (devices.length === 0) {
                throw ServerError.notFoundError('Identity Key not found');
            }
            for(let i=0; i<devices.length; i++) {
                const device = devices[i]; 
                if (device.identityKey !== identityKeyBundle.identityKey) {
                    throw ServerError.securityError(Strings.error.client.idkey_not_identical);
                }
            }
        } catch (e) {
            console.error(e);
            throw ServerError.notFoundError("User not found");
        }
    }

    async getPreKeyBundle(address, deviceId, identityKeyBundle = null) {
        if (!identityKeyBundle) {
            identityKeyBundle = await this.getIdentityKeyBundle(address);
        }
        if (!identityKeyBundle.identityKey || identityKeyBundle.identityKey === '') {
            throw ServerError.emptyResponseError(Strings.error.client.account_not_in_blockchain);
        }

        // public type RetrievePrekeyBundleResult = {
        //     deviceId: Nat;
        //     registrationId: Nat;
        //     identityKey: Text;
        //     signature: Text;
        //     signedPreKey: SignedPreKeyEntry;
        //     preKey: OneTimePreKeyEntry;
        // };

        // export interface SignedPreKeyEntry {
        //     'signature' : string,
        //     'publicKey' : string,
        //     'keyId' : bigint,
        //   }

        // public type OneTimePreKeyEntry = {
        //     keyId: Nat; 
        //     publicKey: Text;
        // };

        // retrievePrekey(address: Text, deviceId: Nat)
        
        try {
            const result = await window.plexiMailService.retrievePreKey(address, deviceId);
            if (identityKeyBundle.identityKey !== result.identityKey) {
                throw ServerError.securityError(Strings.error.client.idkey_not_identical);
            }
            return {code: 0, msg: "success", data: deserializeKeyBundle(result)}
        } catch (e) {
            console.error(e);
            throw ServerError.notFoundError("User not found");
        }
        
    }

    get url() {
        return this._url
    }

    get apiKey() {
        return this._apiKey
    }
}

// function serializeKeyRegistrationBundle(dv: FullDirectoryEntry): SerializedFullDirectoryEntry
export function serializeKeyRegistrationBundle(dv) {
    // export interface RegisterForm {
    //     'signature' : [] | [string],
    //     'signedPreKey' : [] | [SignedPreKeyEntry],
    //     'address' : SignalName,
    //     'deviceId' : bigint,
    //     'identityKey' : [] | [string],
    //     'oneTimePreKeys' : Array<OneTimePreKeyEntry>,
    //     'registrationId' : bigint,
    //   }

    // export interface SignedPreKeyEntry {
    //     'signature' : string,
    //     'publicKey' : string,
    //     'keyId' : bigint,
    //   }

    // OneTimePreKeyEntry { 'publicKey' : string, 'keyId' : bigint }

    const identityKey = dv.identityKey ? [base64.fromByteArray(new Uint8Array(dv.identityKey))] : [];
    const signedPreKey = dv.signedPreKey ? [{
        keyId: dv.signedPreKey.keyId,
        publicKey: base64.fromByteArray(new Uint8Array(dv.signedPreKey.publicKey)),
        signature: base64.fromByteArray(new Uint8Array(dv.signedPreKey.signature)),
    }] : [];

    const oneTimePreKeys = dv.oneTimePreKeys.map((pk) => ({
        keyId: pk.keyId,
        publicKey: base64.fromByteArray(new Uint8Array(pk.publicKey)),
    }))
    
    return {
        address: dv.address,
        deviceId: dv.deviceId,
        registrationId: dv.registrationId,
        signature: (!dv.signature || dv.signature.length === 0) ? [] : [dv.signature],
        identityKey,
        signedPreKey,
        oneTimePreKeys,
    }
}

// function serializeKeyBundle(dv: DeviceType): PublicPreKeyBundle
export function serializeKeyBundle(dv) {


    // public type RetrievePrekeyBundleResult = {
    //     deviceId: Nat;
    //     registrationId: Nat;
    //     identityKey: Text;
    //     signature: Text;
    //     signedPreKey: SignedPreKeyEntry;
    //     preKey: OneTimePreKeyEntry;
    // };

    // export interface SignedPreKeyEntry {
    //     'signature' : string,
    //     'publicKey' : string,
    //     'keyId' : bigint,
    //   }

    // OneTimePreKeyEntry { 'publicKey' : string, 'keyId' : bigint }


    const identityKey = base64.fromByteArray(new Uint8Array(dv.identityKey))
    const signedPreKey = {
        keyId: dv.signedPreKey.keyId,
        publicKey: base64.fromByteArray(new Uint8Array(dv.signedPreKey.publicKey)),
        signature: base64.fromByteArray(new Uint8Array(dv.signedPreKey.signature)),
    }

    const preKey = {
        keyId: dv.preKey.keyId,
        publicKey: base64.fromByteArray(new Uint8Array(dv.preKey.publicKey)),
    }

    return {
        deviceId: dv.deviceId,
        registrationId: dv.registrationId,
        identityKey,
        signedPreKey,
        preKey,
    }
}

// function deserializeKeyBundle(kb: PublicPreKeyBundle): DeviceType
export function deserializeKeyBundle(kb) {
    /**
     * {
     *       deviceId: device.deviceId,
     *       registrationId: device.registrationId,
     *       identityKey: account.identityKey,
     *       signedPreKey: device.signedPreKey,
     *       preKey: preKey
     * };
     */

    // public type RetrievePrekeyBundleResult = {
    //     deviceId: Nat;
    //     registrationId: Nat;
    //     identityKey: Text;
    //     signature: Text;
    //     signedPreKey: SignedPreKeyEntry;
    //     preKey: OneTimePreKeyEntry;
    // };

    // export interface SignedPreKeyEntry {
    //     'signature' : string,
    //     'publicKey' : string,
    //     'keyId' : bigint,
    //   }

    // OneTimePreKeyEntry { 'publicKey' : string, 'keyId' : bigint }

    const identityKey = base64.toByteArray(kb.identityKey).buffer
    const signedPreKey = {
        keyId: kb.signedPreKey.keyId,
        publicKey: base64.toByteArray(kb.signedPreKey.publicKey),
        signature: base64.toByteArray(kb.signedPreKey.signature),
    }
    const preKey = kb.preKey && {
        keyId: kb.preKey.keyId,
        publicKey: base64.toByteArray(kb.preKey.publicKey),
    }

    return {
        deviceId: kb.deviceId,
        registrationId: kb.registrationId,
        signature: kb.signature,
        identityKey,
        signedPreKey,
        preKey,
    }
}