import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { mailAddressSuffix } from "../common/constants";

export function NormalEmailAddressTipsV1({ disclosure, children }) {
  return (
    <Popover
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      placement={"right"}
    >
      <PopoverTrigger>{children}</PopoverTrigger>

      <PopoverContent>
        <PopoverHeader>Tips</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          Sending emails outside of the {mailAddressSuffix()} domain potentially
          may expose your PlexiMail address and/or the metadata, even if they
          are through a VPN or the Tor network before reaching the forwarding
          server. You are also consuming a limited shared resource. Hence, there
          must not be more than one recipient, the size of your content will be
          restricted, no attachments are accepted and there is no return address
          for the recipient to reply to. It may fail if the traffic gets too
          heavy. The delivery is not guaranteed as to all emails.
        </PopoverBody>
        <PopoverFooter>
          <HStack justifyContent={"right"}>
            <Button
              colorScheme="green"
              isLoading={false}
              disabled={false}
              onClick={() => {}}
            >
              I Got It.
            </Button>
          </HStack>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
}

export function NormalEmailAddressTips({ disclosure, children }) {
  return (
    <AlertDialog isOpen={disclosure.isOpen} onClose={disclosure.onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Tips
          </AlertDialogHeader>

          <AlertDialogBody>
            Sending emails outside of the {mailAddressSuffix()} domain
            potentially may expose your PlexiMail address and/or the metadata,
            even if they are through a VPN or the Tor network before reaching
            the forwarding server. You are also consuming a limited shared
            resource. Hence, there must not be more than one recipient, the size
            of your content will be restricted, no attachments are accepted and
            there is no return address for the recipient to reply to. It may
            fail if the traffic gets too heavy. The delivery is not guaranteed
            as to all emails.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              colorScheme="gray"
              mr={3}
              onClick={() => {
                disclosure.onClose();
              }}
            >
              Skip
            </Button>
            <Button
              colorScheme="green"
              isLoading={false}
              disabled={false}
              onClick={() => {
                window.appConfig.showComposeTips = false;
                disclosure.onClose();
              }}
            >
              Got It.
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
