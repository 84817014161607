import { Link, MenuItem, useDisclosure } from "@chakra-ui/react";
import TutorialModal from "./TutorialModal";

export function TutorialModalLinkV1({ title }) {
  const tutorialDisclosure = useDisclosure();
  return (
    <>
      <Link
        style={{ color: "#00aaff" }}
        onClick={() => {
          tutorialDisclosure.onOpen();
        }}
        target={"_blank"}
      >
        {title}
      </Link>
      <TutorialModal disclosure={tutorialDisclosure} />
    </>
  );
}

export function TutorialModalLink({ title, anchor }) {
  return (
    <>
      {anchor && anchor.length > 0 && (
        <Link
          style={{ color: "#00aaff" }}
          href={`https://ai-fi.cc/help.html#${anchor}`}
          target={"_blank"}
        >
          {title}
        </Link>
      )}
      {(!anchor || anchor.length === 0) && (
        <Link
          style={{ color: "#00aaff" }}
          href="https://ai-fi.cc/help.html"
          target={"_blank"}
        >
          {title}
        </Link>
      )}
    </>
  );
}

export function TutorialModalMenuItem({ icon, title }) {
  const tutorialDisclosure = useDisclosure();
  return (
    <>
      <MenuItem
        icon={icon}
        onClick={() => {
          tutorialDisclosure.onOpen();
        }}
      >
        {title}
      </MenuItem>
      <TutorialModal disclosure={tutorialDisclosure} />
    </>
  );
}
