// import './App.css';
import React, { useState, useEffect } from "react";

import { IconButton, Flex, Text } from "@chakra-ui/react";
import { createRef } from "react";

import { showError, showSuccess } from "../../common/errors";
import EnterpriseStrings from "./EnterpriseStrings";
import { FiSave /*, FiRotateCcw*/ } from "react-icons/fi";
// import { FiTrash2, FiCopy, FiPlus } from "react-icons/fi";
// import AccountManager from "./AccountManager";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "myckeditor";

const NotifyContent = ({
  selectedAccount,
  selectedIndex,
  setSelectedIndex,
  disclosure,
}) => {
  var ckeditorRef = createRef();
  const [isCreating, setCreating] = useState(false);
  const [notifyTemplate, setNotifyTemplate] = useState(null);

  useEffect(() => {
    console.log("EnterpriseProfileContent:", selectedIndex);
    if (!disclosure.isOpen) {
      return;
    }
    if (selectedIndex !== 3) {
      return;
    }

    window.mailService
      .getNotifyTemplate()
      .then((template) => {
        setNotifyTemplate(template);
      })
      .catch((e) => {
        console.error(e);
        showError(e);
      });
  }, [disclosure.isOpen, selectedIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const saveTemplate = async () => {
    try {
      setCreating(true);

      // const body = ckeditorRef.current.editor.getData();
      await window.mailService.saveNotifyTemplate(notifyTemplate);
      // setNotifyTemplate(body);
      await window.mailService.syncFoldersFile();

      // disclosure.onClose();
      showSuccess("Saved");
    } catch (e) {
      console.error(e);
      showError(e);
    } finally {
      setCreating(false);
    }
  };

  return (
    <>
      <Flex justifyContent={"flex-end"}>
        {/* <IconButton
                    disabled={isCreating}
                    icon={<FiRotateCcw />}
                    colorScheme={"red"}
                    variant="outline"
                    aria-label="Discard"
                    title="Discard"
                    mr={3} 
                    onClick={() => {

                        window.mailService.getNotifyTemplate().then(template => {
                            setNotifyTemplate(template);
                        }).catch(e => {
                            console.error(e);
                            showError(e);
                        });
                    }}
                    spacing={{ base: "6", md: "6" }}
                    /> */}
        <IconButton
          disabled={!notifyTemplate || notifyTemplate.length === 0}
          isLoading={isCreating}
          aria-label="Save"
          title="Save"
          icon={<FiSave />}
          colorScheme={"green"}
          variant="outline"
          onClick={() => {
            saveTemplate()
              .then(() => {})
              .catch((e) => {
                showError(e);
              });
          }}
          spacing={{ base: "6", md: "6" }}
        />
      </Flex>

      <Flex>
        <Text fontWeight={"bold"}>I. Fixed</Text>
      </Flex>
      <Flex>
        <Text
          width={"100%"}
          dangerouslySetInnerHTML={{
            __html: `${
              EnterpriseStrings.common.notify_tpl.p1
            } <strong>&lt;PIN CODE&gt;</strong> ${
              EnterpriseStrings.common.notify_tpl.p2
            }${"#"} ${EnterpriseStrings.common.notify_tpl.p3} ${"#"}${
              EnterpriseStrings.common.notify_tpl.p5
            }${"https:///ai-fi.cc/alpha-pro/xxx"} ${
              EnterpriseStrings.common.notify_tpl.p7
            }`,
          }}
        ></Text>
      </Flex>

      <Flex mt={3}>
        <Text fontWeight={"bold"}>II. Additional</Text>
      </Flex>
      <CKEditor
        ref={ckeditorRef}
        editor={ClassicEditor}
        // config={ { plugins: [ ClassicEditor.Base64UploadAdapter] } }
        data={notifyTemplate || ""}
        onReady={(editor) => {
          // ckeditor = editor;
          editor.editing.view.change((writer) => {
            writer.setStyle(
              "min-height",
              "200px",
              editor.editing.view.document.getRoot()
            );
            writer.setStyle(
              "max-height",
              "350px",
              editor.editing.view.document.getRoot()
            );
            writer.setStyle(
              "font-size",
              "14px",
              editor.editing.view.document.getRoot()
            );
            // writer.setStyle(
            //   "width",
            //   "600px",
            //   editor.editing.view.document.getRoot()
            // );
          });
          // You can store the "editor" and use when it is needed.
          console.log("Editor is ready to use!", editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          // console.log( { event, editor, data } );
          setNotifyTemplate(data);
        }}
        onBlur={(event, editor) => {
          // console.log( 'Blur.', editor );
        }}
        onFocus={(event, editor) => {
          // console.log( 'Focus.', editor );
        }}
      />
    </>
  );
};

export default NotifyContent;
