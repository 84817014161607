import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  // Button,
  SimpleGrid,
  // Link,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { SetupWizardState } from "../utils/Types";
import { showError /*, showInfo*/ } from "../common/errors";
import Strings from "../config/Strings";
// import { HELP_DOC_URL } from '../common/constants';
import { TutorialModalLink } from "./TutorialModalTrigger";
import { SyncConfirmationButton } from "./SyncModal";

export default function ExportConfigScreen({
  selectedAccount,
  setSetupWizardState,
}) {
  console.log(">> InitialSignalContextScreen");

  const [isRunning, setRunning] = useState(false);
  useEffect(() => {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    if (params.get("action") === "recover") {
      doExport(params.get("config"));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const doExport = async (config) => {
    if (!config || config === "") {
      return;
    }
    const buffer = Buffer.from(config, "hex");
    config = buffer.toString("base64");
    // trigger state machine
    try {
      setRunning(true);
      if (!window.deferredPrompt) {
        window.appConfig.showInstallAlert = false;
      }

      const backup = await window.mailService.exportConfig(selectedAccount);
      // const backup = await window.mailService.exportMnemonic(selectedAccount)
      await saveConfig(backup);
      setRunning(false);
      setSetupWizardState(SetupWizardState.WaitForTransactionReceipt);
    } catch (e) {
      console.error(e);
      setRunning(false);
      showError(e);
    }
  };

  const saveConfig = async (configJsonString) => {
    let blob = new Blob([configJsonString]);
    let url = URL.createObjectURL(blob);
    let a = window.document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", filename());
    // `PlexiMailPlusPlusConfig_${selectedAccount}.cfg`
    window.document.body.append(a);
    a.click();
    window.window.URL.revokeObjectURL(url);
    a.remove();
  };

  const filename = () => {
    const now = new Date();
    let timeString = now.getYear() + 1900 + "-";
    if (now.getMonth() >= 9) {
      timeString += 1 + now.getMonth();
    } else {
      timeString += "0" + (1 + now.getMonth());
    }
    timeString +=
      "-" + (now.getDate() > 9 ? now.getDate() : "0" + now.getDate());
    timeString +=
      "_" + (now.getHours() > 9 ? now.getHours() : "0" + now.getHours());
    timeString +=
      "_" + (now.getMinutes() > 9 ? now.getMinutes() : "0" + now.getMinutes());
    timeString +=
      "_" + (now.getSeconds() > 9 ? now.getSeconds() : "0" + now.getSeconds());
    return `PMCFG_${selectedAccount}_${timeString}.pmcfg`;
  };

  /*
    async function getNewFileHandle() {
        const opts = {
          suggestedName: filename(),
          types: [{
            description: 'PlexiMail Config File',
            accept: {'text/plain': ['.pmcfg']},
          }],
        };
        return await window.showSaveFilePicker(opts);
    }
    const saveConfig1 = async (configJsonString) => {
        const fileHandler = await getNewFileHandle();
        const writable = await fileHandler.createWritable();
        await writable.write(configJsonString)
        await writable.close();
    }

    async function exportClicked() {
        const ua = window.navigator.userAgent.toLowerCase();
        const isBrave = (window.navigator.brave && await window.navigator.brave.isBrave()) || false;

        if (!isBrave && (ua.indexOf('android') !== -1 
            || ua.indexOf('iphone') !== -1 
            || ua.indexOf('ipad') !== -1 
            || ua.indexOf('ipod') !== -1)) {

            setRunning(false);
            setSetupWizardState(SetupWizardState.WaitForTransactionReceipt);
            return;
        }
        // addBinding(foldersIpnsRecord, prekeysIpnsRecord, signedPrekeySignature)
        
        // trigger state machine
        try {
            setRunning(true);
            if (!window.deferredPrompt) {
                window.appConfig.showInstallAlert = false;
            }
            
            const config = await window.mailService.exportConfig(selectedAccount);
            // const config = await window.mailService.exportMnemonic(selectedAccount);
            await saveConfig(config);
            setRunning(false);
            setSetupWizardState(SetupWizardState.WaitForTransactionReceipt);
        } catch (e) {
            console.error(e);
            setRunning(false);
            showError(e);
        }
    }
*/

  async function downloadConfig() {
    setRunning(true);
    if (!window.deferredPrompt) {
      window.appConfig.showInstallAlert = false;
    }

    const config = await window.mailService.exportConfig(selectedAccount);
    // const config = await window.mailService.exportMnemonic(selectedAccount);
    await saveConfig(config);
  }

  return (
    <Box position={"relative"}>
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack
          bg={"gray.50"}
          rounded={"xl"}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: "lg" }}
        >
          <Stack spacing={4}>
            <Heading
              color={"gray.800"}
              lineHeight={1.1}
              fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
            >
              <Text
                as={"span"}
                bgGradient="linear(to-r, red.400,pink.400)"
                bgClip="text"
              >
                {Strings.export.default.step} &nbsp;
              </Text>{" "}
              {Strings.export.default.title}
            </Heading>
            <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
              {Strings.export.default.content.paragraph1}&nbsp;
              <TutorialModalLink title={"Learn more"} />
              {/* <Link style={{color: '#00aaff'}} href={HELP_DOC_URL} target={"_blank"}>Learn more</Link> */}
            </Text>
            {isRunning && (
              <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
                Exporting...
              </Text>
            )}
          </Stack>
          <Box as={"form"} mt={10}>
            {/* <Button
                            fontFamily={'heading'}
                            mt={8}
                            w={'full'}
                            bgGradient="linear(to-r, red.400,pink.400)"
                            color={'white'}
                            isLoading={isRunning}
                            _hover={{
                                bgGradient: 'linear(to-r, red.400,pink.400)',
                                boxShadow: 'xl',
                            }}
                            onClick={exportClicked}>
                            {Strings.export.default.button.export.title}
                        </Button> */}
            <SyncConfirmationButton
              mt={8}
              title={Strings.export.default.button.export.title}
              selectedAccount={selectedAccount}
              onDownload={() => {
                downloadConfig()
                  .then(() => {
                    setRunning(false);
                    setSetupWizardState(
                      SetupWizardState.WaitForTransactionReceipt
                    );
                  })
                  .catch((e) => {
                    console.error(e);
                    setRunning(false);
                    showError(e);
                  });
              }}
              onCompleted={(cancelled) => {
                if (cancelled) {
                  setRunning(false);
                  // showInfo('Cancelled');
                } else {
                  setRunning(false);
                  setSetupWizardState(
                    SetupWizardState.WaitForTransactionReceipt
                  );
                }
              }}
            />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}
