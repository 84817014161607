import { Box, HStack, Text, useRadio, useRadioGroup } from "@chakra-ui/react"


function SegmentItem(props) {
    const { getInputProps, getRadioProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getRadioProps()

  return (
    <Box as='label'>
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        
        bg="none"
        border="none"
        color="gray.600"
        _checked={{
          color: 'rgb(47, 133, 90)',
          fontWeight: 'bold',
          // borderBottomR: '-2px',
          borderBottom: 'rgb(47, 133, 90) 2px solid',
          // borderColor: 'orange',
        }}
        _focus={{
        //   boxShadow: 'outline',
        }}
        px={2}
        // mx={2}
        pt={2}
        pb={4}
        fontSize={"sm"}
      >
        {props.children}
      </Box>
    </Box>
  )
}

// Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
export default function SegmentControl({label, defaultValue, options, onChange}) {

    // const options = [{name: 'react', value: '1'}, {name: 'vue', value: '2'}, {name: 'svelte', value: '3'}]
    const { getRootProps, getRadioProps } = useRadioGroup({
      name: 'contract-types',
      defaultValue: defaultValue,
      onChange
    })
  
    const group = getRootProps()
  
    return (
      <HStack {...group} spacing={1} borderBottomColor={"gray.200"} borderBottomWidth={0}>
        {label &&
        <Text 
        fontWeight={"bold"}
        color={"gray.600"}
        fontSize={"sm"}>{label} </Text>
      }
        {options.map((option, idx) => {
          const radio = getRadioProps({ value: option.value})
          return (
            <SegmentItem key={option.value} {...radio}>
              {option.name}
            </SegmentItem>
          )
        })}
      </HStack>
    )
  }