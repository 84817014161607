import {
    // StorageType,
    // Direction,
    // SessionRecordType,
    SignalProtocolAddress,
    // PreKeyPairType,
    // SignedPreKeyPairType,
    // KeyHelper,
} from '@privacyresearch/libsignal-protocol-typescript';
import { ethers } from 'ethers';
// import * as base64JS from 'base64-js';
import { ClientError } from '../common/errors';
// import * as DBA from 'indexeddb-export-import';
import * as DBUtils from '../utils/DBUtils';
import { IdentityKeyStatus } from '../utils/Types';
import Strings from '../config/Strings';

/*
import {
    KeyHelper,
    SignedPublicPreKeyType,
    SignalProtocolAddress,
    SessionBuilder,
    PreKeyType,
    SessionCipher,
    MessageType }
from '@privacyresearch/libsignal-protocol-typescript';
*/



// Type guards
function isKeyPairType(kp) {
    return (kp.privKey && kp.pubKey)
}

function isPreKeyType(pk) {
    return typeof pk.keyId === 'number' && isKeyPairType(pk.keyPair)
}

function isSignedPreKeyType(spk) {// eslint-disable-line
    return spk.signature && isPreKeyType(spk)
}

/*
interface KeyPairType {
    pubKey: ArrayBuffer
    privKey: ArrayBuffer
}

interface PreKeyType {
    keyId: number
    keyPair: KeyPairType
}
interface SignedPreKeyType extends PreKeyType {
    signature: ArrayBuffer
}
*/

function isArrayBuffer(thing) {
    const t = typeof thing
    return !!thing && t !== 'string' && t !== 'number' && 'byteLength' in (thing)
}


export function arrayBufferToString(b) {
    return uint8ArrayToString(new Uint8Array(b))
}

export function uint8ArrayToString(arr) {
    const end = arr.length
    let begin = 0
    if (begin === end) return ''
    let chars = []
    const parts = []
    while (begin < end) {
        chars.push(arr[begin++])
        if (chars.length >= 1024) {
            parts.push(String.fromCharCode(...chars))
            chars = []
        }
    }
    return parts.join('') + String.fromCharCode(...chars)
}

class SecuritySignalProtocolStoreIndexedDB {
    static #StorePrefix = 'signal.';
    static #StoreNames = {
        Misc: SecuritySignalProtocolStoreIndexedDB.#StorePrefix + 'misc',
        Identities: SecuritySignalProtocolStoreIndexedDB.#StorePrefix + 'identities',
        PreKeys: SecuritySignalProtocolStoreIndexedDB.#StorePrefix + 'preKeys',
        Sessions: SecuritySignalProtocolStoreIndexedDB.#StorePrefix + 'sessions',
        SignedPreKeys: SecuritySignalProtocolStoreIndexedDB.#StorePrefix + 'signedPreKeys',
        EncryptionKeys: SecuritySignalProtocolStoreIndexedDB.#StorePrefix + 'encryptionKeys',
        Contacts: SecuritySignalProtocolStoreIndexedDB.#StorePrefix + 'contacts',
        Users: SecuritySignalProtocolStoreIndexedDB.#StorePrefix + 'users',
        Delegations: SecuritySignalProtocolStoreIndexedDB.#StorePrefix + 'delegations'
    };
    static #DBName = 'smailDB';
    static #DBVersion = 7;
    static #indexedDB = null;
    static #instanceMap = {};

    _db = null;
    _account = '';
    _subAccount = '';
    _encryptionKey = null;
    _isTemp = true;
    constructor(account = '', subAccount, encryptionKey = null, temp=true) {
        console.log('SecuritySignalProtocolStoreIndexedDB - constructor');
        this._account = account;
        this._subAccount = subAccount;
        this._encryptionKey = encryptionKey;
        this._isTemp = temp;
        if (!temp) {
            SecuritySignalProtocolStoreIndexedDB.#instanceMap[subAccount] = this;
        }
    }

    static instanceFor(account = '', subAccount, encryptionKey = null) {
        if (SecuritySignalProtocolStoreIndexedDB.#instanceMap[subAccount]) {
            return SecuritySignalProtocolStoreIndexedDB.#instanceMap[subAccount];
        }
        const instance = new SecuritySignalProtocolStoreIndexedDB(account, subAccount, encryptionKey, false);
        if (SecuritySignalProtocolStoreIndexedDB.#indexedDB) {
            instance._db = SecuritySignalProtocolStoreIndexedDB.#indexedDB;
        }
        return instance;
    }

    async open() {
        console.log('SecuritySignalProtocolStoreIndexedDB - open');
        
        const self = this;
        if (self._db) {
            return;
        }
        
        if (SecuritySignalProtocolStoreIndexedDB.#indexedDB) {
            this._db = SecuritySignalProtocolStoreIndexedDB.#indexedDB;
            return;
        }

        const promise = new Promise((resolve, reject) => {
            if (self._db) {
                resolve();
                return;
            }

            const openRequest = indexedDB.open(SecuritySignalProtocolStoreIndexedDB.#DBName, SecuritySignalProtocolStoreIndexedDB.#DBVersion);
            openRequest.onupgradeneeded = function() {
                const db = openRequest.result;
                try {
                    if (!db.objectStoreNames.contains(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc)) { // 如果没有 “books” 数据
                        // const identitiesStore = db.createObjectStore('identities', {keyPath: 'id', autoIncrement: true}); // 创造它
                        const objectStore = db.createObjectStore(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, {keyPath: 'id'}); // 创造它
                        if (objectStore == null) {
                            throw ClientError.databaseError('failed to create object store');
                        }
                        /* else {
                            const index = identitiesStore.createIndex('identifier_index', 'identifier');
                            if (index == null) {
                                console.log('failed to create index');
                            }
                        }*/
                    }
                    if (!db.objectStoreNames.contains(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Identities)) { // 如果没有 “books” 数据
                            // const identitiesStore = db.createObjectStore('identities', {keyPath: 'id', autoIncrement: true}); // 创造它
                            const objectStore = db.createObjectStore(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Identities, {keyPath: 'id'}); // 创造它
                            if (objectStore == null) {
                                throw ClientError.databaseError('failed to create object store');
                            }
                            /* else {
                                const index = identitiesStore.createIndex('identifier_index', 'identifier');
                                if (index == null) {
                                    console.log('failed to create index');
                                }
                            }*/
                    }
                    if (!db.objectStoreNames.contains(SecuritySignalProtocolStoreIndexedDB.#StoreNames.PreKeys)) { 
                        const objectStore = db.createObjectStore(SecuritySignalProtocolStoreIndexedDB.#StoreNames.PreKeys, {keyPath: 'id'});
                        if (objectStore == null) {
                            throw ClientError.databaseError('failed to create object store');
                        }
                    }
                    if (!db.objectStoreNames.contains(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Sessions)) { 
                        const objectStore = db.createObjectStore(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Sessions, {keyPath: 'id'});
                        if (objectStore == null) {
                            throw ClientError.databaseError('failed to create object store');
                        }
                    }
                    if (!db.objectStoreNames.contains(SecuritySignalProtocolStoreIndexedDB.#StoreNames.SignedPreKeys)) { 
                        const objectStore = db.createObjectStore(SecuritySignalProtocolStoreIndexedDB.#StoreNames.SignedPreKeys, {keyPath: 'id'});
                        if (objectStore == null) {
                            throw ClientError.databaseError('failed to create object store');
                        }
                    }
                    if (!db.objectStoreNames.contains(SecuritySignalProtocolStoreIndexedDB.#StoreNames.EncryptionKeys)) { 
                        const objectStore = db.createObjectStore(SecuritySignalProtocolStoreIndexedDB.#StoreNames.EncryptionKeys, {keyPath: 'id'});
                        if (objectStore == null) {
                            throw ClientError.databaseError('failed to create object store');
                        }
                    }
                    if (!db.objectStoreNames.contains(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Contacts)) { 
                        const objectStore = db.createObjectStore(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Contacts, {keyPath: 'id'});
                        if (objectStore == null) {
                            throw ClientError.databaseError('failed to create object store');
                        }
                    }
                    if (!db.objectStoreNames.contains(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Users)) { 
                        const objectStore = db.createObjectStore(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Users, {keyPath: 'id'});
                        if (objectStore == null) {
                            throw ClientError.databaseError('failed to create object store');
                        }
                    }
                    if (!db.objectStoreNames.contains(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Delegations)) { 
                        const objectStore = db.createObjectStore(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Delegations, {keyPath: 'id'});
                        if (objectStore == null) {
                            throw ClientError.databaseError('failed to create object store');
                        }
                    }
                    
                } catch (e) {
                    console.log('error: ', e);
                    reject(e);
                }
            };
            
            openRequest.onerror = function() {
                console.error("Error", openRequest.error);
                reject(openRequest.error);
            };
        
            openRequest.onsuccess = function() {
                let db = openRequest.result;
                db.onversionchange = function() {
                    db.close();
                    reject(ClientError.databaseError("Database is outdated, please reload the page."));
                };
                self._db = db;
                SecuritySignalProtocolStoreIndexedDB.#indexedDB = db;
                resolve();
            };
        
            openRequest.onblocked = function() {
                // 如果我们正确处理了 onversionchange 事件，这个事件就不应该触发
        
                // 这意味着还有另一个指向同一数据库的连接
                // 并且在 db.onversionchange 被触发后，该连接没有被关闭
            };
        });
        return promise;
    }

    async close() {
        console.log('SecuritySignalProtocolStoreIndexedDB - close');
        const self = this;
        if (self._isTemp) {
            return;
        }
        if (!this._subAccount || this._subAccount === '') {
            return;
        }
        const instance = SecuritySignalProtocolStoreIndexedDB.#instanceMap[this._subAccount];
        if (instance) {
            delete SecuritySignalProtocolStoreIndexedDB.#instanceMap[this._subAccount];
        }
        if (Object.keys(SecuritySignalProtocolStoreIndexedDB.#instanceMap).length > 0) {
            return;
        }

        const promise = new Promise((resolve, reject) => {
            try {
                if (self._db) {
                    self._db.close();
                    self._db = null;
                }
                if (SecuritySignalProtocolStoreIndexedDB.#indexedDB) {
                    SecuritySignalProtocolStoreIndexedDB.#indexedDB = null;
                }
                resolve();
            } catch(e) {
                reject(e);
            }
        });
        return promise;
    }

    #indexedDBTransaction(storeNames, mode = 'readonly') {
        const transaction = this._db.transaction(storeNames, mode);
        return transaction;
    }
    #readOnlyStore(storeName) {
        const tx = this.#indexedDBTransaction(storeName);
        return tx.objectStore(storeName);
    }
    #readwriteStore(storeName) {
        const tx = this.#indexedDBTransaction(storeName, 'readwrite');
        return tx.objectStore(storeName);
    }

    #encodePreKey(preKey) {
        const encodedKp = {
            pubKey: ethers.encodeBase64(new Uint8Array(preKey.pubKey)),
            privKey: ethers.encodeBase64(new Uint8Array(preKey.privKey))
        }
        const encodedKpString = JSON.stringify(encodedKp);
        return encodedKpString;
    }

    #decodePreKey(encodedPreKey) {
        if (typeof encodedPreKey === 'undefined') {
            return encodedPreKey;
        }
        encodedPreKey = JSON.parse(encodedPreKey);
        const decodedKp = {
            pubKey: ethers.decodeBase64(encodedPreKey.pubKey).buffer,
            privKey: ethers.decodeBase64(encodedPreKey.privKey).buffer
        }
        return decodedKp;
    }

    #encodeSignedPreKey(signedPreKey) {
        return this.#encodePreKey(signedPreKey);
        // let encodedSignedPreKey = {
        //     keyId: signedPreKey.keyId,
        //     keyPair: this.#encodePreKey(signedPreKey.keyPair),
        //     signature: ethers.encodeBase64(new Uint8Array(signedPreKey.signature))
        // }
        // const encodedSignedPreKeyString = JSON.stringify(encodedSignedPreKey);
        // return encodedSignedPreKeyString;
    }
    #decodeSignedPreKey(encodedSignedPreKey) {
        return this.#decodePreKey(encodedSignedPreKey);
        // if (typeof encodedSignedPreKey === 'undefined') {
        //     return encodedSignedPreKey;
        // }
        // encodedSignedPreKey = JSON.parse(encodedSignedPreKey);
        // let signedPreKey = {
        //     keyId: encodedSignedPreKey.keyId,
        //     keyPair: this.#decodePreKey(encodedSignedPreKey.keyPair),
        //     signature: ethers.decodeBase64(encodedSignedPreKey.signature).buffer
        // }
        // return signedPreKey;
    }
    #encodeIdentityKey(identityKey) {
        const encodedIdentityKey = ethers.encodeBase64(new Uint8Array(identityKey));
        return encodedIdentityKey;
    }
    #decodeIdentityKey(encodedIdentityKey) {
        if (typeof encodedIdentityKey === 'undefined') {
            return encodedIdentityKey;
        }
        const identityKey = ethers.decodeBase64(encodedIdentityKey).buffer;
        return identityKey;
        // encodedIdentityKey = JSON.parse(encodedIdentityKey);
    }
    #encodeIdentifyKeyPair(identityKeyPair) {
        // {pubKey: ArrayBuffer(33), privKey: ArrayBuffer(32)}
        const encodedKp = {
            pubKey: ethers.encodeBase64(new Uint8Array(identityKeyPair.pubKey)),
            privKey: ethers.encodeBase64(new Uint8Array(identityKeyPair.privKey))
        }
        const encodedKpString = JSON.stringify(encodedKp);
        return encodedKpString;
    }

    #decodeIdentifyKeyPair(encodedIdentityKeyPair) {
        if (typeof encodedIdentityKeyPair === 'undefined') {
            return encodedIdentityKeyPair;
        }
        encodedIdentityKeyPair = JSON.parse(encodedIdentityKeyPair);

        const decodedKp = {
            pubKey: ethers.decodeBase64(encodedIdentityKeyPair.pubKey).buffer,
            privKey: ethers.decodeBase64(encodedIdentityKeyPair.privKey).buffer
        }
        return decodedKp;
    }

    async encryptMailPart(key, part) {
        const self = this;
        let plaintext = null;
        if (typeof part === 'undefined') {
            throw ClientError.invalidParameterError(Strings.error.client.enc_data_undefined);
        } else if (part === null) {
            throw ClientError.invalidParameterError(Strings.error.client.enc_data_null);
        } else if (typeof part === 'string') {
            const encoder = new TextEncoder();
            plaintext = encoder.encode(part);
        } else if (part.constructor === ArrayBuffer) {
            plaintext = new Uint8Array(part);
        } else if (part.constructor === Uint8Array) {
            plaintext = part;
        } else if (part.constructor === Array) {
            plaintext = Uint8Array.from(part);
        } else {
            throw ClientError.invalidDataTypeError(Strings.error.client.unsupported_data_type_1 + part + Strings.error.client.unsupported_data_type_2);
        }
        
        if (plaintext == null) {
            throw ClientError.invalidParameterError(Strings.error.client.plaintext_null);
        }

        const res = await self.encrypt(key, plaintext);
        return res;

    }

    async decryptMailPart(key, partCiphertext, asString=false) {
        const self = this;
        const plaintext = await self.decrypt(key, partCiphertext);
        if (asString) {
            const decoder = new TextDecoder();
            const plaintextString = decoder.decode(plaintext);
            return plaintextString;
        }
        return plaintext;
    }

    async #encrypt(plaintext) {
        // window.crypto.subtle.exportKey('jwk|raw', aKey).then(k => console.log(k))
        // window.crypto.subtle.importKey('jwk|raw', aKey, 'AES-CBC', true, ['encrypt', 'decrypt']).then(k => aKey)
        // window.crypto.subtle.generateKey({ name: "AES-CBC", length: 256}, true, ["encrypt", "decrypt", "wrapKey", "unwrapKey"])
        // const iv = window.crypto.getRandomValues(new Uint8Array(16));
        if (typeof plaintext !== 'string') {
            throw ClientError.invalidParameterError('plaintext must be string');
        }
        
        const encoder = new TextEncoder();
        plaintext = encoder.encode(plaintext);
    
        const key = this._encryptionKey;
        const iv = window.crypto.getRandomValues(new Uint8Array(12));
        // {name: 'AES-GCM', iv: iv, tagLength: 128, additionalData: ArrayBuffer,}
        const ciphertext = await window.crypto.subtle.encrypt(
            {
                name: 'AES-GCM', 
                iv: iv, 
                tagLength: 128
            }, 
            key, 
            plaintext
        );
        
        return {
            iv,
            ciphertext: new Uint8Array(ciphertext)
        };
    }

    async #decrypt({iv, ciphertext}) {
        if (ciphertext.constructor === Uint8Array) {
            ciphertext = ciphertext.buffer;
        }
        
        const key = this._encryptionKey;
        const plaintext = await window.crypto.subtle.decrypt(
            {
                name: 'AES-GCM', 
                iv: iv, 
                tagLength: 128
            }, 
            key, 
            ciphertext
        );
        const decoder = new TextDecoder();
        const plaintextString = decoder.decode(plaintext);
        return plaintextString;
    }


    get namespace() {
        if (!this._account || this._account.length === '') {
            return '';
        }
        return this._account + '.';
    }

    get subNamespace() {
        if (!this._subAccount || this._subAccount.length === '') {
            return '';
        }
        return this._subAccount + '.';
    }



    async get(storeName, key, defaultValue, namespace = null) {
        const self = this;
        const promise = new Promise((resolve, reject) => {
            if (key === null || key === undefined) reject(ClientError.invalidParameterError('Tried to get value for undefined/null key'));
            
            key = (namespace ? namespace : self.namespace) + key;
            
            const objectStore = self.#readOnlyStore(storeName);
            const request = objectStore.get(key);
     
            request.onerror = function(event) {
                console.log('event', event);
                console.error(request.error);
                reject(event);
            };
        
            request.onsuccess = function(event) {
                resolve((request.result ? request.result.value : null) || defaultValue)
            };
        });
        return promise;
        
    }



    async getAll(storeName, namespace = null) {
        const self = this;
        const promise = new Promise((resolve, reject) => {
            const objectStore = self.#readOnlyStore(storeName);
            const request = objectStore.getAll();
            request.onerror = function(event) {
                console.log('event', event);
                console.error(request.error);
                reject(event);
            };
            request.onsuccess = function(event) {
                let res = request.result || [];

                // key = (namespace ? namespace : self.namespace) + key;
                const ns = namespace ? namespace : self.namespace;
                if (ns && ns.length > 0) {
                    res = res.filter(o => {
                        return o.id.startsWith(ns);
                    })
                }
                resolve(res);
            };
        });
        return promise;
    }

    async remove(storeName, key, namespace = null) {
        const self = this;
        const promise = new Promise((resolve, reject) => {
            if (key === null || key === undefined) reject(ClientError.invalidParameterError('Tried to get value for undefined/null key'));
            
            // key = self.namespace + key;
            key = (namespace ? namespace : self.namespace) + key;

            const objectStore = self.#readwriteStore(storeName);
            const request = objectStore.delete(key);

            request.onerror = function(event) {
                console.log('event', event);
                console.error(request.error);
                reject(request.error);
            };
        
            request.onsuccess = function(event) {
                resolve();
            };
        });
        return promise;
    }


    async put(storeName, key, value, namespace = null) {
        const self = this;
        const promise = new Promise((resolve, reject) => {
            if (key === null || key === undefined) reject(ClientError.invalidParameterError('Tried to get value for undefined/null key'));
            
            // const key_with_namespace = self.namespace + key;
            // key = self.namespace + key;
            key = (namespace ? namespace : self.namespace) + key;
            
            const objectStore = self.#readwriteStore(storeName);
            const request = objectStore.put({id: key, value: value});
  
            request.onsuccess = function (event) {
                resolve()
            };
        
            request.onerror = function (event) {
                console.log('event', event);
                console.error(request.error);
                reject(request.error);
            }
        });
        return promise;
    }
    
    async put_without_namespace(storeName, key, value) {
        const self = this;
        const promise = new Promise((resolve, reject) => {
            if (key === null || key === undefined) reject(ClientError.invalidParameterError('Tried to get value for undefined/null key'));
            
            const objectStore = self.#readwriteStore(storeName);
            const request = objectStore.put({id: key, value: value});
  
            request.onsuccess = function (event) {
                resolve()
            };
        
            request.onerror = function (event) {
                console.log('event', event);
                console.error(request.error);
                reject(request.error);
            }
        });
        return promise;
    }

    async getAll_without_namespace(storeName) {
        const self = this;
        const promise = new Promise((resolve, reject) => {
            const objectStore = self.#readOnlyStore(storeName);
            const request = objectStore.getAll();
            request.onerror = function(event) {
                console.log('event', event);
                console.error(request.error);
                reject(event);
            };
            request.onsuccess = function(event) {
                resolve(request.result || [])
            };
        });
        return promise;
    }

    async get_without_namespace(storeName, key, defaultValue) {
        const self = this;
        const promise = new Promise((resolve, reject) => {
            if (key === null || key === undefined) reject(ClientError.invalidParameterError('Tried to get value for undefined/null key'));
            
            const objectStore = self.#readOnlyStore(storeName);
            const request = objectStore.get(key);
     
            request.onerror = function(event) {
                console.log('event', event);
                console.error(request.error);
                reject(event);
            };
        
            request.onsuccess = function(event) {
                resolve((request.result ? request.result.value : null) || defaultValue)
            };
        });
        return promise;
        
    }

    
    async remove_without_namespace(storeName, key) {
        const self = this;
        const promise = new Promise((resolve, reject) => {
            if (key === null || key === undefined) reject(ClientError.invalidParameterError('Tried to get value for undefined/null key'));
            
            const objectStore = self.#readwriteStore(storeName);
            const request = objectStore.delete(key);

            request.onerror = function(event) {
                console.log('event', event);
                console.error(request.error);
                reject(request.error);
            };
        
            request.onsuccess = function(event) {
                resolve()
            };
        });
        return promise;
    }

    async reset() {
        await this.close();

        const promise = new Promise((resolve, reject) => {
            const request = indexedDB.deleteDatabase(SecuritySignalProtocolStoreIndexedDB.#DBName);

            request.onsuccess = function (event) {
                resolve()
            };
        
            request.onerror = function (event) {
                console.log('event', event);
                console.error(request.error);
                reject(request.error);
            }
        });
        return promise;
    }


    /*
    getIdentityKeyPair: () => Promise<KeyPairType | undefined>;
    getLocalRegistrationId: () => Promise<number | undefined>;
    isTrustedIdentity: (identifier: string, identityKey: ArrayBuffer, direction: Direction) => Promise<boolean>;
    saveIdentity: (encodedAddress: string, publicKey: ArrayBuffer, nonblockingApproval?: boolean) => Promise<boolean>;
    loadPreKey: (encodedAddress: string | number) => Promise<KeyPairType | undefined>;
    storePreKey: (keyId: number | string, keyPair: KeyPairType) => Promise<void>;
    removePreKey: (keyId: number | string) => Promise<void>;
    storeSession: (encodedAddress: string, record: SessionRecordType) => Promise<void>;
    loadSession: (encodedAddress: string) => Promise<SessionRecordType | undefined>;
    loadSignedPreKey: (keyId: number | string) => Promise<KeyPairType | undefined>;
    storeSignedPreKey: (keyId: number | string, keyPair: KeyPairType) => Promise<void>;
    removeSignedPreKey: (keyId: number | string) => Promise<void>;
    */
    async getEncryptionKeyBundle(uid) {
        const key = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.EncryptionKeys, uid, null);
        if (!key) {
            return null;
        }
        // return key;
        
        const decryptedKey = await this.#decrypt(key);
        const decodedKey = JSON.parse(decryptedKey);
        return decodedKey;
    }
    async saveEncryptionKeyBundle(uid, key) {
        const encodedKey = JSON.stringify(key);
        const encryptedKey = await this.#encrypt(encodedKey);
        await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.EncryptionKeys, uid, encryptedKey);
    }
    async hasEncryptionKeyBundle(uid) {
        const key = await this.getEncryptionKeyBundle(uid);
        return Boolean(key);
    }

    async saveDeviceId(deviceId) {
        await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `deviceId`, deviceId);
    }
    async getDeviceId() {
        let deviceId = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `deviceId`, 1);
        return deviceId;
    }
    async getMyAddress() {
        const deviceId = await this.getDeviceId();
        const address = { account: this._account, deviceId: deviceId };
        return address;
    }
    async makeNextMessageId() {
        let baseMessageId = await this.getNextMessageId();
        const nextMessageId = baseMessageId + 1;
        await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `nextMessageId`, nextMessageId);
        return baseMessageId;
    }
    async getNextMessageId() {
        let baseMessageId = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `nextMessageId`, 1);
        return baseMessageId;
    }

    async makeKeyId() {
        let baseKeyId = await this.getNextKeyId();
        const nextKeyId = baseKeyId + 1;
        await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `nextKeyId`, nextKeyId);
        return baseKeyId;
    }

    async getNextKeyId() {
        let baseKeyId = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `nextKeyId`, 1);
        return baseKeyId;
    }


    async saveIdentityKeyPair(identityKeyPair) {
        const encodedIdentityKeyPair = this.#encodeIdentifyKeyPair(identityKeyPair)
        const encryptedIdentityKeyPair = await this.#encrypt(encodedIdentityKeyPair);
        await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `identityKey-me`, encryptedIdentityKeyPair);
    }

    async getIdentityKeyPair() {
        const identityKeyPair = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `identityKey-me`, undefined);
        // return identityKeyPair;
        if (!identityKeyPair) {
            return;
        }
        const decryptedIdentityKeyPair = await this.#decrypt(identityKeyPair);
        const decodedIdentityKeyPair = this.#decodeIdentifyKeyPair(decryptedIdentityKeyPair);
        return decodedIdentityKeyPair;
    }

    async saveLocalRegistrationId(registrationId) {
        await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `registrationId`, registrationId);
    }

    async getLocalRegistrationId() {
        const registrationId = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `registrationId`, undefined);
        if (typeof registrationId === 'undefined') {
            return registrationId
        } else if (typeof registrationId === 'number') {
            return registrationId;
        }
        throw ClientError.invalidDataTypeError('Stored Registration ID is not a number')
    }
    
    async identityKeyStatus(
        name,
        identityKey
    ) {
        if (name === null || name === undefined) {
            throw ClientError.invalidParameterError('tried to check identity key for undefined/null key')
        }
        const trusted = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Identities, name, undefined)
        // TODO: Is this right? If the ID is NOT in our store we trust it?
        if (trusted === undefined) {
            return IdentityKeyStatus.NotExist;
        }
        // const decodedTrusted = trusted;
        const decryptedTrusted = await this.#decrypt(trusted);
        const decodedTrusted = this.#decodeIdentityKey(decryptedTrusted);
        
        const isIdentical = arrayBufferToString(identityKey) === arrayBufferToString(decodedTrusted);
        if (isIdentical) {
            return IdentityKeyStatus.Unchanged;
        }
        return IdentityKeyStatus.Changed;
        // const trustedIdentityKey = base64.toByteArray(trusted) 
        // return Promise.resolve(() => {
        //     const isIdentical = arrayBufferToString(identityKey) === arrayBufferToString(trusted);
        //     if (isIdentical) {
        //         return IdentityKeyStatus.Unchanged;
        //     }
        //     return IdentityKeyStatus.Changed;

        // })
    }
    
    async isTrustedIdentity(
        name,
        identityKey,
        _direction
    ) {
        if (name === null || name === undefined) {
            throw ClientError.invalidParameterError('tried to check identity key for undefined/null key')
        }
        const trusted = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Identities, name, undefined)
        // TODO: Is this right? If the ID is NOT in our store we trust it?
        if (trusted === undefined) {
            return Promise.resolve(true)
        }
        // const decodedTrusted = trusted;
        const decryptedTrusted = await this.#decrypt(trusted);
        const decodedTrusted = this.#decodeIdentityKey(decryptedTrusted);

        // console.log('idkey: ', base64.encode(new Uint8Array(identityKey)), ', trusted: ', base64.encode(new Uint8Array(decodedTrusted)))
        // const trustedIdentityKey = base64.toByteArray(decodedTrusted) 
        return Promise.resolve(arrayBufferToString(identityKey) === arrayBufferToString(decodedTrusted))
    }

    async loadPreKey(keyId) {
        let res = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.PreKeys, keyId, undefined)
        
        if (typeof res === 'undefined') {
            return res
        }
        // else if (isKeyPairType(res)) {
        //  res = { pubKey: res.pubKey, privKey: res.privKey }
        //  return res  
        // }
        // throw ClientError.invalidDataTypeError(`stored key has wrong type`)

        res = await this.#decrypt(res);
        res = this.#decodePreKey(res);
        if (isKeyPairType(res)) {
            res = { pubKey: res.pubKey, privKey: res.privKey }
            return res
        }
        throw ClientError.invalidDataTypeError(`stored key has wrong type`)
    }

    async loadSession(identifier) {
        let rec = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Sessions, identifier, undefined, this.subNamespace)
        if (typeof rec === 'undefined' || rec === null) {
            return rec
        } 
        // else if (typeof rec === 'string') {
        //     return rec
        // }
        // throw ClientError.invalidDataTypeError(`session record is not an ArrayBuffer`)
        rec = await this.#decrypt(rec);
        if (typeof rec === 'string') {
            return rec
        }
        throw ClientError.invalidDataTypeError(`session record is not a string`)
    }

    async loadSignedPreKey(keyId) {
        let res = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.SignedPreKeys, keyId, undefined)
        if (typeof res === 'undefined') {
            return res
        } 
        // else if (isKeyPairType(res)) {// isSignedPreKeyType
        //     res = { pubKey: res.pubKey, privKey: res.privKey }
        //     return res;
        // }
        // throw ClientError.invalidDataTypeError(`stored key has wrong type`)
        res = await this.#decrypt(res);
        res = this.#decodeSignedPreKey(res);
        if (isKeyPairType(res)) {// isSignedPreKeyType
            res = { pubKey: res.pubKey, privKey: res.privKey }
            return res;
        }
        throw ClientError.invalidDataTypeError(`stored key has wrong type`);
    }
    
    async removePreKey(keyId) {
        await this.remove(SecuritySignalProtocolStoreIndexedDB.#StoreNames.PreKeys, keyId)
    }

    async saveIdentity(identifier, identityKey) {

        if (identifier === null || identifier === undefined)
            throw ClientError.invalidParameterError('Tried to put identity key for undefined/null key')

        const address = SignalProtocolAddress.fromString(identifier)

        let existing = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Identities, address.getName(), undefined)
        if (existing) {
            existing = await this.#decrypt(existing);
            existing = this.#decodeIdentityKey(existing)
        }

        const encodedIdentityKey = this.#encodeIdentityKey(identityKey);
        const encryptedIdentityKey = await this.#encrypt(encodedIdentityKey);

        await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Identities, address.getName(), encryptedIdentityKey)

        if (existing && !isArrayBuffer(existing)) {
            throw ClientError.invalidDataTypeError('Identity Key is incorrect type')
        }

        if (existing && arrayBufferToString(encryptedIdentityKey) !== arrayBufferToString(existing)) {
            return true
        } else {
            return false
        }
    }
    async removeIdentity(name) {
        await this.remove(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Identities, name);
    }
    
    async storeSession(identifier, record) {
        const encryptedRecord = await this.#encrypt(record);
        return await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Sessions, identifier, encryptedRecord, this.subNamespace)
    }

    async loadIdentityKey(identifier) {

        if (identifier === null || identifier === undefined) {
            throw ClientError.invalidDataTypeError('Tried to get identity key for undefined/null key')
        }

        let key = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Identities, identifier, undefined)

        if (typeof key === 'undefined') {
            return key
        }
        //  else if (isArrayBuffer(key)) {
        //     return key
        // }
        key = await this.#decrypt(key);

        const identityKey = this.#decodeIdentityKey(key);
        if (isArrayBuffer(identityKey)) {
            return identityKey;
        }
        throw ClientError.invalidDataTypeError(`Identity key has wrong type`)
    }

    async storePreKey(keyId, keyPair) {
        const encodedKeyPair = this.#encodePreKey(keyPair);
        const encryptedKeyPair = await this.#encrypt(encodedKeyPair);
        return await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.PreKeys, keyId, encryptedKeyPair);
    }

    // TODO: Why is this keyId a number where others are strings?
    async storeSignedPreKey(keyId, keyPair) {
        const encodedSignedPreKey = this.#encodeSignedPreKey(keyPair);
        const encryptedSignedPreKey = await this.#encrypt(encodedSignedPreKey);

        return await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.SignedPreKeys, keyId, encryptedSignedPreKey)
    }

    async removeSignedPreKey(keyId) {
        return await this.remove(SecuritySignalProtocolStoreIndexedDB.#StoreNames.SignedPreKeys, keyId)
    }

    async removeSession(identifier) {
        return await this.remove(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Sessions, identifier, this.subNamespace)
    }

    async removeAllSessions(identifier) {
        const sessions = await this.getAll(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Sessions, identifier, this.subNamespace);
        for (let i=0; i<sessions.length; i++) {
            if (sessions[i].id.startsWith(identifier + '.')) {
                await this.remove(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Sessions, sessions[i].id, this.subNamespace);  
            } 
        }
    }

    async saveContact(contact) {
        // const encodedContact = JSON.stringify(contact);
        return await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Contacts, contact.uuid, contact);
    }

    async deleteContact(uuid) {
        return await this.remove(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Contacts, uuid);
    }

    async getAllContacts() {
        const contacts = await this.getAll(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Contacts);
        return contacts;
    }

    async getUser(addr) {
        let user = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Users, addr, undefined)
        return user;
    }

    async saveUser(user) {
        // const encodedContact = JSON.stringify(contact);
        return await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Users, user.addr.toLowerCase(), user);
    }

    async deleteUser(addr) {
        return await this.remove(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Users, addr);
    }

    async getAllUsers() {
        const users = await this.getAll(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Users);
        return users;
    }

    async getEnterpriseProfile() {
        const profileStr = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `enterprise-profile`, undefined);
        if (!profileStr || profileStr.length === 0) {
            return null;
        }
        try {
            const profile = JSON.parse(profileStr);
            return profile;
        } catch (e) {
            console.error(e);
            return null;
        }
    }

    async saveEnterpriseProfile(profile) {
        if (!profile || profile.length === 0) {
            return;
        }

        try {
            const profileStr = JSON.stringify(profile);
            if(profileStr && profileStr.length > 0) {
                await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `enterprise-profile`, profileStr);
            }
        } catch (e) {
            console.error(e);
        }
    }


    async getNotifyTemplate() {
        const template = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `notify-template`, undefined) || '';
        return template;
    }
    async saveNotifyTemplate(template) {
        if (!template || template.length === 0) {
            return;
        }
        await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `notify-template`, template);
    }
    
    async getFolders(page, size) {
        await this.open();
        const folders = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `folders`, undefined);
        if (!folders || folders.length === 0) {
            return null;
        }
        return JSON.parse(folders);
    }

    async saveFolders(folders) {
        if (typeof folders === 'object') {
            folders = JSON.stringify(folders);
        }
        return await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `folders`, folders);
    }


    async getAddressTokenPassword() {
        await this.open();
        const password = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `address-token-pwd`, undefined);
        if (!password || password.length === 0) {
            return null;
        }
        return password;
    }
    async saveAddressTokenPassword(password) {
        if (!password || password.length === 0) {
            throw new Error('Invalid password');
        }
        return await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `address-token-pwd`, password);
    }

    async getDraft(type) {
        await this.open();
        const draft = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `mail-draft-${type}`, undefined);
        if (!draft) {
            return null;
        }
        return draft;
    }
    async hasDraft(type) {
        const draft = await this.getDraft(type);
        if (draft) {
            return true;
        }
        return false;
    }

    async saveDraft(type, draft) {
        if (!draft) {
            throw new Error('Invalid Draft');
        }
        return await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `mail-draft-${type}`, draft);
    }

    async removeDraft(type) {
        await this.remove(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `mail-draft-${type}`)
    }


    async getPlexiMailUIState() {
        await this.open();
        const contractThread = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `pleximail-state`, undefined);
        if (!contractThread) {
            return null;
        }
        return contractThread;
    }
    
    async savePlexiMailUIState(threadContext) {
        if (!threadContext) {
            throw new Error('Invalid threadContext');
        }
        await this.open();
        return await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `pleximail-state`, threadContext);
    }

    async removePlexiMailUIState() {
        const ct = await this.getPlexiMailUIState();
        if (ct) {
            await this.remove(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `pleximail-state`)
        }
    }

    /**
     * {default: '', templates: {id: template_object}}
     * @returns templates
     */
    async getContractTemplates() {
        await this.open();
        const templates = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `contract-templates`, undefined);
        if (!templates) {
            return {default: null, templates: {}};
        }
        return templates;
    }
    
    async saveContractTemplate(template) {
        if (!template) {
            throw new Error('Invalid template');
        }
        const result = await this.getContractTemplates();
        result.templates[template.id] = {id:template.id, content:template.content};
        if (template.isDefault) {
            result.default = template.id;
        }
        
        await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `contract-templates`, result);
    }

    async removeContractTemplate(id) {
        
        if (!id) {
            throw new Error('Invalid id');
        }
        
        const result = await this.getContractTemplates();
        if (result.templates[id]) {
            delete result.templates[id];
        }
        if (result.default === id) {
            result.default = null;
        }

        await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `contract-templates`, result);
    }

    async getAddresses(asArray=false) {
        await this.open();
        let addressesDB = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `pleximail-addresses`, undefined);
        if (!addressesDB) {
            addressesDB = {lastIndex: 0, addresses: {}};
            addressesDB.addresses[window.appConfig.recentActiveAccount] = {label: 'Main', path: 0};
        }
        // addressesDB = JSON.parse(await this.#decrypt(addressesDB));
        return asArray ? {lastIndex: addressesDB.lastIndex, addresses: Object.keys(addressesDB.addresses)} : addressesDB;
    }
    // async getAddressEntities(asArray=false) {
    //     await this.open();
    //     let addressesDB = await this.get(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `pleximail-addresses`, undefined);
    //     if (!addressesDB) {
    //         addressesDB = {lastIndex: 0, addresses: {}};
    //         addressesDB.addresses[window.appConfig.recentActiveAccount] = {label: 'Main', path: 0};
    //     }
    //     // addressesDB = JSON.parse(await this.#decrypt(addressesDB));
    //     return asArray ? {lastIndex: addressesDB.lastIndex, addresses: Object.keys(addressesDB.addresses)} : addressesDB;
    // }
    
    
    async saveAddress(address, opt) {
        if (!address) {
            throw new Error('Invalid address');
        }

        const addressesDB = await this.getAddresses()
        addressesDB.addresses[address] = opt;
        if (opt.path > addressesDB.lastIndex) {
            addressesDB.lastIndex = opt.path;
        }

        // const encryptedAddresses = await this.#encrypt(JSON.stringify(addressesDB));

        await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `pleximail-addresses`, addressesDB);
    }

    async removeAddress(address) {
        if (!address) {
            throw new Error('Invalid id');
        }
        if (address === window.appConfig.recentActiveAccount) {
            throw new Error('Master address could not be removed');
        }
        const result = await this.getAddresses();
        delete result.addresses[address];
        
        await this.put(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, `pleximail-addresses`, result);
    }

    // {id: 'alice@gmail.com', key: 'base64', delegation: 'base64'}
    async saveDelegation(delegation) {
        return await this.put_without_namespace(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Delegations, delegation.id, delegation);
    }

    async loadDelegation(email) {
        const delegation = await this.get_without_namespace(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Delegations, email, undefined);
        return delegation;
    }

    async deleteDelegation(email) {
        await this.remove_without_namespace(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Delegations, email)
    }

    async loadAllDelegations() {
        const res = await this.getAll_without_namespace(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Delegations);
        return res;
    }

    async exportAsJson(asString=true) {
        const self = this;
        await self.open();
        const promise = new Promise((resolve, reject) => {
            DBUtils.exportToJsonString(self._db, self._account, (err, json) => {
                self.close().then(() => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(json);
                    }
                }).catch((e) => {
                    reject(e);
                });
            }, asString);
        });
        return promise;
    }

    async importFromJson(json) {
        const self = this;
        await self.open();
        const promise = new Promise((resolve, reject) => {
            DBUtils.importFromJsonString(self._db, json, (err) => {
                if (err) {
                    reject(err);
                } else {
                    resolve();
                }
            });
        })/*.then(() => {
            return self.removeFolderCaches();
        })*/.then(()=>{
            return self.close();
        });
        return promise;
    }
    async removeFolderCaches() {
        const self = this;
        // await self.open();
        const results = (await self.getAll(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc)).filter(r => {
            return r.id.lastIndexOf('.folder') !== -1;
        }).map(r => {
            return r.id;
        });

        for(const result of results) {
            await self.remove_without_namespace(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, result);
        }

        // await self.close();
    }
    async clearDatabase() {
        const self = this;
        const promise = new Promise((resolve, reject) => {
            DBUtils.clearDatabase(self._db, (err) => {
                if (err) {
                    reject(err);
                } else {
                    resolve();
                }
            })
        })
        return promise;
    }
    
    async clearDatabaseFor(account) {
        const self = this;
        const promise = new Promise((resolve, reject) => {
            DBUtils.clearDatabaseWithKeyPrefix(self._db, account, (err) => {
                if (err) {
                    reject(err);
                } else {
                    resolve();
                }
            })
        })
        return promise;
        
    }
    

    static async clear() {

        const promise = new Promise((resolve, reject) => {
            // const openRequest = indexedDB.open(SecuritySignalProtocolStoreIndexedDB.#DBName, SecuritySignalProtocolStoreIndexedDB.#DBVersion);
                
            // openRequest.onerror = function() {
            //     console.error("Error", openRequest.error);
            //     reject(openRequest.error);
            // };
            // openRequest.onsuccess = function() {
            //     let db = openRequest.result;
            //     DBUtils.clearDatabase(db, (err) => {
            //         if (err) {
            //             reject(err);
            //         } else {
            //             resolve();
            //         }
            //     })
            //     resolve();
            // };
            const request = indexedDB.deleteDatabase(SecuritySignalProtocolStoreIndexedDB.#DBName);
            request.onerror = function() {
                console.error("Error", request.error);
                reject(request.error);
            };
            request.onsuccess = function() {
                resolve();
            };
        });
        return promise;
    } 
    static async #openDB() {

        const promise = new Promise((resolve, reject) => {

            const openRequest = indexedDB.open(SecuritySignalProtocolStoreIndexedDB.#DBName, SecuritySignalProtocolStoreIndexedDB.#DBVersion);
            openRequest.onupgradeneeded = function() {
                const db = openRequest.result;
                try {
                    if (!db.objectStoreNames.contains(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc)) { // 如果没有 “books” 数据
                        // const identitiesStore = db.createObjectStore('identities', {keyPath: 'id', autoIncrement: true}); // 创造它
                        const objectStore = db.createObjectStore(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc, {keyPath: 'id'}); // 创造它
                        if (objectStore == null) {
                            throw ClientError.databaseError('failed to create object store');
                        }
                        /* else {
                            const index = identitiesStore.createIndex('identifier_index', 'identifier');
                            if (index == null) {
                                console.log('failed to create index');
                            }
                        }*/
                    }
                    if (!db.objectStoreNames.contains(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Identities)) { // 如果没有 “books” 数据
                            // const identitiesStore = db.createObjectStore('identities', {keyPath: 'id', autoIncrement: true}); // 创造它
                            const objectStore = db.createObjectStore(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Identities, {keyPath: 'id'}); // 创造它
                            if (objectStore == null) {
                                throw ClientError.databaseError('failed to create object store');
                            }
                            /* else {
                                const index = identitiesStore.createIndex('identifier_index', 'identifier');
                                if (index == null) {
                                    console.log('failed to create index');
                                }
                            }*/
                    }
                    if (!db.objectStoreNames.contains(SecuritySignalProtocolStoreIndexedDB.#StoreNames.PreKeys)) { 
                        const objectStore = db.createObjectStore(SecuritySignalProtocolStoreIndexedDB.#StoreNames.PreKeys, {keyPath: 'id'});
                        if (objectStore == null) {
                            throw ClientError.databaseError('failed to create object store');
                        }
                    }
                    if (!db.objectStoreNames.contains(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Sessions)) { 
                        const objectStore = db.createObjectStore(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Sessions, {keyPath: 'id'});
                        if (objectStore == null) {
                            throw ClientError.databaseError('failed to create object store');
                        }
                    }
                    if (!db.objectStoreNames.contains(SecuritySignalProtocolStoreIndexedDB.#StoreNames.SignedPreKeys)) { 
                        const objectStore = db.createObjectStore(SecuritySignalProtocolStoreIndexedDB.#StoreNames.SignedPreKeys, {keyPath: 'id'});
                        if (objectStore == null) {
                            throw ClientError.databaseError('failed to create object store');
                        }
                    }
                    if (!db.objectStoreNames.contains(SecuritySignalProtocolStoreIndexedDB.#StoreNames.EncryptionKeys)) { 
                        const objectStore = db.createObjectStore(SecuritySignalProtocolStoreIndexedDB.#StoreNames.EncryptionKeys, {keyPath: 'id'});
                        if (objectStore == null) {
                            throw ClientError.databaseError('failed to create object store');
                        }
                    }
                    if (!db.objectStoreNames.contains(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Contacts)) { 
                        const objectStore = db.createObjectStore(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Contacts, {keyPath: 'id'});
                        if (objectStore == null) {
                            throw ClientError.databaseError('failed to create object store');
                        }
                    }
                    if (!db.objectStoreNames.contains(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Users)) { 
                        const objectStore = db.createObjectStore(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Users, {keyPath: 'id'});
                        if (objectStore == null) {
                            throw ClientError.databaseError('failed to create object store');
                        }
                    }
                    
                } catch (e) {
                    console.log('error: ', e);
                    reject(e);
                }
            };
            
            openRequest.onerror = function() {
                console.error("Error", openRequest.error);
                reject(openRequest.error);
            };
        
            openRequest.onsuccess = function() {
                let db = openRequest.result;
                db.onversionchange = function() {
                    db.close();
                    reject(ClientError.databaseError("Database is outdated, please reload the page."));
                };
                resolve(db);
            };
        
            openRequest.onblocked = function() {
                // 如果我们正确处理了 onversionchange 事件，这个事件就不应该触发
        
                // 这意味着还有另一个指向同一数据库的连接
                // 并且在 db.onversionchange 被触发后，该连接没有被关闭
            };
        });

        return await promise;
    }
    static async clearFor(account) {
        
        let db = await SecuritySignalProtocolStoreIndexedDB.#openDB();
        const promise1 = new Promise((resolve, reject) => {
            DBUtils.clearDatabaseWithKeyPrefix(db, account, (err) => {
                if (err) {
                    reject(err);
                } else {
                    resolve();
                }
            })
        });
        return  promise1;
    }

    static async isInitializedFor(account) {

        let db = await SecuritySignalProtocolStoreIndexedDB.#openDB();

        const promise = new Promise((resolve, reject) => {
        const key = `${account}.registrationId`;
        const tx = db.transaction([SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc], 'readonly');
        const objectStore = tx.objectStore(SecuritySignalProtocolStoreIndexedDB.#StoreNames.Misc);
            const request = objectStore.get(key);
            request.onerror = function(event) {
                console.log('event', event);
                console.error(request.error);
                reject(event);
            };
        
            request.onsuccess = function(event) {
                resolve((request.result ? request.result.value : null) || undefined)
            };
        });
        return await promise;

    }
}

export default SecuritySignalProtocolStoreIndexedDB;